import { NgIf } from '@angular/common';
import { Component, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { TableComponent, TableComponent as TableComponent_1 } from '@core/components/table/table.component';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { type Company, type CompanySearchModel } from '@core/models/company';
import { type TableConfig } from '@core/models/table';
import { ModalService } from '@core/services/modal.service';
import { type UserPermissions } from '@core/services/permission.service';
import { ToastrService } from '@core/services/toastr.service';
import { UserService } from '@core/services/user.service';

import { CompanyLogoComponent } from '../../core/components/company-logo/company-logo.component';
import { AppDropdownPositionDirective } from '../../core/directives/dropdown-position.directive';
import { DateFormatDistancePipe } from '../../core/pipes/date-format.pipe';
import { CompanyListsService } from '../company-lists.service';
import { AddCompanyToListModalComponent } from '../modals/add-company-to-list-modal/add-company-to-list-modal.component';
import { CreateListModalComponent } from '../modals/create-list-modal/create-list-modal.component';
import { DeleteListModalComponent } from '../modals/delete-list-modal/delete-list-modal.component';
import { RenameListModalComponent } from '../modals/rename-list-modal/rename-list-modal.component';
import { type CompanyList } from '../models/company-list';

@UntilDestroy()
@Component({
  selector: 'company-lists-page',
  templateUrl: './company-lists-page.component.html',
  styleUrls: ['./company-lists-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    TableComponent_1,
    NgbDropdown,
    AppDropdownPositionDirective,
    FocusableDirective,
    NgbDropdownToggle,
    NgbDropdownMenu,
    CompanyLogoComponent,
    TranslateModule,
    DateFormatDistancePipe,
  ],
})
export class CompanyListsPageComponent {
  private readonly userService = inject(UserService);
  private readonly modalService = inject(ModalService);
  private readonly companyListsService = inject(CompanyListsService);
  private readonly toastr = inject(ToastrService);
  @ViewChild('listsTable') listsTable: TableComponent;
  @ViewChild('companiesTable') companiesTable: TableComponent;

  public listTableConfig: TableConfig = {
    loadUrl: '/api/companiesLists/search',
    loadLimit: 10,
    defaultSort: 'name',
    defaultSortDirection: 'asc',
    searchParamConfig: { name: 'name' },
    buttons: [
      {
        label: 'companyLists.buttons.createNewList',
        class: 'btn btn-primary submit-btn company-lists-table_button',
        onClick: this.createList.bind(this),
      },
    ],
    filters: [],
    columns: [
      {
        name: 'name',
        label: 'companyLists.columns.listName',
        sortable: true,
        minWidth: '100px',
        maxWidth: '3fr',
      },
      {
        name: 'membersCount',
        label: 'companyLists.columns.companies',
        sortable: true,
        minWidth: '100px',
        maxWidth: '2fr',
      },
      {
        name: 'updatedAt',
        label: 'companyLists.columns.lastUpdated',
        sortable: true,
        minWidth: '100px',
        maxWidth: '2fr',
      },
      {
        name: 'actions',
        label: 'companyLists.columns.action',
        sortable: false,
        minWidth: '65px',
        maxWidth: '65px',
      },
    ],
  };

  public editTableConfig: TableConfig = null;
  public editedList: CompanyList = null;
  public selectedCompanies: Company[] = [];

  public companyLists: CompanyList[] = [];
  public companies: Company[] = [];

  public get permissions(): UserPermissions {
    return this.userService.permissions;
  }

  public createList(): void {
    this.modalService
      .open({
        content: CreateListModalComponent,
        options: {
          size: 'sm',
        },
      })
      .result.then((res) => {
        this.editList(res);
      })
      .catch(() => {});
  }

  public backToCompanyLists(): void {
    this.editedList = null;
    this.selectedCompanies = [];
    this.listsTable.loadData(true);
  }

  public areNoCompaniesSelected(): boolean {
    return this.selectedCompanies.length === 0;
  }

  public editList(list: CompanyList): void {
    this.editTableConfig = {
      loadUrl: `/api/companiesLists/${list.id}/search?By=name`,
      loadLimit: 10,
      searchParamConfig: { name: 'name' },
      selectable: true,
      buttons: [
        {
          label: 'companyLists.buttons.removeCompanies',
          class: 'btn btn-primary submit-btn company-lists-table_button company-lists-table_remove-company-btn',
          onClick: this.removeSelectedCompanies.bind(this),
          isDisabledFunc: this.areNoCompaniesSelected.bind(this),
        },
        {
          label: 'companyLists.buttons.addCompany',
          class: 'btn btn-primary submit-btn company-lists-table_button',
          onClick: this.addCompanyToList.bind(this),
        },
      ],
      filters: [],
      columns: [
        {
          name: 'companies',
          label: 'companyLists.columns.companies',
          sortable: true,
          minWidth: '220px',
          maxWidth: '1fr',
        },
        {
          name: 'actions',
          label: 'companyLists.columns.action',
          sortable: false,
          minWidth: '100px',
          maxWidth: '100px',
        },
      ],
    };
    this.editedList = list;
    this.selectedCompanies = [];
  }

  public removeSelectedCompanies(): void {
    this.companyListsService
      .removeCompaniesFromList(
        this.editedList.id,
        this.selectedCompanies.map((comp) => comp.id),
      )
      .pipe(
        map(() => {
          this.selectedCompanies = [];
          this.companiesTable.loadData(true);
          this.toastr.success('companyLists.messages.companiesRemoved');
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
      )
      .subscribe();
  }

  public removeCompany(company: Company): void {
    this.companyListsService
      .removeCompaniesFromList(this.editedList.id, [company.id])
      .pipe(
        untilDestroyed(this),
        map(() => {
          this.selectedCompanies = this.selectedCompanies.filter((comp) => comp.id !== company.id);
          this.companiesTable.loadData(true);
          this.toastr.success('companyLists.messages.companyRemoved');
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
      )
      .subscribe();
  }

  public addCompanyToList(): void {
    this.modalService
      .open({
        content: AddCompanyToListModalComponent,
        inputs: {
          list: this.editedList,
        },
      })
      .result.then(() => {
        this.companiesTable.loadData(true);
      })
      .catch(() => {
        this.companiesTable.loadData(true);
      });
  }

  public renameList(list: CompanyList): void {
    this.modalService
      .open({
        content: RenameListModalComponent,
        inputs: {
          listId: list.id,
          name: list.name,
        },
        options: {
          size: 'sm',
        },
      })
      .result.then(() => {
        this.listsTable.loadData(true);
      })
      .catch(() => {});
  }

  public deleteList(list: CompanyList): void {
    this.modalService
      .open({
        content: DeleteListModalComponent,
        inputs: {
          listId: list.id,
          name: list.name,
        },
        options: {
          size: 'sm',
        },
      })
      .result.then(() => {
        this.listsTable.loadData(true);
      })
      .catch(() => {});
  }

  public getCompanyNameString(company: CompanySearchModel): string {
    const address: string[] = [];

    if (company.address?.city) {
      address.push(company.address.city);
    }

    if (company.address?.state) {
      address.push(company.address.state);
    }

    return `${company.name}${address.length > 0 ? ' - ' : ''}${address.join(', ')}`;
  }
}

<div
  [class.hidden]="isCreatingCompany"
  class="modal-body"
>
  <button
    [appFocusable]
    class="close-btn"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <img
      appAssetSrc="assets/svg/common/cross.svg"
      alt="{{ 'common.buttons.close' | translate }}"
    />
  </button>

  <div class="company-selector-modal_header">
    <p>{{ title || 'companySelectorModal.searchForCompanies' | translate }}</p>
  </div>

  <div
    class="company-selector-modal_search"
    [ngClass]="{
      'has-selected-company': selectedCompany,
    }"
  >
    @if (selectedCompany) {
      <company-logo
        class="company-selector-modal_typeahead-logo"
        [company]="selectedCompany"
      >
      </company-logo>
    }

    <company-search
      #companySearch
      [(ngModel)]="searchValue"
      [onlyConnected]="true"
      (selectionChanged)="onTypeaheadSelect($event)"
    ></company-search>

    <div class="company-selector-modal_company-details-container">
      @if (isLoading) {
        <loader></loader>
      }

      @if (!isLoading && selectedCompany) {
        <company-info
          class="company-selector-modal_company-details"
          [company]="selectedCompany"
        >
        </company-info>
      }
    </div>

    @if (isSearching) {
      <div class="loader"></div>
    }
  </div>

  <div class="company-selector-modal_buttons">
    <button
      [appFocusable]
      [disabled]="isLoading || !selectedCompany"
      (click)="clearSearch()"
      class="btn decline-btn"
    >
      {{ 'common.buttons.clearSearch' | translate }}
    </button>
    @if (permissions.Company_CreateNewCompany) {
      <button
        [appBtnLoading]="isLoading"
        (click)="addNewCompany()"
        class="btn decline-btn"
      >
        {{ 'companySelectorModal.buttons.addNewCompany' | translate }}
      </button>
    }
    <button
      [appBtnLoading]="isLoading"
      [disabled]="!selectedCompany"
      (click)="confirmSelection()"
      class="btn btn-primary submit-btn"
    >
      {{ 'companySelectorModal.buttons.selectCompany' | translate }}
    </button>
  </div>
</div>

import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'websiteUrl',
  standalone: true,
})
export class WebsiteUrlPipe implements PipeTransform {
  public transform(value: string): string {
    let url = value;
    if (!url.match(/^https?:\/\//i)) {
      url = 'http://' + url;
    }
    return url;
  }
}

import { Component, forwardRef, HostBinding, inject, Injectable, Input, ViewEncapsulation } from '@angular/core';
import { type ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDatepickerI18n, type NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { format } from 'date-fns';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { DateService } from '@core/services/date.service';

const I18N_VALUES = {
  en: {
    weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },
};

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  private readonly language = 'en';

  constructor() {
    super();
  }

  public getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.language].weekdays[weekday - 1];
  }

  public getMonthShortName(month: number): string {
    return I18N_VALUES[this.language].months[month - 1];
  }

  public getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  public getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  public getWeekdayLabel(weekday: number): string {
    return this.getWeekdayShortName(weekday);
  }
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
    {
      provide: NgbDatepickerI18n,
      useClass: CustomDatepickerI18n,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FormsModule, NgbInputDatepicker, TranslateModule, FocusableDirective, AssetSrcDirective],
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input() placeholder = 'Enter time';

  @HostBinding('attr.disabled')
  public disabled = false;

  public dateString = '';
  private readonly dateService = inject(DateService);

  private _value: NgbDateStruct = null;

  public get value(): NgbDateStruct {
    return this._value;
  }

  public set value(val: NgbDateStruct) {
    this._value = val;

    const date = new Date(val.year, val.month - 1, val.day);
    this.dateString = this.dateService.format(date, {
      date: 'numeric',
    });
    const preformattedDate = format(date, 'MM/dd/yyyy');
    this.onChange(preformattedDate);
    this.onTouched();
  }

  public writeValue(value: string): void {
    if (!value) {
      return;
    }

    const date = new Date(value);
    this.dateString = this.dateService.format(date, {
      date: 'numeric',
    });

    this._value = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (val: string) => {};

  private onTouched = () => {};
}

<div class="company-information_info-container">
  <div class="company-information_info">
    <div
      class="company-information_info-block"
      id="companyDescription"
    >
      <div class="company-information_block-title">
        <span>{{ 'companyDetails.publicInfo' | translate }}</span>
        <app-tooltip [contentTemplate]="publicInfoTooltip">
          <div class="tooltip-icon"></div>
        </app-tooltip>
        <ng-template #publicInfoTooltip>
          <div>{{ 'companyDetails.tooltips.publicInfo' | translate }}</div>
        </ng-template>
      </div>

      <div class="company-information_block_content">
        <company-general-info
          (dataChanged)="onGeneralInfoChanged($event)"
          [company]="company"
          [disabled]="isLoading"
          [isEditable]="
            permissions.AllCompanies_PublicInfo_Edit || (permissions.Company_PublicInfo_Edit && isMyCompany)
          "
        >
        </company-general-info>
      </div>

      @if (company?.publicData?.relatedCompanies?.length > 0) {
        <div class="company-information_block_content">
          <div class="company-information_info-title">
            {{ 'companyRelationships.title' | translate }}
          </div>
          <company-relationships [data]="company.publicData.relatedCompanies"></company-relationships>
        </div>
      }
    </div>

    @if (showPrivateData) {
      <div class="company-information_info-block">
        <div class="company-information_block-title">
          <span>{{ 'companyDetails.privateInfo' | translate }}</span>
          <app-tooltip [contentTemplate]="privateInfoTooltip">
            <div class="tooltip-icon"></div>
          </app-tooltip>
          <ng-template #privateInfoTooltip>
            <div>{{ 'companyDetails.tooltips.privateInfo' | translate }}</div>
          </ng-template>
        </div>
        @if (company?.privateData?.sections?.length > 0) {
          <div class="company-information_block_content">
            <div class="company-information_block-data">
              @for (section of company.privateData.sections; track section) {
                <company-custom-info
                  [companyId]="company.id"
                  [customFieldsSchema]="customFieldsSchema"
                  [isEditable]="false"
                  [section]="section"
                >
                </company-custom-info>
              }
            </div>
          </div>
        }
        @if (company?.privateData?.sections?.length > 1) {
          <div class="company-information_block_content">
            @for (section of company.privateData.sections?.slice(1); track section) {
              <div class="company-information_block-data">
                @if (section.title) {
                  <div class="company-information_info-title">
                    {{ section.title }}
                  </div>
                }
                <div class="company-information_info-fields">
                  @for (field of section.fields; track field) {
                    <div>
                      <div class="detailed-info_detail-name">{{ field.title }}</div>
                      <div class="detailed-info_detail-value">
                        {{ field.value?.title || field.value || '-' }}
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        }
        @if (company?.privateData?.contacts?.length > 0) {
          <div class="company-information_block_content">
            <div class="company-information_info-title">
              {{ 'companyContacts.title' | translate }}
            </div>
            <company-contacts
              [data]="company.privateData.contacts"
              [isMyCompany]="isMyCompany"
            >
            </company-contacts>
          </div>
        }
        @if (company?.privateData?.locations?.length > 0) {
          <div class="company-information_block_content">
            <div class="company-information_info-title company-information_location-title">
              <span>{{ 'companyLocations.title' | translate }}</span>
              @if (permissions.AllCompanies_PrivateInfo_Edit || (permissions.Company_PrivateInfo_Edit && isMyCompany)) {
                <button
                  (click)="addLocation()"
                  class="btn btn-primary submit-btn"
                >
                  {{ 'companyDetails.buttons.addLocation' | translate }}
                </button>
              }
            </div>
            <company-locations
              (locationDeleted)="onLocationDeleted($event)"
              (locationEdited)="onLocationEdited($event)"
              [companyId]="company.id"
              [isEditable]="
                permissions.AllCompanies_PrivateInfo_Edit || (permissions.Company_PrivateInfo_Edit && isMyCompany)
              "
              [locations]="company.privateData.locations"
            >
            </company-locations>
          </div>
        }
      </div>
    }

    @if (showConfidentialData) {
      <div class="company-information_info-block">
        <div class="company-information_block-title">
          <span>{{ 'companyDetails.confidentialInfo' | translate }}</span>
          <app-tooltip [contentTemplate]="confInfoTooltip">
            <div class="tooltip-icon"></div>
          </app-tooltip>
          <ng-template #confInfoTooltip>
            <div>
              {{ 'companyDetails.tooltips.confidentialInfo' | translate }}
            </div>
          </ng-template>
        </div>
        @if (company?.confidentialData?.sections?.length > 0) {
          <div class="company-information_block_content">
            @for (section of company.confidentialData.sections; track section) {
              <company-custom-info
                [companyId]="company.id"
                [customFieldsSchema]="customFieldsSchema"
                [isEditable]="
                  permissions.AllCompanies_ConfidentialInfo_Edit ||
                  (permissions.Company_ConfidentialInfo_Edit && isMyCompany)
                "
                [saveFunc]="confidentialFieldsSaveFunc"
                [section]="section"
              >
              </company-custom-info>
            }
          </div>
        }
      </div>
    }

    @if (showProprietaryData) {
      <div class="company-information_info-block">
        <div class="company-information_custom-info-title">
          <div class="company-information_block-title">
            <span>{{ 'companyDetails.customInfo' | translate }}</span>
            <app-tooltip
              [contentTemplate]="propInfoTooltip"
              maxWidth="800"
            >
              <div class="tooltip-icon"></div>
            </app-tooltip>
            <ng-template #propInfoTooltip>
              <div class="company-information_custom-info-tooltip">
                <div>{{ 'companyDetails.tooltips.customInfo' | translate }}</div>
                <ul>
                  <li innerHtml="{{ 'companyDetails.tooltips.customInfoListItem1' | translate }}"></li>
                  <li innerHtml="{{ 'companyDetails.tooltips.customInfoListItem2' | translate }}"></li>
                </ul>
              </div>
            </ng-template>
          </div>
          @if (isMyCompany) {
            <div class="company-information_shared-with">
              <app-select
                (valueChanged)="onSharedCompanyChanged($event)"
                [(ngModel)]="selectedCompanyForCustomInfo"
                [options]="connectedCompanies"
                [returnFullOption]="true"
                placeholder="companyDetails.sharedWith"
              ></app-select>
            </div>
          }
        </div>
        @if (!isLoadingCustomInfo) {
          @for (data of customInfoSections; track data) {
            <div class="company-information_block_content">
              <company-custom-info
                [companyId]="company.id"
                [customFieldsSchema]="customFieldsSchema"
                [isEditable]="data.accessType === 'Internal' && permissions.Company_CustomInfo_Edit"
                [saveFunc]="internalFieldsSaveFunc"
                [section]="data"
                [showAccessType]="true"
              >
              </company-custom-info>
            </div>
          }
          @if (selectedCompanyForCustomInfo && hasSharedFiles) {
            <div class="company-information_block_content">
              <div class="company-information_info-title">
                {{ 'companyFiles.title' | translate }}
              </div>
              <company-files
                [masterCompanyId]="company.id"
                [sharedCompanyId]="
                  isMyCompany ? selectedCompanyForCustomInfo.value : userService.profile$.value.companyId
                "
                maxHeight="400"
              >
              </company-files>
            </div>
          }
        }
        @if (isLoadingCustomInfo) {
          <loader></loader>
        }
      </div>
    }

    @if (
      !isLoadingCustomInfo &&
      showProprietaryData &&
      isMyCompany &&
      selectedCompanyForCustomInfo &&
      !hasSharedFiles &&
      !customInfoSections?.length
    ) {
      <div class="company-information_hidden-data">
        <div>
          <img appAssetSrc="assets/svg/company-details/placeholder.svg" />
          <h3>
            {{
              'companyDetails.noSharedInfo'
                | translate
                  : {
                      companyName: selectedCompanyForCustomInfo.label,
                    }
            }}
          </h3>
        </div>
      </div>
    }

    @if (showProprietaryData && isMyCompany && !selectedCompanyForCustomInfo) {
      <div class="company-information_hidden-data">
        <div>
          <img appAssetSrc="assets/svg/company-details/placeholder.svg" />
          <h3>{{ 'companyDetails.wheresMyCompanyInfoTitle' | translate }}</h3>
          <div innerHtml="{{ 'companyDetails.needToSelectSharedCompany' | translate }}"></div>
        </div>
      </div>
    }

    @if (isDataHidden) {
      <div class="company-information_hidden-data">
        <div>
          <img appAssetSrc="assets/svg/company-details/placeholder.svg" />
          <h3>{{ 'companyDetails.wheresInfoTitle' | translate }}</h3>
          <div innerHtml="{{ 'companyDetails.needToBeConnected' | translate }}"></div>
        </div>
      </div>
    }
  </div>
</div>

<div class="company-lists-page_header">
  <ng-container *ngIf="editedList">
    <a
      (click)="backToCompanyLists()"
      class="back-link-btn company-lists-page_back-btn"
    >
      <span>{{ 'companyLists.backToLists' | translate }}</span>
    </a>
    <div>{{ editedList.name }}</div>
  </ng-container>
</div>

<div class="company-lists-page_body">
  <app-table
    #listsTable
    [(data)]="companyLists"
    [class.hidden]="editedList"
    [config]="listTableConfig"
    [placeholderTemplate]="placeholderTemplate"
    [template]="rowTemplate"
  >
    <ng-template
      #rowTemplate
      let-list
    >
      <div class="company-lists-table_list-name">
        <div>{{ list.name }}</div>
      </div>
      <div>
        <div *ngIf="list.membersCount === 1">
          {{ 'companyLists.oneCompany' | translate }}
        </div>
        <div *ngIf="list.membersCount !== 1">
          {{ 'companyLists.numberOfCompanies' | translate: { number: list.membersCount } }}
        </div>
      </div>
      <div>
        <div>{{ list.updatedAt || list.createdAt | ccDateFormatDistance: { addSuffix: true } }}</div>
      </div>
      <div class="table_actions">
        <div
          #dropdown="ngbDropdown"
          *ngIf="permissions.CompanyList_Create"
          [appDropdownPosition]="dropdown"
          class="dropdown"
          ngbDropdown
        >
          <span
            [appFocusable]
            class="dropdown-toggle"
            ngbDropdownToggle
          ></span>
          <div
            aria-labelledby="dropdown"
            ngbDropdownMenu
          >
            <button
              (click)="editList(list)"
              class="dropdown-item"
            >
              {{ 'common.buttons.edit' | translate }}
            </button>
            <button
              (click)="renameList(list)"
              class="dropdown-item"
            >
              {{ 'common.buttons.rename' | translate }}
            </button>
            <div class="dropdown-divider"></div>
            <button
              (click)="deleteList(list)"
              [disabled]="!permissions.CompanyList_Delete"
              class="dropdown-item"
            >
              {{ 'common.buttons.delete' | translate }}
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #placeholderTemplate>
      <div class="table_placeholder-message">
        <div>{{ 'common.strings.noMatchesFound' | translate }}</div>
      </div>
    </ng-template>
  </app-table>

  <app-table
    #companiesTable
    *ngIf="editedList"
    [(data)]="companies"
    [(selectedEntities)]="selectedCompanies"
    [config]="editTableConfig"
    [placeholderTemplate]="placeholderTemplate"
    [template]="companyRowTemplate"
  >
    <ng-template
      #companyRowTemplate
      let-company
    >
      <div class="company-lists-table_company-info">
        <company-logo [company]="company"></company-logo>
        <div>
          {{ getCompanyNameString(company) }}
        </div>
      </div>
      <div>
        <button
          (click)="removeCompany(company)"
          class="btn secondary-btn company-lists-table_button"
        >
          {{ 'common.buttons.remove' | translate }}
        </button>
      </div>
    </ng-template>

    <ng-template #placeholderTemplate>
      <div class="table_placeholder-message">
        <div>{{ 'common.strings.noMatchesFound' | translate }}</div>
      </div>
    </ng-template>
  </app-table>
</div>

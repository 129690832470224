<div class="modal-body">
  <h1>{{ 'deleteLocationModal.deleteLocation' | translate }}</h1>
  <p>{{ 'deleteLocationModal.deleteLocationMsg' | translate }}</p>
  <p class="f-bold">
    {{
      'deleteLocationModal.deleteLocationMsg2'
        | translate
          : {
              numberOfContacts: numberOfContacts,
              locationName: deletedLocation.name,
            }
    }}
  </p>
  <p>{{ 'deleteLocationModal.deleteLocationMsg3' | translate }}</p>

  <div class="delete-locaiton-modal_table">
    <app-table
      [(data)]="locations"
      [config]="tableConfig"
      [maxHeight]="400"
      [template]="rowTemplate"
    >
      <ng-template
        #rowTemplate
        let-location
      >
        <div (click)="selectLocation(location)">
          <app-radio [checked]="selectedLocation && location.id === selectedLocation.id"> </app-radio>
        </div>

        <div (click)="selectLocation(location)">
          <div>
            {{ location.name }}
          </div>
        </div>

        <div (click)="selectLocation(location)">
          <div>
            {{ location.type.title }}
          </div>
        </div>

        <div (click)="selectLocation(location)">
          <div>
            {{ location.globalLocationNumber }}
          </div>
        </div>

        <div (click)="selectLocation(location)">
          <div>
            {{ getAddressString(location) }}
          </div>
        </div>
      </ng-template>
    </app-table>
  </div>

  <div class="buttons-group">
    <button
      (click)="activeModal.dismiss()"
      [disabled]="isLoading"
      class="btn btn-primary decline-btn"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
    <button
      (click)="submitSelection()"
      [appBtnLoading]="isLoading"
      [disabled]="!selectedLocation"
      class="btn btn-primary submit-btn"
    >
      {{ 'common.buttons.continue' | translate }}
    </button>
  </div>
</div>

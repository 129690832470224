import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  standalone: true,
  imports: [NgStyle, TranslateModule],
})
export class ProgressBarComponent {
  @Input() completion: number;
  @Input() showPercent = true;
  @Input() label: string;
}

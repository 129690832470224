<div class="page-header">
  <h1>{{ 'networkPage.myNetwork' | translate }}</h1>
  <p>{{ 'networkPage.searchForBuyers' | translate }}</p>
</div>

<div class="network-page_body">
  <div class="tabs">
    <div
      [appFocusable]
      class="tabs_tab"
      [class.active]="activeTab === tabs.connections"
      [routerLink]="['/network-v2/accounts']"
    >
      {{ 'connections.title' | translate }}
    </div>
    @if (showInvitations) {
      <div
        [appFocusable]
        class="tabs_tab"
        [class.active]="activeTab === tabs.invitationsSent"
        [routerLink]="['/network-v2/invites/sent']"
      >
        {{ 'invitations.sentInvitations' | translate }}
      </div>
    }
    @if (showInvitations && showReceivedInvitations) {
      <div
        [appFocusable]
        class="tabs_tab"
        [class.active]="activeTab === tabs.invitationsReceived"
        [class.unread]="hasInvitations"
        [routerLink]="['/network-v2/invites/received']"
      >
        {{ 'invitations.receivedInvitations' | translate }}
      </div>
    }
    @if (showCompanyLists) {
      <div
        [appFocusable]
        class="tabs_tab"
        [class.active]="activeTab === tabs.companyLists"
        [routerLink]="['/network-v2/lists']"
      >
        {{ 'companyLists.myLists' | translate }}
      </div>
    }
  </div>
  @if (activeTab === tabs.connections) {
    <app-connections></app-connections>
  }
  @if (showInvitations && activeTab === tabs.invitationsSent) {
    <app-invitations invitationType="sent"> </app-invitations>
  }
  @if (showInvitations && showReceivedInvitations) {
    <app-invitations
      #invitationsReceived
      invitationType="received"
      [class.hidden]="activeTab !== tabs.invitationsReceived"
    >
    </app-invitations>
  }
  @if (activeTab === tabs.companyLists) {
    <company-lists-page></company-lists-page>
  }
</div>

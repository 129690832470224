<div
  class="tooltip_element"
  [appFocusable]
>
  <ng-content></ng-content>
</div>

@if (isHovered) {
  <div
    class="tooltip_triangle"
    [class.tooltip-black]="theme === 'black'"
  ></div>
}
<div class="tooltip_content">
  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</div>

import { Injectable, inject } from '@angular/core';
import { type ActivatedRouteSnapshot, type RouterStateSnapshot } from '@angular/router';
import { type Observable } from 'rxjs';

import { Company } from '@core/models/company';

import { NetworkService } from '../network.service';

@Injectable()
export class CompanyDetailsResolver {
  private readonly networkService = inject(NetworkService);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.networkService.getCompany(route.params.id);
  }
}

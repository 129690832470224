import { Component, Input, ViewEncapsulation, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { TableComponent } from '@core/components/table/table.component';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { type TableConfig } from '@core/models/table';
import { StringHelpersService } from '@core/services/string-helpers.service';
import { ToastrService } from '@core/services/toastr.service';
import { RadioComponent } from '@design/forms/radio/radio.component';

import { CompanyLocation } from '../../../core/models/location';
import { NetworkService } from '../../network.service';

@UntilDestroy()
@Component({
  selector: 'delete-location-modal',
  templateUrl: './delete-location-modal.component.html',
  styleUrls: ['./delete-location-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TableComponent, RadioComponent, FormsModule, ButtonLoadingDirective, TranslateModule],
})
export class DeleteLocationModalComponent {
  private readonly stringHelpers = inject(StringHelpersService);
  public readonly activeModal = inject(NgbActiveModal);
  private readonly toastr = inject(ToastrService);
  private readonly networkService = inject(NetworkService);
  @Input() companyId: number;
  @Input() locations: CompanyLocation[] = [];
  @Input() deletedLocation: CompanyLocation;
  @Input() numberOfContacts: number;

  public selectedLocation: CompanyLocation;
  public isLoading = false;

  public tableConfig: TableConfig = {
    columns: [
      {
        name: 'selection',
        label: 'common.buttons.select',
        sortable: false,
        minWidth: '50px',
        maxWidth: '50px',
      },
      {
        name: 'name',
        label: 'common.strings.name',
        sortable: true,
        minWidth: '150px',
        maxWidth: '1fr',
        manualSortValueFunc: (location) => {
          return location.name;
        },
      },
      {
        name: 'location',
        label: 'companyLocations.type',
        sortable: true,
        minWidth: '150px',
        maxWidth: '1fr',
        manualSortValueFunc: (location) => {
          return location.type.title;
        },
      },
      {
        name: 'gln',
        label: 'companyLocations.gln',
        sortable: true,
        minWidth: '150px',
        maxWidth: '1fr',
        manualSortValueFunc: (location) => {
          return location.globalLocationNumber || 0;
        },
      },
      {
        name: 'address',
        label: 'common.strings.address',
        sortable: true,
        minWidth: '300px',
        maxWidth: '2fr',
        manualSortValueFunc: (location) => {
          return this.getAddressString(location);
        },
      },
    ],
    hideControls: true,
  };

  public getAddressString(location: any): string {
    return this.stringHelpers.getAddressString(location.address);
  }

  public selectLocation(location: CompanyLocation): void {
    this.selectedLocation = location;
  }

  public submitSelection(): void {
    this.isLoading = true;
    this.networkService
      .deleteLocationAndReassignContacts(this.companyId, this.deletedLocation.id, this.selectedLocation.id)
      .pipe(
        untilDestroyed(this),
        map(() => {
          this.activeModal.close(this.selectedLocation);
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }
}

<scrollable-area
  (scrollDown)="loadCompanies()"
  [scrollActivationPercent]="70"
>
  @for (company of foundCompanies; track company) {
    <div class="existing-companies-search_company">
      <company-logo
        class="existing-companies-search_company-logo"
        [company]="company"
      ></company-logo>
      <div class="existing-companies-search_company-info">
        <div>
          @if (!company.isApproved) {
            <div
              [appFocusable]
              (click)="showPendingApprovalModal()"
              class="existing-companies-search_company-name"
            >
              {{ company.name }}
            </div>
          }
          @if (company.isApproved) {
            <a
              [appFocusable]
              target="_blank"
              [routerLink]="['/network-v2/company/' + company.id]"
              class="existing-companies-search_company-name"
            >
              {{ company.name }}
            </a>
          }
        </div>
        <div class="existing-companies-search_company-description">
          {{ getDescription(company) }}
        </div>
        <div class="existing-companies-search_company-address">
          {{ getCompanyDetailsString(company) }}
        </div>
      </div>
      <div class="existing-companies-search_company-actions">
        @if (
          company.connectionStatus === 'NotConnected' &&
          company.isApproved &&
          (permissions.Company_Connect || isIndividualCompany) &&
          nonConnectedCompanyAction === 'connect'
        ) {
          <!-- Company is not connected and user is able to connect -->
          <button
            [appFocusable]
            class="existing-companies-search_connect-btn"
            (click)="connect(company)"
          >
            <img
              appAssetSrc="assets/svg/common/connect.svg"
              alt=""
            />
            <span>{{ 'common.buttons.connect' | translate }}</span>
          </button>
        } @else {
          <!-- No need to connect -->
          @if (isSelecting) {
            <!-- Select mode -->
            <button
              class="btn btn btn-primary submit-btn existing-companies-search_view-btn"
              (click)="selectCompany(company)"
            >
              {{ 'common.buttons.select' | translate }}
            </button>
          } @else if (company.isApproved) {
            <!-- View in new browser window (only if the company is approved) -->
            <a
              [appFocusable]
              class="btn btn btn-primary submit-btn existing-companies-search_view-btn"
              target="_blank"
              [href]="'network-v2/company/' + company.id"
            >
              <span>{{ 'common.buttons.view' | translate }}</span>
            </a>
          }
        }
      </div>
    </div>
  }
</scrollable-area>

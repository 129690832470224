import { Component, Input, ViewChild, ViewEncapsulation, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { type SelectOption } from '@core/components/select/select.component';
import { TableComponent, TableComponent as TableComponent_1 } from '@core/components/table/table.component';
import { type TableConfig } from '@core/models/table';
import { DateFormatPipe } from '@core/pipes/date-format.pipe';
import { ConfigService } from '@core/services/config.service';
import { EnumService } from '@core/services/enum.service';
import { CheckboxComponent } from '@design/forms/checkbox/checkbox.component';

import { type CompanyFile } from '../../models/company-file';

@Component({
  selector: 'company-files',
  templateUrl: './company-files.component.html',
  styleUrls: ['./company-files.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CheckboxComponent, FormsModule, TableComponent_1, TranslateModule, DateFormatPipe],
})
export class CompanyFilesComponent implements OnInit {
  private readonly enumService = inject(EnumService);
  @Input() masterCompanyId: number;
  @Input() sharedCompanyId: number;
  @Input() loadUrl: string;
  @Input() hideControls = false;
  @Input() maxHeight: number;
  @Input() isProductFiles = false;
  @ViewChild('table') table: TableComponent;
  public tableConfig: TableConfig;
  public files: CompanyFile[] = [];
  private fileCategories: SelectOption[] = [];
  private _hideArchived = true;

  public get hideArchived(): boolean {
    return this._hideArchived;
  }

  public set hideArchived(value: boolean) {
    this._hideArchived = value;
    this.generateConfig();
  }

  public ngOnInit(): void {
    this.generateConfig();

    this.enumService.getFileCategories().then((res) => (this.fileCategories = res));
  }

  public getCategoryString(file: CompanyFile): string {
    const category = this.fileCategories.find((cat) => cat.key === file.fileCategory);
    return category ? category.title : file.fileCategory;
  }

  public getDownloadUrl(file: CompanyFile): string {
    return `${ConfigService.settings.apiUrl}/api/files/${file.fileDownloadToken}`;
  }

  private generateConfig(): void {
    this.tableConfig = {
      loadUrl:
        this.loadUrl ||
        `api/companies/${this.masterCompanyId}/connectedCompanies/${this.sharedCompanyId}/files?includeArchived=${!this
          ._hideArchived}`,
      columns: [
        {
          name: 'name',
          label: 'common.strings.name',
          sortable: true,
          minWidth: '250px',
          maxWidth: '3fr',
        },
        {
          name: this.isProductFiles ? 'file_category' : 'fileCategory',
          label: 'companyFiles.category',
          sortable: true,
          minWidth: '150px',
          maxWidth: '2fr',
        },
        {
          name: this.isProductFiles ? 'created_at' : 'createdAt',
          label: 'companyFiles.created',
          sortable: true,
          minWidth: '100px',
          maxWidth: '1fr',
        },
      ],
      hideControls: this.hideControls,
    };
  }
}

import { NgClass } from '@angular/common';
import { Component, Input, inject, output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { Company } from '@core/models/company';
import { ToastrService } from '@core/services/toastr.service';

import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { NetworkService } from '../../network.service';

@UntilDestroy()
@Component({
  selector: 'app-company-header',
  templateUrl: './company-header.component.html',
  styleUrls: ['./company-header.component.scss'],
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormErrorMessagesComponent,
    ButtonLoadingDirective,
    TranslateModule,
  ],
})
export class CompanyHeaderComponent {
  private readonly networkService = inject(NetworkService);
  private readonly toastr = inject(ToastrService);
  @Input() company: Company;
  @Input() isEditable: boolean;
  @Input() disabled: boolean;
  dataChanged = output();

  public isEdit = false;
  public form: UntypedFormGroup;
  public isFormSubmitted = false;
  public isLoading = false;

  public cancel(): void {
    this.isEdit = false;
  }

  public edit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.company.name, [Validators.required]),
      description: new UntypedFormControl(this.company.publicData?.description, [Validators.required]),
    });

    this.isEdit = true;
  }

  public save(): void {
    this.isFormSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    const formValue = this.form.value;
    this.isLoading = true;
    this.networkService
      .updateCompanyFields(this.company.id, formValue)
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.isEdit = false;
          this.dataChanged.emit(res);
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }
}

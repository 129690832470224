<div class="progress-bar_bar">
  <div class="progress-bar_completion">
    <span [ngStyle]="{ width: completion + '%' }"></span>
  </div>
  @if (label) {
    <div class="progress-bar_progress-label">
      {{ label | translate }}
    </div>
  }
</div>
@if (showPercent) {
  <div class="progress-bar_completion-percent">{{ completion }}%</div>
}

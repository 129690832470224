<app-table
  [config]="tableConfig"
  [template]="rowTemplate"
  [(data)]="contacts"
  [maxHeight]="920"
  [shrinkHeight]="true"
>
  <ng-template
    #rowTemplate
    let-contact
  >
    <div>
      @if (isCurrentUser(contact)) {
        <div class="company-contacts_current-user">
          <div class="company-contacts_contact-logo">
            <user-logo [user]="contact"></user-logo>
          </div>
          <div class="company-contacts_user-info">
            <div>{{ contact.firstName }} {{ contact.lastName }}</div>
            <div>{{ contact.title }}</div>
          </div>
        </div>
      }

      @if (!isCurrentUser(contact)) {
        <div
          ngbDropdown
          container="body"
          placement="bottom-left"
          class="dropdown company-contacts_dropdown"
        >
          <div
            [appFocusable]
            ngbDropdownToggle
            class="dropdown-toggle"
          >
            <div class="company-contacts_contact-logo">
              <user-logo [user]="contact"></user-logo>
            </div>
            <div class="company-contacts_user-info">
              <div>{{ contact.firstName }} {{ contact.lastName }}</div>
              <div>{{ contact.title }}</div>
            </div>
          </div>
          <div
            ngbDropdownMenu
            class="company-contacts_dropdown-menu"
            aria-labelledby="dropdown"
          >
            <button
              [disabled]="!permissions.Task_Assign || isMyCompany"
              class="dropdown-item"
              (click)="assignTask(contact)"
            >
              {{ 'common.buttons.assignTask' | translate }}
            </button>
            <button
              [disabled]="!permissions.Conversation_Read"
              class="dropdown-item"
              (click)="chat(contact)"
            >
              {{ 'companyContacts.buttons.chat' | translate }}
            </button>
            <div class="dropdown-divider"></div>
            @if (!isContactFavorite(contact)) {
              <button
                [disabled]="!permissions.FavoriteContact_Manage || isMyCompany"
                class="dropdown-item"
                (click)="addToFavorites(contact)"
              >
                {{ 'companyContacts.buttons.addToFavorites' | translate }}
              </button>
            }
            @if (isContactFavorite(contact)) {
              <button
                [disabled]="!permissions.FavoriteContact_Manage || isMyCompany"
                class="dropdown-item"
                (click)="removeFromFavorites(contact)"
              >
                {{ 'companyContacts.buttons.removeFromFavorites' | translate }}
              </button>
            }
          </div>
        </div>
      }
    </div>

    <div>
      <div>
        {{ contact.location?.name }}
      </div>
    </div>

    <div>
      <div>
        {{ contact.workEmail }}
      </div>
    </div>
  </ng-template>
</app-table>

@if (!isFullDataVisible) {
  <button
    class="btn btn-link company-contacts_more-btn"
    (click)="showAll()"
  >
    {{ 'companyContacts.showMore' | translate: { number: data?.length - contacts.length } }}
  </button>
}

<div class="modal-header"></div>

<div class="modal-body">
  <h1>{{ 'companyLists.createNewList' | translate }}</h1>

  <form
    autocomplete="off"
    [formGroup]="form"
    #ngForm="ngForm"
    (submit)="createList()"
  >
    <div
      class="form-group"
      [ngClass]="{ 'has-error': ngForm.submitted && form.get('name').invalid }"
    >
      <label
        class="required"
        for="wfName"
        >{{ 'companyLists.controls.listName.label' | translate }}</label
      >
      <input
        ngbAutofocus
        autocomplete="false"
        type="text"
        formControlName="name"
        placeholder="{{ 'companyLists.controls.listName.placeholder' | translate }}"
      />
      <form-error-messages
        [showErrors]="ngForm.submitted"
        [customErrors]="customErrors"
        [control]="form.get('name')"
      >
      </form-error-messages>
    </div>

    <div class="buttons-group">
      <button
        class="btn btn-primary decline-btn"
        [disabled]="isLoading"
        (click)="activeModal.dismiss()"
      >
        {{ 'common.buttons.cancel' | translate }}
      </button>
      <button
        type="submit"
        [appBtnLoading]="isLoading"
        class="btn btn-primary submit-btn"
      >
        {{ 'companyLists.buttons.createList' | translate }}
      </button>
    </div>
  </form>
</div>

<app-table
  #table
  [config]="tableConfig"
  [template]="rowTemplate"
  [shrinkHeight]="false"
  [placeholderTemplate]="placeholderTemplate"
  [(data)]="data"
>
  <ng-template
    #rowTemplate
    let-product
  >
    <div>
      <product-logo [product]="product"></product-logo>
    </div>
    <div class="products_product-name">
      <span>{{ product.name }}</span>
    </div>
    <div>
      <span>
        {{ product.internalItemNumber }}
      </span>
    </div>
    <div>
      <span>
        {{ product.globalTradeIdentificationNumber }}
      </span>
    </div>
    <div>
      <span>
        {{ product.internalVendorName || product.vendorCompanyName }}
      </span>
    </div>
  </ng-template>
  <ng-template #placeholderTemplate>
    <div class="table_placeholder-message">
      <div>{{ 'common.strings.noMatchesFound' | translate }}</div>
    </div>
  </ng-template>
</app-table>

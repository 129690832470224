<div class="sectors-selector_selected-area">
  @if (!value?.length && placeholder) {
    <div class="sectors-selector_placeholder">
      {{ placeholder }}
    </div>
  }
  @if (value && value.length) {
    <scrollable-area>
      <div class="sectors-selector_selected-sectors">
        @for (category of value; track category) {
          <div>
            <span>{{ category.title }}</span>
            <img
              [appFocusable]
              (click)="removeCategory(category)"
              appAssetSrc="assets/svg/common/cross-blue.svg"
            />
          </div>
        }
      </div>
    </scrollable-area>
  }
</div>
<div
  [appFocusable]
  class="sectors-selector_add-btn"
  [attr.disabled]="disabled ? true : null"
  (click)="addNew()"
>
  {{ 'productCategoriesSelector.addNewCategory' | translate }}
</div>

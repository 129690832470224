<button
  (click)="activeModal.dismiss()"
  [appFocusable]
  aria-label="Close"
  class="close-btn"
>
  <img
    alt="{{ 'common.buttons.close' | translate }}"
    appAssetSrc="assets/svg/common/cross.svg"
  />
</button>

<div class="modal-body">
  <div class="task-history-modal_task">
    <div class="task-history-modal_progress-circle">
      <nz-progress
        [nzFormat]="percentageFormatter"
        [nzPercent]="task.completion"
        [nzStrokeColor]="color(task.completion)"
        nzType="circle"
        nzWidth="80"
      >
      </nz-progress>
    </div>
    <div class="task-history-modal_task-info">
      <h3>{{ task.name }}</h3>
      @if (task.assignedByCompanyProduct) {
        <div>
          <span>
            {{ 'tasksPage.assignedTo' | translate: { company: task.assignedByCompanyProduct?.name } }}
          </span>
          |
          <span>
            {{ task.assignedAt | ccDateFormat: { date: 'long' } }}
          </span>
        </div>
      }
      <div class="task-history-modal_task-status">
        {{ stringHelpers.getTaskStatusString(task) | translate }}
      </div>
    </div>
    <div
      [class.disabled]="disableTaskResult(task.status) || isButtonLoading"
      class="task-history-modal_task-results"
    >
      <app-button
        (click)="openTaskResults()"
        [disabled]="disableTaskResult(task.status)"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Primary"
      >
        Task results
        <i
          class="icon-external"
          slot="end-icon"
        ></i>
      </app-button>
    </div>
  </div>

  <div class="task-history-modal_history-container">
    @if (isLoading) {
      <loader></loader>
    }

    @if (!isLoading) {
      <scrollable-area (scrollDown)="onScrollDown()">
        <div class="task-history-modal_history">
          @for (event of history; track event; let index = $index) {
            <div class="task-history-modal_event">
              @if (index !== 0) {
                <div class="task-history-modal_timeline upper-part"></div>
              }
              @if (index !== history.length - 1) {
                <div class="task-history-modal_timeline lower-part"></div>
              }
              @switch (getTimelineIcon(event)) {
                @case (iconTypes.UserLogo) {
                  <user-logo [user]="$any(event.assignedTo?.users[0] || event.performer)"> </user-logo>
                }
                @case (iconTypes.StepCompleted) {
                  <div class="task-history-modal_step-icon completed"></div>
                }
                @case (iconTypes.StepStarted) {
                  <div class="task-history-modal_step-icon empty"></div>
                }
                @default {
                  <div class="task-history-modal_step-icon completed"></div>
                }
              }
              @if (event.type === 'TaskStarted') {
                <div>
                  <div>
                    {{ 'tasksHistoryModal.taskStartedBy' | translate }}
                    <span class="f-bold">
                      {{ event?.performer?.firstName }}
                      {{ event?.performer?.lastName }} -
                      {{ event?.performer?.title }}
                    </span>
                  </div>
                  <div>
                    {{ event.createdAt | ccDateFormatRelative: { capitalizeFirstLetter: true } }}
                  </div>
                </div>
              }
              @if (event.type === 'TaskAccepted') {
                <div>
                  <div>
                    {{ 'tasksHistoryModal.taskAcceptedBy' | translate }}
                    <span class="f-bold">
                      {{ event?.performer?.firstName }}
                      {{ event?.performer?.lastName }} -
                      {{ event?.performer?.title }}
                    </span>
                  </div>
                  <div>{{ event.createdAt | ccDateFormatRelative: { capitalizeFirstLetter: true } }}</div>
                </div>
              }
              @if (event.type === 'TaskClosed') {
                <div>
                  <div>
                    {{ 'tasksHistoryModal.taskClosedBy' | translate }}
                    <span class="f-bold">
                      {{ event?.performer?.firstName }}
                      {{ event?.performer?.lastName }} -
                      {{ event?.performer?.title }}
                    </span>
                  </div>
                  <div>{{ event.createdAt | ccDateFormatRelative: { capitalizeFirstLetter: true } }}</div>
                </div>
              }
              @if (event.type === 'TaskCompleted') {
                <div>
                  <div>
                    {{ 'tasksHistoryModal.taskCompletedBy' | translate }}
                    <span class="f-bold">
                      {{ event?.performer?.firstName }}
                      {{ event?.performer?.lastName }} -
                      {{ event?.performer?.title }}
                    </span>
                  </div>
                  <div>{{ event.createdAt | ccDateFormatRelative: { capitalizeFirstLetter: true } }}</div>
                </div>
              }
              @if (event.type === 'ProductTaskCopied') {
                <div>
                  <div>
                    @if (event?.copiedFromProduct?.name) {
                      <span
                        innerHtml="{{
                          'tasksHistoryModal.productTaskCopiedBy'
                            | translate: { productName: event?.copiedFromProduct?.name }
                        }}"
                      >
                      </span>
                    }
                    @if (!event?.copiedFromProduct?.name) {
                      <span>
                        {{ 'tasksHistoryModal.taskCopiedBy' | translate }}
                      </span>
                    }
                    <span class="f-bold">
                      {{ event?.performer?.firstName }}
                      {{ event?.performer?.lastName }} -
                      {{ event?.performer?.title }}
                    </span>
                  </div>
                  <div>{{ event.createdAt | ccDateFormatRelative: { capitalizeFirstLetter: true } }}</div>
                </div>
              }
              @if (event.type === 'TaskAssigned' || event.type === 'TaskReassigned') {
                <div>
                  <div>
                    <span>
                      {{
                        (event.type === 'TaskAssigned'
                          ? 'tasksHistoryModal.assignedTo'
                          : 'tasksHistoryModal.reassignedTo'
                        ) | translate
                      }}
                    </span>
                    <span class="f-bold">
                      {{ getAssignedToString(event) }}
                    </span>
                  </div>
                  <div>{{ event.createdAt | ccDateFormatRelative: { capitalizeFirstLetter: true } }}</div>
                </div>
              }
              @if (event.type === 'StepStarted' || event.type === 'StepSubmitted' || event.type === 'StepRestarted') {
                <div>
                  @if (event.type === 'StepStarted') {
                    <span>
                      {{ 'tasksHistoryModal.started' | translate }}
                    </span>
                  }
                  @if (event.type === 'StepSubmitted') {
                    <span>
                      {{ 'tasksHistoryModal.completed' | translate }}
                    </span>
                  }
                  @if (event.type === 'StepRestarted') {
                    <span>
                      {{ 'tasksHistoryModal.restarted' | translate }}
                    </span>
                  }
                  <span>
                    {{
                      'taskWizard.stepNumber'
                        | translate
                          : {
                              stepNumber: event.sequenceStepNumber,
                              totalSteps: event.totalStepNumber,
                            }
                    }}
                    -
                  </span>
                  @if (event.stepName) {
                    <span class="f-bold">{{ event.stepName }} - </span>
                  }
                  @if (!event.stepName && event.sequenceStepNumber === event.totalStepNumber) {
                    <span class="f-bold"> {{ 'tasksHistoryModal.finishStep' | translate }} - </span>
                  }
                  <span>{{ event.createdAt | ccDateFormatRelative: { capitalizeFirstLetter: true } }}</span>
                </div>
              }
            </div>
          }
        </div>
      </scrollable-area>
    }
  </div>
</div>

@if (selectedCompany) {
  <company-logo
    class="company-search_selected-company-logo"
    [company]="selectedCompany"
  >
  </company-logo>
}

<search-input
  #searchInput
  [(ngModel)]="searchValue"
  [disabled]="disabled"
  [preselectedItem]="preselectedCompany"
  [preloadedMatches]="preloadedMatches"
  [searchFn]="companySearchFunc"
  (selectionChanged)="onSelectionChanged($event)"
  (matchedItemsChanged)="onMatchesChanged($event)"
  placeholder="{{ placeholder || 'companySelection.controls.companyName.placeholder' | translate }}"
  [itemTemplate]="itemTemplate"
>
</search-input>

<ng-template
  #itemTemplate
  let-item
>
  <div class="company-search_typeahead-result">
    @if (item) {
      <company-logo [company]="item"></company-logo>
    }
    <span innerHtml="{{ item.name }}"></span>
  </div>
</ng-template>

<ng-template
  #notFoundTemplate
  let-notFound
>
  <div>
    {{ 'common.strings.noMatchesFound' | translate }}
  </div>
</ng-template>

@if (display !== 'column') {
  {{ getDataString() }}
  @if (!isExpanded && data && data.length > maxLength) {
    <span
      [appFocusable]
      (click)="expand()"
      class="x-more_button"
      [ngClass]="{ disabled: disabled }"
    >
      {{ labelText || 'common.controls.xMore.label' | translate: { number: data.length - maxLength } }}
    </span>
  }
}
@if (display === 'column') {
  <div>{{ getDataString() }}</div>
  @if (!isExpanded && data && data.length > maxLength) {
    <div
      [appFocusable]
      (click)="expand()"
      class="x-more_button"
      [ngClass]="{ disabled: disabled }"
    >
      {{ getLabelString() | translate: { number: data.length - maxLength } }}
    </div>
  }
}

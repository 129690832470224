<div class="modal-body">
  <h1>{{ 'deleteLocationModal.confirmDelete' | translate }}</h1>

  <div class="t-align-center">
    {{ 'deleteLocationModal.confirmDeleteMsg' | translate }}
  </div>

  <div class="buttons-group">
    <button
      [disabled]="isLoading"
      class="btn btn-primary decline-btn"
      (click)="activeModal.dismiss()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
    <button
      [appBtnLoading]="isLoading"
      (click)="activeModal.close()"
      class="btn btn-primary submit-btn"
    >
      {{ 'common.buttons.ok' | translate }}
    </button>
  </div>
</div>

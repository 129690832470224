@for (user of getVisibleUsers(); track user) {
  <div class="x-more_user">
    <div>
      <user-logo [user]="user"></user-logo>
    </div>
    <div>
      <div>{{ user.firstName }} {{ user.lastName }}</div>
      <div>
        <span>{{ user?.title }}</span>
        @if (user?.title && companyName) {
          <span>, </span>
        }
        <span> {{ companyName }}</span>
      </div>
    </div>
  </div>
}
@if (!isExpanded && data && data.length > maxLength) {
  <div
    [appFocusable]
    (click)="expand()"
    class="x-more_button"
    [ngClass]="{ disabled: disabled }"
  >
    {{
      (data.length - maxLength > 1
        ? 'common.controls.xMoreContacts.label'
        : 'common.controls.xMoreContacts.singleLabel'
      ) | translate: { number: data.length - maxLength }
    }}
  </div>
}

<div class="company-information_info-title">
  <span
    >{{ section.title }}
    @if (showAccessType) {
      ({{ section.accessType }})
    }
  </span>
  @if (!isEdit && isEditable) {
    <div
      (click)="edit()"
      [appFocusable]
      class="company-general-info_edit-btn"
    >
      <img appAssetSrc="assets/svg/company-details/edit.svg" />
    </div>
  }
</div>

@if (!isEdit) {
  <div class="company-information_info-fields">
    @for (field of section.fields; track field) {
      <div>
        <div class="detailed-info_detail-name">
          <span>{{ field.title }}</span>
          @if (field.helpText) {
            <app-tooltip
              [contentTemplate]="tooltip"
              theme="black"
            >
              <div class="tooltip-icon"></div>
            </app-tooltip>
          }
          <ng-template #tooltip>
            <div class="form-builder_tooltip-content">{{ field.helpText }}</div>
          </ng-template>
        </div>
        <div class="detailed-info_detail-value">
          @switch (field.type) {
            @case ('Contact') {
              @if (field.value) {
                <user-logo
                  [user]="$any(getContactValue(field))"
                  class="company-details_user-logo"
                  hideStatus="true"
                ></user-logo>
              }
              <span>{{ getProprietaryFieldValue(field) }}</span>
            }
            @case ('Number') {
              <span>{{ getProprietaryFieldValue(field) | numberFormat }}</span>
            }
            @default {
              <span>{{ getProprietaryFieldValue(field) }}</span>
            }
          }
        </div>
      </div>
    }
  </div>
}

@if (isEdit) {
  <form [formGroup]="form">
    @for (field of section.fields; track field) {
      <div
        [ngClass]="{
          'has-error': isFormSubmitted && form.get($any(field.fieldId)).invalid,
        }"
        class="form-group"
      >
        <label
          class="label-grey"
          for="field.fieldId"
          >{{ field.title }}</label
        >
        @if (field.type === 'String') {
          <input
            [formControlName]="field.fieldId"
            [id]="field.fieldId"
            type="text"
          />
        }
        @if (field.type === 'Number') {
          <input
            [dropSpecialCharacters]="false"
            [formControlName]="field.fieldId"
            [id]="field.fieldId"
            mask="0*.0*"
            type="text"
          />
        }
        @if (field.type === 'Enumeration') {
          <app-select
            [formControlName]="field.fieldId"
            [id]="field.fieldId"
            [options]="selectOptions[field.fieldId]"
          >
          </app-select>
        }
        @if (field.type === 'MultiEnumeration') {
          <app-select
            [formControlName]="field.fieldId"
            [id]="field.fieldId"
            [multiselect]="true"
            [options]="selectOptions[field.fieldId]"
          >
          </app-select>
        }
        @if (field.type === 'Date') {
          <app-date-picker
            [formControlName]="field.fieldId"
            [id]="field.fieldId"
          >
          </app-date-picker>
        }
        @if (field.type === 'Location') {
          <location-selector
            [formControlName]="field.fieldId"
            [id]="field.fieldId"
          >
          </location-selector>
        }
        @if (field.type === 'Contact') {
          <contact-selector
            [formControlName]="field.fieldId"
            [id]="field.fieldId"
          >
          </contact-selector>
        }
        <form-error-messages
          [control]="form.get('field.fieldId')"
          [customErrors]="{ required: 'companyDetails.errors.websiteRequired' }"
          [showErrors]="isFormSubmitted"
        >
        </form-error-messages>
      </div>
    }
  </form>
  <div class="buttons-group">
    <button
      (click)="save()"
      [appBtnLoading]="isLoading"
      class="btn btn-primary submit-btn"
    >
      {{ 'common.buttons.save' | translate }}
    </button>
    <button
      (click)="cancel()"
      [disabled]="isLoading"
      class="btn secondary-btn"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
  </div>
}

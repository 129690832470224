<div class="modal-header"></div>

<div class="modal-body">
  <h1>{{ 'companyLists.deleteList' | translate }}</h1>

  <div
    class="t-align-center"
    innerHtml="{{ 'companyLists.deleteListMessage' | translate: { name: name } }}"
  ></div>

  <div class="buttons-group">
    <button
      class="btn btn-primary decline-btn"
      [disabled]="isLoading"
      (click)="activeModal.dismiss()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
    <button
      [appBtnLoading]="isLoading"
      (click)="deleteList()"
      class="btn btn-primary submit-btn"
    >
      {{ 'companyLists.buttons.deleteList' | translate }}
    </button>
  </div>
</div>

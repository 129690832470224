<div class="modal-body">
  <h3>{{ 'sectorsSelector.companySectors' | translate }}</h3>
  <p>{{ 'sectorsSelector.selectSectorsText' | translate }}</p>
  <div class="sectors-selector_tree">
    <scrollable-area>
      <treeview-picker
        [(options)]="options"
        [(ngModel)]="selectedOptions"
        onlyLowLevel="true"
      ></treeview-picker>
    </scrollable-area>
  </div>
  <div class="sectors-selector_buttons">
    <button
      (click)="activeModal.dismiss()"
      class="btn btn-primary decline-btn"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
    <button
      (click)="save()"
      [disabled]="selectedOptions.length === 0"
      class="btn btn-primary submit-btn"
    >
      {{ 'common.buttons.save' | translate }}
    </button>
  </div>
</div>

<app-table
  #table
  [config]="tableConfig"
  [template]="rowTemplate"
  [data]="locations"
  [shrinkHeight]="true"
  [maxHeight]="1000"
>
  <ng-template
    #rowTemplate
    let-location
  >
    <div>
      <div>
        {{ location.name }}
      </div>
    </div>

    <div>
      <div>
        {{ location.type.title }}
      </div>
    </div>

    <div>
      <div>
        {{ location.globalLocationNumber }}
      </div>
    </div>

    <div>
      <div>
        {{ getAddressString(location) }}
      </div>
    </div>
    <div>
      <div class="company-locations_buttons">
        @if (isEditable) {
          <div
            [appFocusable]
            (click)="editLocation(location)"
          >
            <img appAssetSrc="assets/svg/company-details/edit.svg" />
          </div>
        }

        @if (isEditable) {
          <div
            [appFocusable]
            (click)="deleteLocation(location)"
          >
            <img appAssetSrc="assets/svg/company-details/delete.svg" />
          </div>
        }
      </div>
    </div>
  </ng-template>
</app-table>

import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'app-pending-approval-modal',
  templateUrl: './pending-approval-modal.component.html',
  styleUrls: ['./pending-approval-modal.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TranslateModule],
})
export class PendingApprovalModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
}

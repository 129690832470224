<div class="modal-body">
  <img appAssetSrc="assets/svg/common/success.svg" />
  <h1>{{ 'companyProfileWizard.greatWork' | translate }}</h1>
  <div>{{ 'companyProfileWizard.greatWorkMsg' | translate }}</div>
  <button
    (click)="activeModal.close()"
    class="btn btn-primary submit-btn"
  >
    {{ 'companyProfileWizard.buttons.backToDashboard' | translate }}
  </button>
</div>

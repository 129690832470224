import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NgxMaskDirective } from 'ngx-mask';

import { AddressInputComponent } from '@core/components/address-input/address-input.component';
import { AddressSelectionComponent } from '@core/components/address-selection/address-selection.component';
import { CompanyInfoComponent } from '@core/components/company-info/company-info.component';
import { CompanyLogoComponent } from '@core/components/company-logo/company-logo.component';
import { ConnectionStatusComponent } from '@core/components/connection-status/connection-status.component';
import { DatePickerComponent } from '@core/components/date-picker/date-picker.component';
import { FormErrorMessagesComponent } from '@core/components/form-error-messages/form-error-messages.component';
import { LoaderComponent } from '@core/components/loader/loader.component';
import { LocationSelectorComponent } from '@core/components/location-selector/location-selector.component';
import { ProductCategoriesSelectorComponent } from '@core/components/product-categories-selector/product-categories-selector.component';
import { ProductLogoComponent } from '@core/components/product-logo/product-logo.component';
import { ProgressBarComponent } from '@core/components/progress-bar/progress-bar.component';
import { ScrollableAreaComponent } from '@core/components/scrollable-area/scrollable-area.component';
import { SectorsSelectorComponent } from '@core/components/sectors-selector/sectors-selector.component';
import { SelectComponent } from '@core/components/select/select.component';
import { TableComponent } from '@core/components/table/table.component';
import { TooltipComponent } from '@core/components/tooltip/tooltip.component';
import { TreeviewPickerComponent } from '@core/components/treeview-picker/treeview-picker.component';
import { UserLogoComponent } from '@core/components/user-logo/user-logo.component';
import { XMoreComponent } from '@core/components/x-more/x-more.component';
import { XMoreUsersComponent } from '@core/components/x-more-users/x-more-users.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { DateFormatDistancePipe, DateFormatPipe } from '@core/pipes/date-format.pipe';
import { NumberFormatPipe } from '@core/pipes/number-format.pipe';

import { CompanyProductsComponent } from './company-products/company-products.component';
import { TasksHistoryModule } from '../tasks-history/tasks-history.module';
import { CompanyInformationComponent } from './company-information/company-information.component';
import { CompanyListsModule } from '../company-lists/company-lists.module';
import { CompanyDetailsPageComponent } from './company-details-page/company-details-page.component';
import { CompanyDetailsResolver } from './company-details-page/company-details-route-resolver';
import { CompanyTaskHistoryComponent } from './company-task-history/company-task-history.component';
import { CompanyContactsComponent } from './components/company-contacts/company-contacts.component';
import { CompanyCustomInfoComponent } from './components/company-custom-info/company-custom-info.component';
import { CompanyFilesComponent } from './components/company-files/company-files.component';
import { CompanyGeneralInfoComponent } from './components/company-general-info/company-general-info.component';
import { CompanyHeaderComponent } from './components/company-header/company-header.component';
import { CompanyLocationsComponent } from './components/company-locations/company-locations.component';
import { CompanyRelationshipsComponent } from './components/company-relationships/company-relationships.component';
import { CompanySelectorComponent } from './components/company-selector/company-selector.component';
import { ConnectionsComponent } from './components/connections/connections.component';
import { ContactSelectorComponent } from './components/contact-selector/contact-selector.component';
import { ExistingCompaniesSearchComponent } from './components/existing-companies-search/existing-companies-search.component';
import { InvitationsComponent } from './components/invitations/invitations.component';
import { AddLocationModalComponent } from './modals/add-location-modal/add-location-modal.component';
import { CompanyCreationModalComponent } from './modals/company-creation-modal/company-creation-modal.component';
import { CompanySelectorModalComponent } from './modals/company-selector-modal/company-selector-modal.component';
import { ConfirmLocationDeleteModalComponent } from './modals/confirm-location-delete-modal/confirm-location-delete-modal.component';
import { ContactSelectorModalComponent } from './modals/contact-selector-modal/contact-selector-modal.component';
import { DeclineInvitationModalComponent } from './modals/decline-invitation-modal/decline-invitation-modal.component';
import { DeleteLocationModalComponent } from './modals/delete-location-modal/delete-location-modal.component';
import { DepartmentSelectorModalComponent } from './modals/department-selector-modal/department-selector-modal.component';
import { GreatWorkModalComponent } from './modals/great-work-modal/great-work-modal.component';
import { InvitationSentModalComponent } from './modals/invitation-sent-modal/invitation-sent-modal.component';
import { InviteUserModalComponent } from './modals/invite-user-modal/invite-user-modal.component';
import { PendingApprovalModalComponent } from './modals/pending-approval-modal/pending-approval-modal.component';
import { ProfileCompletionWizardComponent } from './modals/profile-completion-wizard/profile-completion-wizard.component';
import { UserInvidedModalComponent } from './modals/user-invided-modal/user-invided-modal.component';
import { NetworkPageComponent } from './network-page/network-page.component';
import { NetworkRoutingModule } from './network-routing.module';
import { NetworkService } from './network.service';
import { InvitationsWidgetComponent } from './widgets/invitations-widget/invitations-widget.component';

@NgModule({
  declarations: [NetworkPageComponent],
  imports: [
    CommonModule,
    NetworkRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    CompanyListsModule,
    NgbModule,
    TasksHistoryModule,
    NzProgressModule,
    ButtonLoadingDirective,
    SectorsSelectorComponent,
    FormErrorMessagesComponent,
    ProductCategoriesSelectorComponent,
    SelectComponent,
    AddressInputComponent,
    AddressSelectionComponent,
    TableComponent,
    UserLogoComponent,
    FocusableDirective,
    AssetSrcDirective,
    CompanyInfoComponent,
    NgxMaskDirective,
    CompanyLogoComponent,
    ConnectionStatusComponent,
    TooltipComponent,
    ProductLogoComponent,
    DateFormatPipe,
    DateFormatDistancePipe,
    NumberFormatPipe,
    XMoreUsersComponent,
    XMoreComponent,
    ScrollableAreaComponent,
    TreeviewPickerComponent,
    ProgressBarComponent,
    LoaderComponent,
    DatePickerComponent,
    LocationSelectorComponent,
    ConnectionsComponent,
    InvitationsComponent,
    CompanyDetailsPageComponent,
    DeclineInvitationModalComponent,
    InvitationsWidgetComponent,
    CompanyCreationModalComponent,
    InvitationSentModalComponent,
    InviteUserModalComponent,
    CompanyRelationshipsComponent,
    CompanyLocationsComponent,
    CompanyContactsComponent,
    ContactSelectorModalComponent,
    CompanyFilesComponent,
    CompanyGeneralInfoComponent,
    UserInvidedModalComponent,
    ProfileCompletionWizardComponent,
    GreatWorkModalComponent,
    DepartmentSelectorModalComponent,
    AddLocationModalComponent,
    DeleteLocationModalComponent,
    ConfirmLocationDeleteModalComponent,
    CompanyCustomInfoComponent,
    ContactSelectorComponent,
    CompanySelectorModalComponent,
    CompanySelectorComponent,
    CompanyInformationComponent,
    CompanyTaskHistoryComponent,
    CompanyHeaderComponent,
    CompanyProductsComponent,
    PendingApprovalModalComponent,
    ExistingCompaniesSearchComponent,
  ],
  exports: [
    InvitationsWidgetComponent,
    ContactSelectorComponent,
    CompanySelectorComponent,
    CompanyFilesComponent,
    PendingApprovalModalComponent,
    ProfileCompletionWizardComponent,
    InvitationsComponent,
    ConnectionsComponent,
  ],
  providers: [NetworkService, CompanyDetailsResolver],
  bootstrap: [NetworkPageComponent],
})
export class NetworkModule {}

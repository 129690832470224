import { NgClass } from '@angular/common';
import { Component, Input, type OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { type SelectOption } from '@core/components/select/select.component';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { OkModalComponent } from '@core/modals/ok-modal/ok-modal.component';
import { type Address } from '@core/models/address';
import { EnumService } from '@core/services/enum.service';
import { GeolocationService } from '@core/services/geolocation.service';
import { ModalService } from '@core/services/modal.service';
import { ToastrService } from '@core/services/toastr.service';

import { AddressInputComponent } from '../../../core/components/address-input/address-input.component';
import { AddressSelectionComponent } from '../../../core/components/address-selection/address-selection.component';
import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { SelectComponent } from '../../../core/components/select/select.component';
import { CompanyLocation } from '../../../core/models/location';
import { NetworkService } from '../../network.service';

const LOCATION_TYPE_IDS = {
  Branch: 1,
  DistributionCenter: 2,
  Plant: 3,
  Store: 4,
  Headquarters: 5,
};

@UntilDestroy()
@Component({
  selector: 'app-add-location-modal',
  templateUrl: './add-location-modal.component.html',
  styleUrls: ['./add-location-modal.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormErrorMessagesComponent,
    SelectComponent,
    AddressInputComponent,
    ButtonLoadingDirective,
    AddressSelectionComponent,
    TranslateModule,
  ],
})
export class AddLocationModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly geolocationService = inject(GeolocationService);
  private readonly enumService = inject(EnumService);
  private readonly toastr = inject(ToastrService);
  private readonly networkService = inject(NetworkService);
  private readonly modalService = inject(ModalService);
  @Input() isEdit: boolean;
  @Input() location: CompanyLocation;
  @Input() companyId: number;

  public isAddressSelection = false;
  public isLoading = false;
  public isFormSubmitted = false;

  public form: UntypedFormGroup;

  public matchedAddress: Address;
  public enteredAddress: Address;
  public selectedAddress: Address;

  public locationTypes: SelectOption[] = [];

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      locationName: new UntypedFormControl(this.location?.name, [Validators.required]),
      locationType: new UntypedFormControl(this.location?.type?.key, [Validators.required]),
      gln: new UntypedFormControl(this.location?.globalLocationNumber, []),
      address: new UntypedFormControl({ ...this.location?.address }, [Validators.required]),
    });

    this.enumService
      .getLocationTypes()
      .then((res) => (this.locationTypes = res))
      .catch((err) => {
        this.toastr.displayServerErrors(err);
      });
  }

  public checkAddress(): void {
    this.isFormSubmitted = true;
    if (this.form.invalid) {
      return;
    }

    this.enteredAddress = this.form.value.address;
    this.isLoading = true;
    this.geolocationService
      .lookupAddress(this.enteredAddress)
      .then((matchedAddress) => {
        if (!matchedAddress) {
          this.isAddressSelection = true;
          this.matchedAddress = null;
          this.isLoading = false;
          this.selectedAddress = this.enteredAddress;
          return;
        }

        this.matchedAddress = matchedAddress;
        if (this.geolocationService.areAddressesEqual(this.enteredAddress, matchedAddress)) {
          this.selectedAddress = this.matchedAddress;
          this.createLocation();
        } else {
          this.selectedAddress = this.matchedAddress;
          this.isAddressSelection = true;
          this.isLoading = false;
        }
      })
      .catch((res) => {
        this.isLoading = false;
        this.toastr.displayServerErrors(res);
      });
  }

  createLocation(): void {
    const form = this.form.value;
    const selectedLocation = this.locationTypes.find((loc) => loc.value === form.locationType);
    const data: any = {
      name: form.locationName,
      type: {
        id: LOCATION_TYPE_IDS[selectedLocation.value],
        key: selectedLocation.value,
        title: selectedLocation.label,
      },
      globalLocationNumber: form.gln,
      address: this.selectedAddress,
    };

    this.isLoading = true;
    const observable = this.isEdit
      ? this.networkService.updateLocation(this.location.id, this.companyId, {
          id: this.location.id,
          ...data,
        })
      : this.networkService.createLocation(data, this.companyId);
    observable
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.activeModal.close(res);
          this.modalService.open({
            content: OkModalComponent,
            options: {
              size: 'sm',
            },
            inputs: {
              title: this.isEdit ? 'locationEditModal.locationUpdated' : 'locationEditModal.locationAdded',
              text: this.isEdit ? 'locationEditModal.locationUpdatedMsg' : 'locationEditModal.locationAddedMsg',
            },
          });
        }),
        catchError((err) => {
          const error = err?.error?.errors[0];

          if (!error) {
            return;
          }

          switch (error.errorCode) {
            case 'duplicated_name':
              this.modalService.open({
                content: OkModalComponent,
                inputs: {
                  title: 'locationEditModal.duplicateLocation',
                  text: 'locationEditModal.duplicateLocationMsg',
                  isError: true,
                },
              });
              break;
            case 'duplicated_headquarters':
              this.modalService.open({
                content: OkModalComponent,
                inputs: {
                  title: 'locationEditModal.multipleHeadquarters',
                  text: 'locationEditModal.multipleHeadquartersMsg',
                  isError: true,
                },
              });
              break;
            default:
              this.toastr.displayServerErrors(err);
              break;
          }

          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }
}

import { type UntypedFormControl, type ValidationErrors, type ValidatorFn } from '@angular/forms';

export function websiteValidator(): ValidatorFn {
  return (control: UntypedFormControl): ValidationErrors | null => {
    const string = control?.value?.toLowerCase();

    if (!string) return null;
    if (string.indexOf('.') === -1) return { websiteInvalid: true };

    const match = string.match(
      new RegExp(
        '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(/)?(:[0-9]{1,5})?$',
      ),
    );

    return match ? null : { websiteInvalid: true };
  };
}

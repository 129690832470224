<div class="company-general-info_info-title">
  <span>{{ 'companyDetails.general' | translate }}</span>
  @if (!isEdit && isEditable) {
    <div
      class="company-general-info_edit-btn"
      [appFocusable]
      (click)="edit()"
    >
      <img appAssetSrc="assets/svg/company-details/edit.svg" />
    </div>
  }
</div>

@if (!isEdit) {
  <company-info
    [company]="company"
    [showLogo]="false"
    [canExpand]="true"
  ></company-info>
}

@if (isEdit) {
  <form
    class="company-general-info_form"
    [formGroup]="form"
  >
    <div class="form-group">
      <label class="label-grey">
        {{ 'companyDetails.headquartersAddress' | translate }}
      </label>
      <div>
        <div>
          {{ company.publicData.address.streetAddressLine1 }}
        </div>
        <div>
          {{ company.publicData.address.streetAddressLine2 }}
        </div>
        <div>
          {{ company.publicData.address.lastLine }}
        </div>
      </div>
    </div>
    <div
      class="form-group"
      [ngClass]="{
        'has-error': isFormSubmitted && form.get('sectors').invalid,
      }"
    >
      <label class="required label-grey">{{ 'companyDetails.sectors' | translate }}</label>
      <sectors-selector formControlName="sectors"></sectors-selector>
      <form-error-messages
        [showErrors]="isFormSubmitted"
        [control]="form.get('sectors')"
        [customErrors]="{ required: 'companyDetails.errors.sectorsRequired' }"
      >
      </form-error-messages>
    </div>
    <div
      class="form-group"
      [ngClass]="{
        'has-error': isFormSubmitted && form.get('productCategories').invalid,
      }"
    >
      <label
        class="required label-grey"
        for="productCategories"
        >{{ 'companyDetails.productCategories' | translate }}</label
      >
      <product-categories-selector formControlName="productCategories"></product-categories-selector>
      <form-error-messages
        [showErrors]="isFormSubmitted"
        [control]="form.get('productCategories')"
        [customErrors]="{
          required: 'companyDetails.errors.categoriesRequired',
        }"
      >
      </form-error-messages>
    </div>
    <div
      class="form-group"
      [ngClass]="{
        'has-error': isFormSubmitted && form.get('numberOfEmployees').invalid,
      }"
    >
      <label
        class="required label-grey"
        for="numberOfEmployees"
        >{{ 'companyDetails.numberOfEmployees' | translate }}</label
      >
      <app-select
        formControlName="numberOfEmployees"
        [options]="employeeNumbers"
      >
      </app-select>
      <form-error-messages
        [showErrors]="isFormSubmitted"
        [control]="form.get('numberOfEmployees')"
        [customErrors]="{
          required: 'companyDetails.errors.employeesNumberRequired',
        }"
      >
      </form-error-messages>
    </div>
    <div
      class="form-group"
      [ngClass]="{
        'has-error': isFormSubmitted && form.get('businessType').invalid,
      }"
    >
      <label
        class="required label-grey"
        for="businessType"
        >{{ 'companyDetails.businessType' | translate }}</label
      >
      <app-select
        formControlName="businessType"
        [options]="businessTypes"
      >
      </app-select>
      <form-error-messages
        [showErrors]="isFormSubmitted"
        [control]="form.get('businessType')"
        [customErrors]="{
          required: 'companyDetails.errors.businessTypeRequired',
        }"
      >
      </form-error-messages>
    </div>
    <div
      class="form-group"
      [ngClass]="{
        'has-error': isFormSubmitted && form.get('companyType').invalid,
      }"
    >
      <label
        class="required label-grey"
        for="companyType"
        >{{ 'companyDetails.companyType' | translate }}</label
      >
      <app-select
        formControlName="companyType"
        [options]="companyTypes"
      >
      </app-select>
      <form-error-messages
        [showErrors]="isFormSubmitted"
        [control]="form.get('companyType')"
        [customErrors]="{
          required: 'companyDetails.errors.companyTypeRequired',
        }"
      >
      </form-error-messages>
    </div>
    <div
      class="form-group"
      [ngClass]="{
        'has-error': isFormSubmitted && form.get('legalType').invalid,
      }"
    >
      <label
        class="label-grey"
        for="legalType"
        >{{ 'companyDetails.legalType' | translate }}</label
      >
      <app-select
        formControlName="legalType"
        [options]="legalTypes"
      >
      </app-select>
      <form-error-messages
        [showErrors]="isFormSubmitted"
        [control]="form.get('legalType')"
      >
      </form-error-messages>
    </div>
    <div
      class="form-group"
      [ngClass]="{
        'has-error': isFormSubmitted && form.get('annualRevenue').invalid,
      }"
    >
      <label
        class="label-grey"
        for="annualRevenue"
        >{{ 'companyDetails.annualRevenue' | translate }}</label
      >
      <app-select
        formControlName="annualRevenue"
        [options]="annualRevenues"
      >
      </app-select>
      <form-error-messages
        [showErrors]="isFormSubmitted"
        [control]="form.get('annualRevenue')"
      >
      </form-error-messages>
    </div>
    <div
      class="form-group"
      [ngClass]="{
        'has-error': isFormSubmitted && form.get('yearStarted').invalid,
      }"
    >
      <label
        class="label-grey"
        for="yearStarted"
        >{{ 'companyDetails.yearStarted' | translate }}</label
      >
      <input
        type="text"
        formControlName="yearStarted"
        id="yearStarted"
        mask="0{4}"
      />
      <form-error-messages
        [showErrors]="isFormSubmitted"
        [control]="form.get('yearStarted')"
      >
      </form-error-messages>
    </div>
    <div
      class="form-group"
      [ngClass]="{
        'has-error': isFormSubmitted && form.get('websiteUrl').invalid,
      }"
    >
      <label
        class="required label-grey"
        for="websiteUrl"
        >{{ 'companyDetails.website' | translate }}</label
      >
      <input
        type="text"
        formControlName="websiteUrl"
        id="websiteUrl"
      />
      <form-error-messages
        [showErrors]="isFormSubmitted"
        [control]="form.get('websiteUrl')"
        [customErrors]="{ required: 'companyDetails.errors.websiteRequired' }"
      >
      </form-error-messages>
    </div>
    <div
      class="form-group"
      [ngClass]="{
        'has-error': isFormSubmitted && form.get('tickerSymbol').invalid,
      }"
    >
      <label
        class="label-grey"
        for="tickerSymbol"
        >{{ 'companyDetails.tickerSymbol' | translate }}</label
      >
      <input
        type="text"
        formControlName="tickerSymbol"
        id="tickerSymbol"
      />
      <form-error-messages
        [showErrors]="isFormSubmitted"
        [control]="form.get('tickerSymbol')"
      >
      </form-error-messages>
    </div>
    <div class="form-group"></div>
    <div class="buttons-group">
      <button
        [appBtnLoading]="isLoading"
        [disabled]="disabled"
        (click)="save()"
        class="btn btn-primary submit-btn"
      >
        {{ 'common.buttons.save' | translate }}
      </button>
      <button
        [disabled]="isLoading || disabled"
        class="btn secondary-btn"
        (click)="cancel()"
      >
        {{ 'common.buttons.cancel' | translate }}
      </button>
    </div>
  </form>
}

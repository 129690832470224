<div class="search-input">
  <input
    [class.search-input--has-selected]="selectedItem"
    [formControl]="searchQueryFormControl"
    [placeholder]="placeholder"
    autocomplete="false"
    class="search-input__input"
    type="text"
  />

  @if (searching) {
    <div class="search-input__loading-indicator"></div>
  }

  @if (canClear) {
    <app-button
      (click)="handleClear()"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Ghost"
      class="search-input__clear-button"
    >
      <i
        class="icon-close"
        slot="ghost-icon"
      ></i>
    </app-button>
  }

  @if (matchedItems.length || noResults) {
    <div
      (scrolled)="loadNextPage$.next(null)"
      [class.search-input__suggestions--hidden]="!suggestionsDropdownVisible"
      [infiniteScrollDistance]="2.5"
      [infiniteScrollThrottle]="0"
      [infiniteScrollUpDistance]="2.5"
      [scrollWindow]="false"
      class="search-input__suggestions"
      infinite-scroll
    >
      @if (noResults) {
        <div class="search-input__suggestions-no-results">
          {{ 'common.strings.noMatchesFound' | translate }}
        </div>
      }
      @for (item of matchedItems; track item) {
        <div
          (click)="selectItem(item)"
          [appFocusable]
          class="search-input__suggestions-item"
        >
          <ng-container
            [ngTemplateOutletContext]="{ $implicit: item }"
            [ngTemplateOutlet]="itemTemplate"
          ></ng-container>
        </div>
      }
    </div>
  }
</div>

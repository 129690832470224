import { Injectable, inject } from '@angular/core';
import { type Observable } from 'rxjs';

import { type Company } from '../core/models/company';
import { HttpService } from '../core/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyListsService {
  private readonly http = inject(HttpService);

  public createList(name: string): Observable<any> {
    return this.http.postV2(`api/companiesLists`, { name });
  }

  public renameList(listId: number, newName: string): Observable<any> {
    return this.http.postV2(`api/companiesLists/${listId}/rename`, { newName });
  }

  public deleteList(listId: number): Observable<any> {
    return this.http.deleteV2(`api/companiesLists/${listId}`);
  }

  public searchCompanies(
    name: string,
    onlyConnected: boolean = true,
    includeDeferred: boolean = false,
    companiesIdsToExclude: number[] = null,
    limit: number = 20,
    offset: number = 0,
  ): Observable<any> {
    return this.http.postV2(`api/companies/search?offset=${offset}&limit=${limit}`, {
      companiesIdsToExclude,
      includeDeferred,
      includeOnlyConnected: onlyConnected,
      name,
    });
  }

  public getCompanyDetails(id: number): Observable<Company> {
    return this.http.getV2(`api/companies/${id}/public`);
  }

  public addCompaniesToList(listId: number, companiesIds: number[]): Observable<any> {
    return this.http.postV2(`api/companiesLists/${listId}/addCompanies`, {
      companiesIds,
    });
  }

  public removeCompaniesFromList(listId: number, companiesIds: number[]): Observable<any> {
    return this.http.postV2(`api/companiesLists/${listId}/deleteCompanies`, {
      companiesIds,
    });
  }

  public getLists(): Observable<any> {
    return this.http.getV2(`api/companiesLists/search?limit=100`);
  }
}

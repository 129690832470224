<app-checkbox
  [(ngModel)]="hideArchived"
  [label]="'companyDetails.hideArchivedFiles' | translate"
></app-checkbox>
<app-table
  #table
  [(data)]="files"
  [config]="tableConfig"
  [maxHeight]="maxHeight"
  [placeholderTemplate]="placeholderTemplate"
  [shrinkHeight]="true"
  [template]="rowTemplate"
>
  <ng-template
    #rowTemplate
    let-file
  >
    <div>
      <a
        [download]="file.fileName"
        [href]="getDownloadUrl(file)"
        target="_self"
      >
        {{ file.fileName }}
      </a>
    </div>
    <div>
      <div>
        {{ getCategoryString(file) }}
      </div>
    </div>
    <div>
      <div>
        {{ file.createdAt | ccDateFormat: { date: 'short' } }}
      </div>
    </div>
  </ng-template>
  <ng-template #placeholderTemplate>
    <div class="table_placeholder-message">
      <div>{{ 'common.strings.noMatchesFound' | translate }}</div>
    </div>
  </ng-template>
</app-table>

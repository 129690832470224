import { Component, forwardRef, Input, ViewEncapsulation, inject, OnInit } from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';

import { ScrollableAreaComponent } from '@core/components/scrollable-area/scrollable-area.component';
import { type TreeviewPickerOption } from '@core/components/treeview-picker/treeview-picker.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { type EnumerationModel } from '@core/models/enumerationModel';
import { EnumService } from '@core/services/enum.service';
import { ModalService } from '@core/services/modal.service';

import { SectorsSelectorModalComponent } from '../../modals/sectors-selector-modal/sectors-selector-modal.component';

@UntilDestroy()
@Component({
  selector: 'sectors-selector',
  templateUrl: './sectors-selector.component.html',
  styleUrls: ['./sectors-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SectorsSelectorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [ScrollableAreaComponent, FocusableDirective, AssetSrcDirective, TranslateModule],
})
export class SectorsSelectorComponent implements ControlValueAccessor, OnInit {
  private readonly enumService = inject(EnumService);
  private readonly modalService = inject(ModalService);
  @Input() disabled = false;
  @Input() placeholder: string;
  @Input() onAddFunc: () => any;
  public selectedSectors: EnumerationModel[] = [];
  public sectors: TreeviewPickerOption[] = [];
  private _value: EnumerationModel[] = [];

  public get value(): EnumerationModel[] {
    return this._value;
  }

  public set value(val: EnumerationModel[]) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  public ngOnInit(): void {
    this.enumService
      .getCompanySectors()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.sectors = res;
      });
  }

  public removeSector(sector: EnumerationModel): void {
    if (this.disabled) {
      return;
    }

    this.value = this.value.filter((val) => val.key !== sector.key);
  }

  public addNew(): void {
    if (this.onAddFunc) {
      this.onAddFunc();
      return;
    }

    this.modalService
      .open({
        content: SectorsSelectorModalComponent,
        inputs: {
          options: this.sectors,
          selectedSectors: this._value,
        },
        options: {
          size: 'sm',
        },
      })
      .result.then((res: EnumerationModel[]) => (this.value = res))
      .catch(() => {});
  }

  public writeValue(value: EnumerationModel[]): void {
    this._value = value;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (val: EnumerationModel[]) => {};

  private onTouched = () => {};
}

import { NgClass } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { type ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { RadioComponent } from '@design/forms/radio/radio.component';

import { Address } from '../../models/address';

@Component({
  selector: 'address-selection',
  templateUrl: './address-selection.component.html',
  styleUrls: ['./address-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressSelectionComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [FormsModule, TranslateModule, NgClass, RadioComponent],
})
export class AddressSelectionComponent implements ControlValueAccessor, OnInit {
  @Input() enteredAddress: Address;
  @Input() suggestedAddress: Address;

  public disabled: boolean;
  private _value: Address;

  public get value(): Address {
    return this._value;
  }

  public set value(val: Address) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  public ngOnInit(): void {}

  public writeValue(value: Address): void {
    this._value = value;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (val: Address) => {};

  private onTouched = () => {};
}

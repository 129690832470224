import { NgClass } from '@angular/common';
import { Component, Input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FocusableDirective } from '@core/directives/focusable.directive';

import { type EnumerationModel } from '../../models/enumerationModel';

@Component({
  selector: 'x-more',
  templateUrl: './x-more.component.html',
  styleUrls: ['./x-more.component.scss'],
  standalone: true,
  imports: [FocusableDirective, NgClass, TranslateModule],
})
export class XMoreComponent {
  @Input() data: any[] = [];
  @Input() maxLength = 3;
  @Input() disabled = false;
  @Input() display: 'row' | 'column' = 'row';
  @Input() labelText: string;
  @Input() singleLabelText: string;
  expandChanged = output<boolean>();

  public isExpanded = false;

  public getLabelString(): string {
    if (this.data.length - this.maxLength > 1) {
      return this.labelText || 'common.controls.xMore.label';
    } else {
      return this.singleLabelText || 'common.controls.xMore.label';
    }
  }

  public getDataString(): string {
    if (!this.data?.length) {
      return '';
    }

    if (this.isExpanded) {
      return this.buildString(this.data);
    }

    return this.buildString(this.data.slice(0, this.maxLength));
  }

  public expand(): void {
    if (!this.disabled) {
      this.isExpanded = true;
      setTimeout(() => {
        this.expandChanged.emit(true);
      }, 0);
    }
  }

  private buildString(data: any[]): string {
    if (data[0]?.title) {
      return (data as EnumerationModel[]).map((sect) => sect.title).reduce((prev, next) => `${prev}, ${next}`);
    } else {
      return (data as string[]).reduce((p, c) => `${p}, ${c}`);
    }
  }
}

<div
  class="custom-selector"
  [attr.disabled]="isDisabled ? true : null"
>
  <div class="custom-selector_text">
    @if (value) {
      <span>{{ value.name || value.companyName || value.internalName }}</span>
    }
    @if (!value) {
      <span class="custom-selector_placeholder">
        {{ placeholder || '' }}
      </span>
    }
  </div>
  <div
    [appFocusable]
    class="custom-selector_button"
    (click)="openSelector()"
  >
    <img appAssetSrc="assets/svg/common/link.svg" />
  </div>
</div>

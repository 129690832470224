import { NgClass } from '@angular/common';
import { Component, Input, ViewEncapsulation, inject, output, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { type SelectOption } from '@core/components/select/select.component';
import { type TreeviewPickerOption } from '@core/components/treeview-picker/treeview-picker.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { Company } from '@core/models/company';
import { type EnumerationModel } from '@core/models/enumerationModel';
import { EnumService } from '@core/services/enum.service';
import { HelpersService } from '@core/services/helpers.service';
import { ModalService } from '@core/services/modal.service';
import { ToastrService } from '@core/services/toastr.service';
import { websiteValidator } from '@core/validators/website.validator';

import { CompanyLogoComponent } from '../../../core/components/company-logo/company-logo.component';
import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { LoaderComponent } from '../../../core/components/loader/loader.component';
import { ProductCategoriesSelectorComponent } from '../../../core/components/product-categories-selector/product-categories-selector.component';
import { ProgressBarComponent } from '../../../core/components/progress-bar/progress-bar.component';
import { ScrollableAreaComponent } from '../../../core/components/scrollable-area/scrollable-area.component';
import { SectorsSelectorComponent } from '../../../core/components/sectors-selector/sectors-selector.component';
import { SelectComponent } from '../../../core/components/select/select.component';
import { TreeviewPickerComponent } from '../../../core/components/treeview-picker/treeview-picker.component';
import { NetworkService } from '../../network.service';
import { GreatWorkModalComponent } from '../great-work-modal/great-work-modal.component';

interface ProfileCompletionStep {
  id: number;
  title: string;
  description: string;
  logo: string;
}

@UntilDestroy()
@Component({
  selector: 'profile-completion-wizard',
  templateUrl: './profile-completion-wizard.component.html',
  styleUrls: ['./profile-completion-wizard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CompanyLogoComponent,
    AssetSrcDirective,
    ProgressBarComponent,
    LoaderComponent,
    ScrollableAreaComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormErrorMessagesComponent,
    SelectComponent,
    TreeviewPickerComponent,
    SectorsSelectorComponent,
    ProductCategoriesSelectorComponent,
    TranslateModule,
  ],
})
export class ProfileCompletionWizardComponent implements OnInit {
  private readonly enumService = inject(EnumService);
  private readonly helpers = inject(HelpersService);
  private readonly networkService = inject(NetworkService);
  private readonly toastr = inject(ToastrService);
  private readonly modalService = inject(ModalService);
  @Input() companyInfo: Company;

  profileUpdate = output<Company>();
  modalClose = output<void>();

  public steps: ProfileCompletionStep[] = [
    {
      id: 0,
      title: 'companyProfileWizard.steps.generalInfo.title',
      description: 'companyProfileWizard.steps.generalInfo.description',
      logo: 'assets/svg/profile-completion/general-info.svg',
    },
    {
      id: 1,
      title: 'companyProfileWizard.steps.companyDescription.title',
      description: 'companyProfileWizard.steps.companyDescription.description',
      logo: 'assets/svg/profile-completion/company-description.svg',
    },
    {
      id: 2,
      title: 'companyProfileWizard.steps.sectors.title',
      description: 'companyProfileWizard.steps.sectors.description',
      logo: 'assets/svg/profile-completion/sectors.svg',
    },
    {
      id: 3,
      title: 'companyProfileWizard.steps.productCategories.title',
      description: 'companyProfileWizard.steps.productCategories.description',
      logo: 'assets/svg/profile-completion/product-categories.svg',
    },
  ];

  public isFormSubmitted = false;
  public form: UntypedFormGroup;

  public currentStep: ProfileCompletionStep;

  public isLoading = true;

  private company: Company;

  public businessTypes: SelectOption[] = [];
  public employeeNumbers: SelectOption[] = [];
  public companyTypes: SelectOption[] = [];
  public sectors: TreeviewPickerOption[] = [];
  public productCategories: TreeviewPickerOption[] = [];

  public isSelecting = false;
  public selectedOptions: string[] = [];

  constructor() {
    this.currentStep = this.steps[0];
    this.openTreeSelector = this.openTreeSelector.bind(this);
  }

  public ngOnInit(): void {
    this.company = this.companyInfo;
    this.isLoading = true;
    this.loadEnums();
    this.buildForm();
  }

  private loadEnums(): void {
    combineLatest([
      this.enumService.getBusinessTypes(),
      this.enumService.getEmployeeNumbers(),
      this.enumService.getCompanyTypes(),
      this.enumService.getCompanySectors(),
      this.enumService.getCompanyProductCategories(),
    ])
      .pipe(
        untilDestroyed(this),
        map(([businessTypes, employeeNumbers, companyTypes, sectors, productCategories]) => ({
          bt: businessTypes,
          en: employeeNumbers,
          ct: companyTypes,
          s: sectors,
          pc: productCategories,
        })),
      )
      .subscribe((res) => {
        this.businessTypes = res.bt;
        this.employeeNumbers = res.en;
        this.companyTypes = res.ct;
        this.sectors = res.s;
        this.productCategories = res.pc;
        this.isLoading = false;
      });
  }

  private buildForm(): void {
    this.isFormSubmitted = false;

    switch (this.currentStep.id) {
      case 0:
        this.form = new UntypedFormGroup({
          websiteUrl: new UntypedFormControl(this.company.publicData?.websiteUrl, [websiteValidator()]),
          businessType: new UntypedFormControl(this.company.publicData?.businessType?.key),
          numberOfEmployees: new UntypedFormControl(this.company.publicData?.numberOfEmployeesType?.key),
          companyType: new UntypedFormControl(this.company.publicData?.companyType?.key),
        });
        break;

      case 1:
        this.form = new UntypedFormGroup({
          description: new UntypedFormControl(this.company.publicData?.description),
        });
        break;

      case 2:
        this.form = new UntypedFormGroup({
          sectors: new UntypedFormControl(this.company.publicData?.subIndustries?.subIndustries),
        });
        break;

      case 3:
        this.form = new UntypedFormGroup({
          productCategories: new UntypedFormControl(this.company.publicData?.productCategories?.categories),
        });
        break;
      default:
        break;
    }
  }

  public onNextClick(): void {
    if (this.isSelecting) {
      const selectedValue = this.selectedOptions
        .map((value) => {
          const options = this.currentStep.id === 2 ? this.sectors : this.productCategories;
          const option = this.helpers.treeSearch(options, value);
          return option
            ? {
                key: option.value,
                title: option.label,
              }
            : null;
        })
        .filter((value) => !!value);

      if (this.currentStep.id === 2) {
        this.form.setValue({
          sectors: selectedValue,
        });
      }

      if (this.currentStep.id === 3) {
        this.form.setValue({
          productCategories: selectedValue,
        });
      }

      this.isSelecting = false;
      return;
    }

    this.isFormSubmitted = true;
    if (this.form.invalid) {
      return;
    }

    const form = this.form.value;
    const data: Record<string, any> = {};

    switch (this.currentStep.id) {
      case 0:
        if (form.websiteUrl) {
          data.WebsiteUrl = form.websiteUrl;
        }
        if (form.businessType) {
          data.BusinessType = this.convertOptionToModel(
            this.businessTypes.find((type) => type.value === form.businessType),
          );
        }
        if (form.companyType) {
          data.CompanyType = this.convertOptionToModel(
            this.companyTypes.find((type) => type.value === form.companyType),
          );
        }
        if (form.numberOfEmployees) {
          data.NumberOfEmployees = this.convertOptionToModel(
            this.employeeNumbers.find((numb) => numb.value === form.numberOfEmployees),
          );
        }
        break;

      case 1:
        if (form.description) {
          data.Description = form.description;
        }
        break;

      case 2:
        if (form.sectors?.length) {
          data.SubIndustries = form.sectors;
        }
        break;
      case 3:
        if (form.productCategories?.length) {
          data.ProductCategories = form.productCategories;
        }
        break;
      default:
        break;
    }

    this.isLoading = true;
    this.networkService
      .updateCompanyFields(this.company.id, data)
      .pipe(
        untilDestroyed(this),
        map((updatedCompany: Company) => {
          if (this.currentStep.id + 1 === this.steps.length) {
            this.profileUpdate.emit(updatedCompany);
            this.modalClose.emit();
            this.modalService.open({
              content: GreatWorkModalComponent,
              inputs: {
                companyInfo: this.company,
              },
            });
          } else {
            this.company = updatedCompany;
            this.currentStep = this.steps[this.currentStep.id + 1];
            this.buildForm();
          }
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  public onBackClick(): void {
    if (this.isSelecting) {
      this.isSelecting = false;
    } else {
      this.currentStep = this.steps[this.currentStep.id - 1];
      this.buildForm();
    }
  }

  public openTreeSelector(): void {
    this.isSelecting = true;
    this.isFormSubmitted = false;
    if (this.currentStep.id === 2) {
      this.selectedOptions = this.form.value.sectors.map((sect) => sect.key);
    }
    if (this.currentStep.id === 3) {
      this.selectedOptions = this.form.value.productCategories.map((cat) => cat.key);
    }
  }

  private convertOptionToModel(option: SelectOption): EnumerationModel {
    return option
      ? {
          key: option.value,
          title: option.label,
        }
      : null;
  }
}

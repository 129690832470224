<form [formGroup]="form">
  <div
    class="form-group"
    [ngClass]="{
      'has-error': showErrors && form.get('streetAddressLine1').invalid,
    }"
  >
    <label
      [class.required]="required"
      for="street1"
    >
      {{ 'common.controls.address.street1.label' | translate }}
    </label>
    <input
      type="text"
      formControlName="streetAddressLine1"
      id="street1"
      placeholder="{{ 'common.controls.address.street1.placeholder' | translate }}"
    />
    <form-error-messages
      [showErrors]="showErrors"
      [control]="form.get('streetAddressLine1')"
    >
    </form-error-messages>
  </div>

  <div
    class="form-group"
    [ngClass]="{
      'has-error': showErrors && form.get('streetAddressLine2').invalid,
    }"
  >
    <label for="street2">
      {{ 'common.controls.address.street2.label' | translate }}
    </label>
    <input
      type="text"
      formControlName="streetAddressLine2"
      id="street2"
      placeholder="{{ 'common.controls.address.street2.placeholder' | translate }}"
    />
    <form-error-messages
      [showErrors]="showErrors"
      [control]="form.get('streetAddressLine2')"
    >
    </form-error-messages>
  </div>

  <div
    class="form-group"
    [ngClass]="{ 'has-error': showErrors && form.get('country').invalid }"
  >
    <label
      [class.required]="required"
      for="country"
    >
      {{ 'common.controls.address.country.label' | translate }}
    </label>
    <app-select
      formControlName="country"
      [maxHeight]="200"
      [options]="countries"
      placeholder="{{ 'common.controls.address.country.placeholder' | translate }}"
    >
    </app-select>
    <form-error-messages
      [showErrors]="showErrors"
      [control]="form.get('country')"
    >
    </form-error-messages>
  </div>

  <div
    class="form-group"
    [ngClass]="{ 'has-error': showErrors && form.get('city').invalid }"
  >
    <label
      [class.required]="required"
      for="city"
    >
      {{ 'common.controls.address.city.label' | translate }}
    </label>
    <input
      type="text"
      formControlName="city"
      id="city"
      placeholder="{{ 'common.controls.address.city.placeholder' | translate }}"
    />
    <form-error-messages
      [showErrors]="showErrors"
      [control]="form.get('city')"
    >
    </form-error-messages>
  </div>

  <div
    class="form-group"
    [ngClass]="{ 'has-error': showErrors && form.get('state').invalid }"
  >
    <label
      [class.required]="required"
      for="state"
    >
      {{ 'common.controls.address.state.label' | translate }}
    </label>
    <input
      type="text"
      formControlName="state"
      id="state"
      placeholder="{{ 'common.controls.address.state.placeholder' | translate }}"
    />
    <form-error-messages
      [showErrors]="showErrors"
      [control]="form.get('state')"
    >
    </form-error-messages>
  </div>

  <div
    class="form-group"
    [ngClass]="{ 'has-error': showErrors && form.get('zipCode').invalid }"
  >
    <label
      [class.required]="required"
      for="zip"
    >
      {{ 'common.controls.address.zip.label' | translate }}
    </label>
    <input
      type="text"
      formControlName="zipCode"
      id="zip"
      placeholder="{{ 'common.controls.address.zip.placeholder' | translate }}"
    />
    <form-error-messages
      [showErrors]="showErrors"
      [control]="form.get('zipCode')"
    >
    </form-error-messages>
  </div>
</form>

<div class="modal-body">
  @if (!isInvitationSent) {
    <form
      class="invite-user-modal_email-form"
      autocomplete="off"
      [formGroup]="form"
    >
      <h1>{{ 'inviteUserModal.inviteNewUser' | translate }}</h1>
      <div
        class="form-group"
        [ngClass]="{ 'has-error': formSubmitted && form.get('email').invalid }"
      >
        <label
          class="required"
          for="email"
        >
          {{ 'inviteUserModal.controls.email.label' | translate }}
        </label>
        <input
          type="email"
          formControlName="email"
          id="email"
          (keydown.enter)="onEnterKeydown($event, submitBtn)"
          placeholder="{{ 'inviteUserModal.controls.email.placeholder' | translate }}"
        />
        <form-error-messages
          [showErrors]="formSubmitted"
          [customErrors]="customErrors"
          [control]="form.get('email')"
        >
        </form-error-messages>
      </div>
    </form>
    <div class="buttons-group">
      <button
        [disabled]="isLoading"
        class="btn btn-primary decline-btn"
        (click)="activeModal.dismiss()"
      >
        {{ 'common.buttons.cancel' | translate }}
      </button>
      <button
        #submitBtn
        [appBtnLoading]="isLoading"
        class="btn btn-primary submit-btn"
        (click)="inviteUser()"
      >
        {{ 'inviteUserModal.buttons.sendInvite' | translate }}
      </button>
    </div>
  }

  @if (isInvitationSent) {
    <div class="invite-user-modal_invitation-sent">
      <div class="invite-user-modal_image">
        <img appAssetSrc="assets/svg/network/invitation-sent.svg" />
      </div>
      <h1>{{ 'inviteUserModal.invitationSent' | translate }}</h1>
      <div class="invite-user-modal_invitation-sent-text">
        {{ 'inviteUserModal.invitationSentText' | translate }}
      </div>
      <div class="buttons-group">
        <button
          class="btn btn-primary decline-btn"
          (click)="activeModal.close()"
        >
          {{ 'inviteUserModal.buttons.imDone' | translate }}
        </button>
        <button
          (click)="inviteMore()"
          class="btn btn-primary submit-btn"
        >
          {{ 'inviteUserModal.buttons.inviteMore' | translate }}
        </button>
      </div>
    </div>
  }
</div>

<div
  class="custom-selector"
  [attr.disabled]="isDisabled ? true : null"
>
  <div class="custom-selector_text">
    <!--         <user-logo *ngIf="value" [user]="selectedContact" [logoUrl]="selectedContact?.userLogoUrl"></user-logo> -->
    @if (value) {
      <span>{{ getContactString() }}</span>
    }
    @if (multiselect && value?.length > 1) {
      <span class="contact-selector_extra-contacts">(+{{ value?.length - 1 }})</span>
    }
    @if (!value) {
      <span class="custom-selector_placeholder">
        {{ placeholder || '' }}
      </span>
    }
  </div>
  <div
    [appFocusable]
    class="custom-selector_button"
    (click)="openSelector()"
  >
    <img appAssetSrc="assets/svg/common/link.svg" />
  </div>
</div>

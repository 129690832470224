@if (!isEdit) {
  <div class="company-details-header_text">
    <div>
      <span>{{ company.name }}</span>
      @if (!isEdit && isEditable) {
        <div
          class="company-general-info_edit-btn"
          [appFocusable]
          (click)="edit()"
        >
          <img appAssetSrc="assets/svg/company-details/edit.svg" />
        </div>
      }
    </div>
    <div>
      {{ company.publicData.description }}
    </div>
  </div>
}

@if (isEdit) {
  <form
    class="company-general-info_form"
    [formGroup]="form"
  >
    <div
      class="form-group"
      [ngClass]="{ 'has-error': isFormSubmitted && form.get('name').invalid }"
    >
      <input
        type="text"
        formControlName="name"
        id="name"
        placeholder="{{ 'companyDetails.controls.name.placeholder' | translate }}"
      />
      <form-error-messages
        [showErrors]="isFormSubmitted"
        [control]="form.get('name')"
        [customErrors]="{ required: 'companyDetails.errors.nameRequired' }"
      >
      </form-error-messages>
    </div>
    <div
      class="form-group company-details-header_description"
      [ngClass]="{
        'has-error': isFormSubmitted && form.get('description').invalid,
      }"
    >
      <textarea
        id="description"
        formControlName="description"
        placeholder="{{ 'companyDetails.controls.description.placeholder' | translate }}"
      ></textarea>
      <form-error-messages
        [showErrors]="isFormSubmitted"
        [control]="form.get('description')"
        [customErrors]="{ required: 'companyDetails.errors.descriptionRequired' }"
      >
      </form-error-messages>
    </div>
    <div class="buttons-group">
      <button
        [appBtnLoading]="isLoading"
        [disabled]="disabled"
        (click)="save()"
        class="btn btn-primary submit-btn"
      >
        {{ 'common.buttons.save' | translate }}
      </button>
      <button
        [disabled]="isLoading || disabled"
        class="btn secondary-btn"
        (click)="cancel()"
      >
        {{ 'common.buttons.cancel' | translate }}
      </button>
    </div>
  </form>
}

<app-table
  [(data)]="data"
  [config]="tableConfig"
  [placeholderTemplate]="placeholderTemplate"
  [template]="rowTemplate"
>
  <ng-template
    #rowTemplate
    let-connection
  >
    <div class="connections-table_company">
      @if (connection) {
        <company-logo [company]="connection"></company-logo>
        <div>
          <div
            [routerLink]="['/network-v2/company/' + connection.id]"
            [appFocusable]
          >
            {{ connection.name }}
          </div>
          <!--          <div [routerLink]="['/network-v2/company/' + connection.associatedCompany.id]" [appFocusable]>-->
          <!--            {{ connection.associatedCompany.name }}-->
          <!--          </div>-->
          <!-- <x-more
            [data]="connection?.associatedCompany?.subIndustries?.sectors"
            maxLength="2"
          ></x-more> -->
          <div>
            {{ getCompanyDetailsString(connection) }}
          </div>
        </div>
      }
    </div>
  </ng-template>

  <ng-template #placeholderTemplate>
    <div class="table_placeholder-message">
      <div>{{ 'networkPage.noConnections' | translate }}</div>
      <a
        (click)="focusGlobalSearch()"
        [appFocusable]
      >
        {{ 'networkPage.searchForCompany' | translate }}
      </a>
    </div>
  </ng-template>
</app-table>

<app-table
  [config]="tableConfig"
  [template]="rowTemplate"
  [placeholderTemplate]="placeholderTemplate"
  [(data)]="data"
>
  <ng-template
    #rowTemplate
    let-invitation
  >
    <div class="invitations-table_company">
      <company-logo [company]="getCompany(invitation)"></company-logo>
      <div>
        @if (!getCompany(invitation)?.isApproved) {
          <div
            [appFocusable]
            (click)="showPendingApprovalModal()"
          >
            {{ getCompany(invitation)?.name || ('invitations.unknownCompanyName' | translate) }}
          </div>
        }
        @if (getCompany(invitation)?.isApproved) {
          <a
            [appFocusable]
            [routerLink]="['/network-v2/company/' + getCompany(invitation)?.id]"
          >
            {{ getCompany(invitation)?.name || ('invitations.unknownCompanyName' | translate) }}
          </a>
        }
        @if (getCompany(invitation)?.subIndustries?.sectors) {
          <x-more
            [data]="getCompany(invitation)?.subIndustries?.sectors"
            maxLength="2"
          ></x-more>
        }
        <div>{{ getCompanyDetailsString(getCompany(invitation)) }}</div>
      </div>
    </div>
    <div class="invitations-table_delivery-time">
      <div>{{ invitation.createdAt | ccDateFormatDistance: { addSuffix: true } }}</div>
    </div>
    @if (invitationType === 'received') {
      <div class="invitations-table_buttons">
        @if (invitation.status === 'Open') {
          <button
            [appBtnLoading]="acceptingInvitationId === invitation.id"
            [disabled]="isLoading || isImpersonating"
            (click)="accept(invitation)"
            class="btn btn-primary submit-btn"
          >
            {{ 'common.buttons.accept' | translate }}
          </button>
          <button
            [disabled]="isLoading || isImpersonating"
            (click)="decline(invitation)"
            class="btn secondary-btn"
          >
            {{ 'common.buttons.decline' | translate }}
          </button>
        }
      </div>
    }
    @if (invitationType === 'sent') {
      <div class="table_actions">
        <!--       <div ngbDropdown class="dropdown" #dropdown="ngbDropdown" [appDropdownPosition]="dropdown">
        <span [appFocusable] ngbDropdownToggle class="dropdown-toggle"></span>
        <div ngbDropdownMenu aria-labelledby="dropdown">
          <button [disabled]="isLoading || isImpersonating" class="dropdown-item" (click)="sendReminder(invitation)">
            {{ 'campaignsPage.buttons.sendReminders' | translate }}
          </button>
        </div>
      </div> -->
      </div>
    }
  </ng-template>

  <ng-template #placeholderTemplate>
    <div class="table_placeholder-message">
      <div>{{ 'networkPage.noInvitations' | translate }}</div>
    </div>
  </ng-template>
</app-table>

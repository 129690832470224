<div class="custom-selector">
  <div class="custom-selector_text">
    @if (value) {
      <span>{{ getDisplayValue() }}</span>
    }
    @if (!value) {
      <span class="custom-selector_placeholder">
        {{ placeholder || '' }}
      </span>
    }
  </div>
  <div
    [appFocusable]
    class="custom-selector_button"
    (click)="openSelector()"
  >
    <img appAssetSrc="assets/svg/common/link.svg" />
  </div>
</div>

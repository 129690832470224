<app-table
  #tasksTable
  [(data)]="data"
  [config]="tableConfig"
  [placeholderTemplate]="placeholderTemplate"
  [shrinkHeight]="false"
  [template]="rowTemplate"
>
  <ng-template
    #rowTemplate
    let-task
  >
    <div class="tasks-table_task">
      <div class="tasks-table_progress-circle">
        <nz-progress
          (click)="openTaskHistoryModal(task)"
          [nzFormat]="percentageFormatter"
          [nzPercent]="task.completion"
          [nzStrokeColor]="color(task.completion)"
          [appFocusable]
          nzType="circle"
          nzWidth="64"
        >
        </nz-progress>
      </div>
      <div class="tasks-table_task-info">
        <h4>{{ task.name }}</h4>
        @if (task.assignedByCompanyProduct) {
          <div>
            <span>
              {{ task.assignedByCompanyProduct.name }}
            </span>
            | <span> #{{ task.assignedByCompanyProduct.itemNumber }} </span>
          </div>
        }
        <div class="tasks-table_task-status">
          {{ stringHelpers.getTaskStatusString(task) | translate }}
        </div>
      </div>
    </div>
    <div>
      <div>
        @if (task.acceptedByUser) {
          <div class="tasks-table_assigned-to">
            <div class="d-flex">
              <user-logo [user]="task.acceptedByUser"></user-logo>
              <div class="tasks-table_user-info">
                <div>
                  {{ task.acceptedByUser?.firstName }}
                  {{ task.acceptedByUser?.lastName }}
                </div>
                <div>
                  <span>{{ task.acceptedByUser?.title }}</span>
                  @if (task.acceptedByUser?.title && task.assignedToCompany?.name) {
                    <span>, </span>
                  }
                  <span>{{ task.assignedToCompany?.name }}</span>
                </div>
              </div>
            </div>
          </div>
        }
        @if (!task.acceptedByUser && task.assignedTo?.users?.length) {
          <x-more-users
            [companyName]="task.assignedToCompany?.name"
            [data]="task.assignedTo?.users"
            [maxLength]="1"
          ></x-more-users>
        }
        @if (!task.acceptedByUser && task.assignedTo?.communicationRoles?.length) {
          <div class="tasks-table_departments">
            @if (task.assignedToCompany?.name) {
              <div>
                {{ task.assignedToCompany?.name }}
              </div>
            }
            <x-more
              [data]="task.assignedTo?.communicationRoles"
              [maxLength]="1"
              display="column"
              labelText="tasksPage.moreDepartments"
              singleLabelText="tasksPage.oneMoreDepartment"
            >
            </x-more>
          </div>
        }
      </div>
    </div>
    <div>
      <div class="tasks-table_assigned">
        <div>{{ task.assignedAt | ccDateFormatDistance: { addSuffix: true } }}</div>
        <div>{{ task.assignedAt | ccDateFormat: { date: 'long' } }}</div>
      </div>
    </div>
  </ng-template>

  <ng-template #placeholderTemplate>
    <div class="table_placeholder-message">
      <div>{{ 'tasksPage.noTasksToDisplay' | translate }}</div>
    </div>
  </ng-template>
</app-table>

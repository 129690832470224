import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';

import { CompanyDetailsPageComponent } from './company-details-page/company-details-page.component';
import { CompanyDetailsResolver } from './company-details-page/company-details-route-resolver';
import { NetworkPageComponent } from './network-page/network-page.component';
import { PermissionsGuard } from '../core/guards/permissions.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'connections',
    pathMatch: 'full',
  },
  {
    path: 'connections',
    component: NetworkPageComponent,
    data: {
      permissions: ['CompanyConnections_View'],
    },
    canActivate: [PermissionsGuard],
  },
  {
    path: 'invitationsSent',
    component: NetworkPageComponent,
    data: {
      permissions: ['CompanyInvitations_View'],
    },
    canActivate: [PermissionsGuard],
  },
  {
    path: 'invitationsReceived',
    component: NetworkPageComponent,
    data: {
      permissions: ['CompanyInvitations_View'],
    },
    canActivate: [PermissionsGuard],
  },
  {
    path: 'companyLists',
    component: NetworkPageComponent,
    data: {
      permissions: ['CompanyList_View'],
    },
    canActivate: [PermissionsGuard],
  },
  {
    path: 'company/:id',
    component: CompanyDetailsPageComponent,
    resolve: {
      company: CompanyDetailsResolver,
    },
    data: {
      permissions: ['Company_ViewCompanyProfile'],
    },
    canActivate: [PermissionsGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NetworkRoutingModule {}

<div class="modal-header"></div>

<div class="modal-body">
  <div class="invitation-sent-modal_image">
    <img appAssetSrc="assets/svg/network/invitation-sent.svg" />
  </div>

  <h1>{{ 'companyCreation.invitationSent' | translate }}</h1>

  <div class="t-align-center">
    {{ 'companyCreation.invitationSentTo' | translate: { name: company?.name } }}
  </div>

  <div class="buttons-group">
    <button
      class="btn btn-primary submit-btn"
      (click)="activeModal.close()"
    >
      {{ 'companyCreation.buttons.okGreat' | translate }}
    </button>
  </div>
</div>

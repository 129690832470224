import { Component, Input, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

import { Invitation } from '../../models/invitation';
import { NetworkService } from '../../network.service';

@UntilDestroy()
@Component({
  selector: 'decline-invitation-modal',
  templateUrl: './decline-invitation-modal.component.html',
  styleUrls: ['./decline-invitation-modal.component.scss'],
  standalone: true,
  imports: [FocusableDirective, AssetSrcDirective, FormsModule, TranslateModule],
})
export class DeclineInvitationModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly networkService = inject(NetworkService);
  public reason = '';
  @Input() invitation: Invitation;

  public declineInvitation(): void {
    this.networkService
      .declineInvitation({
        invitationId: this.invitation.id,
        comment: this.reason,
      })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.activeModal.close();
      });
  }
}

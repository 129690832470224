<div class="modal-body">
  @if (!isAddressSelection) {
    @if (!isEdit) {
      <h1>{{ 'locationEditModal.addLocation' | translate }}</h1>
    }
    @if (isEdit) {
      <h1>{{ 'locationEditModal.updateLocation' | translate }}</h1>
    }
    <form
      [formGroup]="form"
      autocomplete="off"
      class="add-location-modal_form"
    >
      <div
        [ngClass]="{
          'has-error': isFormSubmitted && form.get('locationName').invalid,
        }"
        class="form-group"
      >
        <label
          class="required"
          for="locationName"
        >
          {{ 'locationEditModal.controls.locationName.label' | translate }}
        </label>
        <input
          formControlName="locationName"
          id="locationName"
          placeholder="{{ 'locationEditModal.controls.locationName.placeholder' | translate }}"
          type="text"
        />
        <form-error-messages
          [control]="form.get('locationName')"
          [showErrors]="isFormSubmitted"
        >
        </form-error-messages>
      </div>
      <div
        [ngClass]="{
          'has-error': isFormSubmitted && form.get('locationType').invalid,
        }"
        class="form-group"
      >
        <label
          class="required"
          for="locationType"
        >
          {{ 'locationEditModal.controls.locationType.label' | translate }}
        </label>
        <app-select
          [options]="locationTypes"
          formControlName="locationType"
          placeholder="locationEditModal.controls.locationType.placeholder"
        >
        </app-select>
        <form-error-messages
          [control]="form.get('locationType')"
          [showErrors]="isFormSubmitted"
        >
        </form-error-messages>
      </div>
      <div
        [ngClass]="{ 'has-error': isFormSubmitted && form.get('gln').invalid }"
        class="form-group"
      >
        <label for="gln">
          {{ 'locationEditModal.controls.gln.label' | translate }}
        </label>
        <input
          formControlName="gln"
          id="gln"
          placeholder="{{ 'locationEditModal.controls.gln.placeholder' | translate }}"
          type="text"
        />
        <form-error-messages
          [control]="form.get('gln')"
          [showErrors]="isFormSubmitted"
        >
        </form-error-messages>
      </div>
      <div
        [ngClass]="{
          'has-error': isFormSubmitted && form.get('address').invalid,
        }"
        class="form-group"
      >
        <address-input
          [showErrors]="isFormSubmitted"
          formControlName="address"
          id="address"
        >
        </address-input>
      </div>
      <div class="buttons-group">
        <button
          (click)="activeModal.dismiss()"
          [disabled]="isLoading"
          class="btn btn-primary decline-btn"
        >
          {{ 'common.buttons.cancel' | translate }}
        </button>
        <button
          #submitBtn
          (click)="checkAddress()"
          [appBtnLoading]="isLoading"
          class="btn btn-primary submit-btn"
        >
          {{ 'common.buttons.save' | translate }}
        </button>
      </div>
    </form>
  }

  @if (isAddressSelection) {
    <div class="add-location-modal_address-selection">
      @if (matchedAddress) {
        <h1>{{ 'companyCreation.isAddressCorrect' | translate }}</h1>
        <p>{{ 'companyCreation.isAddressCorrectSubMsg' | translate }}</p>
      }
      @if (!matchedAddress) {
        <h1>{{ 'companyCreation.addressNotFound' | translate }}</h1>
        <p>{{ 'companyCreation.addressNotFoundSubMsg' | translate }}</p>
      }
      <address-selection
        [(ngModel)]="selectedAddress"
        [enteredAddress]="enteredAddress"
        [suggestedAddress]="matchedAddress"
      ></address-selection>
      <div class="buttons-group">
        @if (!matchedAddress) {
          <button
            #submitBtn
            (click)="isAddressSelection = false"
            [disabled]="isLoading"
            class="btn btn-primary submit-btn"
          >
            {{ 'companySelection.buttons.goBack' | translate }}
          </button>
        }
        @if (matchedAddress) {
          <button
            (click)="isAddressSelection = false"
            [disabled]="isLoading"
            class="btn btn-primary decline-btn"
          >
            {{ 'companySelection.buttons.goBack' | translate }}
          </button>
          <button
            #submitBtn
            (click)="createLocation()"
            [appBtnLoading]="isLoading"
            class="btn btn-primary submit-btn"
          >
            {{ 'common.buttons.save' | translate }}
          </button>
        }
      </div>
    </div>
  }
</div>

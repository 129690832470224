import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { OkModalComponent } from '@core/modals/ok-modal/ok-modal.component';
import { ModalService } from '@core/services/modal.service';

import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { NetworkService } from '../../network.service';

@UntilDestroy()
@Component({
  selector: 'app-invite-user-modal',
  templateUrl: './invite-user-modal.component.html',
  styleUrls: ['./invite-user-modal.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormErrorMessagesComponent,
    ButtonLoadingDirective,
    AssetSrcDirective,
    TranslateModule,
  ],
})
export class InviteUserModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly networkService = inject(NetworkService);
  private readonly modalService = inject(ModalService);
  public isInvitationSent = false;
  public form: UntypedFormGroup;
  public isLoading = false;
  public formSubmitted = false;

  public customErrors = {
    invalid_company_user_association: 'inviteUserModal.errors.invalidDomain',
    entity_already_exists: 'inviteUserModal.errors.emailExists',
    invalid_email_format: 'inviteUserModal.errors.invalidEmail',
  };

  constructor() {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });
  }

  public inviteUser(): void {
    this.form.setValue({
      email: this.form.value.email.trim(),
    });

    this.formSubmitted = true;
    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.networkService
      .sendUserInvitation(this.form.value.email.trim())
      .pipe(
        untilDestroyed(this),
        map(() => {
          this.isInvitationSent = true;
        }),
        catchError((err) => {
          const errors = err.error.errors[0];

          if (errors.errorCode === 'administrative_approval_required') {
            this.activeModal.dismiss();
            this.modalService.open({
              content: OkModalComponent,
              inputs: {
                title: 'inviteUserModal.adminApprovalRequired',
                text: 'inviteUserModal.adminApprovalRequiredMsg',
              },
            });
            return;
          }

          this.form.get('email').setErrors({ [err.status]: err.error.errorMessage });
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  public inviteMore(): void {
    this.form.reset();
    this.isInvitationSent = false;
  }

  public onEnterKeydown(event: KeyboardEvent, btn: HTMLElement): void {
    event.stopPropagation();
    btn.click();
  }
}

import { NgClass } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { type Company, type CompanySearchModel, type ConnectionStatus } from '@core/models/company';
import { ModalService } from '@core/services/modal.service';
import { type UserPermissions } from '@core/services/permission.service';
import { UserService } from '@core/services/user.service';

import { CompanySearchComponent } from '../../../company-lists/components/company-search/company-search.component';
import { CompanyInfoComponent } from '../../../core/components/company-info/company-info.component';
import { CompanyLogoComponent } from '../../../core/components/company-logo/company-logo.component';
import { LoaderComponent } from '../../../core/components/loader/loader.component';
import { NetworkService } from '../../network.service';
import { CompanyCreationModalComponent } from '../company-creation-modal/company-creation-modal.component';

@UntilDestroy()
@Component({
  selector: 'company-selector-modal',
  templateUrl: './company-selector-modal.component.html',
  styleUrls: ['./company-selector-modal.component.scss'],
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    NgClass,
    CompanyLogoComponent,
    CompanySearchComponent,
    FormsModule,
    LoaderComponent,
    CompanyInfoComponent,
    ButtonLoadingDirective,
    TranslateModule,
  ],
})
export class CompanySelectorModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly modalService = inject(ModalService);
  private readonly networkService = inject(NetworkService);
  private readonly userService = inject(UserService);

  @Input() title: string;
  @Input() connectionStatusIds: ConnectionStatus[] = [];
  @Input() nonConnectedCompanyAction: 'default' | 'connect' = 'default';

  public searchValue: string;
  public selectedCompany: Company;
  public isLoading = false;
  public isSearching = false;
  public isCompanyAdded = false;
  public isCreatingCompany = false;

  public get permissions(): UserPermissions {
    return this.userService.permissions;
  }

  public clearSearch(): void {
    this.selectedCompany = null;
    this.searchValue = '';
  }

  public onTypeaheadSelect(company: CompanySearchModel): void {
    if (!company) {
      return;
    }

    this.isLoading = true;
    this.networkService
      .getCompanyDetails(company.id)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe((res) => (this.selectedCompany = res));
  }

  public addNewCompany(): void {
    this.isCreatingCompany = true;

    this.modalService
      .open({
        content: CompanyCreationModalComponent,
        inputs: {
          strict: false,
          isSelecting: true,
          connectionStatusIds: this.connectionStatusIds,
          nonConnectedCompanyAction: this.nonConnectedCompanyAction,
        },
        options: {
          size: 'xl',
        },
      })
      .result.then((res) => {
        this.activeModal.close(res);
      })
      .catch(() => (this.isCreatingCompany = false));
  }

  public confirmSelection(): void {
    this.activeModal.close(this.selectedCompany);
  }
}

<div class="modal-header">
  <button
    [appFocusable]
    type="button"
    class="close-btn"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <img
      appAssetSrc="assets/svg/common/cross.svg"
      alt="{{ 'common.buttons.close' | translate }}"
    />
  </button>
</div>

<div class="modal-body">
  <h1>{{ 'declineInvitationModal.title' | translate }}</h1>
  <p>
    {{ 'declineInvitationModal.pleaseProvideReason' | translate: { company: invitation.requestFrom.name } }}
  </p>

  <textarea
    maxlength="300"
    ngbAutofocus
    class="decline-invitation_reason"
    autocomplete="false"
    type="text"
    [(ngModel)]="reason"
    id="reason"
    placeholder="{{ 'declineInvitationModal.reasonPlaceholder' | translate }}"
  >
  </textarea>

  <div class="buttons-group">
    <button
      class="btn btn-primary submit-btn"
      (click)="declineInvitation()"
    >
      {{ 'declineInvitationModal.buttons.declineInvitation' | translate }}
    </button>
  </div>
</div>

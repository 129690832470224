import { NgClass } from '@angular/common';
import { Component, forwardRef, Input, inject, OnInit } from '@angular/core';
import {
  type ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { FormErrorMessagesComponent } from '@core/components/form-error-messages/form-error-messages.component';

import { type Address } from '../../models/address';
import { EnumService } from '../../services/enum.service';
import { SelectComponent, type SelectOption } from '../select/select.component';

@Component({
  selector: 'address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, FormErrorMessagesComponent, SelectComponent, TranslateModule],
})
export class AddressInputComponent implements ControlValueAccessor, OnInit {
  private readonly enumService = inject(EnumService);
  @Input() disabled = false;
  @Input() showErrors = false;
  @Input() required = true;

  public countries: SelectOption[] = [];
  public form: UntypedFormGroup;

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      streetAddressLine1: new UntypedFormControl('', this.required ? [Validators.required] : []),
      streetAddressLine2: new UntypedFormControl('', []),
      city: new UntypedFormControl('', this.required ? [Validators.required] : []),
      state: new UntypedFormControl('', this.required ? [Validators.required] : []),
      zipCode: new UntypedFormControl('', this.required ? [Validators.required] : []),
      country: new UntypedFormControl('', this.required ? [Validators.required] : []),
    });

    this.form.valueChanges.subscribe((val) => {
      if (this.form.valid) {
        this.onChange({
          streetAddressLine1: val?.streetAddressLine1.trim(),
          streetAddressLine2: val?.streetAddressLine2.trim(),
          city: val?.city.trim(),
          state: val?.state,
          zipCode: val?.zipCode.trim(),
          country: val?.country.trim(),
        });
        this.onTouched();
      }
    });

    this.enumService.getCountries().then((res) => {
      this.countries = res;
    });
  }

  public writeValue(value: Address): void {
    if (value) {
      this.form.setValue({
        streetAddressLine1: value?.streetAddressLine1 || '',
        streetAddressLine2: value?.streetAddressLine2 || '',
        city: value?.city || '',
        state: value?.state || '',
        zipCode: value?.zipCode || '',
        country: value?.country || '',
      });
    }
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (val: Address) => {};

  private onTouched = () => {};
}

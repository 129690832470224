<div class="modal-header"></div>

<div class="modal-body">
  <h1>
    {{ (companyIds.length === 1 ? 'companyLists.addCompanyToList' : 'companyLists.addCompaniesToList') | translate }}
  </h1>

  <div class="form-group save-companies-to-list_list-selector">
    <label for="wfName">{{ 'companyLists.controls.listSelector.label' | translate }}</label>
    <app-select
      [(ngModel)]="selectedListId"
      [options]="availableLists"
      maxHeight="200"
      placeholder="companyLists.controls.listSelector.placeholder"
      [specialOptions]="selectSpecialOptions"
    >
    </app-select>
  </div>

  <div class="buttons-group">
    <button
      class="btn btn-primary decline-btn"
      [disabled]="isLoading"
      (click)="activeModal.dismiss()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
    <button
      type="submit"
      [appBtnLoading]="isLoading"
      [disabled]="!selectedListId"
      class="btn btn-primary submit-btn"
      (click)="saveToList()"
    >
      {{ 'common.buttons.continue' | translate }}
    </button>
  </div>
</div>

<div class="invitations-widget_header">
  <h4>
    {{ 'invitationsWidget.title' | translate }} <span>({{ totalCount }})</span>
  </h4>
  <a
    [appFocusable]
    [routerLink]="['/network-v2/invites/received']"
    >{{ 'invitationsWidget.viewAll' | translate: { total: totalCount } }}</a
  >
</div>

<div class="invitations-widget_content">
  @if (isLoading) {
    <loader></loader>
  }

  @if (!isLoading) {
    @if (loadedData.length === 0) {
      <div>
        {{ 'invitationsWidget.noInvitations' | translate }}
      </div>
    }
    @if (loadedData.length) {
      <div>
        @for (invitation of loadedData; track invitation) {
          <div class="invitations-widget_invitation">
            <company-logo
              class="invitations_invitation-logo"
              [company]="invitation.requestFrom"
            ></company-logo>
            <div class="invitations-widget_invitation-info">
              <h4
                [appFocusable]
                [routerLink]="['/network-v2/company/' + invitation.requestFrom.id]"
              >
                {{ invitation.requestFrom?.name }}
              </h4>
              <div>{{ invitation.createdAt | ccDateFormatDistance: { addSuffix: true } }}</div>
            </div>
            <div class="invitation-widget_buttons">
              @if (invitation.status === 'Open') {
                <button
                  [disabled]="isLoading || disabled"
                  (click)="decline(invitation)"
                  class="btn secondary-btn"
                >
                  {{ 'common.buttons.decline' | translate }}
                </button>
                <button
                  [appBtnLoading]="isLoading"
                  [disabled]="disabled"
                  (click)="accept(invitation)"
                  class="btn btn-primary submit-btn"
                >
                  {{ 'common.buttons.accept' | translate }}
                </button>
              }
            </div>
          </div>
        }
      </div>
    }
  }
</div>

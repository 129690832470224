import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { CompanyInfoComponent } from '@core/components/company-info/company-info.component';
import { CompanyLogoComponent } from '@core/components/company-logo/company-logo.component';
import { FormErrorMessagesComponent } from '@core/components/form-error-messages/form-error-messages.component';
import { LoaderComponent } from '@core/components/loader/loader.component';
import { SearchInputComponent } from '@core/components/search-input/search-input.component';
import { SelectComponent } from '@core/components/select/select.component';
import { TableComponent } from '@core/components/table/table.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { AppDropdownPositionDirective } from '@core/directives/dropdown-position.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { DateFormatDistancePipe } from '@core/pipes/date-format.pipe';

import { CompanyListsPageComponent } from './company-lists-page/company-lists-page.component';
import { CompanyListsRoutingModule } from './company-lists-routing.module';
import { CompanyListsService } from './company-lists.service';
import { CompanySearchComponent } from './components/company-search/company-search.component';
import { AddCompanyToListModalComponent } from './modals/add-company-to-list-modal/add-company-to-list-modal.component';
import { CreateListModalComponent } from './modals/create-list-modal/create-list-modal.component';
import { DeleteListModalComponent } from './modals/delete-list-modal/delete-list-modal.component';
import { RenameListModalComponent } from './modals/rename-list-modal/rename-list-modal.component';
import { SaveCompaniesToListModalComponent } from './modals/save-companies-to-list-modal/save-companies-to-list-modal.component';

@NgModule({
  providers: [CompanyListsService],
  imports: [
    CommonModule,
    CompanyListsRoutingModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FocusableDirective,
    AssetSrcDirective,
    LoaderComponent,
    CompanyInfoComponent,
    ButtonLoadingDirective,
    TableComponent,
    AppDropdownPositionDirective,
    CompanyLogoComponent,
    SearchInputComponent,
    FormErrorMessagesComponent,
    DateFormatDistancePipe,
    SelectComponent,
    CompanyListsPageComponent,
    CreateListModalComponent,
    RenameListModalComponent,
    DeleteListModalComponent,
    AddCompanyToListModalComponent,
    SaveCompaniesToListModalComponent,
    CompanySearchComponent,
  ],
  exports: [CompanyListsPageComponent, CompanySearchComponent],
})
export class CompanyListsModule {}

import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { type Product } from '@clover/products/models/product';
import { Company } from '@core/models/company';
import { type TableConfig } from '@core/models/table';

import { ProductLogoComponent } from '../../core/components/product-logo/product-logo.component';
import { TableComponent } from '../../core/components/table/table.component';

@Component({
  selector: 'app-company-products',
  templateUrl: './company-products.component.html',
  styleUrls: ['./company-products.component.scss'],
  standalone: true,
  imports: [TableComponent, ProductLogoComponent, TranslateModule],
})
export class CompanyProductsComponent implements OnInit {
  @Input() company: Company;

  public data: Product[] = [];
  public tableConfig: TableConfig;

  public ngOnInit(): void {
    this.tableConfig = {
      loadUrl: `/api/companies/${this.company.id}/products/search`,
      method: 'POST',
      loadLimit: 20,
      defaultSort: 'name',
      defaultSortDirection: 'asc',
      filters: [],
      columns: [
        {
          name: 'image',
          label: 'productsPage.columns.image',
          sortable: false,
          minWidth: '75px',
          maxWidth: '75px',
        },
        {
          name: 'name',
          label: 'productsPage.columns.name',
          sortable: true,
          minWidth: '200px',
          maxWidth: '2fr',
        },
        {
          name: 'internalItemNumber',
          label: 'productsPage.columns.itemNumber',
          sortable: true,
          minWidth: '100px',
          maxWidth: '1fr',
        },
        {
          name: 'globalTradeIdentificationNumber',
          label: 'productsPage.columns.gtin',
          sortable: true,
          minWidth: '100px',
          maxWidth: '1fr',
        },
        {
          name: 'vendor',
          label: 'productsPage.columns.vendor',
          sortable: true,
          minWidth: '150px',
          maxWidth: '1.5fr',
        },
      ],
    };
  }
}

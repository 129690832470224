import { Component, Input, ViewChild, ViewEncapsulation, inject, output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { TableComponent, TableComponent as TableComponent_1 } from '@core/components/table/table.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { OkModalComponent } from '@core/modals/ok-modal/ok-modal.component';
import { type TableConfig } from '@core/models/table';
import { ModalService } from '@core/services/modal.service';
import { StringHelpersService } from '@core/services/string-helpers.service';
import { ToastrService } from '@core/services/toastr.service';

import { type CompanyLocation } from '../../../core/models/location';
import { AddLocationModalComponent } from '../../modals/add-location-modal/add-location-modal.component';
import { ConfirmLocationDeleteModalComponent } from '../../modals/confirm-location-delete-modal/confirm-location-delete-modal.component';
import { DeleteLocationModalComponent } from '../../modals/delete-location-modal/delete-location-modal.component';
import { NetworkService } from '../../network.service';

@UntilDestroy()
@Component({
  selector: 'company-locations',
  templateUrl: './company-locations.component.html',
  styleUrls: ['./company-locations.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TableComponent_1, FocusableDirective, AssetSrcDirective],
})
export class CompanyLocationsComponent {
  private readonly stringHelpers = inject(StringHelpersService);
  private readonly modalService = inject(ModalService);
  private readonly networkService = inject(NetworkService);
  private readonly toastr = inject(ToastrService);
  @Input() companyId: number;
  @Input() locations: CompanyLocation[];
  @Input() isEditable: boolean;
  locationEdited = output<CompanyLocation>();
  locationDeleted = output<{
    deletedLocation: CompanyLocation;
    newContactLocation: CompanyLocation;
  }>();

  @ViewChild('table') table: TableComponent;

  public tableConfig: TableConfig = {
    columns: [
      {
        name: 'name',
        label: 'common.strings.name',
        sortable: true,
        minWidth: '150px',
        maxWidth: '1fr',
        manualSortValueFunc: (location) => {
          return location.name;
        },
      },
      {
        name: 'location',
        label: 'companyLocations.type',
        sortable: true,
        minWidth: '150px',
        maxWidth: '1fr',
        manualSortValueFunc: (location) => {
          return location.type.title;
        },
      },
      {
        name: 'gln',
        label: 'companyLocations.gln',
        sortable: true,
        minWidth: '150px',
        maxWidth: '1fr',
        manualSortValueFunc: (location) => {
          return location.globalLocationNumber || 0;
        },
      },
      {
        name: 'address',
        label: 'common.strings.address',
        sortable: true,
        minWidth: '300px',
        maxWidth: '2fr',
        manualSortValueFunc: (location) => {
          return this.getAddressString(location);
        },
      },
      {
        name: 'action',
        label: '',
        sortable: false,
        minWidth: '90px',
        maxWidth: '90px',
      },
    ],
    hideControls: true,
  };

  constructor() {
    this.getAddressString = this.getAddressString.bind(this);
  }

  public getAddressString(location: any): string {
    return this.stringHelpers.getAddressString(location.address);
  }

  public editLocation(location: CompanyLocation): void {
    this.modalService
      .open({
        content: AddLocationModalComponent,
        inputs: {
          location,
          isEdit: true,
          companyId: this.companyId,
        },
      })
      .result.then((res) => {
        this.locationEdited.emit(res);
      })
      .catch(() => {});
  }

  public deleteLocation(location: CompanyLocation): void {
    this.modalService
      .open({
        content: ConfirmLocationDeleteModalComponent,
      })
      .result.then(() => {
        this.networkService
          .deleteLocation(this.companyId, location.id)
          .pipe(
            untilDestroyed(this),
            map(() => {
              this.locationDeleted.emit({
                deletedLocation: location,
                newContactLocation: null,
              });
            }),
            catchError((err) => {
              const error = err?.error?.errors[0];

              if (!error) {
                return;
              }

              switch (error.errorCode) {
                case 'has_associated_contacts':
                  this.modalService
                    .open({
                      content: DeleteLocationModalComponent,
                      inputs: {
                        companyId: this.companyId,
                        locations: this.locations.filter((loc) => loc.id !== location.id),
                        deletedLocation: location,
                        numberOfContacts: error.data?.count,
                      },
                    })
                    .result.then((res) => {
                      this.locationDeleted.emit({
                        deletedLocation: location,
                        newContactLocation: res,
                      });
                      this.displayLocationDeletedModal();
                    })
                    .catch(() => {});
                  break;

                default:
                  this.toastr.displayServerErrors(err);
                  break;
              }

              return of(err);
            }),
          )
          .subscribe();
      });
  }

  private displayLocationDeletedModal(): void {
    this.modalService.open({
      content: OkModalComponent,
      options: {
        size: 'sm',
      },
      inputs: {
        title: 'deleteLocationModal.locationDeleted',
        text: 'deleteLocationModal.locationDeletedMsg',
      },
    });
  }
}

<div class="modal-body">
  @if (!isCompanyAdded) {
    <button
      [appFocusable]
      class="close-btn"
      aria-label="Close"
      (click)="activeModal.dismiss()"
    >
      <img
        appAssetSrc="assets/svg/common/cross.svg"
        alt="{{ 'common.buttons.close' | translate }}"
      />
    </button>
    <div class="add-company-to-list_header">
      <p>{{ 'companyLists.searchForCompany' | translate }}</p>
    </div>
    <div
      class="add-company-to-list_search"
      [ngClass]="{
        'has-selected-company': selectedCompany,
      }"
    >
      <company-search
        #companySearch
        [(ngModel)]="searchValue"
        [onlyConnected]="true"
        [includeDeferred]="true"
        (selectionChanged)="onTypeaheadSelect($event)"
      ></company-search>
      <div class="add-company-to-list_company-details-container">
        @if (isLoading) {
          <loader></loader>
        }
        @if (!isLoading && selectedCompany) {
          <company-info
            class="add-company-to-list_company-details"
            [company]="selectedCompany"
          >
          </company-info>
        }
      </div>
    </div>
    <div class="add-company-to-list_buttons">
      <button
        [appFocusable]
        [disabled]="isLoading || !selectedCompany"
        (click)="clearSearch()"
        class="btn btn-link"
      >
        {{ 'common.buttons.clearSearch' | translate }}
      </button>
      <button
        [appBtnLoading]="isLoading"
        [disabled]="!selectedCompany"
        (click)="confirmSelection()"
        class="btn btn-primary submit-btn"
      >
        {{ 'common.buttons.confirmSelection' | translate }}
      </button>
    </div>
  }

  @if (isCompanyAdded) {
    <div class="add-company-to-list_success-screen">
      <img appAssetSrc="assets/png/company-added.png" />
      <h1>{{ 'companyLists.companyAdded' | translate }}</h1>
      <p>{{ 'companyLists.companyAddedMsg' | translate }}</p>
      <div class="buttons-group">
        <button
          class="btn btn-primary decline-btn"
          (click)="addAnother()"
        >
          {{ 'companyLists.buttons.addAnother' | translate }}
        </button>
        <button
          (click)="activeModal.close()"
          class="btn btn-primary submit-btn"
        >
          {{ 'companyLists.buttons.imDone' | translate }}
        </button>
      </div>
    </div>
  }
</div>

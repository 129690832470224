<div class="location-selector_table">
  <h1>{{ 'locationSelector.selectALocation' | translate }}</h1>
  <app-table
    [(data)]="locations"
    [config]="tableConfig"
    [template]="rowTemplate"
  >
    <ng-template
      #rowTemplate
      let-location
    >
      <div>
        <app-radio
          [checked]="selectedLocation && location.id === selectedLocation.id"
          (click)="selectLocation(location)"
        ></app-radio>
      </div>
      <div>
        <div>
          {{ location.name }}
        </div>
      </div>

      <div>
        <div>
          {{ location.type.title }}
        </div>
      </div>

      <div>
        <div>
          {{ location.globalLocationNumber }}
        </div>
      </div>

      <div>
        <div>
          {{ getAddressString(location) }}
        </div>
      </div>
    </ng-template>
  </app-table>
  <div class="buttons-group">
    <button
      (click)="activeModal.dismiss()"
      class="btn btn-primary decline-btn"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
    <button
      (click)="submitSelection()"
      [disabled]="!selectedLocation"
      class="btn btn-primary submit-btn"
    >
      {{ 'locationSelector.buttons.selectLocation' | translate }}
    </button>
  </div>
</div>

<div class="profile-completion-wizard_panel">
  <div class="profile-completion-wizard_company-logo">
    <company-logo [company]="companyInfo"></company-logo>
    <div>{{ companyInfo?.name }}</div>
  </div>
  <div class="profile-completion-wizard_step-info">
    <img [appAssetSrc]="steps[currentStep.id].logo" />
    <h3>{{ steps[currentStep.id].title | translate }}</h3>
    <p>{{ steps[currentStep.id].description | translate }}</p>
  </div>
  <div class="profile-completion-wizard_profress-bar">
    <div>
      {{ 'companyProfileWizard.of' | translate: { number: currentStep.id + 1, total: steps.length } }}
    </div>
    <app-progress-bar
      [completion]="((currentStep.id + 1) * 100) / steps.length"
      [showPercent]="false"
    >
    </app-progress-bar>
  </div>
</div>

<div class="profile-completion-wizard_step-area">
  @if (isLoading) {
    <loader></loader>
  } @else {
    <div class="profile-completion-wizard_step">
      <scrollable-area>
        @if (currentStep.id === 0) {
          <form
            [formGroup]="form"
            autocomplete="off"
          >
            <div
              [ngClass]="{
                'has-error': isFormSubmitted && form.get('websiteUrl').invalid,
              }"
              class="form-group"
            >
              <label for="website">
                {{ 'companySelection.controls.website.label' | translate }}
              </label>
              <input
                formControlName="websiteUrl"
                id="website"
                placeholder="{{ 'companySelection.controls.website.placeholder' | translate }}"
                type="text"
              />
              <form-error-messages
                [control]="form.get('websiteUrl')"
                [showErrors]="isFormSubmitted"
              >
              </form-error-messages>
            </div>
            <div
              [ngClass]="{
                'has-error': isFormSubmitted && form.get('companyType').invalid,
              }"
              class="form-group"
            >
              <label for="companyType">{{ 'companyDetails.companyType' | translate }}</label>
              <app-select
                [options]="companyTypes"
                formControlName="companyType"
              >
              </app-select>
              <form-error-messages
                [control]="form.get('companyType')"
                [customErrors]="{
                  required: 'companyDetails.errors.companyTypeRequired',
                }"
                [showErrors]="isFormSubmitted"
              >
              </form-error-messages>
            </div>
            <div
              [ngClass]="{
                'has-error': isFormSubmitted && form.get('businessType').invalid,
              }"
              class="form-group"
            >
              <label for="businessType">{{ 'companyDetails.businessType' | translate }}</label>
              <app-select
                [maxHeight]="250"
                [options]="businessTypes"
                formControlName="businessType"
              >
              </app-select>
              <form-error-messages
                [control]="form.get('businessType')"
                [customErrors]="{
                  required: 'companyDetails.errors.businessTypeRequired',
                }"
                [showErrors]="isFormSubmitted"
              >
              </form-error-messages>
            </div>
            <div
              [ngClass]="{
                'has-error': isFormSubmitted && form.get('numberOfEmployees').invalid,
              }"
              class="form-group"
            >
              <label for="numberOfEmployees">{{ 'companyDetails.numberOfEmployees' | translate }}</label>
              <app-select
                [maxHeight]="175"
                [options]="employeeNumbers"
                formControlName="numberOfEmployees"
              >
              </app-select>
              <form-error-messages
                [control]="form.get('numberOfEmployees')"
                [customErrors]="{
                  required: 'companyDetails.errors.employeesNumberRequired',
                }"
                [showErrors]="isFormSubmitted"
              >
              </form-error-messages>
            </div>
          </form>
        }
        @if (currentStep.id === 1) {
          <form
            [formGroup]="form"
            autocomplete="off"
          >
            <div
              [ngClass]="{
                'has-error': isFormSubmitted && form.get('description').invalid,
              }"
              class="form-group profile-completion-wizard_description"
            >
              <label for="description">{{ 'companyDetails.companyDescription' | translate }}</label>
              <textarea
                formControlName="description"
                id="description"
                placeholder="{{ 'companyDetails.controls.description.placeholder' | translate }}"
              ></textarea>
              <form-error-messages
                [control]="form.get('description')"
                [customErrors]="{
                  required: 'companyDetails.errors.descriptionRequired',
                }"
                [showErrors]="isFormSubmitted"
              >
              </form-error-messages>
            </div>
          </form>
        }
        @if (currentStep.id === 2) {
          <label>{{ 'companyProfileWizard.selectedSectors' | translate }}</label>
          @if (isSelecting) {
            <div class="profile-completion-wizard_tree">
              <scrollable-area>
                <treeview-picker
                  [(ngModel)]="selectedOptions"
                  [options]="sectors"
                  onlyLowLevel="true"
                >
                </treeview-picker>
              </scrollable-area>
            </div>
          }
          @if (!isSelecting) {
            <form
              [formGroup]="form"
              autocomplete="off"
            >
              <div
                [ngClass]="{
                  'has-error': isFormSubmitted && form.get('sectors').invalid,
                }"
                class="form-group"
              >
                <sectors-selector
                  [onAddFunc]="openTreeSelector"
                  formControlName="sectors"
                >
                </sectors-selector>
                <form-error-messages
                  [control]="form.get('sectors')"
                  [customErrors]="{
                    required: 'companyDetails.errors.sectorsRequired',
                  }"
                  [showErrors]="isFormSubmitted"
                >
                </form-error-messages>
              </div>
            </form>
          }
        }
        @if (currentStep.id === 3) {
          <label>{{ 'companyProfileWizard.selectedCategories' | translate }}</label>
          @if (isSelecting) {
            <div class="profile-completion-wizard_tree">
              <scrollable-area>
                <treeview-picker
                  [(ngModel)]="selectedOptions"
                  [options]="productCategories"
                  onlyLowLevel="true"
                >
                </treeview-picker>
              </scrollable-area>
            </div>
          }
          @if (!isSelecting) {
            <form
              [formGroup]="form"
              autocomplete="off"
            >
              <div
                [ngClass]="{
                  'has-error': isFormSubmitted && form.get('productCategories').invalid,
                }"
                class="form-group"
              >
                <product-categories-selector
                  [onAddFunc]="openTreeSelector"
                  formControlName="productCategories"
                >
                </product-categories-selector>
                <form-error-messages
                  [control]="form.get('productCategories')"
                  [customErrors]="{
                    required: 'companyDetails.errors.categoriesRequired',
                  }"
                  [showErrors]="isFormSubmitted"
                >
                </form-error-messages>
              </div>
            </form>
          }
        }
      </scrollable-area>
    </div>
    <div class="profile-completion-wizard_step-buttons">
      <button style="display: none"></button>
      @if (currentStep.id === 0) {
        <button
          (click)="modalClose.emit()"
          class="btn btn-primary decline-btn"
        >
          {{ 'common.buttons.cancel' | translate }}
        </button>
      }
      @if (currentStep.id !== 0) {
        <button
          (click)="onBackClick()"
          class="btn btn-primary decline-btn"
        >
          {{ 'common.buttons.back' | translate }}
        </button>
      }
      <button
        (click)="onNextClick()"
        [disabled]="(isFormSubmitted && form.invalid) || isLoading"
        class="btn btn-primary submit-btn"
      >
        {{ 'common.buttons.next' | translate }}
      </button>
    </div>
  }
</div>

import { Component, Input, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ScrollableAreaComponent } from '@core/components/scrollable-area/scrollable-area.component';
import {
  TreeviewPickerComponent,
  type TreeviewPickerOption,
} from '@core/components/treeview-picker/treeview-picker.component';
import { type EnumerationModel } from '@core/models/enumerationModel';
import { HelpersService } from '@core/services/helpers.service';

@Component({
  selector: 'product-categories-selector-modal',
  templateUrl: './product-categories-selector-modal.component.html',
  styleUrls: ['./product-categories-selector-modal.component.scss'],
  standalone: true,
  imports: [ScrollableAreaComponent, FormsModule, TreeviewPickerComponent, TranslateModule],
})
export class ProductCategoriesSelectorModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly helpers = inject(HelpersService);
  @Input() options: TreeviewPickerOption[] = [];
  @Input() selectedCategories: EnumerationModel[] = [];
  @Input() singleSelect = false;

  public selectedOptions: string[] = [];

  public ngOnInit(): void {
    if (this.selectedCategories) {
      this.selectedOptions = this.selectedCategories.filter((cat) => !!cat).map((cat) => cat.key);
    }
  }

  public save(): void {
    const options = this.selectedOptions
      .map((value) => {
        const option = this.helpers.treeSearch(this.options, value);
        return option
          ? {
              key: option.value,
              title: option.label,
            }
          : null;
      })
      .filter((value) => !!value);

    this.activeModal.close(options);
  }
}

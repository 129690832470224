<div class="company-details_header">
  <div class="company-details_logo-container">
    <div
      [class.loading]="isUploadingImage"
      class="company-details_logo"
    >
      <company-logo [company]="company"></company-logo>
      <input
        #fileInput
        (change)="onLogoSelected($event)"
        type="file"
      />
      @if (
        !isImpersonating &&
        (permissions.AllCompanies_PublicInfo_Edit || (permissions.Company_PublicInfo_Edit && isMyCompany))
      ) {
        @if (!isUploadingImage) {
          <button
            (click)="openFileSelector()"
            [appFocusable]
            class="edit-image-btn"
          ></button>
        }
        @if (isUploadingImage) {
          <button class="image-loading-btn"></button>
        }
      }
    </div>

    @if (!isMyCompany) {
      <connection-status [status]="company.connectionStatus"></connection-status>
    }

    <div class="company-details_header-buttons">
      @if (!isMyCompany) {
        <div class="company-details_connect-button">
          @if (
            (permissions.Company_Connect || isIndividualCompany) &&
            (!company.connectionStatus || company.connectionStatus === 'NotConnected')
          ) {
            <button
              (click)="sendInvitation()"
              class="btn btn-primary submit-btn"
            >
              <img appAssetSrc="assets/svg/company-details/connect.svg" />
              {{ 'companyDetails.buttons.connect' | translate }}
            </button>
          }
        </div>
      }
      @if (
        permissions.CompanyList_AddCompany &&
        (company.connectionStatus === 'Connected' || company.connectionStatus === 'Invited')
      ) {
        <button
          (click)="saveCompanyToList()"
          [appFocusable]
          class="btn btn-link company-details_save-to-list-btn"
        >
          {{ 'advancedSearchPage.buttons.addCompanyToList' | translate }}
        </button>
      }
    </div>
  </div>

  <app-company-header
    (dataChanged)="onCompanyDataChanged($event)"
    [company]="company"
    [isEditable]="permissions.AllCompanies_PublicInfo_Edit || (permissions.Company_PublicInfo_Edit && isMyCompany)"
  >
  </app-company-header>
</div>

<div class="company-details_tabs">
  <div class="tabs">
    <div
      (click)="activeTab = tabs.information"
      [class.active]="activeTab === tabs.information"
      [appFocusable]
      class="tabs_tab"
    >
      {{ 'companyDetails.tabs.information' | translate }}
    </div>
    @if (!isMyCompany) {
      <div
        (click)="activeTab = tabs.files"
        [class.active]="activeTab === tabs.files"
        [appFocusable]
        class="tabs_tab"
      >
        {{ 'companyDetails.tabs.files' | translate }}
      </div>
    }
    <div
      (click)="activeTab = tabs.taskHistory"
      [class.active]="activeTab === tabs.taskHistory"
      [appFocusable]
      class="tabs_tab"
    >
      {{ 'companyDetails.tabs.taskHistory' | translate }}
    </div>
    @if (!isMyCompany) {
      <div
        (click)="activeTab = tabs.products"
        [class.active]="activeTab === tabs.products"
        [appFocusable]
        class="tabs_tab"
      >
        {{ 'companyDetails.tabs.products' | translate }}
      </div>
    }
  </div>
</div>

<div class="company-details_tab-content">
  @if (activeTab === tabs.information) {
    <app-company-information
      [(company)]="company"
      [isMyCompany]="isMyCompany"
    >
    </app-company-information>
  }
  @if (activeTab === tabs.taskHistory) {
    <app-company-task-history
      [company]="company"
      [isMyCompany]="isMyCompany"
    >
    </app-company-task-history>
  }
  @if (activeTab === tabs.products) {
    <app-company-products [company]="company"></app-company-products>
  }
  @if (activeTab === tabs.files) {
    <company-files
      [masterCompanyId]="company.id"
      [sharedCompanyId]="userService.profile$.value.companyId"
    >
    </company-files>
  }
</div>

<div
  (click)="value = enteredAddress"
  class="address-selection_address-details"
>
  <div>
    <app-radio [(ngModel)]="value"></app-radio>
    <span class="radio-label">
      {{ 'companyCreation.enteredAddress' | translate }}
    </span>
  </div>
  <div>
    <div [ngClass]="{ invalid: !suggestedAddress }">
      {{ enteredAddress.streetAddressLine1 }}
    </div>
    <div [ngClass]="{ invalid: !suggestedAddress }">
      {{ enteredAddress.streetAddressLine2 }}
    </div>
    <div [ngClass]="{ invalid: !suggestedAddress }">
      <span>{{ enteredAddress.city }}</span
      >, <span>{{ enteredAddress.state }}</span
      >,
      <span>{{ enteredAddress.zipCode }}</span>
    </div>
  </div>
</div>

@if (suggestedAddress) {
  <div
    (click)="value = suggestedAddress"
    class="address-selection_address-details"
  >
    <div>
      <app-radio [(ngModel)]="value"></app-radio>
      <span class="radio-label">
        {{ 'companyCreation.suggestedAddress' | translate }}
      </span>
    </div>
    <div>
      <div [class.invalid]="enteredAddress.streetAddressLine1 !== suggestedAddress.streetAddressLine1">
        {{ suggestedAddress.streetAddressLine1 }}
      </div>
      <div [class.invalid]="enteredAddress.streetAddressLine2 !== suggestedAddress.streetAddressLine2">
        {{ suggestedAddress.streetAddressLine2 }}
      </div>
      <div>
        <span [class.invalid]="enteredAddress.city !== suggestedAddress.city">{{ suggestedAddress.city }}</span
        >, <span [class.invalid]="enteredAddress.state !== suggestedAddress.state">{{ suggestedAddress.state }}</span
        >,
        <span [class.invalid]="enteredAddress.zipCode !== suggestedAddress.zipCode">{{
          suggestedAddress.zipCode
        }}</span>
      </div>
    </div>
  </div>
}

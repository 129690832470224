<input
  #d="ngbDatepicker"
  [(ngModel)]="value"
  [disabled]="disabled"
  ngbDatepicker
/>

<div
  [appFocusable]
  (click)="d.toggle()"
>
  @if (dateString) {
    <span>{{ dateString }}</span>
  } @else {
    <span class="date-picker_placeholder">
      {{ (placeholder | translate) || '' }}
    </span>
  }
</div>

<img
  (click)="d.toggle()"
  alt=""
  appAssetSrc="assets/svg/common/calendar.svg"
  [appFocusable]
/>

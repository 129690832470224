<div class="modal-header"></div>

<div class="modal-body">
  <div class="pending-approval-modal_image">
    <img appAssetSrc="assets/svg/network/pending-approval.svg" />
  </div>

  <h1>{{ 'companyPendingApprovalModal.title' | translate }}</h1>

  <div class="t-align-center">
    {{ 'companyPendingApprovalModal.message' | translate }}
  </div>

  <div class="buttons-group">
    <button
      class="btn btn-primary submit-btn"
      (click)="activeModal.close()"
    >
      {{ 'companyPendingApprovalModal.buttons.gotIt' | translate }}
    </button>
  </div>
</div>

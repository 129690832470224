import { NgClass } from '@angular/common';
import { Component, Input, type SimpleChanges, ViewEncapsulation, inject, OnChanges } from '@angular/core';
import {
  type AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { type Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { CustomFieldType } from '@clover/custom-fields/models/custom-field';
import { CustomFieldsSchema } from '@clover/custom-forms/models/custom-fields-schema';
import { type SelectOption } from '@core/components/select/select.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { CompanyData, type CompanyDataField } from '@core/models/company';
import { type User } from '@core/models/user';
import { HelpersService } from '@core/services/helpers.service';

import { DatePickerComponent } from '../../../core/components/date-picker/date-picker.component';
import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { LocationSelectorComponent } from '../../../core/components/location-selector/location-selector.component';
import { SelectComponent } from '../../../core/components/select/select.component';
import { TooltipComponent } from '../../../core/components/tooltip/tooltip.component';
import { UserLogoComponent } from '../../../core/components/user-logo/user-logo.component';
import { NumberFormatPipe } from '../../../core/pipes/number-format.pipe';
import { ContactSelectorComponent } from '../contact-selector/contact-selector.component';

@UntilDestroy()
@Component({
  selector: 'company-custom-info',
  templateUrl: './company-custom-info.component.html',
  styleUrls: ['./company-custom-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    TooltipComponent,
    UserLogoComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgxMaskDirective,
    SelectComponent,
    DatePickerComponent,
    LocationSelectorComponent,
    ContactSelectorComponent,
    FormErrorMessagesComponent,
    ButtonLoadingDirective,
    TranslateModule,
    NumberFormatPipe,
  ],
})
export class CompanyCustomInfoComponent implements OnChanges {
  private readonly helpers = inject(HelpersService);
  @Input() companyId: number;
  @Input() section: CompanyData;
  @Input() isEditable: boolean;
  @Input() showAccessType: boolean;
  @Input() saveFunc: (data: any) => Observable<any>;
  @Input() customFieldsSchema: CustomFieldsSchema;

  public isEdit = false;
  public form: UntypedFormGroup;
  public isFormSubmitted = false;
  public isLoading = false;

  public selectOptions: Record<string, SelectOption[]> = {};

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.customFieldsSchema && this.customFieldsSchema) {
      this.section.fields.forEach((field) => {
        if (field.type === CustomFieldType.OptionList || field.type === CustomFieldType.MultiOptionList) {
          const control = this.customFieldsSchema.proprietaryFields.find(
            (schemaField) => +schemaField.key === field.fieldId,
          );

          field.helpText = control?.helpText;
          this.selectOptions[field.fieldId] = control ? control.options : [];
        }
      });
    }
  }

  public cancel(): void {
    this.isEdit = false;
  }

  public save(): void {
    this.isLoading = true;
    const data = this.form.value;
    this.section.fields.forEach((field) => {
      if (field.type === CustomFieldType.OptionList) {
        const selectedValue = data[field.fieldId];
        data[field.fieldId] = {
          key: selectedValue,
          title: selectedValue,
        };
      }

      if (field.type === CustomFieldType.MultiOptionList) {
        const selectedValue = data[field.fieldId];
        data[field.fieldId] = selectedValue?.map((val) => {
          return {
            key: val,
            title: val,
          };
        });
      }
    });

    this.saveFunc(data)
      .pipe(
        untilDestroyed(this),
        map(() => (this.isEdit = false)),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  public edit(): void {
    const controls: Record<string, AbstractControl> = {};
    this.section.fields.forEach((field) => {
      controls[field.fieldId] = new UntypedFormControl(this.getFieldValue(field), [Validators.required]);
    });

    this.form = new UntypedFormGroup(controls);

    this.isEdit = true;
  }

  public getFieldValue(field: CompanyDataField): void {
    if (field.type === CustomFieldType.MultiOptionList) {
      return field?.value?.keys;
    }
    return field?.value;
  }

  public getProprietaryFieldValue(field: CompanyDataField): string {
    return this.helpers.getProprietaryFieldValue(field);
  }

  public getContactValue(field: CompanyDataField): User {
    return this.helpers.getContactValue(field);
  }
}

import { NgClass } from '@angular/common';
import { Component, Input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { UserLogoComponent } from '@core/components/user-logo/user-logo.component';
import { FocusableDirective } from '@core/directives/focusable.directive';

import { type UserInfo } from '../../models/user';

@Component({
  selector: 'x-more-users',
  templateUrl: './x-more-users.component.html',
  styleUrls: ['./x-more-users.component.scss'],
  standalone: true,
  imports: [UserLogoComponent, FocusableDirective, NgClass, TranslateModule],
})
export class XMoreUsersComponent {
  @Input() data: UserInfo[] = [];
  @Input() maxLength = 1;
  @Input() companyName: string;
  @Input() disabled = false;
  expandChanged = output<boolean>();

  public isExpanded = false;

  public getVisibleUsers(): UserInfo[] {
    return this.isExpanded ? this.data : this.data.slice(0, this.maxLength);
  }

  public expand(): void {
    if (!this.disabled) {
      this.isExpanded = true;
      setTimeout(() => {
        this.expandChanged.emit(true);
      }, 0);
    }
  }
}

const completionGreen = '#08A262';
const completionYellow = '#F2994A';
const completionRed = '#F22206';

export const percentageFormatter = (percent: number): string => `${percent}%`;

export const colorFormatter = (percent: number): string => {
  if (percent > 70) return completionGreen;
  if (percent > 35) return completionYellow;
  return completionRed;
};

export interface TrustScore {
  id: number;
  label: string;
  logoUrl?: string;
  color?: string;
}

export const TrustScores: TrustScore[] = [
  {
    id: 0,
    label: 'a+',
    logoUrl: 'assets/svg/trust-scores/a+.svg',
    color: '#34A853',
  },
  {
    id: 1,
    label: 'b-',
    logoUrl: 'assets/svg/trust-scores/b-.svg',
    color: '#1EA2E2',
  },
];

<app-table
  [config]="tableConfig"
  [template]="rowTemplate"
  [(data)]="data"
>
  <ng-template
    #rowTemplate
    let-company
  >
    <div>
      <div>
        {{ company.name }}
      </div>
    </div>

    <div>
      <div>
        {{ company.relationshipType }}
      </div>
    </div>

    <div>
      <div>
        {{ getAddressString(company) }}
      </div>
    </div>
  </ng-template>
</app-table>

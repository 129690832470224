import { Component, forwardRef, Input, type OnInit, ViewChild, ViewEncapsulation, inject, output } from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { type Observable } from 'rxjs';

import {
  SearchInputComponent,
  SearchInputComponent as SearchInputComponent_1,
} from '@core/components/search-input/search-input.component';
import { type Company, CompanySearchModel } from '@core/models/company';

import { CompanyLogoComponent } from '../../../core/components/company-logo/company-logo.component';
import { CompanyListsService } from '../../company-lists.service';

@UntilDestroy()
@Component({
  selector: 'company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanySearchComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CompanyLogoComponent, SearchInputComponent_1, FormsModule, TranslateModule],
})
export class CompanySearchComponent implements ControlValueAccessor, OnInit {
  private readonly companyListService = inject(CompanyListsService);
  @Input() onlyConnected = false;
  @Input() includeDeferred = true;
  @Input() preselectedCompany: CompanySearchModel;
  @Input() preloadedMatches: CompanySearchModel[];
  @Input() placeholder: string;
  @Input() companiesIdsToExclude: number[] = null;
  selectionChanged = output<CompanySearchModel>();
  matchedCompaniesChanged = output<CompanySearchModel[]>();
  @ViewChild('searchInput') searchInput: SearchInputComponent;

  public disabled: boolean;
  public selectedCompany: Company;
  public companySearchFunc: (query: string, limit: number, offset: number) => Observable<{ data: any; total: number }>;

  constructor() {
    this.companySearchFunc = (query: string, limit: number, offset: number) => {
      return this.companyListService.searchCompanies(
        query,
        this.onlyConnected,
        this.includeDeferred,
        this.companiesIdsToExclude,
        limit,
        offset,
      );
    };
    this.companySearchFunc = this.companySearchFunc.bind(this);
  }

  private _searchValue: string;

  public get searchValue(): string {
    return this._searchValue;
  }

  public set searchValue(val: string) {
    this._searchValue = val;
    this.onChange(val);
    this.onTouched();
  }

  public get isSearching(): boolean {
    return this.searchInput.searching;
  }

  public ngOnInit(): void {
    this.selectedCompany = this.preselectedCompany;
  }

  public onSelectionChanged(company: CompanySearchModel | any) {
    this.selectedCompany = company;
    this.selectionChanged.emit(company);
  }

  public onMatchesChanged(companies: CompanySearchModel[] | any) {
    this.matchedCompaniesChanged.emit(companies);
  }

  public writeValue(value: string): void {
    this._searchValue = value;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (val: string) => {};

  private onTouched = () => {};
}

@if (company) {
  @if (showLogo) {
    <div class="company-info_logo">
      <company-logo [company]="company"></company-logo>
      <div class="company-info_company-name">
        {{ company.name || company.companyAliasName }}
      </div>
    </div>
  }
  <div class="company-info_data">
    <div>
      <div class="detailed-info_detail-name">
        {{ 'companyDetails.headquartersAddress' | translate }}
      </div>
      <div class="detailed-info_detail-value">
        @if (address) {
          <div>
            <div>
              {{ address?.streetAddressLine1 }}
            </div>
            <div>
              {{ address?.streetAddressLine2 }}
            </div>
            <div>
              {{ address?.city || '' }}{{ address?.city && address?.state ? ',' : '' }} {{ address?.state }}
              {{ address?.zipCode }} {{ address?.country }}
            </div>
          </div>
        }
      </div>
    </div>
    @if (!compactMode && company?.publicData) {
      <div>
        <div class="detailed-info_detail-name">
          {{ 'companyDetails.sectors' | translate }}
        </div>
        <x-more
          [data]="company?.publicData?.subIndustries?.subIndustries"
          [maxLength]="sectorsMaxLength"
          display="column"
        ></x-more>
      </div>
      <div>
        <div class="detailed-info_detail-name">
          {{ 'companyDetails.productCategories' | translate }}
        </div>
        <x-more
          [data]="company?.publicData?.productCategories?.categories"
          [maxLength]="categoriesMaxLength"
          display="column"
        ></x-more>
      </div>
      <div>
        <div class="detailed-info_detail-name">
          {{ 'companyDetails.numberOfEmployees' | translate }}
        </div>
        <div class="detailed-info_detail-value">
          {{ (company.publicData.numberOfEmployeesType && company.publicData.numberOfEmployeesType.title) || '' }}
        </div>
      </div>
      <div>
        <div class="detailed-info_detail-name">
          {{ 'companyDetails.businessType' | translate }}
        </div>
        <div class="detailed-info_detail-value">
          {{ (company.publicData.businessType && company.publicData.businessType.title) || '' }}
        </div>
      </div>
      <div>
        <div class="detailed-info_detail-name">
          {{ 'companyDetails.companyType' | translate }}
        </div>
        <div class="detailed-info_detail-value">
          {{ (company.publicData.companyType && company.publicData.companyType.title) || '' }}
        </div>
      </div>
      <div>
        <div class="detailed-info_detail-name">
          {{ 'companyDetails.legalType' | translate }}
        </div>
        <div class="detailed-info_detail-value">
          {{ (company.publicData.legalType && company.publicData.legalType.title) || '' }}
        </div>
      </div>
      <div>
        <div class="detailed-info_detail-name">
          {{ 'companyDetails.annualRevenue' | translate }}
        </div>
        <div class="detailed-info_detail-value">
          {{ company.publicData?.annualRevenue?.title || '' }}
        </div>
      </div>
      <div>
        <div class="detailed-info_detail-name">
          {{ 'companyDetails.yearStarted' | translate }}
        </div>
        <div class="detailed-info_detail-value">
          {{ company.publicData.yearStarted || '' }}
        </div>
      </div>
      <div>
        <div class="detailed-info_detail-name">
          {{ 'companyDetails.website' | translate }}
        </div>
        <div class="detailed-info_detail-value">
          @if (company?.publicData?.websiteUrl || company.websiteUrl) {
            <a
              target="_blank"
              [href]="company?.publicData?.websiteUrl || company.websiteUrl | websiteUrl"
              >{{ company?.publicData?.websiteUrl || company.websiteUrl }}</a
            >
          }
          @if (!(company?.publicData?.websiteUrl || company.websiteUrl)) {
            <span></span>
          }
        </div>
      </div>
      <div>
        <div class="detailed-info_detail-name">
          {{ 'companyDetails.tickerSymbol' | translate }}
        </div>
        <div class="detailed-info_detail-value">
          {{ company.publicData.tickerSymbol || '' }}
        </div>
      </div>
      <div></div>
    }
  </div>
}

import { Component, forwardRef, HostBinding, Input, inject, output } from '@angular/core';
import { NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';

import { ContactSelectorModalComponent } from '@clover/network/modals/contact-selector-modal/contact-selector-modal.component';
import { type CompanyContact } from '@clover/network/models/contact';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { ModalService } from '@core/services/modal.service';
import { UserService } from '@core/services/user.service';

@Component({
  selector: 'contact-selector',
  templateUrl: './contact-selector.component.html',
  styleUrls: ['./contact-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactSelectorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [FocusableDirective, AssetSrcDirective],
})
export class ContactSelectorComponent implements ControlValueAccessor {
  private readonly modalService = inject(ModalService);
  private readonly userService = inject(UserService);
  @Input() placeholder: string;
  @Input() companyId: number;
  @Input() multiselect = false;
  valueChanged = output();

  @HostBinding('attr.disabled')
  public isDisabled: boolean;
  private _value: any;

  public set value(val: any) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
    this.valueChanged.emit();
  }

  public get value(): any {
    return this._value;
  }

  private onChange = (val: CompanyContact | CompanyContact[]) => {};
  private onTouched = () => {};

  public openSelector(): void {
    if (this.isDisabled) {
      return;
    }

    const preSelectedContacts = [];

    if (this.multiselect && this._value) {
      (this._value as CompanyContact[]).forEach((contact) => {
        contact &&
          preSelectedContacts.push({
            associatedUserId: contact.id,
            contact,
          });
      });
    } else {
      if (this._value) {
        preSelectedContacts.push({
          associatedUserId: (this._value as CompanyContact).id,
          contact: this._value,
        });
      }
    }

    this.modalService
      .open({
        content: ContactSelectorModalComponent,
        inputs: {
          preSelectedContacts,
          companyId: this.companyId,
          multiselect: this.multiselect,
          hideNewUserBtn: this.userService.userCompany.licenseTier !== 'Individual',
        },
        options: {
          size: 'xl',
          windowClass: 'modal-background-transparent',
        },
      })
      .result.then((res: any) => {
        this.value = this.multiselect ? res.contacts.map((c) => c.contact) : res?.contacts[0]?.contact;
      })
      .catch(() => {});
  }

  public get selectedContact(): CompanyContact {
    return this.multiselect ? this._value[0] : this._value;
  }

  public getContactString(): string {
    const value = this.selectedContact;

    if (!value) {
      return '';
    }

    let str = `${value.firstName} ${value.lastName}`;
    if (value.title) {
      str += `, ${value.title}`;
    }

    return str;
  }

  public writeValue(value: CompanyContact | CompanyContact[]): void {
    this._value = value;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { Company } from '@core/models/company';

@Component({
  selector: 'app-invitation-sent-modal',
  templateUrl: './invitation-sent-modal.component.html',
  styleUrls: ['./invitation-sent-modal.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TranslateModule],
})
export class InvitationSentModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  @Input() company: Company;
}

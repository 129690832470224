/* eslint-disable */

/*!
 * Doka Image Editor 6.15.3
 * (c) 2018-2020 PQINA Inc. - All Rights Reserved
 * License: https://pqina.nl/doka/license/
 */
!(function (e, t) {
  'object' == typeof exports && 'object' == typeof module
    ? (module.exports = t())
    : 'function' == typeof define && define.amd
      ? define([], t)
      : 'object' == typeof exports
        ? (exports.Doka = t())
        : (e.Doka = t());
})(window, function () {
  return (function (e) {
    var t = {};
    function n(r) {
      if (t[r]) return t[r].exports;
      var i = (t[r] = { i: r, l: !1, exports: {} });
      return e[r].call(i.exports, i, i.exports, n), (i.l = !0), i.exports;
    }
    return (
      (n.m = e),
      (n.c = t),
      (n.d = function (e, t, r) {
        n.o(e, t) || Object.defineProperty(e, t, { enumerable: !0, get: r });
      }),
      (n.r = function (e) {
        'undefined' != typeof Symbol &&
          Symbol.toStringTag &&
          Object.defineProperty(e, Symbol.toStringTag, { value: 'Module' }),
          Object.defineProperty(e, '__esModule', { value: !0 });
      }),
      (n.t = function (e, t) {
        if ((1 & t && (e = n(e)), 8 & t)) return e;
        if (4 & t && 'object' == typeof e && e && e.__esModule) return e;
        var r = Object.create(null);
        if ((n.r(r), Object.defineProperty(r, 'default', { enumerable: !0, value: e }), 2 & t && 'string' != typeof e))
          for (var i in e)
            n.d(
              r,
              i,
              function (t) {
                return e[t];
              }.bind(null, i),
            );
        return r;
      }),
      (n.n = function (e) {
        var t =
          e && e.__esModule
            ? function () {
                return e.default;
              }
            : function () {
                return e;
              };
        return n.d(t, 'a', t), t;
      }),
      (n.o = function (e, t) {
        return Object.prototype.hasOwnProperty.call(e, t);
      }),
      (n.p = '/dist'),
      n((n.s = 0))
    );
  })([
    function (e, t, n) {
      'use strict';
      n.r(t);
      var r = function (e, t) {
          return t.parentNode.insertBefore(e, t);
        },
        i = function (e, t) {
          return t.parentNode.insertBefore(e, t.nextSibling);
        };
      function o(e) {
        return (o =
          'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
            ? function (e) {
                return typeof e;
              }
            : function (e) {
                return e && 'function' == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype
                  ? 'symbol'
                  : typeof e;
              })(e);
      }
      var a = function (e) {
        return 'object' === o(e) && null !== e;
      };
      function c(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              l(e, t, n[t]);
            });
        }
        return e;
      }
      function l(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var u = function (e, t) {
          for (var n in e) e.hasOwnProperty(n) && t(n, e[n]);
        },
        s = function (e) {
          var t = {};
          return (
            u(e, function (n) {
              !(function (e, t, n) {
                'function' != typeof n ? Object.defineProperty(e, t, n) : (e[t] = n);
              })(t, n, e[n]);
            }),
            t
          );
        },
        f = function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : null;
          if (null === n) return e.getAttribute(t) || e.hasAttribute(t);
          e.setAttribute(t, n);
        };
      function d(e) {
        return (d =
          'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
            ? function (e) {
                return typeof e;
              }
            : function (e) {
                return e && 'function' == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype
                  ? 'symbol'
                  : typeof e;
              })(e);
      }
      var h = ['svg', 'path'],
        p = function (e) {
          return h.includes(e);
        },
        g = function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
          'object' === d(t) && ((n = t), (t = null));
          var r = p(e) ? document.createElementNS('http://www.w3.org/2000/svg', e) : document.createElement(e);
          return (
            t && (p(e) ? f(r, 'class', t) : (r.className = t)),
            u(n, function (e, t) {
              f(r, e, t);
            }),
            r
          );
        },
        m = function (e) {
          return function (t, n) {
            void 0 !== n && e.children[n] ? e.insertBefore(t, e.children[n]) : e.appendChild(t);
          };
        },
        v = function (e, t) {
          return function (e, n) {
            return void 0 !== n ? t.splice(n, 0, e) : t.push(e), e;
          };
        },
        y = function (e, t) {
          return function (n) {
            var r = t.splice(t.indexOf(n), 1);
            return r.length && r[0]._destroy(), n.element.parentNode && e.removeChild(n.element), n;
          };
        };
      function E(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              w(e, t, n[t]);
            });
        }
        return e;
      }
      function w(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var _ = function (e, t, n, r) {
          var i = n[0] || e.left,
            o = n[1] || e.top,
            a = i + e.width,
            c = o + e.height * (r[1] || 1),
            l = {
              element: E({}, e),
              inner: { left: e.left, top: e.top, right: e.right, bottom: e.bottom },
              outer: { left: i, top: o, right: a, bottom: c },
            };
          return (
            t
              .filter(function (e) {
                return !e.isRectIgnored();
              })
              .map(function (e) {
                return e.rect;
              })
              .forEach(function (e) {
                T(l.inner, E({}, e.inner)), T(l.outer, E({}, e.outer));
              }),
            b(l.inner),
            (l.outer.bottom += l.element.marginBottom),
            (l.outer.right += l.element.marginRight),
            b(l.outer),
            l
          );
        },
        T = function (e, t) {
          (t.top += e.top),
            (t.right += e.left),
            (t.bottom += e.top),
            (t.left += e.left),
            t.bottom > e.bottom && (e.bottom = t.bottom),
            t.right > e.right && (e.right = t.right);
        },
        b = function (e) {
          (e.width = e.right - e.left), (e.height = e.bottom - e.top);
        },
        A = function (e) {
          return 'number' == typeof e;
        },
        x = function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
            t = e.stiffness,
            n = void 0 === t ? 0.5 : t,
            r = e.damping,
            i = void 0 === r ? 0.75 : r,
            o = e.mass,
            a = void 0 === o ? 10 : o,
            c = e.delay,
            l = void 0 === c ? 0 : c,
            u = null,
            f = null,
            d = 0,
            h = !1,
            p = null,
            g = s({
              interpolate: function (e) {
                if ((null === p && (p = e), !(e - l < p || h))) {
                  if (!A(u) || !A(f)) return (h = !0), void (d = 0);
                  !(function (e, t, n) {
                    var r = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 0.001;
                    return Math.abs(e - t) < r && Math.abs(n) < r;
                  })((f += d += (-(f - u) * n) / a), u, (d *= i))
                    ? g.onupdate(f)
                    : ((f = u), (d = 0), (h = !0), g.onupdate(f), g.oncomplete(f));
                }
              },
              target: {
                set: function (e) {
                  if (
                    (A(e) && !A(f) && ((f = e), (p = null)),
                    null === u && ((u = e), (f = e), (p = null)),
                    h && (p = null),
                    f === (u = e) || void 0 === u)
                  )
                    return (h = !0), (d = 0), (p = null), g.onupdate(f), void g.oncomplete(f);
                  h = !1;
                },
                get: function () {
                  return u;
                },
              },
              resting: {
                get: function () {
                  return h;
                },
              },
              onupdate: function () {},
              oncomplete: function () {},
              position: {
                get: function () {
                  return f;
                },
              },
            });
          return g;
        },
        O = function (e) {
          return e < 0.5 ? 2 * e * e : (4 - 2 * e) * e - 1;
        };
      function R(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      function I(e) {
        return (I =
          'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
            ? function (e) {
                return typeof e;
              }
            : function (e) {
                return e && 'function' == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype
                  ? 'symbol'
                  : typeof e;
              })(e);
      }
      var C = {
          spring: x,
          tween: function () {
            var e,
              t,
              n = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
              r = n.duration,
              i = void 0 === r ? 500 : r,
              o = n.easing,
              a = void 0 === o ? O : o,
              c = n.delay,
              l = void 0 === c ? 0 : c,
              u = null,
              f = !0,
              d = !1,
              h = null,
              p = s({
                interpolate: function (n) {
                  f ||
                    null === h ||
                    (null === u && (u = n),
                    n - u < l ||
                      ((e = n - u - l) < i
                        ? ((t = e / i), p.onupdate((e >= 0 ? a(d ? 1 - t : t) : 0) * h))
                        : ((e = 1), (t = d ? 0 : 1), p.onupdate(t * h), p.oncomplete(t * h), (f = !0))));
                },
                target: {
                  get: function () {
                    return d ? 0 : h;
                  },
                  set: function (e) {
                    if (null === h) return (h = e), p.onupdate(e), void p.oncomplete(e);
                    e < h ? ((h = 1), (d = !0)) : ((d = !1), (h = e)), (f = !1), (u = null);
                  },
                },
                resting: {
                  get: function () {
                    return f;
                  },
                },
                onupdate: function () {},
                oncomplete: function () {},
              });
            return p;
          },
        },
        S = function (e, t, n) {
          var r = e[t] && 'object' === I(e[t][n]) ? e[t][n] : e[t] || e,
            i = 'string' == typeof r ? r : r.type,
            o =
              'object' === I(r)
                ? (function (e) {
                    for (var t = 1; t < arguments.length; t++) {
                      var n = null != arguments[t] ? arguments[t] : {},
                        r = Object.keys(n);
                      'function' == typeof Object.getOwnPropertySymbols &&
                        (r = r.concat(
                          Object.getOwnPropertySymbols(n).filter(function (e) {
                            return Object.getOwnPropertyDescriptor(n, e).enumerable;
                          }),
                        )),
                        r.forEach(function (t) {
                          R(e, t, n[t]);
                        });
                    }
                    return e;
                  })({}, r)
                : {};
          return C[i] ? C[i](o) : null;
        };
      function M(e) {
        return (M =
          'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
            ? function (e) {
                return typeof e;
              }
            : function (e) {
                return e && 'function' == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype
                  ? 'symbol'
                  : typeof e;
              })(e);
      }
      var L = function (e, t, n) {
        var r = arguments.length > 3 && void 0 !== arguments[3] && arguments[3];
        (t = Array.isArray(t) ? t : [t]).forEach(function (t) {
          e.forEach(function (e) {
            var i = e,
              o = function () {
                return n[e];
              },
              a = function (t) {
                return (n[e] = t);
              };
            'object' === M(e) && ((i = e.key), (o = e.getter || o), (a = e.setter || a)),
              (t[i] && !r) || (t[i] = { get: o, set: a });
          });
        });
      };
      function P(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var k = function (e) {
          return function (t, n) {
            e.addEventListener(t, n);
          };
        },
        G = function (e) {
          return function (t, n) {
            e.removeEventListener(t, n);
          };
        };
      function D(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              U(e, t, n[t]);
            });
        }
        return e;
      }
      function U(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var V = {
          opacity: 1,
          scaleX: 1,
          scaleY: 1,
          translateX: 0,
          translateY: 0,
          rotateX: 0,
          rotateY: 0,
          rotateZ: 0,
          originX: 0,
          originY: 0,
        },
        B = function (e, t) {
          if (Object.keys(e).length !== Object.keys(t).length) return !0;
          for (var n in t) if (t[n] !== e[n]) return !0;
          return !1;
        },
        z = function (e, t) {
          var n = t.opacity,
            r = t.perspective,
            i = t.translateX,
            o = t.translateY,
            a = t.scaleX,
            c = t.scaleY,
            l = t.rotateX,
            u = t.rotateY,
            s = t.rotateZ,
            f = t.originX,
            d = t.originY,
            h = t.width,
            p = t.height,
            g = '',
            m = '';
          (null == f && null == d) || (m += 'transform-origin: '.concat(f || 0, 'px ').concat(d || 0, 'px;')),
            null != r && (g += 'perspective('.concat(r, 'px) ')),
            (null == i && null == o) || (g += 'translate3d('.concat(i || 0, 'px, ').concat(o || 0, 'px, 0) ')),
            (null == a && null == c) ||
              (g += 'scale3d('.concat(null != a ? a : 1, ', ').concat(null != c ? c : 1, ', 1) ')),
            null != s && (g += 'rotateZ('.concat(s, 'rad) ')),
            null != l && (g += 'rotateX('.concat(l, 'rad) ')),
            null != u && (g += 'rotateY('.concat(u, 'rad) ')),
            '' != g && (m += 'transform:'.concat(g, ';')),
            null != n &&
              ((m += 'opacity:'.concat(n, ';')),
              n < 1 && (m += 'pointer-events:none;'),
              0 === n && 'BUTTON' === e.nodeName && (m += 'visibility:hidden;')),
            null != h && (m += 'width:'.concat(h, 'px;')),
            null != p && (m += 'height:'.concat(p, 'px;'));
          var v = e.elementCurrentStyle || '';
          (m.length === v.length && m === v) || ((e.style.cssText = m), (e.elementCurrentStyle = m));
        },
        N = {
          styles: function (e) {
            var t = e.mixinConfig,
              n = e.viewProps,
              r = e.viewInternalAPI,
              i = e.viewExternalAPI,
              o = e.view,
              a = D({}, n),
              c = {};
            L(t, [r, i], n);
            var l = function () {
              return o.rect
                ? _(o.rect, o.childViews, [n.translateX || 0, n.translateY || 0], [n.scaleX || 0, n.scaleY || 0])
                : null;
            };
            return (
              (r.rect = { get: l }),
              (i.rect = { get: l }),
              t.forEach(function (e) {
                n[e] = void 0 === a[e] ? V[e] : a[e];
              }),
              {
                write: function () {
                  if (B(c, n)) return z(o.element, n), Object.assign(c, D({}, n)), !0;
                },
                destroy: function () {},
              }
            );
          },
          listeners: function (e) {
            var t = e.viewExternalAPI,
              n = e.view,
              r = [],
              i = k(n.element),
              o = G(n.element);
            return (
              (t.on = function (e, t) {
                r.push({ type: e, fn: t }), i(e, t);
              }),
              (t.off = function (e, t) {
                r.splice(
                  r.findIndex(function (n) {
                    return n.type === e && n.fn === t;
                  }),
                  1,
                ),
                  o(e, t);
              }),
              {
                write: function () {
                  return !0;
                },
                destroy: function () {
                  r.forEach(function (e) {
                    o(e.type, e.fn);
                  });
                },
              }
            );
          },
          animations: function (e) {
            var t = e.mixinConfig,
              n = e.viewProps,
              r = e.viewInternalAPI,
              i = e.viewExternalAPI,
              o = (function (e) {
                for (var t = 1; t < arguments.length; t++) {
                  var n = null != arguments[t] ? arguments[t] : {},
                    r = Object.keys(n);
                  'function' == typeof Object.getOwnPropertySymbols &&
                    (r = r.concat(
                      Object.getOwnPropertySymbols(n).filter(function (e) {
                        return Object.getOwnPropertyDescriptor(n, e).enumerable;
                      }),
                    )),
                    r.forEach(function (t) {
                      P(e, t, n[t]);
                    });
                }
                return e;
              })({}, n),
              a = [];
            return (
              u(t, function (e, t) {
                var c = S(t);
                c &&
                  ((c.onupdate = function (t) {
                    n[e] = t;
                  }),
                  (c.target = o[e]),
                  L(
                    [
                      {
                        key: e,
                        setter: function (e) {
                          c.target !== e && (c.target = e);
                        },
                        getter: function () {
                          return n[e];
                        },
                      },
                    ],
                    [r, i],
                    n,
                    !0,
                  ),
                  a.push(c));
              }),
              {
                write: function (e) {
                  var t = !0;
                  return (
                    a.forEach(function (n) {
                      n.resting || (t = !1), n.interpolate(e);
                    }),
                    t
                  );
                },
                destroy: function () {},
              }
            );
          },
          apis: function (e) {
            var t = e.mixinConfig,
              n = e.viewProps,
              r = e.viewExternalAPI;
            L(t, r, n);
          },
        },
        F = function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
            t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
            n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
          return (
            t.layoutCalculated ||
              ((e.paddingTop = parseInt(n.paddingTop, 10) || 0),
              (e.marginTop = parseInt(n.marginTop, 10) || 0),
              (e.marginRight = parseInt(n.marginRight, 10) || 0),
              (e.marginBottom = parseInt(n.marginBottom, 10) || 0),
              (e.marginLeft = parseInt(n.marginLeft, 10) || 0),
              (t.layoutCalculated = !0)),
            (e.left = t.offsetLeft || 0),
            (e.top = t.offsetTop || 0),
            (e.width = t.offsetWidth || 0),
            (e.height = t.offsetHeight || 0),
            (e.right = e.left + e.width),
            (e.bottom = e.top + e.height),
            (e.scrollTop = t.scrollTop),
            (e.hidden = null === t.offsetParent && 'fixed' !== n.position),
            e
          );
        },
        j = 'undefined' != typeof window && void 0 !== window.document,
        W = function () {
          return j;
        },
        q =
          'children' in (W() ? g('svg') : {})
            ? function (e) {
                return e.children.length;
              }
            : function (e) {
                return e.childNodes.length;
              };
      function H(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              Y(e, t, n[t]);
            });
        }
        return e;
      }
      function Y(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var X = function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
            t = e.tag,
            n = void 0 === t ? 'div' : t,
            r = e.name,
            i = void 0 === r ? null : r,
            o = e.attributes,
            a = void 0 === o ? {} : o,
            c = e.read,
            l = void 0 === c ? function () {} : c,
            u = e.write,
            f = void 0 === u ? function () {} : u,
            d = e.create,
            h = void 0 === d ? function () {} : d,
            p = e.destroy,
            E = void 0 === p ? function () {} : p,
            w = e.filterFrameActionsForChild,
            T =
              void 0 === w
                ? function (e, t) {
                    return t;
                  }
                : w,
            b = e.didCreateView,
            A = void 0 === b ? function () {} : b,
            x = e.didWriteView,
            O = void 0 === x ? function () {} : x,
            R = e.shouldUpdateChildViews,
            I =
              void 0 === R
                ? function () {
                    return !0;
                  }
                : R,
            C = e.ignoreRect,
            S = void 0 !== C && C,
            M = e.ignoreRectUpdate,
            L = void 0 !== M && M,
            P = e.mixins,
            k = void 0 === P ? [] : P;
          return function (e) {
            var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
              r = g(n, i ? 'doka--'.concat(i) : null, a),
              o = window.getComputedStyle(r, null),
              c = F(),
              u = null,
              d = !1,
              p = [],
              w = [],
              b = {},
              x = {},
              R = [f],
              C = [l],
              M = [E],
              P = function () {
                return r;
              },
              G = function () {
                return [].concat(p);
              },
              D = function () {
                return u || (u = _(c, p, [0, 0], [1, 1]));
              },
              U = function () {
                return (r.layoutCalculated = !1);
              },
              V = {
                element: { get: P },
                style: {
                  get: function () {
                    return o;
                  },
                },
                childViews: { get: G },
              },
              B = H({}, V, {
                rect: { get: D },
                ref: {
                  get: function () {
                    return b;
                  },
                },
                is: function (e) {
                  return i === e;
                },
                appendChild: m(r),
                createChildView: (function (e) {
                  return function (t, n) {
                    return t(e, n);
                  };
                })(e),
                linkView: function (e) {
                  return p.push(e), e;
                },
                unlinkView: function (e) {
                  p.splice(p.indexOf(e), 1);
                },
                appendChildView: v(0, p),
                removeChildView: y(r, p),
                registerWriter: function (e) {
                  return R.push(e);
                },
                registerReader: function (e) {
                  return C.push(e);
                },
                registerDestroyer: function (e) {
                  return M.push(e);
                },
                invalidateLayout: U,
                dispatch: e.dispatch,
                query: e.query,
              }),
              z = {
                element: { get: P },
                childViews: { get: G },
                rect: { get: D },
                resting: {
                  get: function () {
                    return d;
                  },
                },
                isRectIgnored: function () {
                  return S;
                },
                invalidateLayout: U,
                _read: function () {
                  (u = null),
                    I({ root: W, props: t }) &&
                      p.forEach(function (e) {
                        return e._read();
                      }),
                    !(L && c.width && c.height) && F(c, r, o);
                  var e = { root: W, props: t, rect: c };
                  C.forEach(function (t) {
                    return t(e);
                  });
                },
                _write: function (e) {
                  var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : [],
                    r = 0 === n.length;
                  return (
                    R.forEach(function (i) {
                      !1 === i({ props: t, root: W, actions: n, timestamp: e }) && (r = !1);
                    }),
                    w.forEach(function (t) {
                      !1 === t.write(e) && (r = !1);
                    }),
                    I({ props: t, root: W, actions: n, timestamp: e }) &&
                      (p
                        .filter(function (e) {
                          return !!e.element.parentNode;
                        })
                        .forEach(function (t) {
                          t._write(e, T(t, n)) || (r = !1);
                        }),
                      p.forEach(function (t, i) {
                        t.element.parentNode ||
                          (W.appendChild(t.element, i), t._read(), t._write(e, T(t, n)), (r = !1));
                      })),
                    (d = r),
                    O({ props: t, root: W, actions: n, timestamp: e }),
                    r
                  );
                },
                _destroy: function () {
                  w.forEach(function (e) {
                    return e.destroy();
                  }),
                    M.forEach(function (e) {
                      e({ root: W });
                    }),
                    p.forEach(function (e) {
                      return e._destroy();
                    });
                },
              },
              j = H({}, V, {
                rect: {
                  get: function () {
                    return c;
                  },
                },
              });
            Object.keys(k)
              .sort(function (e, t) {
                return 'styles' === e ? 1 : 'styles' === t ? -1 : 0;
              })
              .forEach(function (e) {
                var n = N[e]({
                  mixinConfig: k[e],
                  viewProps: t,
                  viewState: x,
                  viewInternalAPI: B,
                  viewExternalAPI: z,
                  view: s(j),
                });
                n && w.push(n);
              });
            var W = s(B);
            h({ root: W, props: t });
            var Y = q(r) || 0;
            return (
              p.forEach(function (e, t) {
                W.appendChild(e.element, Y + t);
              }),
              A(W),
              s(z)
            );
          };
        },
        Z = function (e, t) {
          return function (n) {
            var r = n.root,
              i = n.props,
              o = n.actions,
              a = void 0 === o ? [] : o,
              c = n.timestamp;
            if (
              (a
                .filter(function (t) {
                  return e[t.type];
                })
                .forEach(function (t) {
                  return e[t.type]({ root: r, props: i, action: t.data, timestamp: c });
                }),
              t)
            )
              return t({ root: r, props: i, actions: a, timestamp: c });
          };
        },
        K = function (e) {
          return Array.isArray(e);
        },
        Q = function (e) {
          return null == e;
        },
        J = function (e) {
          return e.trim();
        },
        $ = function (e) {
          return '' + e;
        },
        ee = function (e) {
          return 'boolean' == typeof e;
        },
        te = function (e) {
          return 'string' == typeof e;
        },
        ne = function (e) {
          return A(e) ? e : te(e) ? $(e).replace(/[a-z]+/gi, '') : 0;
        },
        re = function (e) {
          return parseInt(ne(e), 10);
        },
        ie = function (e) {
          return A(e) && isFinite(e) && Math.floor(e) === e;
        },
        oe = function (e) {
          if (ie(e)) return e;
          var t = $(e).trim();
          return /MB$/i.test(t)
            ? ((t = t.replace(/MB$i/, '').trim()), 1e3 * re(t) * 1e3)
            : /KB/i.test(t)
              ? ((t = t.replace(/KB$i/, '').trim()), 1e3 * re(t))
              : re(t);
        };
      function ae(e) {
        return (ae =
          'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
            ? function (e) {
                return typeof e;
              }
            : function (e) {
                return e && 'function' == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype
                  ? 'symbol'
                  : typeof e;
              })(e);
      }
      var ce = function (e) {
          return K(e)
            ? 'array'
            : (function (e) {
                  return null === e;
                })(e)
              ? 'null'
              : ie(e)
                ? 'int'
                : /^[0-9]+ ?(?:GB|MB|KB)$/gi.test(e)
                  ? 'bytes'
                  : ae(e);
        },
        le = {
          array: function (e) {
            var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : ',';
            return Q(e)
              ? []
              : K(e)
                ? e
                : $(e)
                    .split(t)
                    .map(J)
                    .filter(function (e) {
                      return e.length;
                    });
          },
          boolean: function (e) {
            return ee(e) ? e : 'true' === e;
          },
          int: function (e) {
            return 'bytes' === ce(e) ? oe(e) : re(e);
          },
          float: function (e) {
            return parseFloat(ne(e));
          },
          bytes: oe,
          string: function (e) {
            return (function (e) {
              return 'function' == typeof e;
            })(e)
              ? e
              : $(e);
          },
          object: function (e) {
            try {
              return JSON.parse(
                e
                  .replace(/{\s*'/g, '{"')
                  .replace(/'\s*}/g, '"}')
                  .replace(/'\s*:/g, '":')
                  .replace(/:\s*'/g, ':"')
                  .replace(/,\s*'/g, ',"')
                  .replace(/'\s*,/g, '",'),
              );
            } catch (t) {
              return e;
            }
          },
          file: function (e) {
            return e;
          },
          function: function (e) {
            return (function (e) {
              for (var t = self, n = e.split('.'), r = null; (r = n.shift()); ) if (!(t = t[r])) return null;
              return t;
            })(e);
          },
        },
        ue = function (e, t, n) {
          if (e === t) return e;
          var r,
            i = ce(e);
          if (i !== n) {
            var o = ((r = e), le[n](r));
            if (((i = ce(o)), null === o))
              throw 'Trying to assign value with incorrect type, allowed type: "'.concat(n, '"');
            e = o;
          }
          return e;
        },
        se = function (e) {
          var t = {};
          return (
            u(e, function (n) {
              var r,
                i,
                o,
                a = te(e[n]) ? e[n] : n,
                c = e[a];
              a === n
                ? (t[n] =
                    ((r = c[0]),
                    (i = c[1]),
                    (o = r),
                    {
                      enumerable: !0,
                      get: function () {
                        return o;
                      },
                      set: function (e) {
                        o = ue(e, r, i);
                      },
                    }))
                : (t[n] = t[a]);
            }),
            s(t)
          );
        },
        fe = function (e) {
          (e.file = null),
            (e.activeView = null),
            (e.markup = []),
            (e.markupToolValues = {}),
            (e.rootRect = { x: 0, y: 0, left: 0, top: 0, width: 0, height: 0 }),
            (e.stage = null),
            (e.stageOffset = null),
            (e.image = null),
            (e.zoomTimeoutId = null),
            (e.instantUpdate = !1),
            (e.filePromise = null),
            (e.fileLoader = null),
            (e.instructions = { size: null, crop: null, filter: null, color: null }),
            (e.filter = null),
            (e.filterName = null),
            (e.filterValue = null),
            (e.colorValues = {}),
            (e.colorMatrices = {}),
            (e.size = { width: !1, height: !1, aspectRatioLocked: !0, aspectRatioPrevious: !1 }),
            (e.crop = {
              rectangle: null,
              transforms: null,
              rotation: null,
              flip: null,
              aspectRatio: null,
              isRotating: !1,
              isDirty: !1,
              limitToImageBounds: !0,
              draft: { rectangle: null, transforms: null },
            });
        },
        de = function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : '-';
          return e
            .split(/(?=[A-Z])/)
            .map(function (e) {
              return e.toLowerCase();
            })
            .join(t);
        },
        he = function (e) {
          return function (t, n, r) {
            var i = {};
            return (
              u(e, function (e) {
                var n = de(e, '_').toUpperCase();
                i['SET_'.concat(n)] = function (i) {
                  var o;
                  try {
                    (o = r.options[e]), (r.options[e] = i.value);
                  } catch (e) {}
                  t('DID_SET_'.concat(n), { value: r.options[e], prevValue: o });
                };
              }),
              i
            );
          };
        },
        pe = function (e) {
          return function (t) {
            var n = {};
            return (
              u(e, function (e) {
                n['GET_'.concat(de(e, '_').toUpperCase())] = function () {
                  return t.options[e];
                };
              }),
              n
            );
          };
        },
        ge = function () {
          return Math.random().toString(36).substr(2, 9);
        },
        me = function () {
          var e = [],
            t = function (t, n) {
              var r, i;
              (r = e),
                (i = e.findIndex(function (e) {
                  return e.event === t && (e.cb === n || !n);
                })),
                r.splice(i, 1);
            };
          return {
            fire: function (t) {
              for (var n = arguments.length, r = new Array(n > 1 ? n - 1 : 0), i = 1; i < n; i++)
                r[i - 1] = arguments[i];
              e.filter(function (e) {
                return e.event === t;
              })
                .map(function (e) {
                  return e.cb;
                })
                .forEach(function (e) {
                  setTimeout(function () {
                    e.apply(void 0, r);
                  }, 0);
                });
            },
            on: function (t, n) {
              e.push({ event: t, cb: n });
            },
            onOnce: function (n, r) {
              e.push({
                event: n,
                cb: function () {
                  t(n, r), r.apply(void 0, arguments);
                },
              });
            },
            off: t,
          };
        },
        ve = 'boolean',
        ye = 'int',
        Ee = 'number',
        we = 'string',
        _e = 'array',
        Te = 'object',
        be = 'function',
        Ae = null,
        xe = function () {
          return (
            null === Ae &&
              (Ae =
                (/iPad|iPhone|iPod/.test(navigator.userAgent) ||
                  ('MacIntel' === navigator.platform && navigator.maxTouchPoints > 1)) &&
                !window.MSStream),
            Ae
          );
        };
      function Oe(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var Re = function () {
          return (function (e) {
            for (var t = 1; t < arguments.length; t++) {
              var n = null != arguments[t] ? arguments[t] : {},
                r = Object.keys(n);
              'function' == typeof Object.getOwnPropertySymbols &&
                (r = r.concat(
                  Object.getOwnPropertySymbols(n).filter(function (e) {
                    return Object.getOwnPropertyDescriptor(n, e).enumerable;
                  }),
                )),
                r.forEach(function (t) {
                  Oe(e, t, n[t]);
                });
            }
            return e;
          })({}, Ce);
        },
        Ie = function (e, t) {
          (e = (function (e) {
            return te(Ce[e]) ? Ce[e] : e;
          })(e)),
            (Ce[e][0] = ue(t, Ce[e][0], Ce[e][1]));
        },
        Ce = {
          id: [null, we],
          className: [null, we],
          src: [null, 'file'],
          storageName: ['doka', we],
          maxImagePreviewWidth: [1500, ye],
          maxImagePreviewHeight: [1500, ye],
          imagePreviewScaleMode: ['stage', we],
          allowPreviewFitToView: [!0, ve],
          allowButtonCancel: [!0, ve],
          allowButtonConfirm: [!0, ve],
          allowButtonReset: [!0, ve],
          allowDropFiles: [!1, ve],
          allowBrowseFiles: [!0, ve],
          allowAutoClose: [!0, ve],
          allowAutoDestroy: [!1, ve],
          utils: [['crop', 'filter', 'color', 'markup'], _e],
          util: [null, we],
          initialState: [null, Te],
          outputData: [!1, ve],
          outputFile: [!0, ve],
          outputCorrectImageExifOrientation: [!0, ve],
          outputStripImageHead: [!0, ve],
          outputType: [null, we],
          outputQuality: [null, ye],
          outputFit: ['cover', we],
          outputUpscale: [!0, ve],
          outputWidth: [null, ye],
          outputHeight: [null, ye],
          outputCanvasBackgroundColor: [null, we],
          outputCanvasMemoryLimit: [W() && xe() ? 16777216 : null, ye],
          outputCanvasSyncTarget: [null, Te],
          size: [null, Te],
          sizeMin: [{ width: 1, height: 1 }, Te],
          sizeMax: [{ width: 9999, height: 9999 }, Te],
          filter: [null, Te],
          filters: [
            {
              original: {
                label: 'Original',
                matrix: function () {
                  return null;
                },
              },
              chrome: {
                label: 'Chrome',
                matrix: function () {
                  return [
                    1.398, -0.316, 0.065, -0.273, 0.201, -0.051, 1.278, -0.08, -0.273, 0.201, -0.051, 0.119, 1.151,
                    -0.29, 0.215, 0, 0, 0, 1, 0,
                  ];
                },
              },
              fade: {
                label: 'Fade',
                matrix: function () {
                  return [
                    1.073, -0.015, 0.092, -0.115, -0.017, 0.107, 0.859, 0.184, -0.115, -0.017, 0.015, 0.077, 1.104,
                    -0.115, -0.017, 0, 0, 0, 1, 0,
                  ];
                },
              },
              mono: {
                label: 'Mono',
                matrix: function () {
                  return [
                    0.212, 0.715, 0.114, 0, 0, 0.212, 0.715, 0.114, 0, 0, 0.212, 0.715, 0.114, 0, 0, 0, 0, 0, 1, 0,
                  ];
                },
              },
              noir: {
                label: 'Noir',
                matrix: function () {
                  return [
                    0.15, 1.3, -0.25, 0.1, -0.2, 0.15, 1.3, -0.25, 0.1, -0.2, 0.15, 1.3, -0.25, 0.1, -0.2, 0, 0, 0, 1,
                    0,
                  ];
                },
              },
            },
            Te,
          ],
          crop: [null, Te],
          cropShowSize: [!1, ve],
          cropZoomTimeout: [null, ye],
          cropMask: [null, be],
          cropMaskInset: [0, ye],
          cropAllowResizeRect: [!0, ve],
          cropAllowImageTurnLeft: [!0, ve],
          cropAllowImageTurnRight: [!1, ve],
          cropAllowImageFlipHorizontal: [!0, ve],
          cropAllowImageFlipVertical: [!0, ve],
          cropAllowToggleLimit: [!1, ve],
          cropLimitToImageBounds: [!0, ve],
          cropAllowInstructionZoom: [!1, ve],
          cropAllowRotate: [!0, ve],
          cropResizeMatchImageAspectRatio: [!1, ve],
          cropResizeKeyCodes: [[18, 91, 92, 93], _e],
          cropResizeScrollRectOnly: [!1, ve],
          cropAspectRatio: [null, we],
          cropAspectRatioOptions: [null, _e],
          cropMinImageWidth: [1, ye],
          cropMinImageHeight: [1, ye],
          colorBrightness: [0, Ee],
          colorBrightnessRange: [[-0.25, 0.25], _e],
          colorContrast: [1, Ee],
          colorContrastRange: [[0.5, 1.5], _e],
          colorExposure: [1, Ee],
          colorExposureRange: [[0.5, 1.5], _e],
          colorSaturation: [1, Ee],
          colorSaturationRange: [[0, 2], _e],
          markup: [null, _e],
          markupUtil: ['select', we],
          markupFilter: [
            function () {
              return !0;
            },
            be,
          ],
          markupAllowAddMarkup: [!0, ve],
          markupAllowCustomColor: [!0, ve],
          markupDrawDistance: [4, Ee],
          markupColor: ['#000', we],
          markupColorOptions: [
            [
              ['White', '#fff', '#f6f6f6'],
              ['Silver', '#9e9e9e'],
              ['Black', '#000', '#333'],
              ['Red', '#f44336'],
              ['Orange', '#ff9800'],
              ['Yellow', '#ffeb3b'],
              ['Green', '#4caf50'],
              ['Blue', '#2196f3'],
              ['Violet', '#3f51b5'],
              ['Purple', '#9c27b0'],
            ],
            _e,
          ],
          markupFontSize: [0.1, Ee],
          markupFontSizeOptions: [
            [
              ['XL', 0.15],
              ['L', 0.125],
              ['M', 0.1],
              ['S', 0.075],
              ['XS', 0.05],
            ],
            _e,
          ],
          markupFontFamily: ['Helvetica, Arial, Verdana', we],
          markupFontFamilyOptions: [
            [
              ['Serif', "Palatino, 'Times New Roman', serif"],
              ['Sans Serif', 'Helvetica, Arial, Verdana'],
              ['Monospaced', "Monaco, 'Lucida Console', monospaced"],
            ],
            _e,
          ],
          markupShapeStyle: [[0.015, null], _e],
          markupShapeStyleOptions: [
            [
              ['Fill', 0, null, 0],
              ['Outline thick', 0.025, null, 4],
              ['Outline default', 0.015, null, 2],
              ['Outline thin', 0.005, null, 1],
              ['Outline dashed', 0.005, [0.01], 1],
            ],
            _e,
          ],
          markupLineStyle: [[0.015, null], _e],
          markupLineStyleOptions: [
            [
              ['Thick', 0.025, null, 4],
              ['Default', 0.015, null, 2],
              ['Thin', 0.005, null, 1],
              ['Dashed', 0.005, [0.01], 1],
            ],
            _e,
          ],
          markupLineDecoration: [['arrow-end'], _e],
          markupLineDecorationOptions: [
            [
              ['None', []],
              ['Single arrow', ['arrow-end']],
              ['Double arrow', ['arrow-begin', 'arrow-end']],
            ],
            _e,
          ],
          stickers: [null, _e],
          beforeLoadImage: [null, be],
          beforeCreateBlob: [null, be],
          afterCreateBlob: [null, be],
          afterCreateOutput: [null, be],
          onconfirm: [null, be],
          oncancel: [null, be],
          onclose: [null, be],
          onloadstart: [null, be],
          onload: [null, be],
          onloaderror: [null, be],
          oninit: [null, be],
          onready: [null, be],
          onupdate: [null, be],
          ondestroy: [null, be],
          labelButtonReset: ['Reset', we],
          labelButtonCancel: ['Cancel', we],
          labelButtonConfirm: ['Done', we],
          labelButtonUtilCrop: ['Crop', we],
          labelButtonUtilResize: ['Resize', we],
          labelButtonUtilFilter: ['Filter', we],
          labelButtonUtilColor: ['Colors', we],
          labelButtonUtilMarkup: ['Markup', we],
          labelButtonUtilSticker: ['Stickers', we],
          labelStatusMissingWebGL: ['WebGL is required but is disabled on your browser', we],
          labelStatusAwaitingImage: ['Waiting for image…', we],
          labelStatusLoadImageError: ['Error loading image…', we],
          labelStatusLoadingImage: ['Loading image…', we],
          labelStatusProcessingImage: ['Processing image…', we],
          labelColorBrightness: ['Brightness', we],
          labelColorContrast: ['Contrast', we],
          labelColorExposure: ['Exposure', we],
          labelColorSaturation: ['Saturation', we],
          labelMarkupTypeRectangle: ['Square', we],
          labelMarkupTypeEllipse: ['Circle', we],
          labelMarkupTypeText: ['Text', we],
          labelMarkupTypeLine: ['Arrow', we],
          labelMarkupSelectFontSize: ['Size', we],
          labelMarkupSelectFontFamily: ['Font', we],
          labelMarkupSelectLineDecoration: ['Decoration', we],
          labelMarkupSelectLineStyle: ['Style', we],
          labelMarkupSelectShapeStyle: ['Style', we],
          labelMarkupRemoveShape: ['Remove', we],
          labelMarkupToolSelect: ['Select', we],
          labelMarkupToolDraw: ['Draw', we],
          labelMarkupToolLine: ['Arrow', we],
          labelMarkupToolText: ['Text', we],
          labelMarkupToolRect: ['Square', we],
          labelMarkupToolEllipse: ['Circle', we],
          labelResizeWidth: ['Width', we],
          labelResizeHeight: ['Height', we],
          labelResizeApplyChanges: ['Apply', we],
          labelCropInstructionZoom: ['Zoom in and out with your scroll wheel or touchpad.', we],
          labelButtonCropZoom: ['Zoom', we],
          labelButtonCropRotateLeft: ['Rotate left', we],
          labelButtonCropRotateRight: ['Rotate right', we],
          labelButtonCropRotateCenter: ['Center rotation', we],
          labelButtonCropFlipHorizontal: ['Flip horizontal', we],
          labelButtonCropFlipVertical: ['Flip vertical', we],
          labelButtonCropAspectRatio: ['Aspect ratio', we],
          labelButtonCropToggleLimit: ['Crop selection', we],
          labelButtonCropToggleLimitEnable: ['Limited to image', we],
          labelButtonCropToggleLimitDisable: ['Select outside image', we],
          pointerEventsPolyfillScope: ['root', we],
          styleCropCorner: ['circle', we],
          styleFullscreenSafeArea: [
            W() && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream ? 'bottom' : 'none',
            we,
          ],
          styleLayoutMode: [null, we],
        },
        Se = function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0,
            n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 1;
          return Math.min(n, Math.max(t, e));
        },
        Me = function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 10;
          return parseFloat(e.toFixed(t));
        },
        Le = function (e, t) {
          return { x: Me(e.x, t), y: Me(e.y, t) };
        },
        Pe = function (e, t) {
          return Ve(e.x - t.x, e.y - t.y);
        },
        ke = function (e, t) {
          return (function (e, t) {
            return e.x * t.x + e.y * t.y;
          })(Pe(e, t), Pe(e, t));
        },
        Ge = function (e, t) {
          return Math.sqrt(ke(e, t));
        },
        De = function (e, t) {
          return Ve(Se(e.x, t.x, t.x + t.width), Se(e.y, t.y, t.y + t.height));
        },
        Ue = function (e, t, n) {
          var r = Math.cos(t),
            i = Math.sin(t),
            o = Ve(e.x - n.x, e.y - n.y);
          return Ve(n.x + r * o.x - i * o.y, n.y + i * o.x + r * o.y);
        },
        Ve = function () {
          return {
            x: arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0,
            y: arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0,
          };
        },
        Be = function (e, t) {
          return e.x === t.x && e.y === t.y && e.width === t.width && e.height === t.height;
        },
        ze = function (e, t, n) {
          return Xe(t * (e.x - n.x) + n.x, t * (e.y - n.y) + n.y, t * e.width, t * e.height);
        },
        Ne = function (e, t) {
          return Xe(e.x + t.x, e.y + t.y, e.width, e.height);
        },
        Fe = {
          translate: Ne,
          rotate: function (e, t, n) {
            var r = (function (e, t, n) {
                return 0 === t
                  ? { tl: e.tl, tr: e.tr, br: e.br, bl: e.bl }
                  : { tl: Ue(e.tl, t, n), tr: Ue(e.tr, t, n), br: Ue(e.br, t, n), bl: Ue(e.bl, t, n) };
              })(Ye(e), t, n),
              i = r.tl,
              o = r.tr,
              a = r.br,
              c = r.bl,
              l = Math.min(i.x, o.x, a.x, c.x),
              u = Math.min(i.y, o.y, a.y, c.y),
              s = Math.max(i.x, o.x, a.x, c.x),
              f = Math.max(i.y, o.y, a.y, c.y);
            return Xe(l, u, s - l, f - u);
          },
          scale: ze,
        },
        je = function (e) {
          return Xe(e.x, e.y, e.width, e.height);
        },
        We = function (e) {
          return { top: e.y, right: e.x + e.width, bottom: e.y + e.height, left: e.x };
        },
        qe = function (e) {
          var t = e.top,
            n = e.right,
            r = e.bottom,
            i = e.left;
          return { x: i, y: t, width: n - i, height: r - t };
        },
        He = function (e) {
          return Ve(e.x + 0.5 * e.width, e.y + 0.5 * e.height);
        },
        Ye = function (e) {
          return {
            tl: { x: e.x, y: e.y },
            tr: { x: e.x + e.width, y: e.y },
            br: { x: e.x + e.width, y: e.y + e.height },
            bl: { x: e.x, y: e.y + e.height },
          };
        },
        Xe = function (e, t, n, r) {
          return { x: e, y: t, width: n, height: r };
        },
        Ze = function (e) {
          if (Q(e)) return e;
          if (/:/.test(e)) {
            var t = e.split(':'),
              n = t[0];
            return t[1] / n;
          }
          return parseFloat(e);
        },
        Ke = function (e, t) {
          var n = e.width,
            r = n * t;
          return (
            r > e.height && (n = (r = e.height) / t),
            { x: 0.5 * (e.width - n), y: 0.5 * (e.height - r), width: n, height: r }
          );
        },
        Qe = function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 1,
            r = e.height / e.width,
            i = t,
            o = 1,
            a = r;
          a > i && (o = (a = i) / r);
          var c = Math.max(1 / o, i / a),
            l = e.width / (n * c * o);
          return { width: l, height: l * t };
        },
        Je = function (e, t) {
          return { x: e, y: t };
        },
        $e = function (e, t) {
          return Je(e.x - t.x, e.y - t.y);
        },
        et = function (e, t) {
          return Math.sqrt(
            (function (e, t) {
              return (function (e, t) {
                return e.x * t.x + e.y * t.y;
              })($e(e, t), $e(e, t));
            })(e, t),
          );
        },
        tt = function (e, t) {
          var n = e,
            r = t,
            i = 1.5707963267948966 - t,
            o = Math.sin(1.5707963267948966),
            a = Math.sin(r),
            c = Math.sin(i),
            l = Math.cos(i),
            u = n / o;
          return Je(l * (u * a), l * (u * c));
        },
        nt = function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 0,
            r = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : { x: 0.5, y: 0.5 },
            i = r.x > 0.5 ? 1 - r.x : r.x,
            o = r.y > 0.5 ? 1 - r.y : r.y,
            a = 2 * i * e.width,
            c = 2 * o * e.height,
            l = (function (e, t) {
              var n = e.width,
                r = e.height,
                i = tt(n, t),
                o = tt(r, t),
                a = Je(e.x + Math.abs(i.x), e.y - Math.abs(i.y)),
                c = Je(e.x + e.width + Math.abs(o.y), e.y + Math.abs(o.x)),
                l = Je(e.x - Math.abs(o.y), e.y + e.height - Math.abs(o.x));
              return { width: et(a, c), height: et(a, l) };
            })(t, n);
          return Math.max(l.width / a, l.height / c);
        },
        rt = function (e, t) {
          var n = t.origin,
            r = t.translation;
          return (function (e, t, n) {
            return t.reduce(function (e, t) {
              return (0, Fe[t[0]])(e, t[1], n);
            }, e);
          })(
            e,
            [
              ['scale', t.scale],
              ['translate', r],
            ],
            n,
          );
        },
        it = function (e, t) {
          var n = e,
            r = t,
            i = 1.5707963267948966 - t,
            o = Math.sin(1.5707963267948966),
            a = Math.sin(r),
            c = Math.sin(i),
            l = Math.cos(i),
            u = n / o;
          return Ve(l * (u * a), l * (u * c));
        },
        ot = function (e, t, n, r) {
          var i = { x: e.x + t.x, y: e.y + t.y },
            o = (function (e, t) {
              var n = e.width,
                r = e.height,
                i = t % (Math.PI / 2),
                o = it(n, i),
                a = it(r, i),
                c = Ye(e);
              return {
                tl: Ve(c.tl.x + Math.abs(o.x), c.tl.y - Math.abs(o.y)),
                tr: Ve(c.tr.x + Math.abs(a.y), c.tr.y + Math.abs(a.x)),
                br: Ve(c.br.x - Math.abs(o.x), c.br.y + Math.abs(o.y)),
                bl: Ve(c.bl.x - Math.abs(a.y), c.bl.y - Math.abs(a.x)),
              };
            })(r, n),
            a = Ue(o.tl, -n, i),
            c = Ue(o.tr, -n, i),
            l = Ue(o.br, -n, i);
          return Xe(
            Math.min(a.x, c.x, l.x),
            Math.min(a.y, c.y, l.y),
            Math.max(a.x, c.x, l.x) - Math.min(a.x, c.x, l.x),
            Math.max(a.y, c.y, l.y) - Math.min(a.y, c.y, l.y),
          );
        },
        at = function (e, t, n) {
          var r = !(arguments.length > 3 && void 0 !== arguments[3]) || arguments[3],
            i = n.origin,
            o = n.translation,
            a = rt(e, n),
            c = 2 * Math.PI + (n.rotation % (2 * Math.PI)),
            l = ot(i, o, c, t),
            u = He(l),
            s = t.height / t.width,
            f = { x: (u.x - a.x) / a.width, y: (u.y - a.y) / a.height },
            d = f.y > 0.5 ? 1 - f.y : f.y,
            h = 2 * (f.x > 0.5 ? 1 - f.x : f.x) * a.width,
            p = 2 * d * a.height;
          return {
            center: f,
            zoom: r ? Math.min(h / l.width, p / l.height) : Math.min(a.width / l.width, a.height / l.height),
            rotation: n.rotation,
            aspectRatio: s,
            scaleToFit: r,
          };
        };
      function ct(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              lt(e, t, n[t]);
            });
        }
        return e;
      }
      function lt(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var ut = function (e, t) {
          var n = st(e, t);
          return {
            center: { x: Me(n.center.x), y: Me(n.center.y) },
            rotation: Me(n.rotation),
            zoom: Me(n.zoom),
            aspectRatio: Me(n.aspectRatio),
            flip: ct({}, t.flip),
            scaleToFit: n.scaleToFit,
          };
        },
        st = function (e, t) {
          var n = at(e, t.rectangle, t.transforms, t.limitToImageBounds);
          return {
            center: { x: n.center.x, y: n.center.y },
            rotation: n.rotation,
            zoom: n.zoom,
            aspectRatio: n.aspectRatio,
            flip: ct({}, t.flip),
            scaleToFit: n.scaleToFit,
          };
        },
        ft = function (e, t, n, r) {
          var i = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : 'width',
            o = e.width,
            a = e.height;
          if (!o && !a) return { width: o, height: a };
          if (((o = o && Se(o, t.width, n.width)), (a = a && Se(a, t.height, n.height)), !r))
            return { width: o, height: a };
          if (a)
            if (o)
              'width' === i
                ? (a = o / r)
                : 'height' === i
                  ? (o = a * r)
                  : (a * r < t.width ? (a = (o = t.width) / r) : o / r < t.height && (o = (a = t.height) * r),
                    a * r > n.width ? (a = (o = n.width) / r) : o / r > n.height && (o = (a = n.height) * r));
            else {
              a = Se(a * r, t.width, n.width) / r;
            }
          else o = Se(o / r, t.height, n.height) * r;
          return { width: o, height: a };
        },
        dt = function (e) {
          var t;
          if (/^#/.test(e)) {
            if (4 === e.length) {
              var n = e.split('');
              e = '#' + n[1] + n[1] + n[2] + n[2] + n[3] + n[3];
            }
            var r = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(e);
            t = [parseInt(r[1], 16), parseInt(r[2], 16), parseInt(r[3], 16)];
          } else if (/^rgb/.test(e)) {
            (t = e.match(/\d+/g).map(function (e) {
              return parseInt(e, 10);
            })).length = 3;
          }
          return t;
        };
      function ht(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              pt(e, t, n[t]);
            });
        }
        return e;
      }
      function pt(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      function gt(e) {
        return (gt =
          'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
            ? function (e) {
                return typeof e;
              }
            : function (e) {
                return e && 'function' == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype
                  ? 'symbol'
                  : typeof e;
              })(e);
      }
      function mt(e) {
        return (
          (function (e) {
            if (Array.isArray(e)) {
              for (var t = 0, n = new Array(e.length); t < e.length; t++) n[t] = e[t];
              return n;
            }
          })(e) ||
          (function (e) {
            if (Symbol.iterator in Object(e) || '[object Arguments]' === Object.prototype.toString.call(e))
              return Array.from(e);
          })(e) ||
          (function () {
            throw new TypeError('Invalid attempt to spread non-iterable instance');
          })()
        );
      }
      var vt = [],
        yt = function (e) {
          var t = [];
          return (
            u(e, function (e, n) {
              return t.push(n);
            }),
            t.length
              ? t.reduce(function (e, t) {
                  return (
                    (n = mt(e)),
                    (r = t),
                    ((i = new Array(20))[0] = n[0] * r[0] + n[1] * r[5] + n[2] * r[10] + n[3] * r[15]),
                    (i[1] = n[0] * r[1] + n[1] * r[6] + n[2] * r[11] + n[3] * r[16]),
                    (i[2] = n[0] * r[2] + n[1] * r[7] + n[2] * r[12] + n[3] * r[17]),
                    (i[3] = n[0] * r[3] + n[1] * r[8] + n[2] * r[13] + n[3] * r[18]),
                    (i[4] = n[0] * r[4] + n[1] * r[9] + n[2] * r[14] + n[3] * r[19] + n[4]),
                    (i[5] = n[5] * r[0] + n[6] * r[5] + n[7] * r[10] + n[8] * r[15]),
                    (i[6] = n[5] * r[1] + n[6] * r[6] + n[7] * r[11] + n[8] * r[16]),
                    (i[7] = n[5] * r[2] + n[6] * r[7] + n[7] * r[12] + n[8] * r[17]),
                    (i[8] = n[5] * r[3] + n[6] * r[8] + n[7] * r[13] + n[8] * r[18]),
                    (i[9] = n[5] * r[4] + n[6] * r[9] + n[7] * r[14] + n[8] * r[19] + n[9]),
                    (i[10] = n[10] * r[0] + n[11] * r[5] + n[12] * r[10] + n[13] * r[15]),
                    (i[11] = n[10] * r[1] + n[11] * r[6] + n[12] * r[11] + n[13] * r[16]),
                    (i[12] = n[10] * r[2] + n[11] * r[7] + n[12] * r[12] + n[13] * r[17]),
                    (i[13] = n[10] * r[3] + n[11] * r[8] + n[12] * r[13] + n[13] * r[18]),
                    (i[14] = n[10] * r[4] + n[11] * r[9] + n[12] * r[14] + n[13] * r[19] + n[14]),
                    (i[15] = n[15] * r[0] + n[16] * r[5] + n[17] * r[10] + n[18] * r[15]),
                    (i[16] = n[15] * r[1] + n[16] * r[6] + n[17] * r[11] + n[18] * r[16]),
                    (i[17] = n[15] * r[2] + n[16] * r[7] + n[17] * r[12] + n[18] * r[17]),
                    (i[18] = n[15] * r[3] + n[16] * r[8] + n[17] * r[13] + n[18] * r[18]),
                    (i[19] = n[15] * r[4] + n[16] * r[9] + n[17] * r[14] + n[18] * r[19] + n[19]),
                    i
                  );
                  var n, r, i;
                }, t.shift())
              : []
          );
        },
        Et = function (e) {
          return e.crop.draft.transforms ? e.crop.draft.transforms.scale : e.crop.transforms.scale;
        },
        wt = function (e) {
          var t = e.image.width / e.image.naturalWidth,
            n = Et(e);
          return { width: e.options.cropMinImageWidth * n * t, height: e.options.cropMinImageHeight * n * t };
        },
        _t = function (e) {
          var t = Et(e);
          return { width: e.image.width * t, height: e.image.height * t };
        },
        Tt = function (e) {
          return e.options.cropAspectRatioOptions
            ? e.options.cropAspectRatioOptions.map(function (e) {
                var t = { aspectRatio: null, width: null, height: null };
                return (
                  'number' == typeof e.value && (t.aspectRatio = e.value),
                  'string' == typeof e.value && (t.aspectRatio = Ze(e.value)),
                  'object' === gt(e.value) &&
                    null !== e.value &&
                    ((t.width = e.value.width), (t.height = e.value.height), (t.aspectRatio = t.height / t.width)),
                  { label: e.label, value: t }
                );
              })
            : null;
        },
        bt = function (e) {
          var t = e.image.naturalWidth,
            n = e.image.naturalHeight,
            r = n / t;
          return (
            e.stage.width < t && (n = r * (t = e.stage.width)),
            e.stage.height < n && (t = (n = e.stage.height) / r),
            Xe(0.5 * e.stage.width - 0.5 * t, 0.5 * e.stage.height - 0.5 * n, t, n)
          );
        },
        At = function (e) {
          return {
            GET_SIZE: function () {
              return !1 !== e.size.width && !1 !== e.size.height
                ? { width: e.size.width, height: e.size.height }
                : { width: null, height: null };
            },
            GET_SIZE_INPUT: function () {
              return { width: e.size.width, height: e.size.height };
            },
            GET_SIZE_ASPECT_RATIO_LOCK: function () {
              return e.size.aspectRatioLocked;
            },
            IS_ACTIVE_VIEW: function (t) {
              return e.activeView === t;
            },
            GET_ACTIVE_VIEW: function () {
              return e.activeView;
            },
            GET_STYLES: function () {
              return Object.keys(e.options)
                .filter(function (e) {
                  return /^style/.test(e);
                })
                .map(function (t) {
                  return { name: t, value: e.options[t] };
                });
            },
            GET_FILE: function () {
              return e.file;
            },
            GET_IMAGE: function () {
              return e.image;
            },
            GET_STAGE: function () {
              return ht({}, e.stage, e.stageOffset);
            },
            GET_STAGE_RECT: function (t) {
              var n,
                r = e.options.imagePreviewScaleMode;
              return (
                ((n =
                  'crop' === r
                    ? t
                      ? (function (e, t) {
                          t.aspectRatio || (t.aspectRatio = e.image.height / e.image.width);
                          var n = It(e.image, t, t.scaleToFit),
                            r = n.width / n.height;
                          return (
                            e.stage.width < n.width && ((n.width = e.stage.width), (n.height = n.width / r)),
                            e.stage.height < n.height && ((n.height = e.stage.height), (n.width = n.height * r)),
                            Xe(
                              0.5 * e.stage.width - 0.5 * n.width,
                              0.5 * e.stage.height - 0.5 * n.height,
                              n.width,
                              n.height,
                            )
                          );
                        })(e, t)
                      : bt(e)
                    : 'image' === r
                      ? bt(e)
                      : ht({}, e.stage)).fits = n.width < e.stage.width && n.height < e.stage.height),
                (n.mode = r),
                n
              );
            },
            GET_IMAGE_STAGE_RECT: function () {
              return bt(e);
            },
            GET_ROOT: function () {
              return e.rootRect;
            },
            GET_ROOT_SIZE: function () {
              return { width: e.rootRect.width, height: e.rootRect.height };
            },
            GET_MIN_IMAGE_SIZE: function () {
              return { width: e.options.cropMinImageWidth, height: e.options.cropMinImageHeight };
            },
            GET_IMAGE_PREVIEW_SCALE_FACTOR: function () {
              return e.image.width / e.image.naturalWidth;
            },
            GET_MIN_PREVIEW_IMAGE_SIZE: function () {
              var t = e.image.width / e.image.naturalWidth;
              return { width: e.options.cropMinImageWidth * t, height: e.options.cropMinImageHeight * t };
            },
            GET_MIN_CROP_SIZE: function () {
              return wt(e);
            },
            GET_MAX_CROP_SIZE: function () {
              return _t(e);
            },
            GET_MIN_PIXEL_CROP_SIZE: function () {
              var t = e.crop.transforms.scale,
                n = wt(e);
              return { width: n.width / t, height: n.height / t };
            },
            GET_MAX_PIXEL_CROP_SIZE: function () {
              var t = e.crop.transforms.scale,
                n = _t(e);
              return { width: n.width / t, height: n.height / t };
            },
            GET_CROP_ASPECT_RATIO_OPTIONS: function () {
              return Tt(e);
            },
            GET_ACTIVE_CROP_ASPECT_RATIO: function () {
              var t = e.crop.aspectRatio;
              return te(t) ? Ze(t) : t;
            },
            GET_CROP_ASPECT_RATIO: function () {
              var t = te(e.options.cropAspectRatio) ? Ze(e.options.cropAspectRatio) : e.options.cropAspectRatio,
                n = Tt(e);
              return !n || (n && !n.length)
                ? t
                : n.find(function (e) {
                      return e.value.aspectRatio === t;
                    })
                  ? t
                  : n[0].value.aspectRatio;
            },
            GET_CROP_RECTANGLE_ASPECT_RATIO: function () {
              var t = e.crop,
                n = t.rectangle,
                r = t.aspectRatio;
              return n ? n.width / n.height : r;
            },
            GET_CROP_RECT: function () {
              return ht({}, e.crop.rectangle);
            },
            GET_CROP: function (t, n) {
              var r = vt[t];
              if (r && r.ts === n) return r;
              var i = St(e);
              return i && ((i.ts = n), (vt[t] = i)), i;
            },
            GET_COLOR_MATRIX: function () {
              return yt(e.colorMatrices);
            },
            GET_COLOR_VALUES: function () {
              return ht(
                {
                  exposure: e.options.colorExposure,
                  brightness: e.options.colorBrightness,
                  contrast: e.options.colorContrast,
                  saturation: e.options.colorSaturation,
                },
                e.colorValues,
              );
            },
            GET_MARKUP_TOOL_VALUES: function () {
              return ht(
                {
                  color: e.options.markupColor,
                  fontFamily: e.options.markupFontFamily,
                  fontSize: e.options.markupFontSize,
                  shapeStyle: e.options.markupShapeStyle,
                  lineStyle: e.options.markupLineStyle,
                  lineDecoration: e.options.markupLineDecoration,
                },
                e.markupToolValues,
              );
            },
            GET_PREVIEW_IMAGE_DATA: function () {
              return e.file.preview;
            },
            GET_THUMB_IMAGE_DATA: function () {
              return e.file.thumb;
            },
            GET_FILTER: function () {
              return e.filter;
            },
            GET_UID: function () {
              return e.uid;
            },
            GET_MARKUP_BY_ID: function (t) {
              return e.markup.find(function (e) {
                return e[1].id === t;
              });
            },
            GET_BACKGROUND_COLOR: function () {
              var t = e.options.outputCanvasBackgroundColor;
              if (!t) return Ot;
              if (xt[t]) return xt[t];
              var n = dt(t);
              return (xt[t] = n.concat(1)), xt[t];
            },
          };
        },
        xt = {},
        Ot = [0, 0, 0, 0],
        Rt = function (e, t) {
          var n = (function (e) {
              return !1 === e.size.width
                ? (function (e) {
                    return e.options.size ? e.options.size.width : null;
                  })(e)
                : e.size.width;
            })(e),
            r = (function (e) {
              return !1 === e.size.height
                ? (function (e) {
                    return e.options.size ? e.options.size.height : null;
                  })(e)
                : e.size.height;
            })(e),
            i = t.width / t.height;
          return ft({ width: n, height: r }, e.options.sizeMin, e.options.sizeMax, i);
        },
        It = function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
            n = !(arguments.length > 2 && void 0 !== arguments[2]) || arguments[2],
            r = t.zoom,
            i = t.rotation,
            o = t.center,
            a = t.aspectRatio,
            c = Qe(e, a, r),
            l = { x: 0.5 * c.width, y: 0.5 * c.height },
            u = { x: 0, y: 0, width: c.width, height: c.height, center: l },
            s = r * nt(e, Ke(u, a), i, n ? o : { x: 0.5, y: 0.5 });
          return {
            widthFloat: c.width / s,
            heightFloat: c.height / s,
            width: Math.round(c.width / s),
            height: Math.round(c.height / s),
          };
        },
        Ct = function (e, t) {
          var n,
            r,
            i = He(t),
            o = He(e);
          return (r = i), !(Me((n = o).x) === Me(r.x) && Me(n.y) === Me(r.y));
        },
        St = function (e) {
          if (!e.stage || !e.image) return null;
          var t = e.crop.draft.rectangle || { free: e.crop.rectangle, limited: e.crop.rectangle },
            n = e.crop.draft.transforms || e.crop.transforms,
            r = n.origin,
            i = n.translation,
            o = n.scale,
            a = n.interaction,
            c = e.crop.rotation,
            l = e.crop.flip,
            s = !(!e.crop.draft.rectangle && !e.crop.draft.transforms),
            f = s || e.instantUpdate,
            d = Ct(t.limited, e.stage),
            h = e.crop.isDirty || s,
            p = e.crop.isRotating,
            g = void 0 === e.crop.limitToImageBounds || e.crop.limitToImageBounds,
            m = { width: e.image.naturalWidth, height: e.image.naturalHeight },
            v = yt(e.colorMatrices),
            y = st(e.image, {
              rectangle: t.limited,
              transforms: { origin: r, translation: i, scale: o, rotation: c.main + c.sub },
              flip: l,
              limitToImageBounds: e.crop.limitToImageBounds,
            }),
            E = { props: y, crop: It(m, y, e.crop.limitToImageBounds), image: Rt(e, t.limited) },
            w = E.image,
            _ = E.crop,
            T = _.width,
            b = _.height,
            A = _.widthFloat / _.heightFloat;
          w.width && w.height
            ? ((T = w.width), (b = w.height))
            : w.width && !w.height
              ? ((T = w.width), (b = w.width / A))
              : w.height && !w.width && ((b = w.height), (T = w.height * A)),
            (E.currentWidth = Math.round(T)),
            (E.currentHeight = Math.round(b));
          var x = { x: 0, y: 0 },
            O = 0,
            R = 0;
          if (f && a) {
            if (a.translation) {
              var I = a.translation.x - i.x,
                C = a.translation.y - i.y;
              (x.x = 100 * Math.sign(I) * Math.log10(1 + Math.abs(I) / 100)),
                (x.y = 100 * Math.sign(C) * Math.log10(1 + Math.abs(C) / 100));
            }
            if (a.scale) {
              var S = a.scale - o;
              O = 0.25 * Math.sign(S) * Math.log10(1 + Math.abs(S) / 0.25);
            }
            if (a.rotation) {
              var M = a.rotation - (c.main + c.sub);
              R = 0.05 * Math.sign(M) * Math.log10(1 + Math.abs(M) / 0.05);
            }
          }
          var L = {},
            P = t.free,
            k = We(P),
            G = We(t.limited);
          return (
            u(k, function (e) {
              var t = k[e] - G[e];
              L[e] = G[e] + 5 * Math.sign(t) * Math.log10(1 + Math.abs(t) / 5);
            }),
            {
              canRecenter: d,
              canReset: h,
              isDraft: f,
              isRotating: p,
              isLimitedToImageBounds: g,
              cropRect: { x: L.left, y: L.top, width: L.right - L.left, height: L.bottom - L.top },
              origin: r,
              translation: i,
              translationBand: x,
              scale: o,
              scaleBand: O,
              rotation: c,
              rotationBand: R,
              flip: l,
              interaction: a,
              cropStatus: E,
              colorMatrix: v,
              markup: e.markup,
              previewSize: { width: e.image.width, height: e.image.height },
            }
          );
        },
        Mt = function (e) {
          return /^image/.test(e);
        },
        Lt = {
          1: function () {
            return [1, 0, 0, 1, 0, 0];
          },
          2: function (e) {
            return [-1, 0, 0, 1, e, 0];
          },
          3: function (e, t) {
            return [-1, 0, 0, -1, e, t];
          },
          4: function (e, t) {
            return [1, 0, 0, -1, 0, t];
          },
          5: function () {
            return [0, 1, 1, 0, 0, 0];
          },
          6: function (e, t) {
            return [0, 1, -1, 0, t, 0];
          },
          7: function (e, t) {
            return [0, -1, -1, 0, t, e];
          },
          8: function (e) {
            return [0, -1, 1, 0, 0, e];
          },
        },
        Pt = function (e, t, n) {
          return -1 === n && (n = 1), Lt[n](e, t);
        },
        kt = function (e) {
          (e.width = 1), (e.height = 1), e.getContext('2d').clearRect(0, 0, 1, 1);
        },
        Gt = function (e) {
          return e && (e.horizontal || e.vertical);
        },
        Dt = function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
            r = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
            i = r.canvasMemoryLimit,
            o = r.background,
            a = void 0 === o ? null : o,
            c = n.zoom || 1,
            l = (function (e, t, n) {
              if (t <= 1 && !Gt(n)) return (e.width = e.naturalWidth), (e.height = e.naturalHeight), e;
              var r = document.createElement('canvas'),
                i = e.naturalWidth,
                o = e.naturalHeight,
                a = t >= 5 && t <= 8;
              a ? ((r.width = o), (r.height = i)) : ((r.width = i), (r.height = o));
              var c = r.getContext('2d');
              if ((t && c.transform.apply(c, Pt(i, o, t)), Gt(n))) {
                var l = [1, 0, 0, 1, 0, 0];
                ((!a && n.horizontal) || a & n.vertical) && ((l[0] = -1), (l[4] = i)),
                  ((!a && n.vertical) || (a && n.horizontal)) && ((l[3] = -1), (l[5] = o)),
                  c.transform.apply(c, l);
              }
              return c.drawImage(e, 0, 0, i, o), r;
            })(e, t, n.flip),
            u = { width: l.width, height: l.height },
            s = n.aspectRatio || u.height / u.width,
            f = Qe(u, s, c);
          if (i) {
            var d = f.width * f.height;
            if (d > i) {
              var h = Math.sqrt(i) / Math.sqrt(d);
              (u.width = Math.floor(u.width * h)), (u.height = Math.floor(u.height * h)), (f = Qe(u, s, c));
            }
          }
          var p = document.createElement('canvas'),
            g = { x: 0.5 * f.width, y: 0.5 * f.height },
            m = { x: 0, y: 0, width: f.width, height: f.height, center: g },
            v = void 0 === n.scaleToFit || n.scaleToFit,
            y = c * nt(u, Ke(m, s), n.rotation, v ? n.center : { x: 0.5, y: 0.5 });
          (p.width = Math.round(f.width / y)), (p.height = Math.round(f.height / y)), (g.x /= y), (g.y /= y);
          var E = g.x - u.width * (n.center ? n.center.x : 0.5),
            w = g.y - u.height * (n.center ? n.center.y : 0.5),
            _ = p.getContext('2d');
          a && ((_.fillStyle = a), _.fillRect(0, 0, p.width, p.height)),
            _.translate(g.x, g.y),
            _.rotate(n.rotation || 0),
            _.drawImage(l, E - g.x, w - g.y, u.width, u.height);
          var T = _.getImageData(0, 0, p.width, p.height);
          return kt(p), T;
        };
      'undefined' != typeof window &&
        void 0 !== window.document &&
        (HTMLCanvasElement.prototype.toBlob ||
          Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
            value: function (e, t, n) {
              var r = this.toDataURL(t, n).split(',')[1];
              setTimeout(function () {
                for (var n = atob(r), i = n.length, o = new Uint8Array(i), a = 0; a < i; a++) o[a] = n.charCodeAt(a);
                e(new Blob([o], { type: t || 'image/png' }));
              });
            },
          }));
      var Ut = function (e, t) {
          return Nt(e.x * t, e.y * t);
        },
        Vt = function (e, t) {
          return Nt(e.x + t.x, e.y + t.y);
        },
        Bt = function (e) {
          var t = Math.sqrt(e.x * e.x + e.y * e.y);
          return 0 === t ? { x: 0, y: 0 } : Nt(e.x / t, e.y / t);
        },
        zt = function (e, t, n) {
          var r = Math.cos(t),
            i = Math.sin(t),
            o = Nt(e.x - n.x, e.y - n.y);
          return Nt(n.x + r * o.x - i * o.y, n.y + i * o.x + r * o.y);
        },
        Nt = function () {
          return {
            x: arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0,
            y: arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0,
          };
        },
        Ft = function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 1,
            r = arguments.length > 3 ? arguments[3] : void 0;
          return 'string' == typeof e
            ? parseFloat(e) * n
            : 'number' == typeof e
              ? e * (r ? t[r] : Math.min(t.width, t.height))
              : void 0;
        },
        jt = function (e, t, n) {
          var r = e.borderStyle || e.lineStyle || 'solid',
            i = e.backgroundColor || e.fontColor || 'transparent',
            o = e.borderColor || e.lineColor || 'transparent',
            a = Ft(e.borderWidth || e.lineWidth, t, n);
          return {
            'stroke-linecap': e.lineCap || 'round',
            'stroke-linejoin': e.lineJoin || 'round',
            'stroke-width': a || 0,
            'stroke-dasharray':
              'string' == typeof r
                ? ''
                : r
                    .map(function (e) {
                      return Ft(e, t, n);
                    })
                    .join(','),
            stroke: o,
            fill: i,
            opacity: e.opacity || 1,
          };
        },
        Wt = function (e) {
          return null != e;
        },
        qt = function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 1,
            r = Ft(e.x, t, n, 'width') || Ft(e.left, t, n, 'width'),
            i = Ft(e.y, t, n, 'height') || Ft(e.top, t, n, 'height'),
            o = Ft(e.width, t, n, 'width'),
            a = Ft(e.height, t, n, 'height'),
            c = Ft(e.right, t, n, 'width'),
            l = Ft(e.bottom, t, n, 'height');
          return (
            Wt(i) || (i = Wt(a) && Wt(l) ? t.height - a - l : l),
            Wt(r) || (r = Wt(o) && Wt(c) ? t.width - o - c : c),
            Wt(o) || (o = Wt(r) && Wt(c) ? t.width - r - c : 0),
            Wt(a) || (a = Wt(i) && Wt(l) ? t.height - i - l : 0),
            { x: r || 0, y: i || 0, width: o || 0, height: a || 0 }
          );
        },
        Ht = function (e) {
          return e
            .map(function (e, t) {
              return ''
                .concat(0 === t ? 'M' : 'L', ' ')
                .concat(e.x, ' ')
                .concat(e.y);
            })
            .join(' ');
        };
      function Yt(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              Xt(e, t, n[t]);
            });
        }
        return e;
      }
      function Xt(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var Zt = function (e, t) {
          return Object.keys(t).forEach(function (n) {
            return e.setAttribute(n, t[n]);
          });
        },
        Kt = function (e, t) {
          var n = document.createElementNS('http://www.w3.org/2000/svg', e);
          return t && Zt(n, t), n;
        },
        Qt = { contain: 'xMidYMid meet', cover: 'xMidYMid slice' },
        Jt = { left: 'start', center: 'middle', right: 'end' },
        $t = function (e) {
          return function (t) {
            return Kt(e, { id: t.id });
          };
        },
        en = {
          image: function (e) {
            var t = Kt('image', { id: e.id, 'stroke-linecap': 'round', 'stroke-linejoin': 'round', opacity: '0' });
            return (
              (t.onload = function () {
                t.setAttribute('opacity', e.opacity || 1);
              }),
              t.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', e.src),
              t
            );
          },
          rect: $t('rect'),
          ellipse: $t('ellipse'),
          text: $t('text'),
          path: function (e) {
            var t = Kt('g', { id: e.id }),
              n = Kt('path');
            t.appendChild(n);
            var r = Kt('path', { style: 'stroke-width: 40; stroke-opacity: 0;' });
            return t.appendChild(r), t;
          },
          line: function (e) {
            var t = Kt('g', { id: e.id, 'stroke-linecap': 'round', 'stroke-linejoin': 'round' }),
              n = Kt('line');
            t.appendChild(n);
            var r = Kt('path');
            t.appendChild(r);
            var i = Kt('path');
            t.appendChild(i);
            var o = Kt('line', { style: 'stroke-width: 40; stroke-opacity: 0;' });
            return t.appendChild(o), t;
          },
        },
        tn = {
          rect: function (e) {
            return Zt(e, Yt({}, e.rect, e.styles));
          },
          ellipse: function (e) {
            var t = e.rect.x + 0.5 * e.rect.width,
              n = e.rect.y + 0.5 * e.rect.height,
              r = 0.5 * e.rect.width,
              i = 0.5 * e.rect.height;
            return Zt(e, Yt({ cx: t, cy: n, rx: r, ry: i }, e.styles));
          },
          image: function (e, t) {
            Zt(e, Yt({}, e.rect, e.styles, { preserveAspectRatio: Qt[t.fit] || 'none' }));
          },
          text: function (e, t, n, r) {
            var i = Ft(t.fontSize, n, r),
              o = t.fontFamily || 'sans-serif',
              a = t.fontWeight || 'normal',
              c = Jt[t.textAlign] || 'start';
            Zt(
              e,
              Yt({}, e.rect, e.styles, {
                'stroke-width': 0,
                'font-weight': a,
                'font-size': i,
                'font-family': o,
                'text-anchor': c,
              }),
            ),
              e.text !== t.text && ((e.text = t.text), (e.textContent = t.text.length ? t.text : ' '));
          },
          path: function (e, t, n, r) {
            Zt(e, Yt({}, e.styles, { fill: 'none' }));
            var i = e.childNodes[0],
              o = e.childNodes[1],
              a = Ht(
                t.points.map(function (e) {
                  return { x: Ft(e.x, n, r, 'width'), y: Ft(e.y, n, r, 'height') };
                }),
              );
            Zt(i, { d: a }), Zt(o, { d: a });
          },
          line: function (e, t, n, r) {
            Zt(e, Yt({}, e.rect, e.styles, { fill: 'none' }));
            var i = e.childNodes[0],
              o = e.childNodes[1],
              a = e.childNodes[2],
              c = e.childNodes[3],
              l = e.rect,
              u = { x: e.rect.x + e.rect.width, y: e.rect.y + e.rect.height };
            if (
              (Zt(i, { x1: l.x, y1: l.y, x2: u.x, y2: u.y }),
              Zt(c, { x1: l.x, y1: l.y, x2: u.x, y2: u.y }),
              t.lineDecoration)
            ) {
              (o.style.display = 'none'), (a.style.display = 'none');
              var s = Bt({ x: u.x - l.x, y: u.y - l.y }),
                f = Ft(0.05, n, r);
              if (-1 !== t.lineDecoration.indexOf('arrow-begin')) {
                var d = Ut(s, f),
                  h = Vt(l, d),
                  p = zt(l, 2, h),
                  g = zt(l, -2, h);
                Zt(o, {
                  style: 'display:block;',
                  d: 'M'
                    .concat(p.x, ',')
                    .concat(p.y, ' L')
                    .concat(l.x, ',')
                    .concat(l.y, ' L')
                    .concat(g.x, ',')
                    .concat(g.y),
                });
              }
              if (-1 !== t.lineDecoration.indexOf('arrow-end')) {
                var m = Ut(s, -f),
                  v = Vt(u, m),
                  y = zt(u, 2, v),
                  E = zt(u, -2, v);
                Zt(a, {
                  style: 'display:block;',
                  d: 'M'
                    .concat(y.x, ',')
                    .concat(y.y, ' L')
                    .concat(u.x, ',')
                    .concat(u.y, ' L')
                    .concat(E.x, ',')
                    .concat(E.y),
                });
              }
            }
          },
        },
        nn = function (e, t) {
          return en[e](t);
        },
        rn = function (e, t, n, r, i) {
          'path' !== t && (e.rect = qt(n, r, i)), (e.styles = jt(n, r, i)), tn[t](e, n, r, i);
        },
        on = function (e, t) {
          return e[1].zIndex > t[1].zIndex ? 1 : e[1].zIndex < t[1].zIndex ? -1 : 0;
        },
        an = function () {
          var e = {
              resize: function (e, t) {
                var n = t.mode,
                  r = void 0 === n ? 'contain' : n,
                  i = t.upscale,
                  a = void 0 !== i && i,
                  u = t.width,
                  s = t.height,
                  f = t.matrix;
                if (((f = !f || c(f) ? null : f), !u && !s)) return l(e, f);
                null === u ? (u = s) : null === s && (s = u);
                if ('force' !== r) {
                  var d = u / e.width,
                    h = s / e.height,
                    p = 1;
                  if (
                    ('cover' === r ? (p = Math.max(d, h)) : 'contain' === r && (p = Math.min(d, h)), p > 1 && !1 === a)
                  )
                    return l(e, f);
                  (u = e.width * p), (s = e.height * p);
                }
                for (
                  var g = e.width,
                    m = e.height,
                    v = Math.round(u),
                    y = Math.round(s),
                    E = e.data,
                    w = new Uint8ClampedArray(v * y * 4),
                    _ = g / v,
                    T = m / y,
                    b = Math.ceil(0.5 * _),
                    A = Math.ceil(0.5 * T),
                    x = 0;
                  x < y;
                  x++
                )
                  for (var O = 0; O < v; O++) {
                    for (
                      var R = 4 * (O + x * v),
                        I = 0,
                        C = 0,
                        S = 0,
                        M = 0,
                        L = 0,
                        P = 0,
                        k = 0,
                        G = (x + 0.5) * T,
                        D = Math.floor(x * T);
                      D < (x + 1) * T;
                      D++
                    )
                      for (
                        var U = Math.abs(G - (D + 0.5)) / A, V = (O + 0.5) * _, B = U * U, z = Math.floor(O * _);
                        z < (O + 1) * _;
                        z++
                      ) {
                        var N = Math.abs(V - (z + 0.5)) / b,
                          F = Math.sqrt(B + N * N);
                        if (F >= -1 && F <= 1 && (I = 2 * F * F * F - 3 * F * F + 1) > 0) {
                          var j = E[(N = 4 * (z + D * g)) + 3];
                          (k += I * j),
                            (S += I),
                            j < 255 && (I = (I * j) / 250),
                            (M += I * E[N]),
                            (L += I * E[N + 1]),
                            (P += I * E[N + 2]),
                            (C += I);
                        }
                      }
                    (w[R] = M / C), (w[R + 1] = L / C), (w[R + 2] = P / C), (w[R + 3] = k / S), f && o(R, w, f);
                  }
                return { data: w, width: v, height: y };
              },
              filter: l,
            },
            t = function (t, n) {
              var r = t.transforms,
                i = null;
              if (
                (r.forEach(function (e) {
                  'filter' === e.type && (i = e);
                }),
                i)
              ) {
                var o = null;
                r.forEach(function (e) {
                  'resize' === e.type && (o = e);
                }),
                  o &&
                    ((o.data.matrix = i.data),
                    (r = r.filter(function (e) {
                      return 'filter' !== e.type;
                    })));
              }
              n(
                (function (t, n) {
                  return (
                    t.forEach(function (t) {
                      n = e[t.type](n, t.data);
                    }),
                    n
                  );
                })(r, t.imageData),
              );
            };
          self.onmessage = function (e) {
            t(e.data.message, function (t) {
              self.postMessage({ id: e.data.id, message: t }, [t.data.buffer]);
            });
          };
          var n = 1,
            r = 1,
            i = 1;
          function o(e, t, o) {
            var a = t[e] / 255,
              c = t[e + 1] / 255,
              l = t[e + 2] / 255,
              u = t[e + 3] / 255,
              s = a * o[0] + c * o[1] + l * o[2] + u * o[3] + o[4],
              f = a * o[5] + c * o[6] + l * o[7] + u * o[8] + o[9],
              d = a * o[10] + c * o[11] + l * o[12] + u * o[13] + o[14],
              h = a * o[15] + c * o[16] + l * o[17] + u * o[18] + o[19],
              p = Math.max(0, s * h) + n * (1 - h),
              g = Math.max(0, f * h) + r * (1 - h),
              m = Math.max(0, d * h) + i * (1 - h);
            (t[e] = 255 * Math.max(0, Math.min(1, p))),
              (t[e + 1] = 255 * Math.max(0, Math.min(1, g))),
              (t[e + 2] = 255 * Math.max(0, Math.min(1, m)));
          }
          var a = self.JSON.stringify([1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0]);
          function c(e) {
            return self.JSON.stringify(e || []) === a;
          }
          function l(e, t) {
            if (!t || c(t)) return e;
            for (
              var o = e.data,
                a = o.length,
                l = t[0],
                u = t[1],
                s = t[2],
                f = t[3],
                d = t[4],
                h = t[5],
                p = t[6],
                g = t[7],
                m = t[8],
                v = t[9],
                y = t[10],
                E = t[11],
                w = t[12],
                _ = t[13],
                T = t[14],
                b = t[15],
                A = t[16],
                x = t[17],
                O = t[18],
                R = t[19],
                I = 0,
                C = 0,
                S = 0,
                M = 0,
                L = 0,
                P = 0,
                k = 0,
                G = 0,
                D = 0,
                U = 0,
                V = 0,
                B = 0;
              I < a;
              I += 4
            )
              (P =
                (C = o[I] / 255) * l +
                (S = o[I + 1] / 255) * u +
                (M = o[I + 2] / 255) * s +
                (L = o[I + 3] / 255) * f +
                d),
                (k = C * h + S * p + M * g + L * m + v),
                (G = C * y + S * E + M * w + L * _ + T),
                (D = C * b + S * A + M * x + L * O + R),
                (U = Math.max(0, P * D) + n * (1 - D)),
                (V = Math.max(0, k * D) + r * (1 - D)),
                (B = Math.max(0, G * D) + i * (1 - D)),
                (o[I] = 255 * Math.max(0, Math.min(1, U))),
                (o[I + 1] = 255 * Math.max(0, Math.min(1, V))),
                (o[I + 2] = 255 * Math.max(0, Math.min(1, B)));
            return e;
          }
        },
        cn = function (e, t) {
          if (1165519206 === e.getUint32(t + 4, !1)) {
            t += 4;
            var n = 18761 === e.getUint16((t += 6), !1);
            t += e.getUint32(t + 4, n);
            var r = e.getUint16(t, n);
            t += 2;
            for (var i = 0; i < r; i++)
              if (274 === e.getUint16(t + 12 * i, n)) return e.setUint16(t + 12 * i + 8, 1, n), !0;
            return !1;
          }
        },
        ln = function (e) {
          return new Promise(function (t) {
            var n = new FileReader();
            (n.onload = function () {
              return t(
                (function (e) {
                  var t = new DataView(e);
                  if (65496 !== t.getUint16(0)) return null;
                  for (
                    var n, r, i = 2, o = !1;
                    i < t.byteLength &&
                    ((n = t.getUint16(i, !1)),
                    (r = t.getUint16(i + 2, !1) + 2),
                    (n >= 65504 && n <= 65519) || 65534 === n) &&
                    (o || (o = cn(t, i)), !(i + r > t.byteLength));

                  )
                    i += r;
                  return e.slice(0, i);
                })(n.result) || null,
              );
            }),
              n.readAsArrayBuffer(e.slice(0, 262144));
          });
        },
        un = function (e, t) {
          var n = (window.BlobBuilder =
            window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder || window.MSBlobBuilder);
          if (n) {
            var r = new n();
            return r.append(e), r.getBlob(t);
          }
          return new Blob([e], { type: t });
        },
        sn = function (e) {
          var t = new Blob(['(', e.toString(), ')()'], { type: 'application/javascript' }),
            n = URL.createObjectURL(t),
            r = new Worker(n),
            i = [];
          return {
            transfer: function () {},
            post: function (e, t, n) {
              var o = Math.random().toString(36).substr(2, 9);
              (i[o] = t),
                (r.onmessage = function (e) {
                  var t = i[e.data.id];
                  t && (t(e.data.message), delete i[e.data.id]);
                }),
                r.postMessage({ id: o, message: e }, n);
            },
            terminate: function () {
              r.terminate(), URL.revokeObjectURL(n);
            },
          };
        },
        fn = function (e, t) {
          return new Promise(function (n) {
            var r,
              i = { width: e.width, height: e.height },
              o = e.getContext('2d'),
              a = t.sort(on).map(function (e) {
                return function () {
                  return new Promise(function (t) {
                    pn[e[0]](o, i, e[1], t) && t();
                  });
                };
              });
            ((r = a),
            r.reduce(function (e, t) {
              return e.then(function (e) {
                return t().then(Array.prototype.concat.bind(e));
              });
            }, Promise.resolve([]))).then(function () {
              return n(e);
            });
          });
        },
        dn = function (e, t) {
          e.beginPath(),
            (e.lineCap = t['stroke-linecap']),
            (e.lineJoin = t['stroke-linejoin']),
            (e.lineWidth = t['stroke-width']),
            t['stroke-dasharray'].length && e.setLineDash(t['stroke-dasharray'].split(',')),
            (e.fillStyle = t.fill),
            (e.strokeStyle = t.stroke),
            (e.globalAlpha = t.opacity || 1);
        },
        hn = function (e) {
          e.fill(), e.stroke(), (e.globalAlpha = 1);
        },
        pn = {
          rect: function (e, t, n) {
            var r = qt(n, t),
              i = jt(n, t);
            return dn(e, i), e.rect(r.x, r.y, r.width, r.height), hn(e), !0;
          },
          ellipse: function (e, t, n) {
            var r = qt(n, t),
              i = jt(n, t);
            dn(e, i);
            var o = r.x,
              a = r.y,
              c = r.width,
              l = r.height,
              u = (c / 2) * 0.5522848,
              s = (l / 2) * 0.5522848,
              f = o + c,
              d = a + l,
              h = o + c / 2,
              p = a + l / 2;
            return (
              e.moveTo(o, p),
              e.bezierCurveTo(o, p - s, h - u, a, h, a),
              e.bezierCurveTo(h + u, a, f, p - s, f, p),
              e.bezierCurveTo(f, p + s, h + u, d, h, d),
              e.bezierCurveTo(h - u, d, o, p + s, o, p),
              hn(e),
              !0
            );
          },
          image: function (e, t, n, r) {
            var i = qt(n, t),
              o = jt(n, t);
            dn(e, o);
            var a = new Image();
            new URL(n.src, window.location.href).origin !== window.location.origin && (a.crossOrigin = ''),
              (a.onload = function () {
                if ('cover' === n.fit) {
                  var t = i.width / i.height,
                    o = t > 1 ? a.width : a.height * t,
                    c = t > 1 ? a.width / t : a.height,
                    l = 0.5 * a.width - 0.5 * o,
                    u = 0.5 * a.height - 0.5 * c;
                  e.drawImage(a, l, u, o, c, i.x, i.y, i.width, i.height);
                } else if ('contain' === n.fit) {
                  var s = Math.min(i.width / a.width, i.height / a.height),
                    f = s * a.width,
                    d = s * a.height,
                    h = i.x + 0.5 * i.width - 0.5 * f,
                    p = i.y + 0.5 * i.height - 0.5 * d;
                  e.drawImage(a, 0, 0, a.width, a.height, h, p, f, d);
                } else e.drawImage(a, 0, 0, a.width, a.height, i.x, i.y, i.width, i.height);
                hn(e), r();
              }),
              (a.src = n.src);
          },
          text: function (e, t, n) {
            var r = qt(n, t),
              i = jt(n, t);
            dn(e, i);
            var o = Ft(n.fontSize, t),
              a = n.fontFamily || 'sans-serif',
              c = n.fontWeight || 'normal',
              l = n.textAlign || 'left';
            return (
              (e.font = ''.concat(c, ' ').concat(o, 'px ').concat(a)),
              (e.textAlign = l),
              e.fillText(n.text, r.x, r.y),
              hn(e),
              !0
            );
          },
          line: function (e, t, n) {
            var r = qt(n, t),
              i = jt(n, t);
            dn(e, i), e.beginPath();
            var o = { x: r.x, y: r.y },
              a = { x: r.x + r.width, y: r.y + r.height };
            e.moveTo(o.x, o.y), e.lineTo(a.x, a.y);
            var c = Bt({ x: a.x - o.x, y: a.y - o.y }),
              l = 0.04 * Math.min(t.width, t.height);
            if (-1 !== n.lineDecoration.indexOf('arrow-begin')) {
              var u = Ut(c, l),
                s = Vt(o, u),
                f = zt(o, 2, s),
                d = zt(o, -2, s);
              e.moveTo(f.x, f.y), e.lineTo(o.x, o.y), e.lineTo(d.x, d.y);
            }
            if (-1 !== n.lineDecoration.indexOf('arrow-end')) {
              var h = Ut(c, -l),
                p = Vt(a, h),
                g = zt(a, 2, p),
                m = zt(a, -2, p);
              e.moveTo(g.x, g.y), e.lineTo(a.x, a.y), e.lineTo(m.x, m.y);
            }
            return hn(e), !0;
          },
          path: function (e, t, n) {
            var r = jt(n, t);
            dn(e, r), e.beginPath();
            var i = n.points.map(function (e) {
              return { x: Ft(e.x, t, 1, 'width'), y: Ft(e.y, t, 1, 'height') };
            });
            e.moveTo(i[0].x, i[0].y);
            for (var o = i.length, a = 1; a < o; a++) e.lineTo(i[a].x, i[a].y);
            return hn(e), !0;
          },
        },
        gn = function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
          return new Promise(function (r, i) {
            if (!e || !Mt(e.type)) return i({ status: 'not an image file', file: e });
            var o = n.stripImageHead,
              a = n.beforeCreateBlob,
              c = n.afterCreateBlob,
              l = n.canvasMemoryLimit,
              u = t.crop,
              s = t.size,
              f = t.filter,
              d = t.markup,
              h = t.output,
              p = t.image && t.image.orientation ? Math.max(1, Math.min(8, t.image.orientation)) : null,
              g = h && h.quality,
              m = null === g ? null : g / 100,
              v = (h && h.type) || null,
              y = (h && h.background) || null,
              E = [];
            !s || ('number' != typeof s.width && 'number' != typeof s.height) || E.push({ type: 'resize', data: s }),
              f && 20 === f.length && E.push({ type: 'filter', data: f });
            var w = function (e) {
                var t = c ? c(e) : e;
                Promise.resolve(t).then(r);
              },
              _ = function (t, n) {
                var r = (function (e) {
                    var t = document.createElement('canvas');
                    return (t.width = e.width), (t.height = e.height), t.getContext('2d').putImageData(e, 0, 0), t;
                  })(t),
                  c = d.length ? fn(r, d) : r;
                Promise.resolve(c).then(function (t) {
                  (function (e, t) {
                    var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : null;
                    return new Promise(function (r) {
                      var i = n ? n(e) : e;
                      Promise.resolve(i).then(function (e) {
                        e.toBlob(r, t.type, t.quality);
                      });
                    });
                  })(t, n, a)
                    .then(function (n) {
                      if ((kt(t), o)) return w(n);
                      ln(e).then(function (e) {
                        null !== e && (n = new Blob([e, n.slice(20)], { type: n.type })), w(n);
                      });
                    })
                    .catch(i);
                });
              };
            if (/svg/.test(e.type) && null === v)
              return (function (e, t, n, r) {
                return new Promise(function (i) {
                  var o = r.background,
                    a = void 0 === o ? null : o,
                    c = new FileReader();
                  (c.onloadend = function () {
                    var e = c.result,
                      r = document.createElement('div');
                    (r.style.cssText = 'position:absolute;pointer-events:none;width:0;height:0;visibility:hidden;'),
                      (r.innerHTML = e);
                    var o = r.querySelector('svg');
                    document.body.appendChild(r);
                    var l = o.getBBox();
                    r.parentNode.removeChild(r);
                    var u = r.querySelector('title'),
                      s = o.getAttribute('viewBox') || '',
                      f = o.getAttribute('width') || '',
                      d = o.getAttribute('height') || '',
                      h = parseFloat(f) || null,
                      p = parseFloat(d) || null,
                      g = (f.match(/[a-z]+/) || [])[0] || '',
                      m = (d.match(/[a-z]+/) || [])[0] || '',
                      v = s.split(' ').map(parseFloat),
                      y = v.length ? { x: v[0], y: v[1], width: v[2], height: v[3] } : l,
                      E = null != h ? h : y.width,
                      w = null != p ? p : y.height;
                    (o.style.overflow = 'visible'), o.setAttribute('width', E), o.setAttribute('height', w);
                    var _ = '';
                    if (n && n.length) {
                      var T = { width: E, height: w };
                      (_ = n.sort(on).reduce(function (e, t) {
                        var n = nn(t[0], t[1]);
                        return (
                          rn(n, t[0], t[1], T),
                          n.removeAttribute('id'),
                          1 === n.getAttribute('opacity') && n.removeAttribute('opacity'),
                          e + '\n' + n.outerHTML + '\n'
                        );
                      }, '')),
                        (_ = '\n\n<g>'.concat(_.replace(/&nbsp;/g, ' '), '</g>\n\n'));
                    }
                    var b = t.aspectRatio || w / E,
                      A = E,
                      x = A * b,
                      O = void 0 === t.scaleToFit || t.scaleToFit,
                      R = nt(
                        { width: E, height: w },
                        Ke({ width: A, height: x }, b),
                        t.rotation,
                        O ? t.center : { x: 0.5, y: 0.5 },
                      ),
                      I = t.zoom * R,
                      C = t.rotation * (180 / Math.PI),
                      S = { x: 0.5 * A, y: 0.5 * x },
                      M = { x: S.x - E * t.center.x, y: S.y - w * t.center.y },
                      L = [
                        'rotate('.concat(C, ' ').concat(S.x, ' ').concat(S.y, ')'),
                        'translate('.concat(S.x, ' ').concat(S.y, ')'),
                        'scale('.concat(I, ')'),
                        'translate('.concat(-S.x, ' ').concat(-S.y, ')'),
                        'translate('.concat(M.x, ' ').concat(M.y, ')'),
                      ],
                      P = [
                        'scale('.concat(t.flip.horizontal ? -1 : 1, ' ').concat(t.flip.vertical ? -1 : 1, ')'),
                        'translate('.concat(t.flip.horizontal ? -E : 0, ' ').concat(t.flip.vertical ? -w : 0, ')'),
                      ],
                      k = '<?xml version="1.0" encoding="UTF-8"?>\n<svg width="'
                        .concat(A)
                        .concat(g, '" height="')
                        .concat(x)
                        .concat(m, '" \nviewBox="0 0 ')
                        .concat(A, ' ')
                        .concat(x, '" ')
                        .concat(
                          a ? 'style="background:' + a + '" ' : '',
                          '\npreserveAspectRatio="xMinYMin"\nxmlns:xlink="http://www.w3.org/1999/xlink"\nxmlns="http://www.w3.org/2000/svg">\n\x3c!-- Generated by PQINA - https://pqina.nl/ --\x3e\n<title>',
                        )
                        .concat(u ? u.textContent : '', '</title>\n<g transform="')
                        .concat(L.join(' '), '">\n<g transform="')
                        .concat(P.join(' '), '">\n')
                        .concat(o.outerHTML)
                        .concat(_, '\n</g>\n</g>\n</svg>');
                    i(k);
                  }),
                    c.readAsText(e);
                });
              })(e, u, d, { background: y }).then(function (e) {
                r(un(e, 'image/svg+xml'));
              });
            var T = URL.createObjectURL(e);
            (function (e) {
              return new Promise(function (t, n) {
                var r = new Image();
                (r.onload = function () {
                  t(r);
                }),
                  (r.onerror = function (e) {
                    n(e);
                  }),
                  (r.src = e);
              });
            })(T)
              .then(function (t) {
                URL.revokeObjectURL(T);
                var n = Dt(t, p, u, { canvasMemoryLimit: l, background: y }),
                  r = { quality: m, type: v || e.type };
                if (!E.length) return _(n, r);
                var i = sn(an);
                i.post(
                  { transforms: E, imageData: n },
                  function (e) {
                    _(
                      (function (e) {
                        var t;
                        try {
                          t = new ImageData(e.width, e.height);
                        } catch (n) {
                          t = document.createElement('canvas').getContext('2d').createImageData(e.width, e.height);
                        }
                        return t.data.set(e.data), t;
                      })(e),
                      r,
                    ),
                      i.terminate();
                  },
                  [n.data.buffer],
                );
              })
              .catch(i);
          });
        },
        mn = function (e, t) {
          if (1165519206 !== e.getUint32((t += 2), !1)) return -1;
          var n = 18761 === e.getUint16((t += 6), !1);
          t += e.getUint32(t + 4, n);
          var r = e.getUint16(t, n);
          t += 2;
          for (var i = 0; i < r; i++) if (274 === e.getUint16(t + 12 * i, n)) return e.getUint16(t + 12 * i + 8, n);
        },
        vn = function (e) {
          return new Promise(function (t) {
            var n = new FileReader();
            (n.onload = function () {
              return t(
                (function (e) {
                  var t = new DataView(e);
                  if (65496 != t.getUint16(0, !1)) return null;
                  for (var n, r = t.byteLength, i = 2; i < r; ) {
                    if (t.getUint16(i + 2, !1) <= 8) return -1;
                    if (((n = t.getUint16(i, !1)), (i += 2), 65505 === n)) return mn(t, i);
                    if (65280 != (65280 & n)) return null;
                    i += t.getUint16(i, !1);
                  }
                })(n.result) || -1,
              );
            }),
              n.readAsArrayBuffer(e.slice(0, 262144));
          });
        },
        yn = 1,
        En = 2,
        wn = function (e, t, n) {
          var r = !(arguments.length > 3 && void 0 !== arguments[3]) || arguments[3],
            i = e.center,
            o = e.zoom,
            a = e.aspectRatio,
            c = He(t),
            l = { x: c.x - n.width * i.x, y: c.y - n.height * i.y },
            u = 2 * Math.PI + (e.rotation % (2 * Math.PI)),
            s = o * nt(n, Ke(t, a || n.height / n.width), u, r ? i : { x: 0.5, y: 0.5 });
          return { origin: { x: i.x * n.width, y: i.y * n.height }, translation: l, scale: s, rotation: e.rotation };
        };
      function _n(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              Tn(e, t, n[t]);
            });
        }
        return e;
      }
      function Tn(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var bn = function (e) {
        return { origin: _n({}, e.origin), translation: _n({}, e.translation), rotation: e.rotation, scale: e.scale };
      };
      function An(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              xn(e, t, n[t]);
            });
        }
        return e;
      }
      function xn(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var On = function (e, t, n, r) {
          var i,
            o,
            a,
            c = n.translation,
            l = n.scale,
            u = n.rotation,
            s = n.origin,
            f = { origin: An({}, s), translation: An({}, c), scale: l, rotation: 2 * Math.PI + (u % (2 * Math.PI)) },
            d = e.height / e.width,
            h = ot(s, c, f.rotation, t),
            p = He(h),
            g = We(h),
            m = rt(e, n),
            v = He(m),
            y = { x: m.x, y: m.y },
            E = { x: v.x, y: v.y },
            w = p.x,
            _ = p.y,
            T = { x: y.x, y: y.y, width: m.width, height: m.height };
          if (
            ((i = m),
            (o = We(h)),
            (a = We(i)),
            !(o.left >= a.left && o.top >= a.top && o.bottom <= a.bottom && o.right <= a.right))
          )
            if ('moving' === r) {
              T.y > h.y ? (T.y = h.y) : T.y + T.height < g.bottom && (T.y = g.bottom - T.height),
                T.x > h.x ? (T.x = h.x) : T.x + T.width < g.right && (T.x = g.right - T.width);
              var b = rt(e, An({}, n, { scale: f.scale })),
                A = He(b);
              (E.x = A.x),
                (E.y = A.y),
                (y.x = b.x),
                (y.y = b.y),
                (T.x = E.x - 0.5 * T.width),
                (T.y = E.y - 0.5 * T.height),
                T.y > h.y ? (T.y = h.y) : T.y + T.height < g.bottom && (T.y = g.bottom - T.height),
                T.x > h.x ? (T.x = h.x) : T.x + T.width < g.right && (T.x = g.right - T.width);
              var x = { x: T.x - y.x, y: T.y - y.y },
                O = {
                  x: x.x * Math.cos(f.rotation) - x.y * Math.sin(f.rotation),
                  y: x.x * Math.sin(f.rotation) + x.y * Math.cos(f.rotation),
                };
              (f.translation.x += O.x), (f.translation.y += O.y);
            } else if ('resizing' === r) {
              m.width < h.width &&
                ((T.width = h.width),
                (T.height = T.width * d),
                T.height < h.height && ((T.height = h.height), (T.width = T.height / d))),
                m.height < h.height &&
                  ((T.height = h.height),
                  (T.width = T.height / d),
                  T.width < h.width && ((T.width = h.width), (T.height = T.width * d))),
                (T.x = E.x - 0.5 * T.width),
                (T.y = E.y - 0.5 * T.height),
                T.y > h.y ? (T.y = h.y) : T.y + T.height < g.bottom && (T.y = g.bottom - T.height),
                T.x > h.x ? (T.x = h.x) : T.x + T.width < g.right && (T.x = g.right - T.width),
                (f.scale = nt(e, t, f.rotation, { x: (w - T.x) / T.width, y: (_ - T.y) / T.height }));
              var R = rt(e, An({}, n, { scale: f.scale })),
                I = He(R);
              (E.x = I.x),
                (E.y = I.y),
                (y.x = R.x),
                (y.y = R.y),
                (T.x = E.x - 0.5 * T.width),
                (T.y = E.y - 0.5 * T.height),
                T.y > h.y ? (T.y = h.y) : T.y + T.height < g.bottom && (T.y = g.bottom - T.height),
                T.x > h.x ? (T.x = h.x) : T.x + T.width < g.right && (T.x = g.right - T.width);
              var C = { x: T.x - y.x, y: T.y - y.y },
                S = {
                  x: C.x * Math.cos(f.rotation) - C.y * Math.sin(f.rotation),
                  y: C.x * Math.sin(f.rotation) + C.y * Math.cos(f.rotation),
                };
              (f.translation.x += S.x), (f.translation.y += S.y);
            } else if ('rotating' === r) {
              var M = !1;
              if (T.y > h.y) {
                var L = T.y - h.y;
                (T.y = h.y), (T.height += 2 * L), (M = !0);
              }
              if (T.y + T.height < g.bottom) {
                var P = g.bottom - (T.y + T.height);
                (T.y = g.bottom - T.height), (T.height += 2 * P), (M = !0);
              }
              if (T.x > h.x) {
                var k = T.x - h.x;
                (T.x = h.x), (T.width += 2 * k), (M = !0);
              }
              if (T.x + T.width < g.right) {
                var G = g.right - (T.x + T.width);
                (T.x = g.right - T.width), (T.width += 2 * G), (M = !0);
              }
              M && (f.scale = nt(e, t, f.rotation, { x: (w - m.x) / m.width, y: (_ - m.y) / m.height }));
            }
          return An({}, f, { rotation: n.rotation });
        },
        Rn = {
          n: function (e) {
            return { x: e.x + 0.5 * e.width, y: e.y };
          },
          e: function (e) {
            return { x: e.x + e.width, y: e.y + 0.5 * e.height };
          },
          s: function (e) {
            return { x: e.x + 0.5 * e.width, y: e.y + e.height };
          },
          w: function (e) {
            return { x: e.x, y: e.y + 0.5 * e.height };
          },
        },
        In = function (e, t) {
          return Rn[e](t);
        };
      function Cn(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var Sn = function (e, t, n) {
          var r = n.origin,
            i = n.translation,
            o = 2 * Math.PI + (n.rotation % (2 * Math.PI)),
            a = rt(e, n),
            c = { x: r.x + i.x, y: r.y + i.y },
            l = ot(r, i, o, t),
            u = We(l),
            s = We(a),
            f = a;
          if (u.top < s.top || u.right > s.right || u.bottom > s.bottom || u.left < s.left) {
            var d = (function (e) {
              for (var t = 1; t < arguments.length; t++) {
                var n = null != arguments[t] ? arguments[t] : {},
                  r = Object.keys(n);
                'function' == typeof Object.getOwnPropertySymbols &&
                  (r = r.concat(
                    Object.getOwnPropertySymbols(n).filter(function (e) {
                      return Object.getOwnPropertyDescriptor(n, e).enumerable;
                    }),
                  )),
                  r.forEach(function (t) {
                    Cn(e, t, n[t]);
                  });
              }
              return e;
            })({}, s);
            if (u.top <= d.top) {
              var h = d.bottom - d.top,
                p = d.right - d.left,
                g = Math.max(1, l.height / h),
                m = h * g,
                v = p * g - p;
              (d.bottom = u.top + m), (d.top = u.top), (d.left -= 0.5 * v), (d.right += 0.5 * v);
            }
            if (u.bottom >= d.bottom) {
              var y = d.bottom - d.top,
                E = d.right - d.left,
                w = Math.max(1, l.height / y),
                _ = y * w,
                T = E * w - E;
              (d.bottom = u.bottom), (d.top = u.bottom - _), (d.left -= 0.5 * T), (d.right += 0.5 * T);
            }
            if (u.left <= d.left) {
              var b = d.bottom - d.top,
                A = d.right - d.left,
                x = Math.max(1, l.width / A),
                O = A * x,
                R = b * x - b;
              (d.right = u.left + O), (d.left = u.left), (d.top -= 0.5 * R), (d.bottom += 0.5 * R);
            }
            if (u.right >= d.right) {
              var I = d.bottom - d.top,
                C = d.right - d.left,
                S = Math.max(1, l.width / C),
                M = C * S,
                L = I * S - I;
              (d.right = u.right), (d.left = u.right - M), (d.top -= 0.5 * L), (d.bottom += 0.5 * L);
            }
            f = Xe(d.left, d.top, d.right - d.left, d.bottom - d.top);
          }
          var P = Ye(f),
            k = He(f),
            G = Ue(P.tl, o, c),
            D = Ue(P.br, o, c),
            U = G.x + 0.5 * (D.x - G.x),
            V = G.y + 0.5 * (D.y - G.y),
            B = Ne(f, { x: U - k.x, y: V - k.y }),
            z = Ne(l, { x: U - k.x, y: V - k.y }),
            N = He(z),
            F = { x: B.x, y: B.y },
            j = B.width,
            W = B.height,
            q = (N.x - F.x) / j,
            H = (N.y - F.y) / W,
            Y = j / e.width,
            X = { x: q * e.width, y: H * e.height },
            Z = 1 - Y,
            K = X.x * Z,
            Q = X.y * Z,
            J = { x: F.x + j * q, y: F.y + W * H },
            $ = Ue(F, o, { x: F.x + 0.5 * j, y: F.y + 0.5 * W }),
            ee = Ue(F, o, J),
            te = $.x - ee.x,
            ne = $.y - ee.y;
          return { origin: X, translation: { x: F.x - K + te, y: F.y - Q + ne }, scale: Y, rotation: n.rotation };
        },
        Mn = {
          nw: function (e) {
            return { x: e.x, y: e.y };
          },
          ne: function (e) {
            return { x: e.x + e.width, y: e.y };
          },
          se: function (e) {
            return { x: e.x + e.width, y: e.y + e.height };
          },
          sw: function (e) {
            return { x: e.x, y: e.y + e.height };
          },
        },
        Ln = function (e, t) {
          return Mn[e](t);
        },
        Pn = Math.PI / 2,
        kn = Math.PI / 4,
        Gn = function (e) {
          var t = Me(kn),
            n = Me(Pn),
            r = e / n,
            i = Math.floor(r) * n,
            o = e - i;
          return o > t && ((o -= n), (i += n)), { main: i, sub: o };
        },
        Dn = function (e) {
          return new Promise(function (t, n) {
            var r = new Image();
            (r.src = URL.createObjectURL(e)),
              (r.onerror = function (e) {
                clearInterval(i), n(e);
              });
            var i = setInterval(function () {
              r.naturalWidth &&
                r.naturalHeight &&
                (clearInterval(i), URL.revokeObjectURL(r.src), t({ width: r.naturalWidth, height: r.naturalHeight }));
            }, 1);
          });
        },
        Un = function (e, t) {
          var n = { width: e.width, height: e.height };
          if (e.width > t.width || e.height > t.height) {
            var r = e.height / e.width,
              i = t.width / e.width,
              o = t.height / e.height;
            i < o
              ? ((n.width = e.width * i), (n.height = n.width * r))
              : ((n.height = e.height * o), (n.width = n.height / r));
          }
          return n;
        },
        Vn = function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : '';
          return (t + e).slice(-t.length);
        },
        Bn = function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : new Date();
          return ''
            .concat(e.getFullYear(), '-')
            .concat(Vn(e.getMonth() + 1, '00'), '-')
            .concat(Vn(e.getDate(), '00'), '_')
            .concat(Vn(e.getHours(), '00'), '-')
            .concat(Vn(e.getMinutes(), '00'), '-')
            .concat(Vn(e.getSeconds(), '00'));
        },
        zn = function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
            r = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
            i = e('GET_CROP_ASPECT_RATIO'),
            o = {
              center: { x: 0.5, y: 0.5 },
              flip: { horizontal: !1, vertical: !1 },
              zoom: 1,
              rotation: 0,
              aspectRatio: null,
            };
          n ? Object.assign(o, n) : t.options.crop ? Object.assign(o, t.options.crop) : (o.aspectRatio = i);
          var a = r.width,
            c = r.height;
          if (a && c) o.aspectRatio = c / a;
          else if (t.instructions.size) {
            var l = t.instructions.size,
              u = l.width,
              s = l.height;
            u && s && (o.aspectRatio = s / u);
          }
          return o;
        },
        Nn = function (e) {
          return e.charAt(0).toUpperCase() + e.slice(1);
        },
        Fn = function (e) {
          return e.split('.').pop();
        },
        jn = { jpeg: 'jpg', 'svg+xml': 'svg' },
        Wn = function (e) {
          return Array.isArray(e) && 20 === e.length;
        };
      function qn(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              Hn(e, t, n[t]);
            });
        }
        return e;
      }
      function Hn(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      function Yn(e, t) {
        return (
          (function (e) {
            if (Array.isArray(e)) return e;
          })(e) ||
          (function (e, t) {
            var n = [],
              r = !0,
              i = !1,
              o = void 0;
            try {
              for (
                var a, c = e[Symbol.iterator]();
                !(r = (a = c.next()).done) && (n.push(a.value), !t || n.length !== t);
                r = !0
              );
            } catch (e) {
              (i = !0), (o = e);
            } finally {
              try {
                r || null == c.return || c.return();
              } finally {
                if (i) throw o;
              }
            }
            return n;
          })(e, t) ||
          (function () {
            throw new TypeError('Invalid attempt to destructure non-iterable instance');
          })()
        );
      }
      var Xn = ['x', 'y', 'left', 'top', 'right', 'bottom', 'width', 'height'],
        Zn = function (e) {
          var t = Yn(e, 2),
            n = t[0],
            r = t[1],
            i = !1 !== r.allowSelect,
            o = !1 !== r.allowMove,
            a = !1 !== r.allowResize,
            c = !1 !== r.allowInput,
            l = !1 !== r.allowDestroy,
            u = void 0 === r.allowEdit || r.allowEdit;
          (!0 === r.allowResize || !0 === r.allowMove || !0 === r.allowInput || r.allowEdit) && (i = !0),
            !1 === r.allowMove && (a = !1),
            !0 === r.allowResize && (o = !0);
          var s = r.points
            ? {}
            : Xn.reduce(function (e, t) {
                var n;
                return (e[t] = 'string' == typeof (n = r[t]) && /%/.test(n) ? parseFloat(n) / 100 : n), e;
              }, {});
          return (
            r.points && (a = !1),
            [
              n,
              qn({ zIndex: 0, id: Math.random().toString(36).substr(2, 9) }, r, s, {
                isDestroyed: !1,
                isSelected: !1,
                isDirty: !0,
                allowDestroy: l,
                allowSelect: i,
                allowMove: o,
                allowResize: a,
                allowInput: c,
                allowEdit: u,
              }),
            ]
          );
        },
        Kn = void 0,
        Qn = W() ? new Image() : {};
      (Qn.onload = function () {
        (Kn = Qn.naturalWidth > Qn.naturalHeight), (Qn = void 0);
      }),
        (Qn.src =
          'data:image/jpg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4QA6RXhpZgAATU0AKgAAAAgAAwESAAMAAAABAAYAAAEoAAMAAAABAAIAAAITAAMAAAABAAEAAAAAAAD/2wBDAP//////////////////////////////////////////////////////////////////////////////////////wAALCAABAAIBASIA/8QAJgABAAAAAAAAAAAAAAAAAAAAAxABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQAAPwBH/9k=');
      var Jn = null,
        $n = function () {
          return null === Jn && (Jn = /MSIE|Trident/.test(window.navigator.userAgent)), Jn;
        };
      function er(e) {
        return (
          (function (e) {
            if (Array.isArray(e)) {
              for (var t = 0, n = new Array(e.length); t < e.length; t++) n[t] = e[t];
              return n;
            }
          })(e) ||
          (function (e) {
            if (Symbol.iterator in Object(e) || '[object Arguments]' === Object.prototype.toString.call(e))
              return Array.from(e);
          })(e) ||
          (function () {
            throw new TypeError('Invalid attempt to spread non-iterable instance');
          })()
        );
      }
      function tr(e, t) {
        return (
          (function (e) {
            if (Array.isArray(e)) return e;
          })(e) ||
          (function (e, t) {
            var n = [],
              r = !0,
              i = !1,
              o = void 0;
            try {
              for (
                var a, c = e[Symbol.iterator]();
                !(r = (a = c.next()).done) && (n.push(a.value), !t || n.length !== t);
                r = !0
              );
            } catch (e) {
              (i = !0), (o = e);
            } finally {
              try {
                r || null == c.return || c.return();
              } finally {
                if (i) throw o;
              }
            }
            return n;
          })(e, t) ||
          (function () {
            throw new TypeError('Invalid attempt to destructure non-iterable instance');
          })()
        );
      }
      function nr(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              rr(e, t, n[t]);
            });
        }
        return e;
      }
      function rr(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var ir = {
          contrast: function (e) {
            return [e, 0, 0, 0, 0.5 * (1 - e), 0, e, 0, 0, 0.5 * (1 - e), 0, 0, e, 0, 0.5 * (1 - e), 0, 0, 0, 1, 0];
          },
          exposure: function (e) {
            return [e, 0, 0, 0, 0, 0, e, 0, 0, 0, 0, 0, e, 0, 0, 0, 0, 0, 1, 0];
          },
          brightness: function (e) {
            return [1, 0, 0, 0, e, 0, 1, 0, 0, e, 0, 0, 1, 0, e, 0, 0, 0, 1, 0];
          },
          saturation: function () {
            var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0;
            return [
              0.213 + 0.787 * e,
              0.715 - 0.715 * e,
              0.072 - 0.072 * e,
              0,
              0,
              0.213 - 0.213 * e,
              0.715 + 0.285 * e,
              0.072 - 0.072 * e,
              0,
              0,
              0.213 - 0.213 * e,
              0.715 - 0.715 * e,
              0.072 + 0.928 * e,
              0,
              0,
              0,
              0,
              0,
              1,
              0,
            ];
          },
        },
        or = Math.PI / 2,
        ar = function (e) {
          var t = {
              upscale: e('GET_OUTPUT_UPSCALE'),
              mode: e('GET_OUTPUT_FIT'),
              width: e('GET_OUTPUT_WIDTH'),
              height: e('GET_OUTPUT_HEIGHT'),
            },
            n = e('GET_SIZE_INPUT');
          if (n.width || n.height) {
            var r = n.width,
              i = n.height,
              o = e('GET_CROP_RECTANGLE_ASPECT_RATIO');
            r && !i ? (i = r / o) : i && !r && (r = i * o),
              (t.width = r),
              (t.height = i),
              (t.upscale = !0),
              (t.mode = 'force');
          }
          return t;
        },
        cr = function (e, t) {
          var n = t('GET_UID'),
            r = t('GET_CROP', n, Date.now()),
            i = { width: r.cropStatus.currentWidth, height: r.cropStatus.currentHeight },
            o = e.mode,
            a = e.width,
            c = e.height,
            l = e.upscale;
          if (!a && !c) return i;
          if ((null === a ? (a = c) : null === c && (c = a), 'force' !== o)) {
            var u = a / i.width,
              s = c / i.height,
              f = 1;
            if (('cover' === o ? (f = Math.max(u, s)) : 'contain' === o && (f = Math.min(u, s)), f > 1 && !1 === l))
              return i;
            (a = i.width * f), (c = i.height * f);
          }
          return { width: Math.round(a), height: Math.round(c) };
        },
        lr = function (e) {
          return e.markup.filter(function (e) {
            return !e[1].isDestroyed;
          });
        },
        ur = function (e, t, n) {
          return new Promise(function (r, i) {
            var o,
              a = { data: null, file: null },
              c = ut(t.image, t.crop),
              l = ar(n),
              u = {
                crop: c,
                image: nr({}, cr(l, n), { orientation: t.file.orientation }),
                size: l,
                output: {
                  type: n('GET_OUTPUT_TYPE'),
                  quality: n('GET_OUTPUT_QUALITY'),
                  background: t.options.outputCanvasBackgroundColor,
                },
                filter: t.colorMatrices.filter
                  ? { id: t.filterName, value: t.filterValue, matrix: t.colorMatrices.filter }
                  : null,
                color: Object.keys(t.colorValues).length
                  ? Object.keys(t.colorValues).reduce(function (e, n) {
                      return (
                        (e[n] = {
                          value: t.colorValues[n],
                          matrix: t.colorMatrices[n].map(function (e) {
                            return Me(e, 5);
                          }),
                        }),
                        e
                      );
                    }, {})
                  : null,
                markup:
                  ((o = lr(t).map(function (e) {
                    return [e[0], nr({}, e[1])];
                  })),
                  o.map(function (e) {
                    var t = nr({}, e[1]);
                    return (
                      Object.keys(t).forEach(function (e) {
                        void 0 === t[e] && delete t[e];
                      }),
                      delete t.isDestroyed,
                      delete t.isSelected,
                      delete t.isDirty,
                      [e[0], t]
                    );
                  })),
                colorMatrix: n('GET_COLOR_MATRIX'),
              };
            if ((e.data && (a.data = u), e.file)) {
              var s = {
                  beforeCreateBlob: n('GET_BEFORE_CREATE_BLOB'),
                  afterCreateBlob: n('GET_AFTER_CREATE_BLOB'),
                  stripImageHead: n('GET_OUTPUT_STRIP_IMAGE_HEAD'),
                  canvasMemoryLimit: n('GET_OUTPUT_CANVAS_MEMORY_LIMIT'),
                },
                f = t.file.data,
                d = nr({}, u, { filter: u.colorMatrix, markup: u.markup });
              gn(f, d, s)
                .then(function (e) {
                  var t;
                  (a.file = (function (e, t) {
                    var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : null,
                      r = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : null,
                      i = 'string' == typeof n ? e.slice(0, e.size, n) : e.slice(0, e.size, e.type);
                    return (
                      (i.lastModifiedDate = new Date()),
                      te(t) || (t = Bn()),
                      t && null === r && Fn(t)
                        ? (i.name = t)
                        : ((r =
                            r ||
                            (function (e) {
                              if ('string' != typeof e) return '';
                              var t = e.split('/').pop();
                              return /svg/.test(t)
                                ? 'svg'
                                : /zip|compressed/.test(t)
                                  ? 'zip'
                                  : /plain/.test(t)
                                    ? 'txt'
                                    : /msword/.test(t)
                                      ? 'doc'
                                      : /[a-z]+/.test(t)
                                        ? 'jpeg' === t
                                          ? 'jpg'
                                          : t
                                        : '';
                            })(i.type)),
                          (i.name = t + (r ? '.' + r : ''))),
                      i
                    );
                  })(
                    e,
                    (function (e, t) {
                      var n = (function (e) {
                          return e.substr(0, e.lastIndexOf('.')) || e;
                        })(e),
                        r = t.split('/')[1],
                        i = jn[r] || r;
                      return ''.concat(n, '.').concat(i);
                    })(f.name, ((t = e.type), /jpeg|png|svg\+xml/.test(t) ? t : 'image/jpeg')),
                  )),
                    r(a);
                })
                .catch(i);
            } else r(a);
          });
        },
        sr = function (e) {
          e.crop.draft.rotateMinScale = null;
        },
        fr = function (e, t, n) {
          var r = arguments.length > 3 && void 0 !== arguments[3] && arguments[3],
            i = !(arguments.length > 4 && void 0 !== arguments[4]) || arguments[4];
          !(function (e) {
            e.crop.draft.rotateMinScale || (e.crop.draft.rotateMinScale = e.crop.transforms.scale);
          })(e);
          var o = nr({}, e.crop.transforms, { scale: e.crop.draft.rotateMinScale });
          (e.crop.draft.transforms = wr(
            e.image,
            e.crop.rectangle,
            o,
            t.main + t.sub,
            n,
            e.crop.draft.transforms ? e.crop.draft.transforms.rotation : e.crop.rotation.main + e.crop.rotation.sub,
            r,
            i,
          )),
            (e.crop.rotation = Gn(e.crop.draft.transforms.rotation));
        },
        dr = function (e, t) {
          if (null !== e.stage) {
            var n =
                void 0 === e.instructions.crop.scaleToFit
                  ? void 0 === e.crop.limitToImageBounds
                    ? e.options.cropLimitToImageBounds
                    : e.crop.limitToImageBounds
                  : e.instructions.crop.scaleToFit,
              r = t('GET_STAGE_RECT', e.instructions.crop);
            (e.crop.rectangle = Ke(r.fits ? r : e.stage, e.instructions.crop.aspectRatio || e.image.aspectRatio)),
              (e.crop.draft.rectangle = null),
              'stage' !== r.mode && r.fits && ((e.crop.rectangle.x = r.x), (e.crop.rectangle.y = r.y)),
              (e.crop.transforms = wn(e.instructions.crop, r, e.image, n)),
              (e.crop.draft.transforms = null),
              (e.crop.rotation = Gn(e.instructions.crop.rotation)),
              (e.crop.flip = nr({}, e.instructions.crop.flip));
            var i = t('GET_CROP_ASPECT_RATIO_OPTIONS') || [],
              o = i
                .map(function (e) {
                  return e.value.aspectRatio;
                })
                .find(function (t) {
                  return t === e.instructions.crop.aspectRatio;
                }),
              a = i.find(function (e) {
                return null === e.value.aspectRatio;
              });
            o
              ? (e.crop.aspectRatio = o)
              : a && i.length
                ? (e.crop.aspectRatio = null)
                : (e.crop.aspectRatio = t('GET_CROP_ASPECT_RATIO')),
              (e.crop.isDirty = !1);
          }
        },
        hr = function (e, t, n) {
          if (null !== e.stage) {
            vr(e),
              (e.size.width = !!e.instructions.size && e.instructions.size.width),
              (e.size.height = !!e.instructions.size && e.instructions.size.height),
              (e.size.aspectRatioLocked = !0),
              (e.size.aspectRatioPrevious = !1);
            var r =
              void 0 === e.instructions.crop.scaleToFit
                ? void 0 === e.crop.limitToImageBounds
                  ? e.options.cropLimitToImageBounds
                  : e.crop.limitToImageBounds
                : e.instructions.crop.scaleToFit;
            dr(e, t),
              e.instructions.markup && n('MARKUP_SET_VALUE', { value: e.instructions.markup }),
              n('CROP_SET_LIMIT', { value: r, silent: !0 }),
              Object.keys(e.instructions.color).forEach(function (t) {
                return n('COLOR_SET_VALUE', { key: t, value: e.instructions.color[t] });
              }),
              n('FILTER_SET_VALUE', { value: e.instructions.filter }),
              sr(e);
          }
        },
        pr = function (e, t) {
          if (e.stage) {
            vr(e);
            var n = e.crop.rectangle,
              r = n.height / n.width,
              i = e.crop.aspectRatio;
            if (null !== i && Me(r, 3) !== Me(i, 3)) {
              var o = t('GET_MIN_CROP_SIZE');
              (o.width = Me(o.width)), (o.height = Me(o.height));
              var a = Math.min(n.width, n.height);
              Math.min(a * i, a / i) < Math.max(o.width, o.height) &&
                ((e.crop.rectangle = (function (e, t, n) {
                  var r = je(e);
                  return (
                    (r.width = Math.min(r.height, r.width)),
                    (r.height = r.width),
                    (r.height = r.width * t),
                    r.height < n.height && ((r.height = n.height), (r.width = r.height / t)),
                    r.width < n.width && ((r.width = n.width), (r.height = r.width * t)),
                    r
                  );
                })(nr({}, e.crop.rectangle), i, o)),
                (e.crop.draft.transforms = Sn(e.image, e.crop.rectangle, e.crop.transforms)));
            }
            var c = e.crop.draft.transforms || e.crop.transforms,
              l = at(e.image, e.crop.rectangle, c, e.crop.limitToImageBounds);
            e.crop.aspectRatio && (l.aspectRatio = e.crop.aspectRatio);
            var u = t('GET_STAGE_RECT', l);
            (e.crop.transforms = wn(l, u, e.image, l.scaleToFit)), (e.crop.draft.transforms = null);
            var s = e.crop.aspectRatio || e.crop.rectangle.height / e.crop.rectangle.width;
            (e.crop.rectangle = Ke(u, s)),
              (e.crop.draft.rectangle = null),
              'stage' !== u.mode && ((e.crop.rectangle.x += u.x), (e.crop.rectangle.y += u.y)),
              sr(e);
          }
        },
        gr = function (e, t, n) {
          var r = t('GET_CROP_ZOOM_TIMEOUT');
          r &&
            (clearTimeout(e.zoomTimeoutId),
            (e.zoomTimeoutId = setTimeout(function () {
              n('CROP_ZOOM');
            }, r)));
        },
        mr = function (e, t, n) {
          vr(e), gr(e, t, n);
        },
        vr = function (e) {
          clearTimeout(e.zoomTimeoutId);
        },
        yr = function (e) {
          (e.crop.draft.transforms = bn(e.crop.transforms)),
            (e.crop.draft.rectangle = { limited: je(e.crop.rectangle), free: je(e.crop.rectangle) }),
            vr(e);
        },
        Er = function (e, t) {
          return Math.min(e.width / t.width, e.height / t.height);
        },
        wr = function (e, t, n, r, i, o, a, c) {
          var l = nr({}, bn(n), { rotation: r }),
            u = c ? On(e, t, l, 'rotating') : l,
            s = Er(t, i);
          return Me(u.scale, 5) > Me(s, 5)
            ? (a && (o += 2 * a), nr({}, bn(n), { rotation: o, interaction: { rotation: u.rotation } }))
            : ((u.scale = Math.min(s, u.scale)), (u.interaction = { rotation: u.rotation }), u);
        },
        _r = function (e, t, n, r, i, o) {
          var a = Math.max(1e-10, r),
            c = nr({}, bn(n), { scale: a }),
            l = o ? On(e, t, c, 'resizing') : c,
            u = Er(t, i);
          return (l.scale = Math.min(u, l.scale)), (l.interaction = { scale: a }), l;
        },
        Tr = function (e) {
          var t, n, r, i, o, a, c, l, u, s, f, d, h, p, g, m, v, y, E, w, _, T, b, A, x, O, R, I, C, S, M, L, P;
          (e.crop.draft.rectangle = null),
            (e.crop.transforms = e.crop.draft.transforms || e.crop.transforms),
            (e.crop.transforms.interaction = null),
            (e.crop.draft.transforms = null),
            (e.crop.transforms = nr(
              {},
              e.crop.transforms,
              ((t = e.image),
              (n = e.crop.rectangle),
              (r = e.crop.transforms),
              (i = r.origin),
              (o = r.translation),
              (a = r.scale),
              (c = 2 * Math.PI + (r.rotation % (2 * Math.PI))),
              (l = { x: i.x + o.x, y: i.y + o.y }),
              (u = ot(i, o, c, n)),
              (s = rt(t, r)),
              (f = Ye(s)),
              (d = He(s)),
              (h = Ue(f.tl, c, l)),
              (p = Ue(f.br, c, l)),
              (g = h.x + 0.5 * (p.x - h.x)),
              (m = h.y + 0.5 * (p.y - h.y)),
              (v = Ne(s, { x: g - d.x, y: m - d.y })),
              (y = Ne(u, { x: g - d.x, y: m - d.y })),
              (E = He(y)),
              (w = { x: v.x, y: v.y }),
              (_ = v.width),
              (T = v.height),
              (b = (E.x - w.x) / _),
              (A = (E.y - w.y) / T),
              (x = { x: b * t.width, y: A * t.height }),
              (R = x.x * (O = 1 - a)),
              (I = x.y * O),
              (C = { x: w.x + _ * b, y: w.y + T * A }),
              (S = Ue(w, c, { x: w.x + 0.5 * _, y: w.y + 0.5 * T })),
              (M = Ue(w, c, C)),
              (L = S.x - M.x),
              (P = S.y - M.y),
              { origin: Le(x), translation: Le({ x: w.x - R + L, y: w.y - I + P }) }),
            )),
            (e.crop.isRotating = !1),
            (e.crop.isDirty = !0);
        },
        br = function (e, t) {
          return e.getAllResponseHeaders().indexOf(t) >= 0 ? e.getResponseHeader(t) : null;
        },
        Ar = { svg: 'svg+xml', jpg: 'jpeg' },
        xr = function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
            n = t.progress,
            r = void 0 === n ? function (e) {} : n,
            i = t.load,
            o = void 0 === i ? function (e, t) {} : i,
            a = t.error,
            c = void 0 === a ? function () {} : a,
            l = new XMLHttpRequest();
          (l.onprogress = function (e) {
            return r(e.lengthComputable ? e.loaded / e.total : null);
          }),
            (l.onerror = function () {
              return c(l);
            }),
            (l.onload = function () {
              var t = l.status >= 200 && l.status < 300,
                n = l.response;
              if (!t || !n) return c(l);
              var r = br(l, 'Content-Disposition'),
                i = r
                  ? (function (e) {
                      if (!e) return null;
                      var t = e
                        .split(/filename=|filename\*=.+''/)
                        .splice(1)
                        .map(function (e) {
                          return e.trim().replace(/^["']|[;"']{0,2}$/g, '');
                        })
                        .filter(function (e) {
                          return e.length;
                        });
                      return t.length ? decodeURI(t[t.length - 1]) : null;
                    })(r)
                  : (function (e) {
                      return e.split('/').pop().split('?').shift();
                    })(e),
                a = (function (e, t) {
                  if (Mt(e)) return e;
                  if (!t) return e;
                  var n = Fn(t);
                  return n ? 'image/'.concat(Ar[n] || n) : e;
                })(l.getResponseHeader('Content-Type'), i),
                u = br(l, 'Content-Doka'),
                s = null;
              if (u)
                try {
                  s = JSON.parse(u);
                } catch (e) {}
              !Mt(n.type) && a && (n = n.slice(0, n.size, a)), i && (n.name = i), o(n, s);
            }),
            l.open('GET', e),
            (l.responseType = 'blob'),
            l.send();
        },
        Or = function (e) {
          for (
            var t = e.split(','),
              n = t[0].match(/([a-z]+\/[a-z]+)/)[0],
              r = atob(t[1]),
              i = r.length,
              o = new ArrayBuffer(r.length),
              a = new Uint8Array(o),
              c = 0;
            c < i;
            c++
          )
            a[c] = r.charCodeAt(c);
          return new Blob([o], { type: n });
        },
        Rr = function (e) {
          return !1 === e.file;
        },
        Ir = function (e, t, n) {
          return nr(
            {
              SET_UID: function (e) {
                var t = e.id;
                n.uid = t;
              },
              AWAIT_IMAGE: function () {
                n.file ||
                  (n.noImageTimeout = setTimeout(function () {
                    e('AWAITING_IMAGE');
                  }, 250));
              },
              REQUEST_REMOVE_IMAGE: function () {
                e('UNLOAD_IMAGE'),
                  (n.file = !1),
                  (n.noImageTimeout = setTimeout(function () {
                    e('AWAITING_IMAGE');
                  }, 500));
              },
              DID_UNLOAD_IMAGE: function () {
                e('ABORT_IMAGE');
              },
              REQUEST_ABORT_IMAGE: function (t) {
                e('UNLOAD_IMAGE'), (n.file = !1), (n.queuedFile = t);
              },
              DID_SET_SRC: function (t) {
                t.value !== t.prevValue &&
                  (clearTimeout(n.noImageTimeout), e('REQUEST_LOAD_IMAGE', { source: t.value }));
              },
              ABORT_IMAGE: function () {
                if (((n.file = null), n.queuedFile)) {
                  var t = n.queuedFile;
                  (n.queuedFile = null), e('REQUEST_LOAD_IMAGE', t);
                }
              },
              REQUEST_LOAD_IMAGE: function (t) {
                var r = t.source,
                  i = t.success,
                  o = void 0 === i ? function () {} : i,
                  a = t.failure,
                  c = void 0 === a ? function (e) {} : a,
                  l = t.options,
                  u = t.resolveOnConfirm,
                  s = void 0 !== u && u;
                if ((clearTimeout(n.noImageTimeout), !r)) return c('no-image-source');
                if (null === n.file) {
                  fe(n), (n.file = { uid: ge() }), e('DID_REQUEST_LOAD_IMAGE', { source: r });
                  var f,
                    d,
                    h,
                    p = function (t) {
                      if (Rr(n)) return e('ABORT_IMAGE');
                      e('DID_LOAD_IMAGE_ERROR', { error: { status: 'IMAGE_LOAD_ERROR', data: t } }), c(t);
                    };
                  ((f = r),
                  (d = {
                    progress: function (t) {
                      return null !== t && e('DID_MAKE_PROGRESS', { progress: t });
                    },
                  }),
                  (h = d.progress),
                  new Promise(function (e, t) {
                    if (te(f)) {
                      var n = /^data:/.test(f);
                      return n && $n()
                        ? e({ file: Or(f) })
                        : void xr(f, {
                            progress: n ? function () {} : h,
                            error: t,
                            load: function (t, n) {
                              return e({ file: t, fileInstructions: n });
                            },
                          });
                    }
                    if (f instanceof Blob) e({ file: f });
                    else {
                      if ('IMG' === f.nodeName) {
                        var r = function (t) {
                          var n = document.createElement('canvas');
                          (n.width = t.naturalWidth),
                            (n.height = t.naturalHeight),
                            n.getContext('2d').drawImage(t, 0, 0),
                            n.toBlob(function (t) {
                              return e({ file: t });
                            });
                        };
                        return f.complete
                          ? void r(f)
                          : void (f.onload = function () {
                              return r(f);
                            });
                      }
                      'CANVAS' !== f.nodeName
                        ? t(f)
                        : f.toBlob(function (t) {
                            return e({ file: t });
                          });
                    }
                  }))
                    .then(function (t) {
                      var r = t.file,
                        i = t.fileInstructions;
                      if (!l && i) {
                        var a = i.crop,
                          u = i.filter,
                          f = i.colorMatrix,
                          d = i.color,
                          h = i.markup,
                          g = i.size;
                        l = { crop: a, filter: u ? u.id || u.matrix : f, color: d, markup: h, size: g };
                      }
                      if (Rr(n)) return e('ABORT_IMAGE');
                      var m = n.options.beforeLoadImage,
                        v = m ? m(r) : r;
                      Promise.resolve(v)
                        .then(function (t) {
                          t.name || (t.name = Bn()),
                            (n.file.orientation = -1),
                            (n.file.data = t),
                            e('LOAD_IMAGE', { success: o, failure: c, options: l, resolveOnConfirm: s }, !0),
                            e('KICK');
                        })
                        .catch(function (e) {
                          setTimeout(function () {
                            p(e);
                          }, 100);
                        });
                    })
                    .catch(p);
                } else e('REQUEST_ABORT_IMAGE', { source: r, success: o, failure: c, options: l, resolveOnConfirm: s });
              },
              LOAD_IMAGE: function (r) {
                var i = r.success,
                  o = r.failure,
                  a = r.options,
                  c = void 0 === a ? {} : a,
                  l = r.resolveOnConfirm;
                if (Rr(n)) return e('ABORT_IMAGE');
                var u = n.file.data;
                Promise.all([Dn(u), vn(u)])
                  .then(function (r) {
                    var a = tr(r, 2),
                      u = a[0],
                      s = a[1];
                    if (Rr(n)) return e('ABORT_IMAGE');
                    if (
                      ((n.file.orientation = t('GET_OUTPUT_CORRECT_IMAGE_EXIF_ORIENTATION') && Kn ? s : -1),
                      n.file.orientation > -1)
                    ) {
                      var f = u.width,
                        d = u.height;
                      s >= 5 && s <= 8 && ((u.width = d), (u.height = f));
                    }
                    var h = t('GET_MIN_IMAGE_SIZE');
                    if (u.width < h.width || u.height < h.height)
                      return (
                        e('DID_LOAD_IMAGE_ERROR', {
                          error: { status: 'IMAGE_MIN_SIZE_VALIDATION_ERROR', data: { size: u, minImageSize: h } },
                        }),
                        fe(n),
                        void o()
                      );
                    var p = Un(u, {
                      width: t('GET_MAX_IMAGE_PREVIEW_WIDTH'),
                      height: t('GET_MAX_IMAGE_PREVIEW_HEIGHT'),
                    });
                    if (
                      ((n.image = {
                        x: 0,
                        y: 0,
                        width: p.width,
                        height: p.height,
                        naturalWidth: u.width,
                        naturalHeight: u.height,
                        aspectRatio: u.height / u.width,
                        orientation: s,
                      }),
                      c.size &&
                        (c.size.hasOwnProperty('mode') && c.size.hasOwnProperty('upscale')
                          ? ((n.options.outputWidth = c.size.width),
                            (n.options.outputHeight = c.size.height),
                            (n.options.outputFit = c.size.mode),
                            (n.options.upscale = c.size.upscale))
                          : ((n.size.width = c.size.width),
                            (n.size.height = c.size.height),
                            (n.size.aspectRatioLocked = !0),
                            (n.size.aspectRatioPrevious = !1),
                            (n.instructions.size = { width: c.size.width, height: c.size.height }))),
                      (n.instructions.crop = zn(t, n, c.crop ? nr({}, c.crop) : null, n.size)),
                      (n.crop.limitToImageBounds = n.options.cropLimitToImageBounds),
                      !1 === n.instructions.crop.scaleToFit &&
                        (n.crop.limitToImageBounds = n.instructions.crop.scaleToFit),
                      void 0 === c.filter)
                    )
                      n.instructions.filter = n.options.filter;
                    else {
                      var g = c.filter;
                      n.instructions.filter = null === g ? g : g.id || g.matrix || g;
                    }
                    var m = n.options.markup || [];
                    (n.instructions.markup = m.concat(c.markup || [])),
                      (n.instructions.color = Object.keys(ir).reduce(function (e, t) {
                        return (
                          (e[t] =
                            c.color && void 0 !== c.color[t]
                              ? 'number' == typeof c.color[t]
                                ? c.color[t]
                                : c.color[t].value
                              : n.options['color'.concat(Nn(t))]),
                          e
                        );
                      }, {})),
                      e('DID_LOAD_IMAGE', {
                        image: nr(
                          { size: n.file.data.size, name: n.file.data.name, type: n.file.data.type, orientation: s },
                          u,
                        ),
                      }),
                      (n.filePromise = { resolveOnConfirm: l, success: i, failure: o });
                  })
                  .catch(function (t) {
                    if (Rr(n)) return e('ABORT_IMAGE');
                    e('DID_LOAD_IMAGE_ERROR', { error: { status: 'IMAGE_UNKNOWN_ERROR', data: t } }), fe(n), o();
                  });
              },
              CHANGE_VIEW: function (t) {
                var r = t.id;
                (n.activeView = r), e('SHOW_VIEW', { id: r });
              },
              UPDATE_ROOT_RECT: function (e) {
                var t = e.rect;
                n.rootRect = t;
              },
              DID_RESIZE_STAGE: function (r) {
                var i = r.size,
                  o = r.offset,
                  a = r.animate,
                  c = null === n.stage;
                if (
                  ((n.stage = Xe(0, 0, i.width, i.height)),
                  (n.stageOffset = Ve(o.x, o.y)),
                  !t('GET_ALLOW_PREVIEW_FIT_TO_VIEW'))
                ) {
                  var l = t('GET_IMAGE_STAGE_RECT');
                  (n.stage = Xe(0, 0, l.width, l.height)),
                    (n.stageOffset = Ve(n.stageOffset.x + l.x, n.stageOffset.y + l.y));
                }
                if (c) {
                  if (
                    (hr(n, t, e),
                    e('DID_SHOW_IMAGE', {
                      image: {
                        size: n.file.data.size,
                        name: n.file.data.name,
                        type: n.file.data.type,
                        orientation: n.image.orientation,
                        width: n.image.naturalWidth,
                        height: n.image.naturalHeight,
                      },
                    }),
                    !n.filePromise.resolveOnConfirm)
                  ) {
                    var u = ut(n.image, n.crop),
                      s = ar(t);
                    n.filePromise.success({
                      crop: u,
                      image: { orientation: n.file.orientation },
                      size: s,
                      output: { type: t('GET_OUTPUT_TYPE'), quality: t('GET_OUTPUT_QUALITY') },
                    });
                  }
                } else
                  (n.instantUpdate = !a),
                    pr(n, t),
                    setTimeout(function () {
                      n.instantUpdate = !1;
                    }, 16);
              },
              RESIZE_SET_OUTPUT_SIZE_ASPECT_RATIO_LOCK: function (e) {
                var t = e.value;
                n.size.aspectRatioLocked = t;
              },
              RESIZE_SET_OUTPUT_SIZE: function (r) {
                var i = r.width,
                  o = r.height,
                  a = ft(
                    { width: (i = i || null), height: (o = o || null) },
                    t('GET_SIZE_MIN'),
                    t('GET_SIZE_MAX'),
                    t('GET_CROP_RECTANGLE_ASPECT_RATIO'),
                  );
                if (
                  ((n.size.width = a.width ? Math.round(a.width) : null),
                  (n.size.height = a.height ? Math.round(a.height) : null),
                  i && o)
                ) {
                  var c = o / i;
                  if (c === n.crop.aspectRatio) return;
                  !1 === n.size.aspectRatioPrevious && (n.size.aspectRatioPrevious = n.crop.aspectRatio),
                    e('CROP_SET_ASPECT_RATIO', { value: c });
                } else
                  !1 !== n.size.aspectRatioPrevious &&
                    (e('CROP_SET_ASPECT_RATIO', { value: n.size.aspectRatioPrevious }),
                    (n.size.aspectRatioPrevious = !1));
              },
              CROP_SET_ASPECT_RATIO: function (e) {
                var r = e.value;
                if (
                  (vr(n),
                  (n.crop.aspectRatio = te(r) ? Ze(r) : r),
                  n.crop.aspectRatio && pr(n, t),
                  (n.crop.isDirty = !0),
                  n.size.width && n.size.height)
                )
                  if (n.crop.aspectRatio) {
                    var i = n.size.width * n.crop.aspectRatio,
                      o = Se(i, t('GET_SIZE_MIN').height, t('GET_SIZE_MAX').height);
                    (n.size.height = o), (n.size.width = o / n.crop.aspectRatio);
                  } else n.size.height = null;
              },
              DID_SET_CROP_ASPECT_RATIO: function (t) {
                var n = t.value,
                  r = t.prevValue;
                Ze(n) !== Ze(r) && e('CROP_SET_ASPECT_RATIO', { value: n });
              },
              CROP_ZOOM: function () {
                n.stage && (vr(n), pr(n, t));
              },
              DID_SET_CROP_LIMIT_TO_IMAGE_BOUNDS: function (t) {
                var r = t.value,
                  i = t.prevValue;
                (n.crop.limitToImageBounds = r), !1 === i && !0 === r && e('CROP_ENABLED_LIMIT_TO_IMAGE_BOUNDS');
              },
              CROP_ENABLED_LIMIT_TO_IMAGE_BOUNDS: function () {
                var e = n.stage,
                  r = n.image;
                if (n.crop.rectangle) {
                  var i = n.crop.rectangle.height / n.crop.rectangle.width,
                    o = Ke(e, i);
                  (n.crop.rectangle = o),
                    (n.crop.transforms = On(r, n.crop.rectangle, n.crop.transforms, 'moving')),
                    (n.crop.transforms = On(r, n.crop.rectangle, n.crop.transforms, 'resizing')),
                    (n.crop.transforms = On(r, n.crop.rectangle, n.crop.transforms, 'rotating')),
                    (n.crop.draft.rectangle = null),
                    (n.crop.draft.transforms = null),
                    pr(n, t);
                }
              },
              CROP_SET_LIMIT: function (t) {
                var r = t.value,
                  i = t.silent,
                  o = void 0 !== i && i,
                  a = n.crop.limitToImageBounds !== r;
                (n.crop.limitToImageBounds = r),
                  a && !o && (n.crop.isDirty = !0),
                  a && r && e('CROP_ENABLED_LIMIT_TO_IMAGE_BOUNDS');
              },
              CROP_IMAGE_RESIZE_GRAB: function () {
                yr(n), vr(n);
              },
              CROP_IMAGE_ROTATE_GRAB: function () {
                yr(n), vr(n), (n.crop.isRotating = !0);
              },
              CROP_RECT_DRAG_GRAB: function () {
                yr(n), vr(n);
              },
              CROP_RECT_DRAG_RELEASE: function () {
                !(function (e) {
                  (e.crop.rectangle = e.crop.draft.rectangle.limited), (e.crop.draft.rectangle = null), Tr(e), sr(e);
                })(n),
                  gr(n, t, e);
              },
              CROP_RECT_EDGE_DRAG: function (e) {
                var r = e.offset,
                  i = e.origin,
                  o = e.anchor,
                  a = n.image,
                  c = n.stage,
                  l = /n|s/.test(i) ? En : yn,
                  u = In(i, n.crop.rectangle),
                  s = In(o, n.crop.rectangle),
                  f = De({ x: u.x + (l === yn ? r.x : 0), y: u.y + (l === En ? r.y : 0) }, c),
                  d = t('GET_MIN_CROP_SIZE'),
                  h = t('GET_MAX_CROP_SIZE');
                (d.width = Me(d.width)), (d.height = Me(d.height));
                var p =
                  Er(n.crop.rectangle, t('GET_MIN_PREVIEW_IMAGE_SIZE')) /
                  (n.crop.draft.transforms.scale || n.crop.transforms.scale);
                (h.width = Me(h.width * p)), (h.height = Me(h.height * p));
                var g = { x: Math.sign(u.x - s.x), y: Math.sign(u.y - s.y) };
                (n.crop.draft.rectangle = (function (e, t, n, r, i, o, a, c, l) {
                  var u = o.left,
                    s = o.right,
                    f = o.top,
                    d = o.bottom,
                    h = s - u,
                    p = d - f,
                    g = i.left,
                    m = i.right,
                    v = i.top,
                    y = i.bottom;
                  if (n === En) {
                    if (
                      ((v = e.y > 0 ? r.y : Math.min(r.y, Math.max(t.y, f))),
                      (y = e.y > 0 ? Math.max(r.y, Math.min(t.y, d)) : r.y),
                      a)
                    ) {
                      var E = (y - v) / a;
                      (g = r.x - 0.5 * E), (m = r.x + 0.5 * E);
                    }
                  } else if (
                    ((g = e.x > 0 ? r.x : Math.min(r.x, Math.max(t.x, u))),
                    (m = e.x > 0 ? Math.max(r.x, Math.min(t.x, s)) : r.x),
                    a)
                  ) {
                    var w = (m - g) * a;
                    (v = r.y - 0.5 * w), (y = r.y + 0.5 * w);
                  }
                  var _,
                    T,
                    b,
                    A,
                    x = c.width,
                    O = c.height;
                  if (
                    (n === En
                      ? ((_ = r.x - 0.5 * x),
                        (T = r.x + 0.5 * x),
                        e.y < 0 ? ((b = r.y - O), (A = r.y)) : e.y > 0 && ((b = r.y), (A = r.y + O)))
                      : ((b = r.y - 0.5 * O),
                        (A = r.y + 0.5 * O),
                        e.x < 0 ? ((_ = r.x - x), (T = r.x)) : e.x > 0 && ((_ = r.x), (T = r.x + x))),
                    a)
                  )
                    if (n === En) {
                      var R = Math.min((y - v) / a, h),
                        I = R * a;
                      g < u && (m = (g = u) + R),
                        m > s && (g = (m = s) - R),
                        (r.x = g + 0.5 * R),
                        e.y < 0 ? (v = r.y - I) : e.y > 0 && (y = r.y + I);
                    } else {
                      var C = Math.min((m - g) * a, p),
                        S = C / a;
                      v < f && (y = (v = f) + C),
                        y > d && (v = (y = d) - C),
                        (r.y = v + 0.5 * C),
                        e.x < 0 ? (g = r.x - S) : e.x > 0 && (m = r.x + S);
                    }
                  var M = qe({ top: v, right: m, bottom: y, left: g }),
                    L = function () {
                      var t = x * a;
                      n === yn
                        ? ((v = r.y - 0.5 * t), (y = r.y + 0.5 * t))
                        : e.y < 0
                          ? ((y = r.y), (v = y - t))
                          : e.y > 0 && ((v = r.y), (y = v + t));
                    },
                    P = function () {
                      var t = O / a;
                      n === En
                        ? ((g = r.x - 0.5 * t), (m = r.x + 0.5 * t))
                        : e.x < 0
                          ? ((m = r.x), (g = m - t))
                          : e.x > 0 && ((g = r.x), (m = g + t));
                    };
                  m < T && ((m = T), (g = T - x), a && L()),
                    g > _ && ((g = _), (m = _ + x), a && L()),
                    v > b && ((v = b), (y = b + O), a && P()),
                    y < A && ((y = A), (v = A - O), a && P());
                  var k = l.width,
                    G = l.height;
                  if (
                    (a && (a < 1 ? (k = G / a) : (G = k * a)),
                    m - g > k && (e.x < 0 ? (g = r.x - k) : (m = r.x + k)),
                    y - v > G && (e.y < 0 ? (v = r.y - G) : (y = r.y + G)),
                    m - g == 0 && (e.x > 0 ? (m = r.x + 2) : (g = r.x - 2)),
                    y - v == 0 && (e.y > 0 ? (y = r.y + 2) : (v = r.y - 2)),
                    Math.round(g) < u || Math.round(m) > s || Math.round(v) < f || Math.round(y) > d)
                  ) {
                    var D = d - f,
                      U = s - u;
                    if (g < u) {
                      g = u;
                      var V = Math.min(m - g, U);
                      m = g + V;
                    }
                    if (m > s) {
                      m = s;
                      var B = Math.min(m - g, U);
                      g = m - B;
                    }
                    if (v < f) {
                      v = f;
                      var z = Math.min(y - v, D);
                      y = v + z;
                    }
                    if (y > d) {
                      y = d;
                      var N = Math.min(y - v, D);
                      v = y - N;
                    }
                    M = qe({ top: v, right: m, bottom: y, left: g });
                  }
                  return { free: M, limited: qe({ top: v, right: m, bottom: y, left: g }) };
                })(g, f, l, s, We(n.crop.rectangle), We(c), n.crop.aspectRatio, d, h)),
                  n.crop.limitToImageBounds &&
                    (n.crop.draft.transforms = Sn(a, n.crop.draft.rectangle.limited, n.crop.transforms));
              },
              CROP_RECT_CORNER_DRAG: function (e) {
                var r = e.offset,
                  i = e.origin,
                  o = e.anchor,
                  a = n.image,
                  c = n.stage,
                  l = Ln(i, n.crop.rectangle),
                  u = Ln(o, n.crop.rectangle),
                  s = { x: l.x + r.x, y: l.y + r.y },
                  f = t('GET_MIN_CROP_SIZE'),
                  d = t('GET_MAX_CROP_SIZE');
                (f.width = Me(f.width)), (f.height = Me(f.height));
                var h =
                  Er(n.crop.rectangle, t('GET_MIN_PREVIEW_IMAGE_SIZE')) /
                  (n.crop.draft.transforms.scale || n.crop.transforms.scale);
                (d.width = Me(d.width * h)), (d.height = Me(d.height * h));
                var p = { x: Math.sign(l.x - u.x), y: Math.sign(l.y - u.y) };
                (n.crop.draft.rectangle = (function (e, t, n, r, i, o, a) {
                  var c = We(r),
                    l = c.left,
                    u = c.right,
                    s = c.top,
                    f = c.bottom,
                    d = De({ x: t.x, y: t.y }, r),
                    h = e.x > 0 ? n.x : Math.min(d.x, n.x),
                    p = e.x > 0 ? Math.max(n.x, d.x) : n.x,
                    g = e.y > 0 ? n.y : Math.min(d.y, n.y),
                    m = e.y > 0 ? Math.max(n.y, d.y) : n.y;
                  if (i) {
                    var v = d.x - n.x;
                    e.x > 0 ? (p = Math.max(n.x, n.x + e.x * v)) : (h = Math.min(n.x, n.x - e.x * v)),
                      e.y > 0 ? (m = Math.max(n.y, n.y + e.x * v * i)) : (g = Math.min(n.y, n.y - e.x * v * i));
                  }
                  var y = qe({ top: g, right: p, bottom: m, left: h });
                  qe({ top: g, right: p, bottom: m, left: h });
                  if (o.width && o.height) {
                    var E = o.width,
                      w = o.height;
                    i && (1 === i ? (w = E = Math.max(E, w)) : E < w ? (E = w / i) : E > w ? (w = E * i) : (E = w / i)),
                      p - h < E && (e.x > 0 ? (p = n.x + E) : (h = n.x - E)),
                      m - g < w && (e.y > 0 ? (m = n.y + w) : (g = n.y - w));
                    var _ = a.width,
                      T = a.height;
                    i && (i < 1 ? (_ = T / i) : (T = _ * i)),
                      p - h > _ && (e.x < 0 ? (h = n.x - _) : (p = n.x + _)),
                      m - g > T && (e.y < 0 ? (g = n.y - T) : (m = n.y + T));
                  }
                  if (
                    (p - h == 0 && (e.x > 0 ? (p = n.x + 2) : (h = n.x - 2)),
                    m - g == 0 && (e.y > 0 ? (m = n.y + 2) : (g = n.y - 2)),
                    Math.round(h) < l || Math.round(p) > u || Math.round(g) < s || Math.round(m) > f)
                  ) {
                    var b = f - s,
                      A = u - l;
                    if (h < l) {
                      h = l;
                      var x = Math.min(p - h, A);
                      (p = h + x), i && (e.y > 0 && (m = n.y + x * i), e.y < 0 && (g = n.y - x * i));
                    }
                    if (p > u) {
                      p = u;
                      var O = Math.min(p - h, A);
                      (h = p - O), i && (e.y > 0 && (m = n.y + O * i), e.y < 0 && (g = n.y - O * i));
                    }
                    if (g < s) {
                      g = s;
                      var R = Math.min(m - g, b);
                      (m = g + R), i && (e.x > 0 && (p = n.x + R / i), e.x < 0 && (h = n.x - R / i));
                    }
                    if (m > f) {
                      m = f;
                      var I = Math.min(m - g, b);
                      (g = m - I), i && (e.x > 0 && (p = n.x + I / i), e.x < 0 && (h = n.x - I / i));
                    }
                    y = qe({ top: g, right: p, bottom: m, left: h });
                  }
                  return { free: y, limited: qe({ top: g, right: p, bottom: m, left: h }) };
                })(p, s, u, c, n.crop.aspectRatio, f, d)),
                  n.crop.limitToImageBounds &&
                    (n.crop.draft.transforms = Sn(a, n.crop.draft.rectangle.limited, n.crop.transforms));
              },
              CROP_IMAGE_DRAG_GRAB: function () {
                return yr(n) || vr(n);
              },
              CROP_IMAGE_DRAG_RELEASE: function () {
                Tr(n), sr(n), gr(n, t, e);
              },
              CROP_IMAGE_ROTATE_RELEASE: function () {
                Tr(n), gr(n, t, e);
              },
              CROP_IMAGE_DRAG: function (e) {
                var t,
                  r,
                  i,
                  o,
                  a,
                  c,
                  l,
                  u,
                  s = e.value;
                vr(n),
                  (n.crop.draft.transforms =
                    ((t = n.image),
                    (r = n.crop.rectangle),
                    (i = n.crop.transforms),
                    (o = s),
                    (a = n.crop.limitToImageBounds),
                    (c = { x: i.translation.x + o.x, y: i.translation.y + o.y }),
                    (l = nr({}, bn(i), { translation: c })),
                    ((u = a ? On(t, r, l, 'moving') : l).interaction = { translation: c }),
                    u));
              },
              CROP_IMAGE_RESIZE_RELEASE: function () {
                t('GET_CROP_RESIZE_MATCH_IMAGE_ASPECT_RATIO') &&
                  (function (e, t) {
                    var n = Me(e.crop.draft.transforms.scale, 5);
                    if (!(Me(e.crop.draft.targetSize, 5) < n)) return !1;
                    if (null !== e.crop.aspectRatio) return !1;
                    if (!1 === e.crop.limitToImageBounds) return !1;
                    if (0 !== Me(e.crop.rotation.sub, 5)) return !1;
                    var r =
                      Me(e.crop.rotation.main / or, 5) % 2 != 0
                        ? e.image.width / e.image.height
                        : e.image.height / e.image.width;
                    if (r === e.crop.rectangle.height / e.crop.rectangle.width) return !1;
                    var i = e.stage.x + 0.5 * e.stage.width,
                      o = e.stage.y + 0.5 * e.stage.height,
                      a = e.crop.rectangle.x + 0.5 * e.crop.rectangle.width,
                      c = e.crop.rectangle.y + 0.5 * e.crop.rectangle.height;
                    if (a !== i || c !== o) return !1;
                    var l = t('GET_STAGE_RECT');
                    (e.crop.rectangle = Ke(l, r)),
                      'stage' !== l.mode && ((e.crop.rectangle.x += l.x), (e.crop.rectangle.y += l.y)),
                      (e.crop.transforms = wn(
                        { center: { x: 0.5, y: 0.5 }, rotation: e.crop.transforms.rotation, zoom: 1, aspectRatio: r },
                        l,
                        e.image,
                        !0,
                      )),
                      (e.crop.draft.transforms = null);
                  })(n, t),
                  Tr(n),
                  sr(n),
                  gr(n, t, e);
              },
              CROP_IMAGE_RESIZE: function (e) {
                var r = e.value;
                vr(n);
                var i = n.crop.transforms;
                (n.crop.draft.targetSize = i.scale + i.scale * r),
                  (n.crop.draft.transforms = _r(
                    n.image,
                    n.crop.rectangle,
                    i,
                    n.crop.draft.targetSize,
                    t('GET_MIN_PREVIEW_IMAGE_SIZE'),
                    n.crop.limitToImageBounds,
                  ));
              },
              CROP_IMAGE_RESIZE_SET: function (e) {
                var r = e.value,
                  i = Math.max(n.crop.rectangle.width / n.image.width, n.crop.rectangle.height / n.image.height);
                vr(n);
                var o = n.crop.transforms;
                (n.crop.draft.targetSize = r * i),
                  (n.crop.draft.transforms = _r(
                    n.image,
                    n.crop.rectangle,
                    o,
                    n.crop.draft.targetSize,
                    t('GET_MIN_PREVIEW_IMAGE_SIZE'),
                    n.crop.limitToImageBounds,
                  ));
              },
              CROP_IMAGE_RESIZE_MULTIPLY: function (e) {
                var r = e.value;
                vr(n);
                var i = n.crop.transforms;
                (n.crop.draft.targetSize = i.scale * r),
                  (n.crop.draft.transforms = _r(
                    n.image,
                    n.crop.rectangle,
                    i,
                    n.crop.draft.targetSize,
                    t('GET_MIN_PREVIEW_IMAGE_SIZE'),
                    n.crop.limitToImageBounds,
                  ));
              },
              CROP_IMAGE_RESIZE_AMOUNT: function (e) {
                var r = e.value;
                vr(n);
                var i = n.crop.transforms;
                (n.crop.draft.targetSize = (n.crop.draft.transforms ? n.crop.draft.transforms.scale : i.scale) + r),
                  (n.crop.draft.transforms = _r(
                    n.image,
                    n.crop.rectangle,
                    i,
                    n.crop.draft.targetSize,
                    t('GET_MIN_PREVIEW_IMAGE_SIZE'),
                    n.crop.limitToImageBounds,
                  ));
              },
              CROP_IMAGE_ROTATE: function (e) {
                var r = e.value;
                vr(n),
                  (n.crop.isRotating = !0),
                  fr(
                    n,
                    { main: n.crop.rotation.main, sub: r },
                    t('GET_MIN_PREVIEW_IMAGE_SIZE'),
                    !1,
                    n.crop.limitToImageBounds,
                  );
              },
              CROP_IMAGE_ROTATE_ADJUST: function (e) {
                var r = e.value;
                vr(n),
                  fr(
                    n,
                    {
                      main: n.crop.rotation.main,
                      sub: Math.min(Math.PI / 4, Math.max(-Math.PI / 4, n.crop.rotation.sub + r)),
                    },
                    t('GET_MIN_PREVIEW_IMAGE_SIZE'),
                    !1,
                    n.crop.limitToImageBounds,
                  ),
                  Tr(n);
              },
              CROP_IMAGE_ROTATE_CENTER: function () {
                vr(n),
                  fr(
                    n,
                    { main: n.crop.rotation.main, sub: 0 },
                    t('GET_MIN_PREVIEW_IMAGE_SIZE'),
                    !1,
                    n.crop.limitToImageBounds,
                  ),
                  Tr(n);
              },
              CROP_IMAGE_ROTATE_LEFT: function () {
                mr(n, t, e),
                  fr(
                    n,
                    { main: n.crop.rotation.main - or, sub: n.crop.rotation.sub },
                    t('GET_MIN_PREVIEW_IMAGE_SIZE'),
                    -or,
                    n.crop.limitToImageBounds,
                  ),
                  Tr(n),
                  t('GET_CROP_FORCE_LETTERBOX') && e('CROP_UPDATE_LETTERBOX');
              },
              CROP_IMAGE_ROTATE_RIGHT: function () {
                mr(n, t, e),
                  fr(
                    n,
                    { main: n.crop.rotation.main + or, sub: n.crop.rotation.sub },
                    t('GET_MIN_PREVIEW_IMAGE_SIZE'),
                    or,
                    n.crop.limitToImageBounds,
                  ),
                  Tr(n),
                  t('GET_CROP_FORCE_LETTERBOX') && e('CROP_UPDATE_LETTERBOX');
              },
              CROP_IMAGE_FLIP_HORIZONTAL: function () {
                mr(n, t, e),
                  0 === Me((n.crop.rotation.main % Math.PI) / 2, 5)
                    ? (n.crop.flip.horizontal = !n.crop.flip.horizontal)
                    : (n.crop.flip.vertical = !n.crop.flip.vertical),
                  (n.crop.isDirty = !0);
              },
              CROP_IMAGE_FLIP_VERTICAL: function () {
                mr(n, t, e),
                  0 === Me((n.crop.rotation.main % Math.PI) / 2, 5)
                    ? (n.crop.flip.vertical = !n.crop.flip.vertical)
                    : (n.crop.flip.horizontal = !n.crop.flip.horizontal),
                  (n.crop.isDirty = !0);
              },
              DID_RECEIVE_IMAGE_DATA: function (e) {
                var t = e.previewData,
                  r = e.thumbData;
                (n.file.preview = t), (n.file.thumb = r);
              },
              MARKUP_SET_VALUE: function (e) {
                var t = e.value;
                n.markup = (t || []).map(Zn).sort(on);
              },
              MARKUP_ADD_DEFAULT: function (n) {
                var r = n.value,
                  i = function () {
                    return -0.5 + Math.random();
                  },
                  o = t('GET_CROP_RECTANGLE_ASPECT_RATIO'),
                  a = o > 1 ? 0.5 / o : 0.5,
                  c = o > 1 ? 0.5 : 0.5 * o,
                  l = function () {
                    return { width: a, height: c, x: 0.5 + 0.5 * i() - 0.5 * a, y: 0.5 + 0.5 * i() - 0.5 * c };
                  },
                  u = function (e) {
                    return t('GET_MARKUP_TOOL_VALUES')[e];
                  },
                  s = function () {
                    var e = u('shapeStyle'),
                      t = u('color'),
                      n = e[0] || e[1] ? null : t;
                    return {
                      backgroundColor: n,
                      borderWidth: e[0],
                      borderStyle: e[1] ? e[1] : null,
                      borderColor: null !== n ? null : t,
                    };
                  },
                  f = {
                    rect: function () {
                      return nr({}, l(), s());
                    },
                    ellipse: function () {
                      return nr({}, l(), s());
                    },
                    text: function () {
                      return {
                        x: 0.5 + 0.5 * i() - 0.1,
                        y: 0.5 + 0.5 * i(),
                        width: 0,
                        height: 0,
                        fontColor: u('color'),
                        fontSize: u('fontSize'),
                        fontFamily: u('fontFamily'),
                        text: 'Text',
                      };
                    },
                    line: function () {
                      var e = u('lineStyle');
                      return nr({}, l(), {
                        lineColor: u('color'),
                        lineWidth: e[0],
                        lineStyle: e[1] ? e[1] : null,
                        lineDecoration: u('lineDecoration'),
                      });
                    },
                  }[r]();
                e('MARKUP_ADD', [r, f]);
              },
              MARKUP_ADD: function (r) {
                n.markup.forEach(function (e) {
                  return (e[1].isSelected = !1);
                }),
                  (n.markup = n.markup.filter(function (e) {
                    return !e[1].isDestroyed;
                  }));
                var i = Zn(r);
                n.markup.push(i),
                  n.markup.sort(on),
                  'draw' !== t('GET_MARKUP_UTIL') && e('MARKUP_SELECT', { id: i[1].id }),
                  (n.crop.isDirty = !0);
              },
              MARKUP_SELECT: function (e) {
                var t = e.id;
                n.markup.forEach(function (e) {
                  (e[1].isSelected = e[1].id === t), (e[1].isDirty = !0);
                });
              },
              MARKUP_ELEMENT_DRAG: function (e) {
                var t = e.id,
                  r = e.origin,
                  i = e.offset,
                  o = e.size,
                  a = n.markup.find(function (e) {
                    return e[1].id === t;
                  });
                if (a) {
                  var c = a[1],
                    l = r.x / o.width,
                    u = r.y / o.height,
                    s = r.width / o.width,
                    f = r.height / o.height,
                    d = i.x / o.width,
                    h = i.y / o.height;
                  (c.x = l + d),
                    (c.y = u + h),
                    (c.width = s),
                    (c.height = f),
                    (c.left = void 0),
                    (c.top = void 0),
                    (c.right = void 0),
                    (c.bottom = void 0),
                    (c.isDirty = !0),
                    (n.crop.isDirty = !0);
                }
              },
              MARKUP_ELEMENT_RESIZE: function (e) {
                var t = e.id,
                  r = e.corner,
                  i = e.origin,
                  o = e.offset,
                  a = e.size,
                  c = n.markup.find(function (e) {
                    return e[1].id === t;
                  });
                if (c) {
                  var l = tr(c, 2),
                    u = l[0],
                    s = l[1],
                    f = (i.x + o.x) / a.width,
                    d = (i.y + o.y) / a.height;
                  if (/n/.test(r))
                    if ('line' === u) (s.height = s.height - (d - s.y)), (s.y = d);
                    else {
                      var h = s.y + s.height;
                      d > h && (d = h), (s.height = s.height - (d - s.y)), (s.y = d);
                    }
                  if (/w/.test(r))
                    if ('line' === u) (s.width = s.width - (f - s.x)), (s.x = f);
                    else {
                      var p = s.x + s.width;
                      f > p && (f = p), (s.width = s.width - (f - s.x)), (s.x = f);
                    }
                  /s/.test(r) && (s.height = 'line' === u ? d - s.y : Math.max(0, d - s.y)),
                    /e/.test(r) && (s.width = 'line' === u ? f - s.x : Math.max(0, f - s.x)),
                    (s.left = void 0),
                    (s.top = void 0),
                    (s.right = void 0),
                    (s.bottom = void 0),
                    (s.isDirty = !0),
                    (n.crop.isDirty = !0);
                }
              },
              MARKUP_DELETE: function (t) {
                var r = t.id,
                  i = n.markup.find(function (e) {
                    return e[1].id === r;
                  });
                if (i) {
                  var o = i[1];
                  o.allowDestroy && ((o.isDestroyed = !0), (o.isSelected = !1), (o.isDirty = !0));
                  for (var a = null, c = n.markup.length; c > 0; ) {
                    c--;
                    var l = n.markup[c][1];
                    if (!l.isDestroyed && l.allowDestroy) {
                      a = l.id;
                      break;
                    }
                  }
                  e('MARKUP_SELECT', { id: a });
                }
              },
              MARKUP_UPDATE: function (e) {
                var t = e.style,
                  r = e.value;
                (n.markupToolValues[t] = r),
                  n.markup
                    .map(function (e) {
                      return e[1];
                    })
                    .filter(function (e) {
                      return e.isSelected;
                    })
                    .forEach(function (e) {
                      var n;
                      if ('color' === t)
                        e[
                          (n = e).borderWidth
                            ? 'borderColor'
                            : n.lineWidth
                              ? 'lineColor'
                              : n.fontColor
                                ? 'fontColor'
                                : n.backgroundColor
                                  ? 'backgroundColor'
                                  : void 0
                        ] = r;
                      else if ('shapeStyle' === t) {
                        var i = (function (e) {
                          var t = e.fontColor,
                            n = e.backgroundColor,
                            r = e.lineColor,
                            i = e.borderColor;
                          return t || n || r || i;
                        })(e);
                        (e.borderColor = i),
                          (e.borderWidth = r[0]),
                          (e.borderStyle = r[1]),
                          (e.backgroundColor = r[0] || r[1] ? null : i),
                          null !== e.backgroundColor && (e.borderColor = null);
                      } else 'lineStyle' === t ? ((e.lineWidth = r[0]), (e.lineStyle = r[1])) : (e[t] = r);
                      e.isDirty = !0;
                    }),
                  (n.crop.isDirty = !0);
              },
            },
            ['color', 'shapeStyle', 'lineStyle', 'textDecoration', 'fontSize', 'fontFamily'].reduce(function (t, r) {
              var i = r
                  .split(/(?=[A-Z])/)
                  .join('_')
                  .toUpperCase(),
                o = Nn(r);
              return (
                (t['SET_MARKUP_' + i] = function (t) {
                  var i = t.value;
                  i !== t.prevValue &&
                    ((n.options['markup'.concat(o)] = i), e('MARKUP_UPDATE', { style: r, value: i }));
                }),
                t
              );
            }, {}),
            {
              DID_SET_CROP: function (t) {
                var n = t.value;
                n !== t.prevValue && e('SET_DATA', { crop: n });
              },
              COLOR_SET_COLOR_VALUE: function (t) {
                var r = t.key,
                  i = t.value;
                (n.crop.isDirty = !0), e('COLOR_SET_VALUE', { key: r, value: i });
              },
              COLOR_SET_VALUE: function (t) {
                var r = t.key,
                  i = t.value;
                (n.colorValues[r] = i), e('SET_COLOR_MATRIX', { key: r, matrix: ir[r](i) });
              },
            },
            Object.keys(ir).reduce(function (r, i) {
              var o = i.toUpperCase(),
                a = Nn(i);
              return (
                (r['SET_COLOR_'.concat(o)] = function (r) {
                  var c = r.value;
                  if (c !== r.prevValue) {
                    var l = tr(t('GET_COLOR_'.concat(o, '_RANGE')), 2),
                      u = l[0],
                      s = l[1],
                      f = Se(c, u, s);
                    (n.options['color'.concat(a)] = f),
                      n.instructions.color || (n.instructions.color = {}),
                      (n.instructions.color[i] = f),
                      e('COLOR_SET_VALUE', { key: i, value: f });
                  }
                }),
                r
              );
            }, {}),
            {
              SET_COLOR_MATRIX: function (t) {
                var r = t.key,
                  i = t.matrix;
                i ? (n.colorMatrices[r] = er(i)) : delete n.colorMatrices[r],
                  e('DID_SET_COLOR_MATRIX', { key: r, matrix: i });
              },
              FILTER_SET_FILTER: function (t) {
                var r = t.value;
                (n.crop.isDirty = !0), e('FILTER_SET_VALUE', { value: r });
              },
              FILTER_SET_VALUE: function (r) {
                var i = r.value,
                  o = Wn(i) ? i : null;
                if (te(i)) {
                  var a = t('GET_FILTERS');
                  u(a, function (e, t) {
                    e === i && (o = t.matrix());
                  });
                }
                (n.filter = i), (n.filterName = te(i) ? i : null), e('SET_COLOR_MATRIX', { key: 'filter', matrix: o });
              },
              DID_SET_UTIL: function (t) {
                var r = t.value;
                t.prevValue;
                -1 !== n.options.utils.indexOf(r) && e('CHANGE_VIEW', { id: r });
              },
              DID_SET_FILTER: function (t) {
                var n = t.value;
                n !== t.prevValue && (e('FILTER_SET_VALUE', { value: n }), e('SET_DATA', { filter: n }));
              },
              DID_SET_SIZE: function (t) {
                var n = t.value;
                n !== t.prevValue && e('SET_DATA', { size: n });
              },
              DID_SET_MARKUP_UTIL: function (t) {
                var n = t.value;
                n !== t.prevValue &&
                  n &&
                  (/^(draw|line|text|rect|ellipse)$/.test(n) || (n = 'select'), e('SWITCH_MARKUP_UTIL', { util: n }));
              },
              DID_SET_MARKUP: function (t) {
                var n = t.value,
                  r = t.prevValue;
                (n !== r && JSON.stringify(n) === JSON.stringify(r)) ||
                  (e('MARKUP_SET_VALUE', { value: n }), e('SET_DATA', { markup: n }));
              },
              SET_DATA: function (r) {
                if (r.size) {
                  var i = nr({ width: null, height: null }, r.size),
                    o = ft(i, t('GET_SIZE_MIN'), t('GET_SIZE_MAX'), null);
                  (n.instructions.size = nr({}, o)), e('RESIZE_SET_OUTPUT_SIZE', o);
                }
                r.filter && (n.instructions.filter = r.filter ? r.filter.id || r.filter.matrix : r.colorMatrix),
                  (n.instructions.markup = r.markup || []),
                  n.instructions.markup.forEach(function (e) {
                    return (e[1].isDirty = !0);
                  }),
                  (n.instructions.color = Object.keys(ir).reduce(function (e, t) {
                    var i = void 0 === r.color || void 0 === r.color[t],
                      o = n.options['color'.concat(Nn(t))];
                    return (e[t] = i ? o : A(r.color[t]) ? r.color[t] : r.color[t].value), e;
                  }, {})),
                  r.crop &&
                    ((n.instructions.crop = zn(t, n, r.crop, n.size)),
                    (n.crop.limitToImageBounds = n.options.cropLimitToImageBounds),
                    !1 === n.instructions.crop.scaleToFit &&
                      (n.crop.limitToImageBounds = n.instructions.crop.scaleToFit),
                    dr(n, t));
              },
              DID_SET_INITIAL_STATE: function (e) {
                var r = e.value || {},
                  i = r.crop,
                  o = r.filter,
                  a = r.color,
                  c = r.size,
                  l = void 0 === c ? {} : c,
                  u = r.markup,
                  s = void 0 === u ? [] : u,
                  f = nr({ width: null, height: null }, l),
                  d = ft(f, t('GET_SIZE_MIN'), t('GET_SIZE_MAX'), null);
                (n.instructions.size = nr({}, d)),
                  (n.instructions.crop = zn(t, n, i)),
                  (n.crop.limitToImageBounds = n.options.cropLimitToImageBounds),
                  !1 === n.instructions.crop.scaleToFit && (n.crop.limitToImageBounds = n.instructions.crop.scaleToFit),
                  (n.instructions.filter = o || null),
                  (n.instructions.color = Object.keys(ir).reduce(function (e, t) {
                    return (e[t] = void 0 === a || void 0 === a[t] ? n.options['color'.concat(Nn(t))] : a[t]), e;
                  }, {})),
                  (n.instructions.markup = s),
                  (n.crop.isDirty = !0);
              },
              GET_DATA: function (r) {
                var i = r.success,
                  o = r.failure,
                  a = r.file,
                  c = r.data;
                if (!n.file) return o('no-image-source');
                if (!n.stage) return o('image-not-fully-loaded');
                var l = {
                  file: ee(a) ? a : t('GET_OUTPUT_FILE'),
                  data: ee(c) ? c : t('GET_OUTPUT_DATA'),
                  success: i,
                  failure: o,
                };
                e(l.file ? 'REQUEST_PREPARE_OUTPUT' : 'PREPARE_OUTPUT', l);
              },
              REQUEST_PREPARE_OUTPUT: function (t) {
                var n = t.file,
                  r = t.data,
                  i = t.success,
                  o = t.failure;
                e('PREPARE_OUTPUT', { file: n, data: r, success: i, failure: o }, !0), e('DID_REQUEST_PREPARE_OUTPUT');
              },
              PREPARE_OUTPUT: function (r) {
                var i = r.file,
                  o = r.data,
                  a = r.success,
                  c = void 0 === a ? function () {} : a,
                  l = r.failure,
                  u = void 0 === l ? function () {} : l;
                if (Rr(n)) return e('ABORT_IMAGE');
                var s = function (t) {
                  if ((e('DID_PREPARE_OUTPUT'), Rr(n))) return e('ABORT_IMAGE');
                  c(t);
                };
                ur({ file: i, data: o }, n, t)
                  .then(function (t) {
                    var r = n.options.afterCreateOutput,
                      i = r
                        ? r(t, function (t, n) {
                            return (
                              e('DID_REQUEST_POSTPROCESS_OUTPUT', { label: t, progress: n }),
                              function (t) {
                                e('DID_MAKE_PROGRESS', { progress: t });
                              }
                            );
                          })
                        : t;
                    Promise.resolve(i)
                      .then(s)
                      .catch(function (t) {
                        e('DID_REQUEST_POSTPROCESS_OUTPUT_ERROR', { error: t });
                      });
                  })
                  .catch(function (t) {
                    if (Rr(n)) return e('ABORT_IMAGE');
                    u(t);
                  });
              },
              EDIT_RESET: function () {
                vr(n), hr(n, t, e);
              },
              EDIT_CONFIRM: function () {
                if (n.file && n.stage) {
                  vr(n), e('CROP_ZOOM');
                  var r = {
                    file: t('GET_OUTPUT_FILE'),
                    data: t('GET_OUTPUT_DATA'),
                    success: function (t) {
                      n.filePromise.resolveOnConfirm && n.filePromise.success(t), e('DID_CONFIRM', { output: t });
                    },
                    failure: console.error,
                  };
                  e(r.file ? 'REQUEST_PREPARE_OUTPUT' : 'PREPARE_OUTPUT', r);
                }
              },
              EDIT_CANCEL: function () {
                n.filePromise && n.filePromise.success(null), e('DID_CANCEL');
              },
              EDIT_CLOSE: function () {
                vr(n);
              },
              EDIT_DESTROY: function () {
                fe(n);
              },
              SET_OPTIONS: function (t) {
                var n = t.options;
                u(n, function (t, n) {
                  e('SET_'.concat(de(t, '_').toUpperCase()), { value: n });
                });
              },
            },
          );
        },
        Cr = function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 24;
          return '<svg width="'
            .concat(t, '" height="')
            .concat(t, '" viewBox="0 0 ')
            .concat(t, ' ')
            .concat(t, '" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">')
            .concat(e, '</svg>');
        },
        Sr = X({
          ignoreRect: !0,
          ignoreRectUpdate: !0,
          name: 'button',
          mixins: {
            styles: ['opacity'],
            animations: { opacity: { type: 'tween', duration: 250 } },
            apis: ['id'],
            listeners: !0,
          },
          tag: 'button',
          create: function (e) {
            var t = e.root,
              n = e.props;
            (t.element.innerHTML = ''.concat(n.icon || '', '<span>').concat(n.label, '</span>')),
              t.element.setAttribute('type', n.type || 'button'),
              n.name &&
                n.name.split(' ').forEach(function (e) {
                  t.element.className += ' doka--button-'.concat(e);
                }),
              (t.ref.handleClick = function () {
                'string' == typeof n.action ? t.dispatch(n.action) : n.action();
              }),
              t.element.addEventListener('click', t.ref.handleClick),
              (t.ref.handlePointer = function (e) {
                return e.stopPropagation();
              }),
              t.element.addEventListener('pointerdown', t.ref.handlePointer),
              n.create && n.create({ root: t, props: n });
          },
          destroy: function (e) {
            var t = e.root;
            t.element.removeEventListener('pointerdown', t.ref.handlePointer),
              t.element.removeEventListener('click', t.ref.handleClick);
          },
        }),
        Mr = X({
          name: 'status-progress',
          tag: 'svg',
          ignoreRect: !0,
          ignoreRectUpdate: !0,
          mixins: {
            apis: ['progress'],
            animations: { progress: { type: 'spring', stiffness: 0.25, damping: 0.25, mass: 2.5 } },
          },
          create: function (e) {
            var t = e.root,
              n = e.props;
            t.element.setAttribute('data-value', 0),
              t.element.setAttribute('width', 24),
              t.element.setAttribute('height', 24),
              t.element.setAttribute('viewBox', '0 0 20 20');
            var r = (t.ref.circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')),
              i = {
                r: 5,
                cx: 10,
                cy: 10,
                fill: 'none',
                stroke: 'currentColor',
                'stroke-width': 10,
                transform: 'rotate(-90) translate(-20)',
              };
            t.element.appendChild(r),
              Object.keys(i).forEach(function (e) {
                r.setAttribute(e, i[e]);
              }),
              (t.ref.updateStroke = function (e) {
                t.ref.circle.setAttribute('stroke-dasharray', ''.concat(31.42 * Math.min(1, e), ' 31.42'));
              }),
              'number' == typeof n.progress
                ? ((t.progress = n.progress),
                  t.element.setAttribute('data-value', Math.max(n.progress, 0.001)),
                  t.ref.updateStroke(t.progress))
                : (t.progress = 0);
          },
          write: Z(
            {
              DID_MAKE_PROGRESS: function (e) {
                var t = e.root,
                  n = e.action;
                (t.progress = n.progress), t.element.setAttribute('data-value', Math.max(n.progress, 0.001));
              },
            },
            function (e) {
              var t = e.root;
              t.ref.updateStroke(t.progress);
            },
          ),
        }),
        Lr = X({
          name: 'status-bubble-inner',
          create: function (e) {
            var t = e.root,
              n = e.props;
            n.onClose
              ? t.appendChildView(
                  t.createChildView(Sr, {
                    label: 'Close',
                    name: 'icon-only status-bubble-close',
                    icon: Cr(
                      '<g fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6L6 18M6 6l12 12"/></g>',
                    ),
                    action: n.onClose,
                  }),
                )
              : (t.ref.progressIndicator = t.appendChildView(t.createChildView(Mr, { progress: n.progress }))),
              t.appendChildView(
                t.createChildView(
                  X({
                    ignoreRect: !0,
                    tag: 'p',
                    create: function (e) {
                      var t = e.root,
                        n = e.props;
                      t.element.textContent = n.text;
                    },
                  }),
                  { text: n.label },
                ),
              );
          },
        }),
        Pr = X({
          name: 'status-bubble',
          mixins: {
            styles: ['opacity', 'translateY'],
            apis: ['markedForRemoval'],
            animations: { opacity: { type: 'tween', duration: 500 }, translateY: { type: 'spring', mass: 20 } },
          },
          create: function (e) {
            var t = e.root,
              n = e.props;
            return t.appendChildView(t.createChildView(Lr, n));
          },
        }),
        kr = function (e) {
          (e.element.dataset.viewStatus = 'idle'), Gr(e);
        },
        Gr = function (e) {
          e.ref.busyIndicators.forEach(function (e) {
            (e.translateY = -10), (e.opacity = 0), (e.markedForRemoval = !0);
          });
        },
        Dr = function (e, t, n, r) {
          e.element.dataset.viewStatus = 'busy';
          var i = Ur(e, t, n, r);
          Gr(e), e.ref.busyIndicators.push(i), (i.markedForRemoval = !1), (i.translateY = 0), (i.opacity = 1);
        },
        Ur = function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
            r = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : null;
          return e.appendChildView(
            e.createChildView(Pr, { translateY: 20, opacity: 0, label: t, onClose: n, progress: r }),
          );
        },
        Vr = X({
          name: 'edit-status',
          ignoreRect: !0,
          create: function (e) {
            var t = e.root;
            (t.ref.busyIndicators = []), t.element.setAttribute('tabindex', -1);
          },
          write: Z({
            MISSING_WEBGL: function (e) {
              var t = e.root,
                n = /fullscreen/.test(t.query('GET_STYLE_LAYOUT_MODE'));
              Dr(
                t,
                t.query('GET_LABEL_STATUS_MISSING_WEB_G_L'),
                n
                  ? function () {
                      t.dispatch('EDIT_CANCEL');
                    }
                  : null,
              );
            },
            AWAITING_IMAGE: function (e) {
              var t = e.root;
              Dr(t, t.query('GET_LABEL_STATUS_AWAITING_IMAGE'));
            },
            DID_PRESENT_IMAGE: function (e) {
              var t = e.root;
              kr(t);
            },
            DID_LOAD_IMAGE_ERROR: function (e) {
              var t = e.root,
                n = e.action,
                r = /fullscreen/.test(t.query('GET_STYLE_LAYOUT_MODE')),
                i = t.query('GET_LABEL_STATUS_LOAD_IMAGE_ERROR'),
                o = 'function' == typeof i ? i(n.error) : i;
              Dr(
                t,
                o,
                r
                  ? function () {
                      t.dispatch('EDIT_CANCEL');
                    }
                  : null,
              );
            },
            DID_REQUEST_LOAD_IMAGE: function (e) {
              var t = e.root;
              Dr(t, t.query('GET_LABEL_STATUS_LOADING_IMAGE'));
            },
            DID_REQUEST_PREPARE_OUTPUT: function (e) {
              var t = e.root;
              Dr(t, t.query('GET_LABEL_STATUS_PROCESSING_IMAGE'));
            },
            DID_REQUEST_POSTPROCESS_OUTPUT: function (e) {
              var t = e.root,
                n = e.action;
              Dr(t, n.label, null, n.progress);
            },
            DID_REQUEST_POSTPROCESS_OUTPUT_ERROR: function (e) {
              var t = e.root,
                n = e.action.error;
              Dr(t, n, function () {
                return t.dispatch('DID_PREPARE_OUTPUT');
              });
            },
            DID_PREPARE_OUTPUT: function (e) {
              var t = e.root;
              kr(t);
            },
          }),
          didWriteView: function (e) {
            var t = e.root;
            t.ref.busyIndicators = t.ref.busyIndicators.filter(function (e) {
              return !e.markedForRemoval || 0 !== e.opacity || (t.removeChildView(e), !1);
            });
          },
        }),
        Br = { down: 'pointerdown', move: 'pointermove', up: 'pointerup' },
        zr = function () {
          var e = [],
            t = function (t) {
              return e.findIndex(function (e) {
                return e.pointerId === t.pointerId;
              });
            };
          return {
            update: function (n) {
              var r = t(n);
              r < 0 || (e[r] = n);
            },
            multiple: function () {
              return e.length > 1;
            },
            count: function () {
              return e.length;
            },
            active: function () {
              return e.concat();
            },
            push: function (n) {
              (function (e) {
                return t(e) >= 0;
              })(n) || e.push(n);
            },
            pop: function (n) {
              var r = t(n);
              r < 0 || e.splice(r, 1);
            },
          };
        },
        Nr = function (e, t, n, r) {
          return e.addEventListener(Br[t], n, r);
        },
        Fr = function (e, t, n) {
          return e.removeEventListener(Br[t], n);
        },
        jr = function (e, t) {
          if ('contains' in e) return e.contains(t);
          var n = t;
          do {
            if (n === e) return !0;
          } while ((n = n.parentNode));
          return !1;
        },
        Wr = function (e, t, n, r) {
          var i =
              arguments.length > 4 && void 0 !== arguments[4]
                ? arguments[4]
                : { stopPropagation: !0, cancelOnMultiple: !1 },
            o = { x: 0, y: 0 },
            a = { enabled: !0, origin: null, cancel: !1, cancelled: !1, pointers: zr() },
            c = function (e) {
              return { x: e.pageX - o.x, y: e.pageY - o.y };
            },
            l = null,
            u = function (e, t) {
              t &&
                (l || s(e, t),
                cancelAnimationFrame(l),
                (l = requestAnimationFrame(function () {
                  s(e, t), (l = null);
                })));
            },
            s = function (e, t) {
              return t.apply(null, [e, c(e)]);
            },
            f = function (n) {
              var r = 0 === a.pointers.count();
              r && ((a.active = !1), (a.cancel = !1), (a.cancelled = !1)),
                (e === n.target || jr(e, n.target)) &&
                  (r
                    ? n.isPrimary &&
                      (a.pointers.push(n),
                      Nr(document.documentElement, 'up', h),
                      n.preventDefault(),
                      i.stopPropagation && (n.stopPropagation(), n.stopImmediatePropagation()),
                      (a.active = !0),
                      (o.x = n.pageX),
                      (o.y = n.pageY),
                      Nr(document.documentElement, 'move', d),
                      t(n))
                    : i.cancelOnMultiple && (a.cancel = !0));
            },
            d = function (e) {
              e.isPrimary &&
                (a.cancelled ||
                  (e.preventDefault(),
                  i.stopPropagation && e.stopPropagation(),
                  u(e, n),
                  a.cancel && ((a.cancelled = !0), u(e, r))));
            },
            h = function e(t) {
              a.pointers.pop(t),
                0 === a.pointers.count() &&
                  (Fr(document.documentElement, 'move', d), Fr(document.documentElement, 'up', e)),
                a.active &&
                  (a.cancelled || (t.preventDefault(), i.stopPropagation && t.stopPropagation(), u(t, n), u(t, r)));
            };
          return (
            Nr(document.documentElement, 'down', f),
            {
              enable: function () {
                a.enabled || Nr(document.documentElement, 'down', f), (a.enabled = !0);
              },
              disable: function () {
                a.enabled && Fr(document.documentElement, 'down', f), (a.enabled = !1);
              },
              destroy: function () {
                Fr(document.documentElement, 'up', h),
                  Fr(document.documentElement, 'move', d),
                  Fr(document.documentElement, 'down', f);
              },
            }
          );
        },
        qr = { type: 'spring', stiffness: 0.4, damping: 0.65, mass: 7 };
      function Hr(e, t) {
        return (
          (function (e) {
            if (Array.isArray(e)) return e;
          })(e) ||
          (function (e, t) {
            var n = [],
              r = !0,
              i = !1,
              o = void 0;
            try {
              for (
                var a, c = e[Symbol.iterator]();
                !(r = (a = c.next()).done) && (n.push(a.value), !t || n.length !== t);
                r = !0
              );
            } catch (e) {
              (i = !0), (o = e);
            } finally {
              try {
                r || null == c.return || c.return();
              } finally {
                if (i) throw o;
              }
            }
            return n;
          })(e, t) ||
          (function () {
            throw new TypeError('Invalid attempt to destructure non-iterable instance');
          })()
        );
      }
      function Yr(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              Xr(e, t, n[t]);
            });
        }
        return e;
      }
      function Xr(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var Zr = function (e, t, n) {
          if (/^(line|text|ellipse|rect)$/.test(n))
            e.dispatch('MARKUP_ADD_DEFAULT', { value: n }), e.dispatch('SET_MARKUP_UTIL', { value: 'select' });
          else if ('draw' === n && !e.ref.drawInput) {
            var r = e.ref,
              i = r.drawState,
              o = r.viewSize,
              a = 0,
              c = 0,
              l = {},
              u = {},
              s = e.query('GET_MARKUP_DRAW_DISTANCE');
            e.ref.drawInput = Wr(
              e.element,
              function (n) {
                var r = e.query('GET_MARKUP_TOOL_VALUES'),
                  s = r.lineStyle[0],
                  f = r.lineStyle[1];
                (i.lineColor = r.color), (i.lineWidth = s), (i.lineStyle = f);
                var d = e.query('GET_ROOT'),
                  h = void 0 !== n.offsetX ? n.offsetX : n.pageX - d.x - t.stageOffsetX - window.pageXOffset,
                  p = void 0 !== n.offsetY ? n.offsetY : n.pageY - d.y - t.stageOffsetY - window.pageYOffset;
                (a = h - e.markupX),
                  (c = p - e.markupY),
                  (l.x = 0),
                  (l.y = 0),
                  (u.x = 0),
                  (u.y = 0),
                  i.points.push({ x: a / o.width, y: c / o.height });
              },
              function (t, n) {
                if ((e.dispatch('KICK'), s)) {
                  var r,
                    l = Ge(n, u);
                  if (l > s) {
                    var f = ((r = Pe(u, n)), Math.atan2(r.y, r.x)) + Math.PI / 2,
                      d = s - l;
                    (u.x += Math.sin(f) * d),
                      (u.y -= Math.cos(f) * d),
                      i.points.push({ x: (a + u.x) / o.width, y: (c + u.y) / o.height });
                  }
                } else i.points.push({ x: (a + n.x) / o.width, y: (c + n.y) / o.height });
              },
              function (t, n) {
                i.points.length > 1 && e.dispatch('MARKUP_ADD', ['path', Yr({}, i)]), (i.points = []);
              },
            );
          }
          'draw' !== n && e.ref.drawInput && (e.ref.drawInput.destroy(), (e.ref.drawInput = null));
        },
        Kr = function (e, t) {
          return Object.keys(t).forEach(function (n) {
            e.setAttribute(n, t[n]);
          });
        },
        Qr = function (e, t) {
          var n = document.createElementNS('http://www.w3.org/2000/svg', e);
          return t && Kr(n, t), n;
        },
        Jr = ['nw', 'se'],
        $r = ['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se'],
        ei = { nw: 'nwse', n: 'ns', ne: 'nesw', w: 'ew', e: 'ew', sw: 'nesw', s: 'ns', se: 'nwse' },
        ti = {
          nw: function (e) {
            return { x: e.x, y: e.y };
          },
          n: function (e) {
            return { x: e.x + 0.5 * e.width, y: e.y };
          },
          ne: function (e) {
            return { x: e.x + e.width, y: e.y };
          },
          w: function (e) {
            return { x: e.x, y: e.y + 0.5 * e.height };
          },
          e: function (e) {
            return { x: e.x + e.width, y: e.y + 0.5 * e.height };
          },
          sw: function (e) {
            return { x: e.x, y: e.y + e.height };
          },
          s: function (e) {
            return { x: e.x + 0.5 * e.width, y: e.y + e.height };
          },
          se: function (e) {
            return { x: e.x + e.width, y: e.y + e.height };
          },
        },
        ni = {
          nw: function (e) {
            return { x: e.x - 5, y: e.y - 5 };
          },
          n: function (e) {
            return { x: e.x + 0.5 * e.width, y: e.y - 5 };
          },
          ne: function (e) {
            return { x: e.x + e.width + 5, y: e.y - 5 };
          },
          w: function (e) {
            return { x: e.x - 5, y: e.y + 0.5 * e.height };
          },
          e: function (e) {
            return { x: e.x + e.width + 5, y: e.y + 0.5 * e.height };
          },
          sw: function (e) {
            return { x: e.x - 5, y: e.y + e.height + 5 };
          },
          s: function (e) {
            return { x: e.x + 0.5 * e.width, y: e.y + e.height + 5 };
          },
          se: function (e) {
            return { x: e.x + e.width + 5, y: e.y + e.height + 5 };
          },
        },
        ri = X({
          tag: 'div',
          name: 'image-markup',
          ignoreRect: !0,
          mixins: {
            styles: ['opacity'],
            animations: { opacity: 'spring', markupX: qr, markupY: qr, markupWidth: qr, markupHeight: qr },
            listeners: !0,
            apis: [
              'toolsReference',
              'onSelect',
              'onDrag',
              'markupX',
              'markupY',
              'markupWidth',
              'markupHeight',
              'allowInteraction',
              'stageOffsetX',
              'stageOffsetY',
            ],
          },
          create: function (e) {
            var t = e.root,
              n = e.props,
              r = n.onSelect,
              i = void 0 === r ? function () {} : r,
              o = n.onUpdate,
              a = void 0 === o ? function () {} : o,
              c = Qr('svg', { xmlns: 'http://www.w3.org/2000/svg', 'xmlns:xlink': 'http://www.w3.org/1999/xlink' });
            t.ref.canvas = c;
            var l = t.query('GET_ROOT_SIZE');
            c.setAttribute('width', l.width), c.setAttribute('height', l.height);
            var u = document.createElement('input');
            Kr(u, { type: 'text', autocomplete: 'off', autocapitalize: 'off' }),
              u.addEventListener('keydown', function (e) {
                e.stopPropagation(),
                  13 === e.keyCode || 9 === e.keyCode
                    ? (e.target.blur(), f())
                    : 8 !== e.keyCode ||
                      t.ref.input.value.length ||
                      t.dispatch('MARKUP_DELETE', { id: t.ref.selected.id });
              }),
              (t.ref.input = u),
              (t.ref.elements = []),
              (t.ref.viewSize = { width: 0, height: 0, scale: 0 }),
              (t.ref.resetSelected = function () {
                return (t.ref.selected = { id: null, type: null, settings: {} }), t.ref.selected;
              }),
              t.ref.resetSelected();
            var s = function (e) {
                return e.id ? e : e.parentNode;
              },
              f = function () {
                t.ref.resetSelected(), i(null);
              };
            (t.ref.handleDeselect = function (e) {
              var r;
              (t.query('IS_ACTIVE_VIEW', 'markup') || t.query('IS_ACTIVE_VIEW', 'sticker')) &&
                t.ref.selected.id &&
                e.target !== t.ref.removeButton.element &&
                ((r = e.target),
                t.ref.selected.id !== s(r).id &&
                  ((function (e) {
                    return jr(t.ref.manipulatorGroup, e) || e === t.ref.input;
                  })(e.target) ||
                    n.isMarkupUtil(e.target) ||
                    f()));
            }),
              Nr(document.body, 'down', t.ref.handleDeselect),
              (t.ref.handleTextInput = function () {
                return a('text', t.ref.input.value);
              }),
              t.ref.input.addEventListener('input', t.ref.handleTextInput),
              (t.ref.handleAttemptDelete = function (e) {
                (t.query('IS_ACTIVE_VIEW', 'markup') || t.query('IS_ACTIVE_VIEW', 'sticker')) &&
                  (!t.ref.selected.id ||
                    (8 !== e.keyCode && 46 !== e.keyCode) ||
                    (e.stopPropagation(), e.preventDefault(), t.dispatch('MARKUP_DELETE', { id: t.ref.selected.id })));
              }),
              document.body.addEventListener('keydown', t.ref.handleAttemptDelete);
            var d = Qr('g'),
              h = Qr('g', { class: 'doka--shape-group' });
            d.appendChild(h), (t.ref.shapeGroup = h);
            var p = Qr('g', { fill: 'none', class: 'doka--manipulator-group' }),
              m = Qr('rect', { x: 0, y: 0, width: 0, height: 0, fill: 'none' }),
              v = Qr('path');
            p.appendChild(v),
              p.appendChild(m),
              (t.ref.manipulatorPath = v),
              (t.ref.manipulatorRect = m),
              (t.ref.manipulators = []);
            for (var y = 0; y < 10; y++) {
              var E = Qr('circle', { r: 6, 'stroke-width': 2 }),
                w = Qr('circle', { r: 22, class: 'doka--hitbox', style: 'opacity: 0' });
              p.appendChild(w), p.appendChild(E), t.ref.manipulators.push({ visual: E, hitbox: w, dragger: null });
            }
            d.appendChild(p),
              (t.ref.manipulatorGroup = p),
              c.appendChild(d),
              (t.ref.shapeOffsetGroup = d),
              (t.ref.removeButton = t.appendChildView(
                t.createChildView(Sr, {
                  label: t.query('GET_LABEL_MARKUP_REMOVE_SHAPE'),
                  name: 'destroy-shape',
                  action: function () {
                    t.dispatch('MARKUP_DELETE', { id: t.ref.selected.id });
                  },
                }),
              )),
              (t.query('IS_ACTIVE_VIEW', 'markup') || t.query('IS_ACTIVE_VIEW', 'sticker')) &&
                (t.element.dataset.active = !0),
              (t.ref.drawInput = null),
              (t.ref.drawState = { lineColor: null, lineWidth: null, lineStyle: null, points: [] });
            var _ = Qr('path', { fill: 'none', class: 'doka--draw-path' });
            (t.ref.drawPath = _), c.appendChild(_);
            var T = g('div', 'doka--image-markup-clip');
            T.appendChild(u),
              T.appendChild(c),
              (t.ref.clip = T),
              t.element.appendChild(T),
              'draw' === t.query('GET_MARKUP_UTIL') && Zr(t, n, 'draw');
          },
          destroy: function (e) {
            var t = e.root;
            t.ref.elements.concat(t.ref.manipulators).forEach(function (e) {
              e.dragger && e.dragger.destroy();
            }),
              t.ref.input.removeEventListener('input', t.ref.handleTextInput),
              document.body.removeEventListener('keydown', t.ref.handleAttemptDelete),
              Fr(document.body, 'down', t.ref.handleDeselect);
          },
          read: function (e) {
            var t = e.root;
            if (!t.rect.element.hidden)
              for (var n in t.ref.elements) {
                var r = t.ref.elements[n];
                if (r && 'text' === r.nodeName && r.parentNode) {
                  var i = r.getBBox();
                  r.bbox = { x: i.x, y: i.y, width: i.width, height: i.height };
                }
              }
          },
          write: Z(
            {
              SHOW_VIEW: function (e) {
                var t = e.root,
                  n = e.props,
                  r = e.action;
                'markup' === r.id || 'sticker' === r.id
                  ? (t.element.dataset.active = !0)
                  : ((t.element.dataset.active = !1), n.onSelect(null));
              },
              MARKUP_SET_VALUE: function (e) {
                var t = e.root;
                u(t.ref.elements, function (e, t) {
                  t && t.dragger && t.dragger.destroy();
                }),
                  (t.ref.elements = []),
                  (t.ref.shapeGroup.innerHTML = '');
              },
              UPDATE_ROOT_RECT: function (e) {
                var t = e.root,
                  n = e.action,
                  r = t.ref.canvas;
                r.setAttribute('width', n.rect.width),
                  r.setAttribute('height', n.rect.height),
                  (t.ref.previousScale = null);
              },
              SWITCH_MARKUP_UTIL: function (e) {
                var t = e.root,
                  n = e.action,
                  r = e.props,
                  i = n.util;
                Zr(t, r, i);
              },
            },
            function (e) {
              var t = e.root,
                n = e.props,
                r = e.timestamp;
              if (!(t.opacity <= 0)) {
                var i = t.query('GET_CROP', n.id, r);
                if (i) {
                  var o = t.query('GET_MARKUP_UTIL');
                  t.element.dataset.util = o || '';
                  var a = i.markup,
                    c = i.cropStatus,
                    l = n.onSelect,
                    u = n.onDrag,
                    s = t.ref,
                    f = s.clip,
                    d = s.manipulatorGroup,
                    h = s.drawPath,
                    p = s.viewSize,
                    g = s.shapeOffsetGroup,
                    m = s.manipulators,
                    v = s.manipulatorPath,
                    y = s.manipulatorRect,
                    E = s.removeButton,
                    w = s.drawState,
                    _ = t.query('GET_OUTPUT_WIDTH'),
                    T = t.query('GET_OUTPUT_HEIGHT'),
                    b = c.image,
                    A = c.crop,
                    x = A.width,
                    O = A.height,
                    R = A.widthFloat / A.heightFloat;
                  if (_ || T) {
                    var I = t.query('GET_OUTPUT_FIT');
                    _ && !T && (T = _), T && !_ && (_ = T);
                    var C,
                      S = _ / x,
                      M = T / O;
                    if ('force' === I) (x = _), (O = T);
                    else
                      'cover' === I ? (C = Math.max(S, M)) : 'contain' === I && (C = Math.min(S, M)),
                        (x *= C),
                        (O *= C);
                  } else
                    b.width && b.height
                      ? ((x = b.width), (O = b.height))
                      : b.width && !b.height
                        ? ((x = b.width), (O = b.width / R))
                        : b.height && !b.width && ((O = b.height), (x = b.height * R));
                  var L = w.points.length,
                    P = Me(t.markupX, 3),
                    k = Me(t.markupY, 3),
                    G = Me(t.markupWidth, 3),
                    D = Me(t.markupHeight, 3),
                    U = Me(Math.min(t.markupWidth / x, t.markupHeight / O), 4);
                  if (
                    ((p.width = G),
                    (p.height = D),
                    (p.scale = U),
                    oi(t, {
                      drawLength: L,
                      markupX: P,
                      markupY: k,
                      scale: U,
                      markup: a,
                      currentWidth: x,
                      currentHeight: O,
                    }))
                  ) {
                    var V = P,
                      B = t.rect.element.width - P - G,
                      z = k,
                      N = t.rect.element.height - k - D,
                      F = 'inset('.concat(z, 'px ').concat(B, 'px ').concat(N, 'px ').concat(V, 'px)');
                    if (
                      ((f.style.clipPath = F),
                      (f.style.webkitClipPath = F),
                      g.setAttribute('transform', 'translate('.concat(P, ' ').concat(k, ')')),
                      (t.ref.previousDrawLength = L),
                      (t.ref.previousX = P),
                      (t.ref.previousY = k),
                      (t.ref.previousScale = U),
                      (t.ref.previousCurrentHeight = O),
                      (t.ref.previousCurrentWidth = x),
                      (t.ref.previousMarkupLength = a.length),
                      !(p.width < 1 || p.height < 1))
                    ) {
                      var j,
                        W = a.find(function (e) {
                          return e[1].isSelected;
                        }),
                        q = (W && t.ref.selected.id !== W[1].id) || (t.ref.selected.id && !W);
                      if (
                        ((j = W
                          ? (t.ref.selected = { id: W[1].id, type: W[0], settings: W[1] })
                          : t.ref.resetSelected()),
                        w.points.length)
                      ) {
                        var H = jt(w, p, U);
                        return (
                          (H.d = Ht(
                            w.points.map(function (e) {
                              return { x: P + e.x * p.width, y: k + e.y * p.height };
                            }),
                          )),
                          void Kr(h, H)
                        );
                      }
                      h.removeAttribute('d'),
                        (t.ref.input.hidden = 'text' !== t.ref.selected.type || !t.ref.selected.settings.allowInput),
                        (E.element.dataset.active = null !== t.ref.selected.id),
                        v.setAttribute('style', 'opacity:0'),
                        y.setAttribute('style', 'opacity:0'),
                        m.forEach(function (e) {
                          e.visual.setAttribute('style', 'opacity:0; pointer-events:none;'),
                            e.hitbox.setAttribute('style', 'pointer-events:none;');
                        });
                      var Y = t.query('GET_MARKUP_FILTER');
                      a
                        .filter(Y)
                        .sort(on)
                        .forEach(function (e, r) {
                          var i = Hr(e, 2),
                            o = i[0],
                            a = i[1],
                            c = a.id,
                            s = a.isDestroyed,
                            f = a.isDirty,
                            h = a.isSelected,
                            g = a.allowSelect,
                            w = a.allowMove,
                            _ = a.allowResize,
                            T = a.allowInput;
                          if (s) {
                            var b = t.ref.elements[c];
                            b &&
                              (b.dragger && b.dragger.destroy(),
                              (t.ref.elements[c] = null),
                              b.parentNode.removeChild(b));
                          } else {
                            var A,
                              x,
                              O,
                              R = t.ref.elements[c];
                            if (!R)
                              if (((R = nn(o, a)), (t.ref.elements[c] = R), g))
                                (R.dragger = Wr(
                                  R,
                                  function () {
                                    (x = Date.now()), (A = Yr({}, R.rect)), (O = c === t.ref.selected.id) || l(c);
                                  },
                                  function (e, t) {
                                    w && u(c, A, t, p, U);
                                  },
                                  function (e, n) {
                                    if (T && 'text' === o && O) {
                                      var r = ke({ x: 0, y: 0 }, n),
                                        i = Date.now() - x;
                                      if (!(r > 10 || i > 750)) {
                                        t.ref.input.focus();
                                        var a = t.markupX + R.bbox.x,
                                          c = R.bbox.width,
                                          l = (e.offsetX - a) / c,
                                          u = Math.round(t.ref.input.value.length * l);
                                        t.ref.input.setSelectionRange(u, u);
                                      }
                                    }
                                  },
                                )),
                                  R.dragger.disable();
                              else R.setAttribute('style', 'pointer-events:none;');
                            if (
                              (R.dragger && (n.allowInteraction ? R.dragger.enable() : R.dragger.disable()),
                              r !== R.index)
                            ) {
                              R.index = r;
                              var I = t.ref.shapeGroup;
                              I.insertBefore(R, I.childNodes[r + 1]);
                            }
                            if ((f && rn(R, o, a, p, U), h)) {
                              var C = E.rect.element.width,
                                S = E.rect.element.height,
                                M = t.markupX - 0.5 * C,
                                L = t.markupY - S - 15,
                                P = 'text' === o ? R.bbox : R.rect,
                                k = !1,
                                G = (function (e) {
                                  var t = e.fontColor,
                                    n = e.backgroundColor,
                                    r = e.lineColor,
                                    i = e.borderColor;
                                  return t || n || r || i;
                                })(a);
                              if (G) {
                                var D = dt(G);
                                (k = (0.2126 * D[0] + 0.7152 * D[1] + 0.0722 * D[2]) / 255 > 0.65),
                                  d.setAttribute('is-bright-color', k);
                              }
                              'line' === o
                                ? ((M += P.x),
                                  (L += P.y),
                                  Kr(v, {
                                    d: 'M '
                                      .concat(P.x, ' ')
                                      .concat(P.y, ' L ')
                                      .concat(P.x + P.width, ' ')
                                      .concat(P.y + P.height),
                                    style: 'opacity:1',
                                  }))
                                : 'path' === o
                                  ? ((M += (P = {
                                      x: a.points[0].x * p.width,
                                      y: a.points[0].y * p.height,
                                      width: 0,
                                      height: 0,
                                    }).x),
                                    (L += P.y),
                                    Kr(v, {
                                      d: Ht(
                                        a.points.map(function (e) {
                                          return { x: e.x * p.width, y: e.y * p.height };
                                        }),
                                      ),
                                      style: 'opacity:1',
                                    }))
                                  : P &&
                                    ((M += P.x + 0.5 * P.width),
                                    (L += P.y),
                                    Kr(y, {
                                      x: P.x - ('text' === o ? 5 : 0),
                                      y: P.y,
                                      width: P.width + ('text' === o ? 10 : 0),
                                      height: P.height,
                                      style: 'opacity:1',
                                    }));
                              var V = t.markupY + 10,
                                B = t.markupY + t.markupHeight - 10,
                                z = t.markupX + 10,
                                N = t.markupX + t.markupWidth - 10;
                              if (
                                (L < V ? (L = V) : L + S > B && (L = B - S),
                                M < z ? (M = z) : M + C > N && (M = N - C),
                                P || (E.element.dataset.active = 'false'),
                                E.element.setAttribute(
                                  'style',
                                  'transform: translate3d('.concat(M, 'px, ').concat(L, 'px, 0)'),
                                ),
                                'text' === o && P && T)
                              ) {
                                var F = P.width + 65,
                                  j = t.markupWidth - P.x,
                                  W = '\n                        width: '
                                    .concat(Math.min(F, j), 'px;\n                        height: ')
                                    .concat(P.height, 'px;\n                        color: ')
                                    .concat(R.getAttribute('fill'), ';\n                        font-family: ')
                                    .concat(R.getAttribute('font-family'), ';\n                        font-size: ')
                                    .concat(
                                      R.getAttribute('font-size').replace(/px/, ''),
                                      'px;\n                        font-weight: ',
                                    )
                                    .concat(R.getAttribute('font-weight') || 'normal', ';\n                    ');
                                xe()
                                  ? (W += '\n                            left: '
                                      .concat(Math.round(t.markupX + P.x), 'px;\n                            top: ')
                                      .concat(Math.round(t.markupY + P.y), 'px;\n                        '))
                                  : (W += '\n                            transform: translate3d('
                                      .concat(Math.round(t.markupX + P.x), 'px,')
                                      .concat(Math.round(t.markupY + P.y), 'px,0);\n                        ')),
                                  t.ref.input.setAttribute('style', W),
                                  R.setAttribute('fill', 'none');
                              }
                              if ('text' === o) return;
                              if (!_) return;
                              var q = 'line' === o ? Jr : $r;
                              m.forEach(function (e, t) {
                                var n = q[t];
                                if (n) {
                                  var r = 'line' === o ? 'move' : ''.concat(ei[n], '-resize'),
                                    i = ti[n](R.rect),
                                    a = R.rect.width < 100 || R.rect.height < 100,
                                    c = 2 === n.length ? 1 : a ? 0 : 1;
                                  Kr(e.visual, { cx: i.x, cy: i.y, style: 'opacity:'.concat(c) });
                                  var l = ni[n](R.rect);
                                  Kr(e.hitbox, { cx: l.x, cy: l.y, style: 'cursor:'.concat(r, ';') });
                                }
                              });
                            }
                            a.isDirty = !1;
                          }
                        }),
                        q &&
                          (ci(t),
                          'text' === j.type && t.ref.selected.settings.allowInput
                            ? (t.ref.input.value = j.settings.text)
                            : t.ref.selected.id && ai(t, n.onResize));
                    }
                  }
                }
              }
            },
          ),
        }),
        ii = function (e) {
          return e.forEach(function (e) {
            return (e[1].isDirty = !0);
          });
        },
        oi = function (e, t) {
          var n = t.drawLength,
            r = t.markup,
            i = t.markupX,
            o = t.markupY,
            a = t.currentWidth,
            c = t.currentHeight,
            l = t.scale;
          return (
            n !== e.ref.previousDrawLength ||
            (i !== e.ref.previousX
              ? (ii(r), !0)
              : o !== e.ref.previousY
                ? (ii(r), !0)
                : l !== e.ref.previousScale
                  ? (ii(r), !0)
                  : c !== e.ref.previousCurrentHeight
                    ? (ii(r), !0)
                    : a !== e.ref.previousCurrentWidth
                      ? (ii(r), !0)
                      : r.length !== e.ref.previousMarkupLength ||
                        !!r.find(function (e) {
                          return e[1].isDirty;
                        }))
          );
        },
        ai = function (e, t) {
          var n = e.ref.selected.id,
            r = 'g' === e.ref.elements[n].nodeName ? Jr : $r;
          e.ref.manipulators.forEach(function (i, o) {
            var a = r[o];
            if (a) {
              var c = null;
              i.dragger = Wr(
                i.hitbox,
                function () {
                  c = { x: parseFloat(f(i.hitbox, 'cx')), y: parseFloat(f(i.hitbox, 'cy')) };
                },
                function (r, i) {
                  t(n, a, c, i, e.ref.viewSize);
                },
                null,
                { stopPropagation: !0 },
              );
            }
          });
        },
        ci = function (e) {
          e.ref.manipulators.forEach(function (e) {
            e.dragger && (e.dragger.destroy(), (e.dragger = null));
          });
        },
        li = {
          38: 'up',
          40: 'down',
          37: 'left',
          39: 'right',
          189: 'minus',
          187: 'plus',
          72: 'h',
          76: 'l',
          81: 'q',
          82: 'r',
          84: 't',
          86: 'v',
          90: 'z',
          219: 'left_bracket',
          221: 'right_bracket',
        },
        ui = function (e, t, n, r, i) {
          var o = null,
            a = !0,
            c = { enabled: !0 },
            l = function (e) {
              var i = li[e.keyCode] || e.keyCode;
              n[i] && (e.stopPropagation(), a && ((o = t(i)), (a = !1)), n[i](o), r(o));
            },
            u = function (e) {
              var t = li[e.keyCode] || e.keyCode;
              n[t] && (e.stopPropagation(), i(o), (a = !0));
            };
          return (
            e.addEventListener('keydown', l),
            e.addEventListener('keyup', u),
            {
              enable: function () {
                c.enabled || (e.addEventListener('keydown', l), e.addEventListener('keyup', u)), (c.enabled = !0);
              },
              disable: function () {
                c.enabled && (e.removeEventListener('keydown', l), e.removeEventListener('keyup', u)), (c.enabled = !1);
              },
              destroy: function () {
                e.removeEventListener('keydown', l), e.removeEventListener('keyup', u);
              },
            }
          );
        },
        si = function (e, t, n) {
          var r = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 1,
            i = arguments.length > 4 ? arguments[4] : void 0;
          (t = Math.round(t)), (n = Math.round(n));
          var o = i || document.createElement('canvas'),
            a = o.getContext('2d');
          return (
            r >= 5 && r <= 8 ? ((o.width = n), (o.height = t)) : ((o.width = t), (o.height = n)),
            a.save(),
            -1 !== r && a.transform.apply(a, Pt(t, n, r)),
            a.drawImage(e, 0, 0, t, n),
            a.restore(),
            o
          );
        },
        fi = function () {
          self.onmessage = function (e) {
            createImageBitmap(e.data.message.file).then(function (t) {
              self.postMessage({ id: e.data.id, message: t }, [t]);
            });
          };
        },
        di = function (e, t, n) {
          var r = e.createShader(n);
          return e.shaderSource(r, t), e.compileShader(r), r;
        },
        hi = function (e, t, n) {
          var r = e.createProgram();
          return (
            e.attachShader(r, di(e, t, e.VERTEX_SHADER)),
            e.attachShader(r, di(e, n, e.FRAGMENT_SHADER)),
            e.linkProgram(r),
            r
          );
        },
        pi = function () {
          var e = new Float32Array(16);
          return (e[0] = 1), (e[5] = 1), (e[10] = 1), (e[15] = 1), e;
        },
        gi = function (e, t, n, r, i) {
          var o = 1 / Math.tan(t / 2),
            a = 1 / (r - i);
          (e[0] = o / n),
            (e[1] = 0),
            (e[2] = 0),
            (e[3] = 0),
            (e[4] = 0),
            (e[5] = o),
            (e[6] = 0),
            (e[7] = 0),
            (e[8] = 0),
            (e[9] = 0),
            (e[11] = -1),
            (e[12] = 0),
            (e[13] = 0),
            (e[15] = 0),
            (e[10] = (i + r) * a),
            (e[14] = 2 * i * r * a);
        },
        mi = function (e, t) {
          var n = t[0],
            r = t[1],
            i = t[2];
          (e[12] = e[0] * n + e[4] * r + e[8] * i + e[12]),
            (e[13] = e[1] * n + e[5] * r + e[9] * i + e[13]),
            (e[14] = e[2] * n + e[6] * r + e[10] * i + e[14]),
            (e[15] = e[3] * n + e[7] * r + e[11] * i + e[15]);
        },
        vi = function (e, t) {
          var n = t[0],
            r = t[1],
            i = t[2];
          (e[0] = e[0] * n),
            (e[1] = e[1] * n),
            (e[2] = e[2] * n),
            (e[3] = e[3] * n),
            (e[4] = e[4] * r),
            (e[5] = e[5] * r),
            (e[6] = e[6] * r),
            (e[7] = e[7] * r),
            (e[8] = e[8] * i),
            (e[9] = e[9] * i),
            (e[10] = e[10] * i),
            (e[11] = e[11] * i);
        },
        yi = function (e, t) {
          var n = Math.sin(t),
            r = Math.cos(t),
            i = e[4],
            o = e[5],
            a = e[6],
            c = e[7],
            l = e[8],
            u = e[9],
            s = e[10],
            f = e[11];
          (e[4] = i * r + l * n),
            (e[5] = o * r + u * n),
            (e[6] = a * r + s * n),
            (e[7] = c * r + f * n),
            (e[8] = l * r - i * n),
            (e[9] = u * r - o * n),
            (e[10] = s * r - a * n),
            (e[11] = f * r - c * n);
        },
        Ei = function (e, t) {
          var n = Math.sin(t),
            r = Math.cos(t),
            i = e[0],
            o = e[1],
            a = e[2],
            c = e[3],
            l = e[8],
            u = e[9],
            s = e[10],
            f = e[11];
          (e[0] = i * r - l * n),
            (e[1] = o * r - u * n),
            (e[2] = a * r - s * n),
            (e[3] = c * r - f * n),
            (e[8] = i * n + l * r),
            (e[9] = o * n + u * r),
            (e[10] = a * n + s * r),
            (e[11] = c * n + f * r);
        },
        wi = function (e, t) {
          var n = Math.sin(t),
            r = Math.cos(t),
            i = e[0],
            o = e[1],
            a = e[2],
            c = e[3],
            l = e[4],
            u = e[5],
            s = e[6],
            f = e[7];
          (e[0] = i * r + l * n),
            (e[1] = o * r + u * n),
            (e[2] = a * r + s * n),
            (e[3] = c * r + f * n),
            (e[4] = l * r - i * n),
            (e[5] = u * r - o * n),
            (e[6] = s * r - a * n),
            (e[7] = f * r - c * n);
        };
      function _i(e) {
        return (
          (function (e) {
            if (Array.isArray(e)) {
              for (var t = 0, n = new Array(e.length); t < e.length; t++) n[t] = e[t];
              return n;
            }
          })(e) ||
          (function (e) {
            if (Symbol.iterator in Object(e) || '[object Arguments]' === Object.prototype.toString.call(e))
              return Array.from(e);
          })(e) ||
          (function () {
            throw new TypeError('Invalid attempt to spread non-iterable instance');
          })()
        );
      }
      var Ti = '\nattribute vec4 aPosition;\nvoid main() {\n\tgl_Position = aPosition;\n}\n',
        bi = function (e, t, n) {
          var r = { width: 0, height: 0 },
            i = { x: 0, y: 0 },
            o = null,
            a = (30 * Math.PI) / 180,
            c = Math.tan(a / 2),
            l = { antialias: !1, alpha: !1 },
            u = e.getContext('webgl', l) || e.getContext('experimental-webgl', l);
          if (!u) return null;
          u.enable(u.BLEND), u.blendFunc(u.SRC_ALPHA, u.ONE_MINUS_SRC_ALPHA);
          var s = hi(
              u,
              Ti,
              '\nprecision mediump float;\n\nuniform vec2 uViewportSize;\nuniform vec3 uColorStart;\nuniform vec3 uColorEnd;\nuniform vec2 uOverlayLeftTop;\nuniform vec2 uOverlayRightBottom;\nuniform vec4 uColorCanvasBackground;\n\nvoid main() {\n\n\tfloat x = gl_FragCoord.x;\n\tfloat y = gl_FragCoord.y;\n\n\tvec2 center = vec2(.5, .5);\n\tvec2 st = vec2(x / uViewportSize.x, y / uViewportSize.y);\n\tfloat mixValue = distance(st, center) * 1.5; // expand outside view (same as doka--root::after)\n\tvec3 color = mix(uColorStart, uColorEnd, mixValue);\n\n\tif (uColorCanvasBackground[3] == 1.0) {\n\n\t\tfloat innerLeft = uOverlayLeftTop.x;\n\t\tfloat innerRight = uOverlayRightBottom.x;\n\t\tfloat innerTop = uOverlayRightBottom.y;\n\t\tfloat innerBottom = uOverlayLeftTop.y;\n\n\t\tif (x < innerLeft || x > innerRight || y < innerTop || y > innerBottom) {\n\t\t\tgl_FragColor = vec4(color, 1.0);\n\t\t\treturn;\n\t\t}\n\n\t\tgl_FragColor = uColorCanvasBackground;\n\t\treturn;\n\t}\n\t\n\tgl_FragColor = vec4(color, 1.0);\n}\n',
            ),
            f = u.getUniformLocation(s, 'uColorStart'),
            d = u.getUniformLocation(s, 'uColorEnd'),
            h = u.getUniformLocation(s, 'uViewportSize'),
            p = u.getAttribLocation(s, 'aPosition'),
            g = u.getUniformLocation(s, 'uOverlayLeftTop'),
            m = u.getUniformLocation(s, 'uOverlayRightBottom'),
            v = u.getUniformLocation(s, 'uColorCanvasBackground'),
            y = u.createBuffer(),
            E = new Float32Array([1, -1, 1, 1, -1, -1, -1, 1]);
          u.bindBuffer(u.ARRAY_BUFFER, y),
            u.bufferData(u.ARRAY_BUFFER, E, u.STATIC_DRAW),
            u.bindBuffer(u.ARRAY_BUFFER, null);
          var w = hi(
              u,
              Ti,
              '\nprecision mediump float;\n\nuniform vec2 uOverlayLeftTop;\nuniform vec2 uOverlayRightBottom;\nuniform vec4 uOutlineColor;\nuniform float uOutlineWidth;\n\nvoid main() {\n\n\tfloat x = gl_FragCoord.x;\n\tfloat y = gl_FragCoord.y;\n\n\tfloat innerLeft = uOverlayLeftTop.x;\n\tfloat innerRight = uOverlayRightBottom.x;\n\tfloat innerTop = uOverlayRightBottom.y;\n\tfloat innerBottom = uOverlayLeftTop.y;\n\n\tfloat outerLeft = innerLeft - uOutlineWidth;\n\tfloat outerRight = innerRight + uOutlineWidth;\n\tfloat outerTop = innerTop - uOutlineWidth;\n\tfloat outerBottom = innerBottom + uOutlineWidth;\n\t\n\tif (x < outerLeft || x >= outerRight || y < outerTop || y >= outerBottom) {\n\t\tdiscard;\n\t}\n\n\tif (x < innerLeft || x >= innerRight || y < innerTop || y >= innerBottom) {\n\t\tgl_FragColor = uOutlineColor;\n\t}\n}\n',
            ),
            _ = u.getAttribLocation(w, 'aPosition'),
            T = u.getUniformLocation(w, 'uOutlineWidth'),
            b = u.getUniformLocation(w, 'uOutlineColor'),
            A = u.getUniformLocation(w, 'uOverlayLeftTop'),
            x = u.getUniformLocation(w, 'uOverlayRightBottom'),
            O = u.createBuffer(),
            R = new Float32Array([1, -1, 1, 1, -1, -1, -1, 1]);
          u.bindBuffer(u.ARRAY_BUFFER, O),
            u.bufferData(u.ARRAY_BUFFER, R, u.STATIC_DRAW),
            u.bindBuffer(u.ARRAY_BUFFER, null);
          var I = hi(
            u,
            '\nattribute vec4 aPosition;\nattribute vec2 aTexCoord;\nuniform mat4 uMatrix;\n\n// send to fragment shader\nvarying vec2 vTexCoord;\nvarying vec4 vPosition;\n\nvoid main () {\n    vPosition = uMatrix * aPosition;\n    gl_Position = vPosition;\n    vTexCoord = aTexCoord;\n}\n',
            '\nprecision mediump float;\n\nuniform sampler2D uTexture;\nuniform vec2 uTextureSize;\n\nuniform float uColorOpacity;\nuniform mat4 uColorMatrix;\nuniform vec4 uColorOffset;\n\nuniform vec4 uOverlayColor;\nuniform vec2 uOverlayLeftTop;\nuniform vec2 uOverlayRightBottom;\n\nvarying vec2 vTexCoord;\nvarying vec4 vPosition;\n\nvoid main () {\n\n    vec3 cB = vec3(1.0);\n\n\tvec4 cF = texture2D(uTexture, vTexCoord);\n\t\n\tvec4 cM = (cF * uColorMatrix) + uColorOffset;\n\n    float r = max(0.0, cM.r * cM.a) + (cB.r * (1.0 - cM.a));\n    float g = max(0.0, cM.g * cM.a) + (cB.g * (1.0 - cM.a));\n    float b = max(0.0, cM.b * cM.a) + (cB.b * (1.0 - cM.a));\n\n\tvec4 color = vec4(r, g, b, cF.a);\n\t\n\t// test if falls within\n    if ((gl_FragCoord.x < uOverlayLeftTop.x || gl_FragCoord.x > uOverlayRightBottom.x) || \n        (gl_FragCoord.y > uOverlayLeftTop.y || gl_FragCoord.y < uOverlayRightBottom.y)) {\n\t\tcolor *= uOverlayColor;\n\t}\n\t\n    gl_FragColor = color * uColorOpacity;\n}\n',
          );
          u.useProgram(I);
          var C = u.getUniformLocation(I, 'uMatrix'),
            S = u.getUniformLocation(I, 'uTexture'),
            M = u.getUniformLocation(I, 'uTextureSize'),
            L = u.getUniformLocation(I, 'uOverlayColor'),
            P = u.getUniformLocation(I, 'uOverlayLeftTop'),
            k = u.getUniformLocation(I, 'uOverlayRightBottom'),
            G = u.getUniformLocation(I, 'uColorOpacity'),
            D = u.getUniformLocation(I, 'uColorOffset'),
            U = u.getUniformLocation(I, 'uColorMatrix'),
            V = u.getAttribLocation(I, 'aPosition'),
            B = u.getAttribLocation(I, 'aTexCoord'),
            z = (function (e, t, n, r, i) {
              var o = e.createTexture();
              e.activeTexture(e.TEXTURE0 + r),
                e.bindTexture(e.TEXTURE_2D, o),
                e.texParameteri(e.TEXTURE_2D, e.TEXTURE_MIN_FILTER, e.LINEAR),
                e.texParameteri(e.TEXTURE_2D, e.TEXTURE_MAG_FILTER, e.LINEAR),
                e.texParameteri(e.TEXTURE_2D, e.TEXTURE_WRAP_S, e.CLAMP_TO_EDGE),
                e.texParameteri(e.TEXTURE_2D, e.TEXTURE_WRAP_T, e.CLAMP_TO_EDGE),
                e.uniform1i(t, r),
                e.uniform2f(n, i.width, i.height);
              try {
                e.texImage2D(e.TEXTURE_2D, 0, e.RGBA, e.RGBA, e.UNSIGNED_BYTE, i);
              } catch (t) {
                e.texImage2D(e.TEXTURE_2D, 0, e.RGBA, i.width, i.height, 0, e.RGBA, e.UNSIGNED_BYTE, null);
              }
              return o;
            })(u, S, M, 0, t),
            N = t.width * n,
            F = t.height * n,
            j = -0.5 * N,
            W = 0.5 * F,
            q = 0.5 * N,
            H = -0.5 * F,
            Y = new Float32Array([j, W, j, H, q, W, q, H]),
            X = new Float32Array([0, 0, 0, 1, 1, 0, 1, 1]),
            Z = Y.length / 2,
            K = u.createBuffer();
          u.bindBuffer(u.ARRAY_BUFFER, K),
            u.bufferData(u.ARRAY_BUFFER, Y, u.STATIC_DRAW),
            u.bindBuffer(u.ARRAY_BUFFER, null);
          var Q = u.createBuffer();
          u.bindBuffer(u.ARRAY_BUFFER, Q),
            u.bufferData(u.ARRAY_BUFFER, X, u.STATIC_DRAW),
            u.bindBuffer(u.ARRAY_BUFFER, null);
          var J = 0,
            $ = 0,
            ee = {
              release: function () {
                (e.width = 1), (e.height = 1);
              },
              resize: function (t, a) {
                (e.width = t * n),
                  (e.height = a * n),
                  (e.style.width = ''.concat(t, 'px')),
                  (e.style.height = ''.concat(a, 'px')),
                  (r.width = t * n),
                  (r.height = a * n),
                  (i.x = 0.5 * r.width),
                  (i.y = 0.5 * r.height),
                  (o = r.width / r.height),
                  u.viewport(0, 0, u.canvas.width, u.canvas.height);
              },
              update: function (e, l, E, R, S, M, N, F, j, W, q, H, Y, X, te, ne, re, ie, oe) {
                var ae = q ? q.height * n : r.height;
                (J = t.width * n), ($ = t.height * n), (e *= n), (l *= n), (E *= n), (R *= n);
                var ce = ($ / 2 / c) * (r.height / ae) * -1;
                ce /= (-c * ce * 2) / r.height;
                var le = 0.5 * J,
                  ue = 0.5 * $;
                (e -= le), (l -= ue);
                var se = F,
                  fe = -(i.x - le) + E,
                  de = i.y - ue - R,
                  he = pi();
                gi(he, a, o, 1, 2 * -ce),
                  mi(he, [fe, de, ce]),
                  mi(he, [e, -l, 0]),
                  vi(he, [se, se, se]),
                  wi(he, -N),
                  mi(he, [-e, l, 0]),
                  Ei(he, M),
                  yi(he, S),
                  u.clearColor(X[0], X[1], X[2], 1),
                  u.clear(u.COLOR_BUFFER_BIT);
                var pe = H.x * n,
                  ge = H.y * n,
                  me = H.width * n,
                  ve = H.height * n,
                  ye = pe,
                  Ee = ye + me,
                  we = r.height - ge,
                  _e = r.height - (ge + ve);
                u.useProgram(s),
                  u.uniform3fv(f, te),
                  u.uniform3fv(d, ne),
                  u.uniform4fv(
                    v,
                    oe.map(function (e, t) {
                      return t < 3 ? e / 255 : e;
                    }),
                  ),
                  u.uniform2f(h, r.width, r.height),
                  u.uniform2f(g, ye, we),
                  u.uniform2f(m, Ee, _e),
                  u.bindBuffer(u.ARRAY_BUFFER, y),
                  u.vertexAttribPointer(p, 2, u.FLOAT, !1, 0, 0),
                  u.enableVertexAttribArray(p),
                  u.drawArrays(u.TRIANGLE_STRIP, 0, 4),
                  u.useProgram(I),
                  u.bindFramebuffer(u.FRAMEBUFFER, null),
                  u.bindTexture(u.TEXTURE_2D, z),
                  u.bindBuffer(u.ARRAY_BUFFER, K),
                  u.vertexAttribPointer(V, 2, u.FLOAT, !1, 0, 0),
                  u.enableVertexAttribArray(V),
                  u.bindBuffer(u.ARRAY_BUFFER, Q),
                  u.vertexAttribPointer(B, 2, u.FLOAT, !1, 0, 0),
                  u.enableVertexAttribArray(B),
                  u.uniformMatrix4fv(C, !1, he),
                  u.uniform2f(P, ye, we),
                  u.uniform2f(k, Ee, _e),
                  u.uniform4fv(L, Y),
                  u.uniform1f(G, W),
                  u.uniform4f(D, j[4], j[9], j[14], j[19]),
                  u.uniformMatrix4fv(
                    U,
                    !1,
                    [].concat(_i(j.slice(0, 4)), _i(j.slice(5, 9)), _i(j.slice(10, 14)), _i(j.slice(15, 19))),
                  ),
                  u.drawArrays(u.TRIANGLE_STRIP, 0, Z),
                  u.useProgram(w),
                  u.uniform1f(T, re),
                  u.uniform4fv(b, ie),
                  u.uniform2f(A, ye, we),
                  u.uniform2f(x, Ee, _e),
                  u.bindBuffer(u.ARRAY_BUFFER, O),
                  u.vertexAttribPointer(_, 2, u.FLOAT, !1, 0, 0),
                  u.enableVertexAttribArray(_),
                  u.drawArrays(u.TRIANGLE_STRIP, 0, 4),
                  ee.onupdate(u);
              },
              onupdate: function () {},
            };
          return ee;
        },
        Ai = function (e) {
          var t = 0,
            n = {},
            r = x(e),
            i = x(e),
            o = x(e),
            a = x(e);
          return (
            (r.onupdate = function (e) {
              return (n.x = e);
            }),
            (r.oncomplete = function () {
              return t++;
            }),
            (i.onupdate = function (e) {
              return (n.y = e);
            }),
            (i.oncomplete = function () {
              return t++;
            }),
            (o.onupdate = function (e) {
              return (n.width = e);
            }),
            (o.oncomplete = function () {
              return t++;
            }),
            (a.onupdate = function (e) {
              return (n.height = e);
            }),
            (a.oncomplete = function () {
              return t++;
            }),
            {
              interpolate: function (e) {
                r.interpolate(e), i.interpolate(e), o.interpolate(e), a.interpolate(e);
              },
              setTarget: function (e) {
                (t = 0),
                  (r.target = e ? e.x : null),
                  (i.target = e ? e.y : null),
                  (o.target = e ? e.width : null),
                  (a.target = e ? e.height : null);
              },
              getRect: function () {
                return n;
              },
              isStable: function () {
                return 4 === t;
              },
            }
          );
        },
        xi = function (e) {
          var t = 0,
            n = {},
            r = x(e),
            i = x(e),
            o = x(e);
          return (
            (r.onupdate = function (e) {
              return (n.r = e);
            }),
            (r.oncomplete = function () {
              return t++;
            }),
            (i.onupdate = function (e) {
              return (n.g = e);
            }),
            (i.oncomplete = function () {
              return t++;
            }),
            (o.onupdate = function (e) {
              return (n.b = e);
            }),
            (o.oncomplete = function () {
              return t++;
            }),
            {
              interpolate: function (e) {
                r.interpolate(e), i.interpolate(e), o.interpolate(e);
              },
              setTarget: function (e) {
                (t = 0), (r.target = e ? e[0] : null), (i.target = e ? e[1] : null), (o.target = e ? e[2] : null);
              },
              getColor: function () {
                return [n.r, n.g, n.b];
              },
              isStable: function () {
                return 3 === t;
              },
            }
          );
        },
        Oi = { stiffness: 0.25, damping: 0.25, mass: 2.5 },
        Ri = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0],
        Ii = X({
          name: 'image-gl',
          ignoreRect: !0,
          ignoreRectUpdate: !0,
          mixins: {
            apis: [
              'top',
              'left',
              'width',
              'height',
              'xOrigin',
              'yOrigin',
              'xTranslation',
              'yTranslation',
              'xRotation',
              'yRotation',
              'zRotation',
              'scale',
              'overlay',
              'stage',
              'colorMatrix',
              'colorOpacity',
              'overlayOpacity',
              'outlineWidth',
              'isDraft',
            ],
            animations: {
              xTranslation: qr,
              yTranslation: qr,
              xOrigin: qr,
              yOrigin: qr,
              scale: qr,
              xRotation: { type: 'spring', stiffness: 0.25, damping: 0.25, mass: 2.5 },
              yRotation: { type: 'spring', stiffness: 0.25, damping: 0.25, mass: 2.5 },
              zRotation: { type: 'spring', stiffness: 0.25, damping: 0.25, mass: 2.5 },
              colorOpacity: { type: 'tween', delay: 150, duration: 750 },
              overlayOpacity: 'spring',
              introScale: { type: 'spring', stiffness: 0.25, damping: 0.75, mass: 15 },
              outlineWidth: qr,
            },
          },
          create: function (e) {
            var t = e.root;
            (t.ref.canvas = document.createElement('canvas')),
              (t.ref.canvas.width = 0),
              (t.ref.canvas.height = 0),
              t.appendChild(t.ref.canvas),
              (t.ref.gl = null),
              (t.introScale = 1),
              (t.ref.isPreview = 'preview' === t.query('GET_STYLE_LAYOUT_MODE')),
              (t.ref.shouldZoom = !t.ref.isPreview),
              (t.ref.didZoom = !1),
              (t.ref.backgroundColor = null),
              (t.ref.backgroundColorSpring = xi(Oi)),
              (t.ref.backgroundColorCenter = null),
              (t.ref.backgroundColorCenterSpring = xi(Oi)),
              (t.ref.overlaySpring = Ai(qr)),
              (t.ref.stageSpring = Ai(qr)),
              (t.ref.outlineSpring = x(qr)),
              (t.ref.colorMatrixSpring = []),
              (t.ref.colorMatrixStable = !0),
              (t.ref.colorMatrixStableCount = 0),
              (t.ref.colorMatrixPositions = []);
            for (var n = 0; n < 20; n++)
              !(function () {
                var e = n,
                  r = x(Oi);
                (r.target = Ri[e]),
                  (r.onupdate = function (n) {
                    t.ref.colorMatrixPositions[e] = n;
                  }),
                  (r.oncomplete = function () {
                    t.ref.colorMatrixStableCount++;
                  }),
                  (t.ref.colorMatrixSpring[e] = r);
              })();
            t.ref.dragger = Wr(
              t.element,
              function () {
                t.dispatch('CROP_IMAGE_DRAG_GRAB');
              },
              function (e, n) {
                t.dispatch('CROP_IMAGE_DRAG', { value: n });
              },
              function () {
                t.dispatch('CROP_IMAGE_DRAG_RELEASE');
              },
              { cancelOnMultiple: !0 },
            );
            var r = 0,
              i = 0;
            t.ref.keyboard = ui(
              t.element,
              function () {
                return (r = 0), (i = 0), { x: 0, y: 0 };
              },
              {
                up: function (e) {
                  e.y -= 20;
                },
                down: function (e) {
                  e.y += 20;
                },
                left: function (e) {
                  e.x -= 20;
                },
                right: function (e) {
                  e.x += 20;
                },
                plus: function () {
                  (r += 0.1),
                    t.dispatch('CROP_IMAGE_RESIZE_AMOUNT', { value: r }),
                    t.dispatch('CROP_IMAGE_RESIZE_RELEASE');
                },
                minus: function () {
                  (r -= 0.1),
                    t.dispatch('CROP_IMAGE_RESIZE_AMOUNT', { value: r }),
                    t.dispatch('CROP_IMAGE_RESIZE_RELEASE');
                },
                left_bracket: function () {
                  (i -= Math.PI / 128), t.dispatch('CROP_IMAGE_ROTATE_ADJUST', { value: i });
                },
                right_bracket: function () {
                  (i += Math.PI / 128), t.dispatch('CROP_IMAGE_ROTATE_ADJUST', { value: i });
                },
                h: function () {
                  t.dispatch('CROP_IMAGE_FLIP_HORIZONTAL');
                },
                l: function () {
                  t.dispatch('CROP_IMAGE_ROTATE_LEFT');
                },
                q: function () {
                  t.dispatch('CROP_RESET');
                },
                r: function () {
                  t.dispatch('CROP_IMAGE_ROTATE_RIGHT');
                },
                v: function () {
                  t.dispatch('CROP_IMAGE_FLIP_VERTICAL');
                },
                z: function () {
                  t.dispatch('CROP_ZOOM');
                },
              },
              function (e) {
                e && t.dispatch('CROP_IMAGE_DRAG', { value: e });
              },
              function (e) {
                e && t.dispatch('CROP_IMAGE_DRAG_RELEASE');
              },
            );
            var o = t.query('GET_FILE'),
              a = URL.createObjectURL(o.data),
              c = function (e) {
                var n = Un(e, {
                    width: t.query('GET_MAX_IMAGE_PREVIEW_WIDTH'),
                    height: t.query('GET_MAX_IMAGE_PREVIEW_HEIGHT'),
                  }),
                  r = si(e, n.width, n.height, o.orientation),
                  i = Math.max(1, 0.75 * window.devicePixelRatio),
                  a = r.height / r.width,
                  c = 96 * i,
                  l = si(r, a > 1 ? c : c / a, a > 1 ? c * a : c),
                  u = r.getContext('2d').getImageData(0, 0, r.width, r.height),
                  s = l.getContext('2d').getImageData(0, 0, l.width, l.height);
                kt(r), kt(l), (t.ref.gl = bi(t.ref.canvas, u, i));
                var f = t.query('GET_OUTPUT_CANVAS_SYNC_TARGET');
                f &&
                  (t.ref.gl.onupdate = function () {
                    var e = t.ref.overlaySpring.getRect();
                    f.getContext('2d').drawImage(
                      t.ref.canvas,
                      e.x * i,
                      e.y * i,
                      e.width * i,
                      e.height * i,
                      0,
                      0,
                      f.width,
                      f.height,
                    );
                  }),
                  t.ref.gl
                    ? (t.dispatch('DID_RECEIVE_IMAGE_DATA', { previewData: u, thumbData: s }),
                      t.dispatch('DID_PRESENT_IMAGE'))
                    : t.dispatch('MISSING_WEBGL');
              },
              l = function () {
                var e;
                ((e = a),
                new Promise(function (t, n) {
                  var r = new Image();
                  (r.onload = function () {
                    t(r);
                  }),
                    (r.onerror = function (e) {
                      n(e);
                    }),
                    (r.src = e);
                })).then(c);
              };
            if (
              (function (e) {
                var t = window.navigator.userAgent.match(/Firefox\/([0-9]+)\./);
                return (
                  !((t ? parseInt(t[1]) : null) <= 58) &&
                  'createImageBitmap' in window &&
                  (function (e) {
                    return /^image/.test(e.type) && !/svg/.test(e.type);
                  })(e)
                );
              })(o.data)
            ) {
              var u = sn(fi);
              u.post({ file: o.data }, function (e) {
                u.terminate(), e ? c(e) : l();
              });
            } else l();
            (t.ref.canvasStyle = getComputedStyle(t.ref.canvas)),
              t.ref.previousBackgroundColor,
              t.ref.previousLeft,
              t.ref.previousTop,
              t.ref.previousWidth,
              t.ref.previousHeight,
              (t.element.dataset.showInteractionIndicator = !1),
              (t.ref.handleFocus = function (e) {
                9 === e.keyCode && (t.element.dataset.showInteractionIndicator = !0);
              }),
              (t.ref.handleBlur = function (e) {
                t.element.dataset.showInteractionIndicator = !1;
              }),
              k(t.element)('keyup', t.ref.handleFocus),
              k(t.element)('blur', t.ref.handleBlur);
          },
          destroy: function (e) {
            var t = e.root;
            t.ref.gl && (t.ref.gl.release(), (t.ref.gl = null)),
              t.ref.dragger.destroy(),
              G(t.element)('keyup', t.ref.handleFocus),
              G(t.element)('blur', t.ref.handleBlur);
          },
          read: function (e) {
            var t = e.root,
              n = t.ref.canvasStyle.backgroundColor,
              r = t.ref.canvasStyle.color;
            if (
              (('transparent' !== r && '' !== r) || (r = null),
              ('transparent' !== n && '' !== n) || (n = null),
              n && n !== t.ref.previousBackgroundColor)
            ) {
              var i = dt(n).map(function (e) {
                  return e / 255;
                }),
                o = (i[0] + i[1] + i[2]) / 3;
              (t.ref.backgroundColor = i),
                (t.ref.backgroundColorCenter = i.map(function (e) {
                  return o > 0.5 ? e - 0.15 : e + 0.15;
                })),
                (t.ref.previousBackgroundColor = n);
            }
            r &&
              r !== t.ref.previousOutlineColor &&
              ((t.ref.outlineColor = dt(r)
                .map(function (e) {
                  return e / 255;
                })
                .concat(1)),
              (t.ref.previousOutlineColor = r));
          },
          write: Z(
            {
              SHOW_VIEW: function (e) {
                var t = e.root;
                'crop' === e.action.id
                  ? (t.ref.dragger.enable(), t.element.setAttribute('tabindex', '0'))
                  : (t.ref.dragger.disable(), t.element.removeAttribute('tabindex'));
              },
            },
            function (e) {
              var t = e.root,
                n = e.props,
                r = (e.actions, e.timestamp);
              if (t.ref.gl && n.width && n.height) {
                var i = t.ref,
                  o = i.gl,
                  a = i.previousWidth,
                  c = i.previousHeight,
                  l = i.shouldZoom,
                  u = i.stageSpring,
                  s = i.overlaySpring,
                  f = i.backgroundColorSpring,
                  d = i.backgroundColorCenterSpring;
                (n.width === a && n.height === c) ||
                  (t.ref.gl.resize(n.width, n.height),
                  (t.ref.previousWidth = n.width),
                  (t.ref.previousHeight = n.height)),
                  (n.left === t.ref.previousLeft && n.top === t.ref.previousTop) ||
                    ((t.ref.canvas.style.transform = 'translate('.concat(-n.left, 'px, ').concat(-n.top, 'px)')),
                    (t.ref.previousLeft = n.left),
                    (t.ref.previousTop = n.top)),
                  l &&
                    !t.ref.didZoom &&
                    ((t.introScale = null), (t.introScale = 1.15), (t.introScale = 1), (t.ref.didZoom = !0)),
                  f.setTarget(t.ref.backgroundColor),
                  f.interpolate(r);
                var h = f.isStable();
                d.setTarget(t.ref.backgroundColorCenter), d.interpolate(r);
                var p = d.isStable();
                t.ref.colorMatrixStableCount = 0;
                var g = n.colorMatrix || Ri,
                  m = t.ref.colorMatrixSpring.map(function (e, n) {
                    return (e.target = g[n]), e.interpolate(r), t.ref.colorMatrixPositions[n];
                  }),
                  v = 20 === t.ref.colorMatrixStableCount;
                n.isDraft && s.setTarget(null), s.setTarget(n.overlay), s.interpolate(r);
                var y = s.isStable();
                n.isDraft && u.setTarget(null), u.setTarget(n.stage), u.interpolate(r);
                var E = u.isStable();
                return (
                  o.update(
                    t.xOrigin,
                    t.yOrigin,
                    t.xTranslation + n.left,
                    t.yTranslation + n.top,
                    t.xRotation,
                    t.yRotation,
                    t.zRotation,
                    t.scale * t.introScale,
                    m,
                    t.ref.isPreview ? 1 : t.colorOpacity,
                    u.getRect(),
                    s.getRect(),
                    [1, 1, 1, 1 - t.overlayOpacity],
                    f.getColor(),
                    d.getColor(),
                    f.getColor(),
                    t.outlineWidth,
                    t.ref.outlineColor,
                    t.query('GET_BACKGROUND_COLOR'),
                  ),
                  y && E && v && h && p
                );
              }
            },
          ),
        });
      function Ci(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var Si = X({
        name: 'image',
        ignoreRect: !0,
        mixins: { apis: ['offsetTop'] },
        create: function (e) {
          var t = e.root,
            n = e.props;
          (t.ref.imageGL = t.appendChildView(t.createChildView(Ii))),
            /markup|sticker/.test(t.query('GET_UTILS')) &&
              (t.ref.markup = t.appendChildView(
                t.createChildView(ri, {
                  id: n.id,
                  opacity: 0,
                  onSelect: function (e) {
                    t.dispatch('MARKUP_SELECT', { id: e });
                  },
                  onDrag: function (e, n, r, i, o) {
                    t.dispatch('MARKUP_ELEMENT_DRAG', { id: e, origin: n, offset: r, size: i, scale: o });
                  },
                  onResize: function (e, n, r, i, o) {
                    t.dispatch('MARKUP_ELEMENT_RESIZE', { id: e, corner: n, origin: r, offset: i, size: o });
                  },
                  onUpdate: function (e, n) {
                    t.dispatch('MARKUP_UPDATE', { style: e, value: n });
                  },
                  isMarkupUtil: function (e) {
                    var t = e;
                    do {
                      if ('doka--markup-tools' === t.className) return !0;
                    } while ((t = t.parentNode));
                    return !1;
                  },
                }),
              )),
            (t.ref.isModal = /modal/.test(t.query('GET_STYLE_LAYOUT_MODE')));
        },
        write: Z(
          {
            DID_PRESENT_IMAGE: function (e) {
              e.root.ref.imageGL.colorOpacity = 1;
            },
          },
          function (e) {
            var t = e.root,
              n = e.props,
              r = e.timestamp,
              i = t.ref.imageGL,
              o = t.ref.markup,
              a = t.query('GET_CROP', n.id, r);
            if (a) {
              var c = a.isDraft,
                l = a.cropRect,
                u = a.cropStatus,
                s = a.origin,
                f = a.translation,
                d = a.translationBand,
                h = a.scale,
                p = a.scaleBand,
                g = a.rotation,
                m = a.rotationBand,
                v = a.flip,
                y = a.colorMatrix,
                E = t.query('GET_ROOT'),
                w = t.query('GET_STAGE'),
                _ = w.x,
                T = w.y;
              c &&
                ((i.scale = null),
                (i.zRotation = null),
                (i.xTranslation = null),
                (i.yTranslation = null),
                (i.xOrigin = null),
                (i.yOrigin = null)),
                (i.colorMatrix = y);
              var b = t.query('IS_ACTIVE_VIEW', 'crop'),
                A = t.query('IS_ACTIVE_VIEW', 'markup') || t.query('IS_ACTIVE_VIEW', 'sticker'),
                x = b ? 0.75 : 0.95,
                O = (function (e) {
                  for (var t = 1; t < arguments.length; t++) {
                    var n = null != arguments[t] ? arguments[t] : {},
                      r = Object.keys(n);
                    'function' == typeof Object.getOwnPropertySymbols &&
                      (r = r.concat(
                        Object.getOwnPropertySymbols(n).filter(function (e) {
                          return Object.getOwnPropertyDescriptor(n, e).enumerable;
                        }),
                      )),
                      r.forEach(function (t) {
                        Ci(e, t, n[t]);
                      });
                  }
                  return e;
                })({}, l),
                R = 1,
                I = b ? 1 : 5;
              if (t.query('IS_ACTIVE_VIEW', 'resize')) {
                var C = u.image.width,
                  S = u.image.height;
                (R = null === C && null === S ? u.crop.width / l.width : null === C ? S / l.height : C / l.width),
                  (R /= window.devicePixelRatio);
                var M = l.width * R,
                  L = l.height * R;
                (O.x = O.x + (0.5 * l.width - 0.5 * M)),
                  (O.y = O.y + (0.5 * l.height - 0.5 * L)),
                  (O.width = M),
                  (O.height = L);
              }
              var P = t.ref.isModal ? 0 : E.left,
                k = t.ref.isModal ? 0 : E.top,
                G = t.ref.isModal ? 0 : E.width - t.rect.element.width,
                D = t.ref.isModal ? 0 : E.height - t.rect.element.height - n.offsetTop,
                U = (h + p) * R;
              (i.isDraft = c),
                (i.overlayOpacity = x),
                (i.xOrigin = s.x),
                (i.yOrigin = s.y),
                (i.xTranslation = f.x + d.x + _),
                (i.yTranslation = f.y + d.y + T),
                (i.left = P),
                (i.top = k + n.offsetTop),
                (i.width = t.rect.element.width + G),
                (i.height = t.rect.element.height + D + n.offsetTop),
                (i.scale = U),
                (i.xRotation = v.vertical ? Math.PI : 0),
                (i.yRotation = v.horizontal ? Math.PI : 0),
                (i.zRotation = g.main + g.sub + m),
                (i.stage = { x: w.x + P, y: w.y + k + n.offsetTop, width: w.width, height: w.height }),
                (i.overlay = { x: O.x + _ + P, y: O.y + T + k + n.offsetTop, width: O.width, height: O.height }),
                (i.outlineWidth = I),
                o &&
                  (c &&
                    ((o.translateX = null),
                    (o.translateY = null),
                    (o.markupX = null),
                    (o.markupY = null),
                    (o.markupWidth = null),
                    (o.markupHeight = null)),
                  (o.opacity = b ? 0.3 : 1),
                  (o.stageOffsetX = _),
                  (o.stageOffsetY = T),
                  (o.markupX = O.x + _),
                  (o.markupY = O.y + T),
                  (o.markupWidth = O.width),
                  (o.markupHeight = O.height),
                  (o.allowInteraction = A));
            }
          },
        ),
      });
      function Mi(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              Li(e, t, n[t]);
            });
        }
        return e;
      }
      function Li(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      function Pi(e) {
        return (
          (function (e) {
            if (Array.isArray(e)) {
              for (var t = 0, n = new Array(e.length); t < e.length; t++) n[t] = e[t];
              return n;
            }
          })(e) ||
          (function (e) {
            if (Symbol.iterator in Object(e) || '[object Arguments]' === Object.prototype.toString.call(e))
              return Array.from(e);
          })(e) ||
          (function () {
            throw new TypeError('Invalid attempt to spread non-iterable instance');
          })()
        );
      }
      var ki = function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 'group',
          t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : ['opacity'],
          n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
        return X({
          ignoreRect: !0,
          name: e,
          mixins: {
            styles: ['opacity'].concat(Pi(t)),
            animations: Mi({ opacity: { type: 'spring', stiffness: 0.25, damping: 0.5, mass: 5 } }, n),
          },
          create: function (e) {
            var t = e.root,
              n = e.props;
            (n.controls || []).map(function (e) {
              var n = t.createChildView(e.view, e);
              e.didCreateView && e.didCreateView(n), t.appendChildView(n);
            }),
              n.element && t.element.appendChild(n.element);
          },
        });
      };
      function Gi(e) {
        return (Gi =
          'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
            ? function (e) {
                return typeof e;
              }
            : function (e) {
                return e && 'function' == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype
                  ? 'symbol'
                  : typeof e;
              })(e);
      }
      function Di(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              Ui(e, t, n[t]);
            });
        }
        return e;
      }
      function Ui(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var Vi = X({
          ignoreRect: !0,
          tag: 'div',
          name: 'dropdown-list',
          mixins: {
            styles: ['translateY', 'opacity'],
            apis: ['selectedValue', 'options', 'onSelect'],
            animations: { translateY: 'spring', opacity: { type: 'tween', duration: 250 } },
          },
          create: function (e) {
            var t = e.root,
              n = e.props;
            t.element.setAttribute('role', 'list'),
              (t.ref.handleClick = function () {
                return n.action && n.action();
              }),
              t.element.addEventListener('click', t.ref.handleClick),
              (t.ref.activeOptions = null),
              t.ref.activeSelectedValue;
          },
          write: function (e) {
            var t = e.root,
              n = e.props;
            if (
              (n.options !== t.ref.activeOptions &&
                ((t.ref.activeOptions = n.options),
                t.childViews.forEach(function (e) {
                  return t.removeChildView(e);
                }),
                n.options.map(function (e) {
                  var r = t.createChildView(
                    Sr,
                    Di({}, e, {
                      action: function () {
                        return n.onSelect(e.value);
                      },
                    }),
                  );
                  return t.appendChildView(r);
                })),
              n.selectedValue !== t.ref.activeSelectedValue)
            ) {
              t.ref.activeSelectedValue = n.selectedValue;
              var r = n.options.findIndex(function (e) {
                return 'object' === Gi(e.value) && n.selectedValue
                  ? JSON.stringify(e.value) === JSON.stringify(n.selectedValue)
                  : e.value === n.selectedValue;
              });
              t.childViews.forEach(function (e, t) {
                e.element.setAttribute('aria-selected', t === r);
              });
            }
          },
          destroy: function (e) {
            var t = e.root;
            t.element.removeEventListener('click', t.ref.handleClick);
          },
        }),
        Bi = X({
          ignoreRect: !0,
          tag: 'div',
          name: 'dropdown',
          mixins: {
            styles: ['opacity'],
            animations: { opacity: 'spring' },
            apis: ['direction', 'selectedValue', 'options', 'onSelect'],
          },
          create: function (e) {
            var t = e.root,
              n = e.props;
            t.ref.open = !1;
            var r = function (e) {
              (t.ref.open = e), t.dispatch('KICK');
            };
            (t.ref.button = t.appendChildView(
              t.createChildView(
                Sr,
                Di({}, n, {
                  action: function () {
                    r(!t.ref.open);
                  },
                }),
              ),
            )),
              (t.ref.list = t.appendChildView(
                t.createChildView(
                  Vi,
                  Di({}, n, {
                    opacity: 0,
                    action: function () {
                      r(!1);
                    },
                  }),
                ),
              )),
              (t.ref.handleBodyClick = function (e) {
                jr(t.element, e.target) || r(!1);
              }),
              t.element.addEventListener('focusin', function (e) {
                e.target !== t.ref.button.element && r(!0);
              }),
              t.element.addEventListener('focusout', function (e) {
                e.relatedTarget && (jr(t.element, e.relatedTarget) || r(!1));
              }),
              document.body.addEventListener('click', t.ref.handleBodyClick);
          },
          destroy: function (e) {
            var t = e.root;
            document.body.removeEventListener('click', t.ref.handleBodyClick);
          },
          write: function (e) {
            var t = e.root,
              n = e.props;
            if (
              ((t.ref.list.opacity = t.ref.open ? 1 : 0),
              (t.ref.list.selectedValue = n.selectedValue),
              (t.ref.list.options = n.options),
              'up' === n.direction)
            ) {
              var r = t.ref.list.rect.element.height;
              t.ref.list.translateY = (t.ref.open ? -(r + 5) : -r) - t.rect.element.height;
            } else t.ref.list.translateY = t.ref.open ? 0 : -5;
          },
        }),
        zi = X({
          name: 'crop-rotator-line',
          ignoreRect: !0,
          ignoreRectUpdate: !0,
          mixins: { styles: ['translateX'], animations: { translateX: 'spring' } },
          create: function (e) {
            for (
              var t = e.root,
                n =
                  '<svg viewBox="-90 -5 180 10" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">',
                r = 0;
              r <= 180;
              r += 2
            ) {
              var i = r * (176 / 180) - 90 + 2,
                o = r % 10 == 0 ? 0.5 : 0.2;
              if (
                ((n += '<circle fill="currentColor" cx="'.concat(i, '" cy="').concat(0, '" r="').concat(o, '"/>')),
                r % 10 == 0)
              )
                n += '<text fill="currentColor" x="'
                  .concat(i + (i < 0 ? -2.25 : 0 === i ? -0.75 : -1.5), '" y="')
                  .concat(3.5, '">')
                  .concat(-90 + r, '&deg;</text>');
            }
            (n += '</svg>'), (t.element.innerHTML = n);
          },
        }),
        Ni = X({
          name: 'crop-rotator',
          ignoreRect: !0,
          mixins: {
            styles: ['opacity', 'translateY'],
            animations: { opacity: { type: 'spring', damping: 0.5, mass: 5 }, translateY: 'spring' },
            apis: ['rotation', 'animate', 'setAllowInteraction'],
          },
          create: function (e) {
            var t = e.root,
              n = e.props;
            t.element.setAttribute('tabindex', 0);
            var r = document.createElement('button');
            (r.innerHTML = '<span>'.concat(t.query('GET_LABEL_BUTTON_CROP_ROTATE_CENTER'), '</span>')),
              (r.className = 'doka--crop-rotator-center'),
              r.addEventListener('click', function () {
                t.dispatch('CROP_IMAGE_ROTATE_CENTER');
              }),
              t.appendChild(r);
            var i = null;
            t.appendChildView(
              t.createChildView(
                (function (e, t) {
                  return X({ name: e, ignoreRect: !0, create: t });
                })('crop-rotator-line-mask', function (e) {
                  var t = e.root,
                    n = e.props;
                  i = t.appendChildView(t.createChildView(zi, { translateX: Math.round(312 * n.rotation) }));
                }),
                n,
              ),
            ),
              (t.ref.line = i);
            var o = document.createElement('div');
            (o.className = 'doka--crop-rotator-bar'), t.appendChild(o);
            var a = Math.PI / 4,
              c = 0;
            (t.ref.dragger = Wr(
              o,
              function () {
                (c = i.translateX / 312), t.dispatch('CROP_IMAGE_ROTATE_GRAB');
              },
              function (e, n) {
                var r = (n.x / t.rect.element.width) * (Math.PI / 2),
                  i = Se(c + r, -a, a);
                t.dispatch('CROP_IMAGE_ROTATE', { value: -i });
              },
              function () {
                t.dispatch('CROP_IMAGE_ROTATE_RELEASE');
              },
              { stopPropagation: !0 },
            )),
              (n.setAllowInteraction = function (e) {
                e ? t.ref.dragger.enable() : t.ref.dragger.disable();
              }),
              (t.ref.keyboard = ui(
                t.element,
                function () {
                  c = 0;
                },
                {
                  left: function () {
                    (c += Math.PI / 128), t.dispatch('CROP_IMAGE_ROTATE_ADJUST', { value: c });
                  },
                  right: function () {
                    (c -= Math.PI / 128), t.dispatch('CROP_IMAGE_ROTATE_ADJUST', { value: c });
                  },
                },
                function () {},
                function () {},
              )),
              t.ref.prevRotation;
          },
          destroy: function (e) {
            var t = e.root;
            t.ref.dragger.destroy(), t.ref.keyboard.destroy();
          },
          write: function (e) {
            var t = e.root,
              n = e.props,
              r = e.timestamp,
              i = n.animate,
              o = n.rotation;
            if (t.ref.prevRotation !== o) {
              (t.ref.prevRotation = o), i || 0 === o || (t.ref.line.translateX = null);
              var a = 0,
                c = t.query('GET_CROP', n.id, r);
              if (c && c.interaction && c.interaction.rotation) {
                var l = Gn(c.interaction.rotation).sub - o;
                a = 0.025 * Math.sign(l) * Math.log10(1 + Math.abs(l) / 0.025);
              }
              t.ref.line.translateX = Math.round(312 * (-o - a));
            }
          },
        }),
        Fi = ['nw', 'ne', 'se', 'sw'],
        ji = ['n', 'e', 's', 'w'],
        Wi =
          W() && 1 === window.devicePixelRatio
            ? function (e) {
                return Math.round(e);
              }
            : function (e) {
                return e;
              },
        qi = X({
          ignoreRect: !0,
          ignoreRectUpdate: !0,
          name: 'crop-rect-focal-line',
          mixins: {
            styles: ['translateX', 'translateY', 'scaleX', 'scaleY', 'opacity'],
            animations: {
              translateX: 'spring',
              translateY: 'spring',
              scaleX: 'spring',
              scaleY: 'spring',
              opacity: 'spring',
            },
          },
        }),
        Hi = function (e) {
          return X({
            ignoreRect: !0,
            ignoreRectUpdate: !0,
            tag: 'div',
            name: 'crop-rect-edge-'.concat(e),
            mixins: { styles: ['translateX', 'translateY', 'scaleX', 'scaleY'], apis: ['setAllowInteraction'] },
            create: function (t) {
              var n = t.root,
                r = t.props;
              n.element.classList.add('doka--crop-rect-edge'),
                n.element.setAttribute('tabindex', 0),
                n.element.setAttribute('role', 'button');
              var i,
                o = e,
                a = ((i = e), ji[(ji.indexOf(i) + 2) % ji.length]);
              (n.ref.dragger = Wr(
                n.element,
                function () {
                  n.dispatch('CROP_RECT_DRAG_GRAB');
                },
                function (e, t) {
                  return n.dispatch('CROP_RECT_EDGE_DRAG', { offset: t, origin: o, anchor: a });
                },
                function () {
                  return n.dispatch('CROP_RECT_DRAG_RELEASE');
                },
                { stopPropagation: !0, cancelOnMultiple: !0 },
              )),
                (r.setAllowInteraction = function (e) {
                  e ? n.ref.dragger.enable() : n.ref.dragger.disable();
                }),
                (n.ref.keyboard = ui(
                  n.element,
                  function () {
                    return { x: 0, y: 0 };
                  },
                  {
                    up: function (e) {
                      e.y -= 20;
                    },
                    down: function (e) {
                      e.y += 20;
                    },
                    left: function (e) {
                      e.x -= 20;
                    },
                    right: function (e) {
                      e.x += 20;
                    },
                  },
                  function (e) {
                    n.dispatch('CROP_RECT_DRAG_GRAB'),
                      n.dispatch('CROP_RECT_EDGE_DRAG', { offset: e, origin: o, anchor: a });
                  },
                  function () {
                    n.dispatch('CROP_RECT_DRAG_RELEASE');
                  },
                ));
            },
            destroy: function (e) {
              var t = e.root;
              t.ref.keyboard.destroy(), t.ref.dragger.destroy();
            },
          });
        },
        Yi = function (e, t, n) {
          return X({
            ignoreRect: !0,
            ignoreRectUpdate: !0,
            tag: 'div',
            name: 'crop-rect-corner-'.concat(e),
            mixins: {
              styles: ['translateX', 'translateY', 'scaleX', 'scaleY'],
              animations: {
                translateX: qr,
                translateY: qr,
                scaleX: { type: 'spring', delay: n },
                scaleY: { type: 'spring', delay: n },
                opacity: { type: 'spring', delay: t },
              },
              apis: ['setAllowInteraction'],
            },
            create: function (t) {
              var n = t.root,
                r = t.props;
              n.element.classList.add('doka--crop-rect-corner'),
                n.element.setAttribute('role', 'button'),
                n.element.setAttribute('tabindex', -1);
              var i,
                o = e,
                a = ((i = e), Fi[(Fi.indexOf(i) + 2) % Fi.length]);
              (n.ref.dragger = Wr(
                n.element,
                function () {
                  n.dispatch('CROP_RECT_DRAG_GRAB');
                },
                function (e, t) {
                  n.dispatch('CROP_RECT_CORNER_DRAG', { offset: t, origin: o, anchor: a });
                },
                function () {
                  n.dispatch('CROP_RECT_DRAG_RELEASE');
                },
                { stopPropagation: !0, cancelOnMultiple: !0 },
              )),
                (r.setAllowInteraction = function (e) {
                  e ? n.ref.dragger.enable() : n.ref.dragger.disable();
                });
            },
            destroy: function (e) {
              e.root.ref.dragger.destroy();
            },
          });
        },
        Xi = X({
          ignoreRect: !0,
          ignoreRectUpdate: !0,
          name: 'crop-rect',
          mixins: { apis: ['rectangle', 'draft', 'rotating', 'enabled'] },
          create: function (e) {
            var t = e.root;
            t.ref.wasRotating = !1;
            Fi.forEach(function (e, n) {
              var r = 10 * n,
                i = 250 + r + 50,
                o = 250 + r;
              t.ref[e] = t.appendChildView(t.createChildView(Yi(e, i, o), { opacity: 0, scaleX: 0.5, scaleY: 0.5 }));
            }),
              ji.forEach(function (e) {
                t.ref[e] = t.appendChildView(t.createChildView(Hi(e)));
              }),
              (t.ref.lines = []);
            for (var n = 0; n < 10; n++) t.ref.lines.push(t.appendChildView(t.createChildView(qi, { opacity: 0 })));
            (t.ref.animationDir = null),
              t.ref.previousRotating,
              (t.ref.previousRect = {}),
              t.ref.previousEnabled,
              t.ref.previousDraft;
          },
          write: function (e) {
            var t = e.root,
              n = e.props,
              r = n.rectangle,
              i = n.draft,
              o = n.rotating,
              a = n.enabled;
            if (
              r &&
              (!Be(r, t.ref.previousRect) ||
                o !== t.ref.previousRotating ||
                a !== t.ref.previousEnabled ||
                i !== t.ref.previousDraft)
            ) {
              (t.ref.previousRect = r),
                (t.ref.previousRotating = o),
                (t.ref.previousEnabled = a),
                (t.ref.previousDraft = i);
              var c = t.ref,
                l = c.n,
                u = c.e,
                s = c.s,
                f = c.w,
                d = c.nw,
                h = c.ne,
                p = c.se,
                g = c.sw,
                m = c.lines,
                v = c.animationDir,
                y = r.x,
                E = r.y,
                w = r.x + r.width,
                _ = r.y + r.height,
                T = _ - E,
                b = w - y,
                A = Math.min(b, T);
              (t.element.dataset.indicatorSize = A < 80 ? 'none' : 'default'),
                ji.forEach(function (e) {
                  return t.ref[e].setAllowInteraction(a);
                }),
                Fi.forEach(function (e) {
                  return t.ref[e].setAllowInteraction(a);
                });
              var x = t.query('IS_ACTIVE_VIEW', 'crop');
              if (
                (x && 'in' !== v
                  ? ((t.ref.animationDir = 'in'),
                    Fi.map(function (e) {
                      return t.ref[e];
                    }).forEach(function (e) {
                      (e.opacity = 1), (e.scaleX = 1), (e.scaleY = 1);
                    }))
                  : x ||
                    'out' === v ||
                    ((t.ref.animationDir = 'out'),
                    Fi.map(function (e) {
                      return t.ref[e];
                    }).forEach(function (e) {
                      (e.opacity = 0), (e.scaleX = 0.5), (e.scaleY = 0.5);
                    })),
                Ki(i, d, y, E),
                Ki(i, h, w, E),
                Ki(i, p, w, _),
                Ki(i, g, y, _),
                Zi(i, l, y, E, b / 100, 1),
                Zi(i, u, w, E, 1, T / 100),
                Zi(i, s, y, _, b / 100, 1),
                Zi(i, f, y, E, 1, T / 100),
                o)
              ) {
                t.ref.wasRotating = !0;
                var O = m.slice(0, 5),
                  R = 1 / O.length;
                O.forEach(function (e, t) {
                  Zi(i, e, y, E + T * (R + t * R), b / 100, 0.01), (e.opacity = 0.5);
                });
                var I = m.slice(5);
                (R = 1 / I.length),
                  I.forEach(function (e, t) {
                    Zi(i, e, y + b * (R + t * R), E, 0.01, T / 100), (e.opacity = 0.5);
                  });
              } else if (i) {
                t.ref.wasRotating = !1;
                var C = m[0],
                  S = m[1],
                  M = m[2],
                  L = m[3];
                Zi(i, C, y, E + 0.333 * T, b / 100, 0.01),
                  Zi(i, S, y, E + 0.666 * T, b / 100, 0.01),
                  Zi(i, M, y + 0.333 * b, E, 0.01, T / 100),
                  Zi(i, L, y + 0.666 * b, E, 0.01, T / 100),
                  (C.opacity = 0.5),
                  (S.opacity = 0.5),
                  (M.opacity = 0.5),
                  (L.opacity = 0.5);
              } else {
                var P = m[0],
                  k = m[1],
                  G = m[2],
                  D = m[3];
                !t.ref.wasRotating &&
                  P.opacity > 0 &&
                  (Zi(i, P, y, E + 0.333 * T, b / 100, 0.01),
                  Zi(i, k, y, E + 0.666 * T, b / 100, 0.01),
                  Zi(i, G, y + 0.333 * b, E, 0.01, T / 100),
                  Zi(i, D, y + 0.666 * b, E, 0.01, T / 100)),
                  m.forEach(function (e) {
                    return (e.opacity = 0);
                  });
              }
            }
          },
        }),
        Zi = function (e, t, n, r, i, o) {
          e && ((t.translateX = null), (t.translateY = null), (t.scaleX = null), (t.scaleY = null)),
            (t.translateX = Wi(n)),
            (t.translateY = Wi(r)),
            (t.scaleX = i),
            (t.scaleY = o);
        },
        Ki = function (e, t, n, r) {
          e && ((t.translateX = null), (t.translateY = null)), (t.translateX = Wi(n)), (t.translateY = Wi(r));
        },
        Qi = function (e, t) {
          if (!/svg/.test(e.namespaceURI) || 'innerHTML' in e) e.innerHTML = t;
          else {
            var n = document.createElement('div');
            n.innerHTML = '<svg>' + t + '</svg>';
            for (var r = n.firstChild; r.firstChild; ) e.appendChild(r.firstChild);
          }
        },
        Ji = X({
          ignoreRect: !0,
          ignoreRectUpdate: !0,
          name: 'crop-mask',
          tag: 'svg',
          mixins: {
            styles: ['opacity', 'translateX', 'translateY'],
            animations: {
              scale: qr,
              maskWidth: qr,
              maskHeight: qr,
              translateX: qr,
              translateY: qr,
              opacity: { type: 'tween', delay: 0, duration: 1e3 },
            },
            apis: ['rectangle', 'animate', 'maskWidth', 'maskHeight', 'scale'],
          },
          create: function (e) {
            e.root.ref.writer = null;
          },
          write: function (e) {
            var t = e.root,
              n = t.query('GET_CROP_MASK');
            n !== t.ref.writer &&
              ((t.ref.writer = n), (t.ref.writerFn = n ? n(t.element, Qi) : null), t.ref.writer || Qi(t.element, ''));
          },
          didWriteView: function (e) {
            var t = e.root,
              n = e.props,
              r = n.maskWidth,
              i = n.maskHeight,
              o = n.scale;
            if (
              t.ref.writer &&
              r &&
              i &&
              (t.element.setAttribute('width', Wi(r)), t.element.setAttribute('height', Wi(i)), t.ref.writerFn)
            ) {
              var a = t.query('GET_CROP_MASK_INSET');
              t.ref.writerFn(
                { x: o * a, y: o * a, width: r - o * a * 2, height: i - o * a * 2 },
                { width: r, height: i },
              );
            }
          },
        }),
        $i = function (e, t) {
          var n = e.childNodes[0];
          n ? t !== n.nodeValue && (n.nodeValue = t) : ((n = document.createTextNode(t)), e.appendChild(n));
        },
        eo = { type: 'spring', stiffness: 0.25, damping: 0.1, mass: 1 },
        to = X({
          ignoreRect: !0,
          name: 'crop-size',
          mixins: {
            styles: ['translateX', 'translateY', 'opacity'],
            animations: {
              translateX: 'spring',
              translateY: 'spring',
              opacity: 'spring',
              sizeWidth: eo,
              sizeHeight: eo,
            },
            apis: ['sizeWidth', 'sizeHeight'],
            listeners: !0,
          },
          create: function (e) {
            var t = e.root,
              n = g('span');
            (n.className = 'doka--crop-size-info doka--crop-resize-percentage'),
              (t.ref.resizePercentage = n),
              t.appendChild(n);
            var r = g('span');
            r.className = 'doka--crop-size-info';
            var i = g('span');
            (i.className = 'doka--crop-size-multiply'), (i.textContent = '×');
            var o = g('span'),
              a = g('span');
            (t.ref.outputWidth = o),
              (t.ref.outputHeight = a),
              r.appendChild(o),
              r.appendChild(i),
              r.appendChild(a),
              t.appendChild(r),
              (t.ref.previousValues = { width: 0, height: 0, percentage: 0 });
          },
          write: function (e) {
            var t = e.root,
              n = e.props,
              r = e.timestamp;
            if (!(t.opacity <= 0)) {
              var i = t.query('GET_CROP', n.id, r);
              if (i) {
                var o = i.cropStatus,
                  a = i.isDraft,
                  c = t.ref,
                  l = c.outputWidth,
                  u = c.outputHeight,
                  s = c.resizePercentage,
                  f = c.previousValues,
                  d = o.image,
                  h = o.crop,
                  p = o.currentWidth,
                  g = o.currentHeight,
                  m = d.width ? Math.round((d.width / h.width) * 100) : 0;
                a && ((t.sizeWidth = null), (t.sizeHeight = null)), (t.sizeWidth = p), (t.sizeHeight = g);
                var v = Math.round(t.sizeWidth),
                  y = Math.round(t.sizeHeight);
                v !== f.width && ($i(l, v), (f.width = v)),
                  y !== f.height && ($i(u, y), (f.height = y)),
                  m !== f.percentage && (d.width ? $i(s, ''.concat(m, '%')) : $i(s, ''), (f.percentage = m));
              }
            }
          },
        }),
        no = function () {
          return console.log('Doka: localStorage not available');
        },
        ro = function (e) {
          try {
            JSON.parse(localStorage.getItem(e) || '{}');
          } catch (e) {
            no();
          }
          return {};
        },
        io = function () {
          return window.matchMedia('(pointer: fine) and (hover: hover)').matches;
        },
        oo = X({
          ignoreRect: !0,
          ignoreRectUpdate: !0,
          name: 'instructions-bubble',
          mixins: {
            styles: ['opacity', 'translateX', 'translateY'],
            animations: { opacity: { type: 'tween', duration: 400 } },
            apis: ['seen'],
          },
          create: function (e) {
            var t = e.root,
              n = e.props;
            return (t.element.innerHTML = (n.iconBefore || '') + n.text);
          },
          write: function (e) {
            var t = e.root;
            e.props.seen && (t.opacity = 0);
          },
        }),
        ao = { type: 'spring', stiffness: 0.4, damping: 0.65, mass: 7 },
        co = X({
          name: 'crop-subject',
          ignoreRect: !0,
          mixins: {
            styles: ['opacity', 'translateX', 'translateY'],
            animations: { opacity: { type: 'tween', duration: 250 }, translateX: ao, translateY: ao },
          },
          create: function (e) {
            var t,
              n,
              r,
              i,
              o = e.root,
              a = e.props;
            ((o.opacity = 1), (o.ref.timestampOffset = null), o.query('GET_CROP_ALLOW_INSTRUCTION_ZOOM') && io()) &&
              (((t = o.query('GET_STORAGE_NAME')),
              (n = 'instruction_zoom_shown'),
              (r = !1),
              void 0 === (i = ro(t))[n] ? r : i[n]) ||
                (o.ref.instructions = o.appendChildView(
                  o.createChildView(oo, {
                    opacity: 0,
                    seen: !1,
                    text: o.query('GET_LABEL_CROP_INSTRUCTION_ZOOM'),
                    iconBefore: Cr(
                      '<rect stroke-width="1.5" fill="none" stroke="currentColor" x="5" y="1" width="14" height="22" rx="7" ry="7"></rect><circle fill="currentColor" stroke="none" cx="12" cy="8" r="2"></circle>',
                    ),
                  }),
                )));
            (o.ref.maskView = o.appendChildView(o.createChildView(Ji))),
              o.query('GET_CROP_ALLOW_RESIZE_RECT') && (o.ref.cropView = o.appendChildView(o.createChildView(Xi))),
              o.query('GET_CROP_SHOW_SIZE') &&
                (o.ref.cropSize = o.appendChildView(
                  o.createChildView(to, { id: a.id, opacity: 1, scaleX: 1, scaleY: 1, translateX: null }),
                )),
              o.query('GET_CROP_ZOOM_TIMEOUT') ||
                (o.ref.btnZoom = o.appendChildView(
                  o.createChildView(
                    X({
                      ignoreRect: !0,
                      name: 'zoom-wrapper',
                      mixins: {
                        styles: ['opacity', 'translateX', 'translateY'],
                        animations: { opacity: { type: 'tween', duration: 250 } },
                      },
                      create: function (e) {
                        var t = e.root,
                          n = e.props;
                        n.className && t.element.classList.add(n.className),
                          n.controls.map(function (e) {
                            var n = t.createChildView(e.view, e);
                            e.didCreateView && e.didCreateView(n), t.appendChildView(n);
                          });
                      },
                    }),
                    {
                      opacity: 0,
                      controls: [
                        {
                          view: Sr,
                          label: o.query('GET_LABEL_BUTTON_CROP_ZOOM'),
                          name: 'zoom',
                          icon: Cr(
                            '<g fill="currentColor" fill-rule="nonzero"><path d="M12.5 19a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13zm0-2a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9z"/><path d="M15.765 17.18a1 1 0 1 1 1.415-1.415l3.527 3.528a1 1 0 0 1-1.414 1.414l-3.528-3.527z"/></g>',
                            26,
                          ),
                          action: function () {
                            return o.dispatch('CROP_ZOOM');
                          },
                        },
                      ],
                    },
                  ),
                ));
          },
          write: Z(
            {
              CROP_IMAGE_RESIZE_MULTIPLY: function (e) {
                var t = e.root,
                  n = t.ref.instructions;
                n &&
                  !n.seen &&
                  ((n.seen = !0),
                  (function (e, t, n) {
                    var r = ro(e);
                    r[t] = n;
                    try {
                      localStorage.setItem(e, JSON.stringify(r));
                    } catch (e) {
                      no();
                    }
                  })(t.query('GET_STORAGE_NAME'), 'instruction_zoom_shown', !0));
              },
              CROP_RECT_DRAG_RELEASE: function (e) {
                var t = e.root,
                  n = e.props,
                  r = e.timestamp,
                  i = t.ref.btnZoom;
                if (i) {
                  var o = t.query('GET_CROP', n.id, r).cropRect,
                    a = o.x + 0.5 * o.width,
                    c = o.y + 0.5 * o.height;
                  (i.translateX = a), (i.translateY = c);
                }
              },
            },
            function (e) {
              var t = e.root,
                n = e.props,
                r = e.timestamp,
                i = t.ref,
                o = i.cropView,
                a = i.maskView,
                c = i.btnZoom,
                l = i.cropSize,
                u = i.instructions;
              if (!t.query('IS_ACTIVE_VIEW', 'crop') && o)
                return (o.enabled = !1), (t.ref.timestampOffset = null), void (l && (l.opacity = 0));
              t.ref.timestampOffset || (t.ref.timestampOffset = r);
              var s = t.query('GET_CROP', n.id, r);
              if (s) {
                var f = s.cropRect,
                  d = s.isRotating,
                  h = s.isDraft,
                  p = s.scale,
                  g = t.query('GET_STAGE');
                if (
                  ((t.translateX = g.x - t.rect.element.left),
                  (t.translateY = g.y - t.rect.element.top),
                  o && ((o.draft = h), (o.rotating = d), (o.rectangle = f), (o.enabled = !0)),
                  l)
                ) {
                  (l.opacity = 1), h && ((l.translateX = null), (l.translateY = null));
                  var m = lo(t.rect.element, l.rect.element, f);
                  (l.translateX = h ? m.x : Wi(m.x)), (l.translateY = h ? m.y : Wi(m.y));
                }
                if (
                  (t.query('GET_CROP_MASK') &&
                    (h && ((a.translateX = null), (a.translateY = null), (a.maskWidth = null), (a.maskHeight = null)),
                    (a.translateX = Wi(f.x)),
                    (a.translateY = Wi(f.y)),
                    (a.maskWidth = f.width),
                    (a.maskHeight = f.height),
                    (a.scale = p)),
                  s.canRecenter)
                )
                  u && (u.opacity = 0), c && (c.opacity = s.isDraft ? 0 : 1);
                else if ((c && (c.opacity = 0), u && !u.seen && !s.isDraft)) {
                  var v = f.x + 0.5 * f.width,
                    y = f.y + 0.5 * f.height;
                  (u.translateX = Math.round(v - 0.5 * u.rect.element.width)),
                    (u.translateY = Math.round(y - 0.5 * u.rect.element.height)),
                    r - t.ref.timestampOffset > 2e3 && (u.opacity = 1);
                }
              }
            },
          ),
        }),
        lo = function (e, t, n) {
          var r = n.x,
            i = n.x + n.width,
            o = n.y + n.height,
            a = i - t.width - 16,
            c = o - t.height - 16;
          return (
            t.width > n.width - 32 &&
              ((a = r + (0.5 * n.width - 0.5 * t.width)),
              (c = o + 16) > e.height - t.height && (c = o - t.height - 16)),
            { x: (a = Math.max(0, Math.min(a, e.width - t.width))), y: c }
          );
        },
        uo = function () {
          return performance.now();
        },
        so = function (e, t) {
          for (; 1 === e.nodeType && !t(e); ) e = e.parentNode;
          return 1 === e.nodeType ? e : null;
        };
      function fo(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              ho(e, t, n[t]);
            });
        }
        return e;
      }
      function ho(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var po = function (e, t) {
          var n = so(t, function (e) {
            return e.classList.contains('doka--root');
          });
          return !!n && jr(n, e);
        },
        go = function (e) {
          var t = e.root,
            n = e.props,
            r = e.action.position,
            i = n.pivotPoint,
            o = t.ref,
            a = o.indicatorA,
            c = o.indicatorB,
            l = i.x - r.x,
            u = i.y - r.y,
            s = { x: i.x + l, y: i.y + u },
            f = { x: i.x - l, y: i.y - u };
          (a.style.cssText = 'transform: translate3d('.concat(s.x, 'px, ').concat(s.y, 'px, 0)')),
            (c.style.cssText = 'transform: translate3d('.concat(f.x, 'px, ').concat(f.y, 'px, 0)'));
        },
        mo = function (e) {
          return { x: e.pageX, y: e.pageY };
        },
        vo = X({
          ignoreRect: !0,
          ignoreRectUpdate: !0,
          name: 'crop-resizer',
          mixins: { apis: ['pivotPoint', 'scrollRect'] },
          create: function (e) {
            var t = e.root,
              n = e.props;
            (t.ref.isActive = !1),
              (t.ref.isCropping = !1),
              (t.ref.indicatorA = document.createElement('div')),
              t.appendChild(t.ref.indicatorA),
              (t.ref.indicatorB = document.createElement('div')),
              t.appendChild(t.ref.indicatorB);
            var r = t.query('GET_CROP_RESIZE_KEY_CODES');
            t.ref.hasEnabledResizeModifier = r.length > 0;
            var i = {
                origin: { x: null, y: null },
                position: { x: null, y: null },
                selecting: !1,
                enabled: !1,
                scrollY: 0,
                offsetX: 0,
                offsetY: 0,
              },
              o = uo();
            t.ref.state = i;
            var a = zr(),
              c = 0,
              l = !1;
            (t.ref.resizeStart = function (e) {
              if (
                t.ref.isActive &&
                (0 === a.count() && (l = !1),
                po(t.element, e.target) &&
                  (a.push(e), Nr(document.documentElement, 'up', t.ref.resizeEnd), a.multiple()))
              ) {
                e.stopPropagation(), e.preventDefault();
                var n = a.active(),
                  r = mo(n[0]),
                  i = mo(n[1]);
                (c = Ge(r, i)), Nr(document.documentElement, 'move', t.ref.resizeMove), (l = !0);
              }
            }),
              (t.ref.resizeMove = function (e) {
                if (t.ref.isActive && l && (e.preventDefault(), 2 === a.count())) {
                  a.update(e);
                  var n = a.active(),
                    r = mo(n[0]),
                    i = mo(n[1]),
                    o = (Ge(r, i) - c) / c;
                  t.dispatch('CROP_IMAGE_RESIZE', { value: o });
                }
              }),
              (t.ref.resizeEnd = function (e) {
                if (t.ref.isActive) {
                  a.pop(e);
                  var n = 0 === a.count();
                  n &&
                    (Fr(document.documentElement, 'move', t.ref.resizeMove),
                    Fr(document.documentElement, 'up', t.ref.resizeEnd)),
                    l && (e.preventDefault(), n && t.dispatch('CROP_IMAGE_RESIZE_RELEASE'));
                }
              }),
              Nr(document.documentElement, 'down', t.ref.resizeStart);
            var u = performance.now(),
              s = 0,
              f = 1,
              d = (function (e, t) {
                var n = null,
                  r = null;
                return function () {
                  var i = arguments;
                  if (!r) return e.apply(null, Array.from(arguments)), void (r = uo());
                  clearTimeout(n),
                    (n = setTimeout(
                      function () {
                        uo() - r >= t && (e.apply(null, Array.from(i)), (r = uo()));
                      },
                      t - (uo() - r),
                    ));
                };
              })(function (e) {
                if (!t.ref.isCropping) {
                  var n = Math.sign(e.wheelDelta || e.deltaY),
                    r = uo(),
                    i = r - u;
                  (u = r),
                    (i > 750 || s !== n) && ((f = 1), (s = n)),
                    (f += 0.05 * n),
                    t.dispatch('CROP_IMAGE_RESIZE_MULTIPLY', { value: Math.max(0.1, f) }),
                    t.dispatch('CROP_IMAGE_RESIZE_RELEASE');
                }
              }, 100);
            (t.ref.wheel = function (e) {
              if (t.ref.isActive && po(t.element, e.target)) {
                if (n.scrollRect) {
                  var r = n.scrollRect,
                    i = t.query('GET_ROOT'),
                    o = mo(e),
                    a = { x: o.x - i.leftScroll, y: o.y - i.topScroll };
                  if (a.x < r.x || a.x > r.x + r.width || a.y < r.y || a.y > r.y + r.height) return;
                }
                e.preventDefault(), d(e);
              }
            }),
              document.addEventListener('wheel', t.ref.wheel, { passive: !1 }),
              t.ref.hasEnabledResizeModifier &&
                ((t.ref.move = function (e) {
                  if (
                    t.ref.isActive &&
                    !t.ref.isCropping &&
                    ((i.position.x = e.pageX - t.ref.state.offsetX),
                    (i.position.y = e.pageY - t.ref.state.scrollY - t.ref.state.offsetY),
                    i.enabled)
                  )
                    if (po(t.element, e.target)) {
                      'idle' === t.element.dataset.state &&
                        t.dispatch('RESIZER_SHOW', { position: fo({}, i.position) }),
                        e.preventDefault(),
                        t.dispatch('RESIZER_MOVE', { position: fo({}, i.position) });
                      var r = n.pivotPoint,
                        a = r.x - i.position.x,
                        l = r.y - i.position.y,
                        u = { x: r.x + a, y: r.y + l },
                        s = fo({}, i.position);
                      if (i.selecting) {
                        var f = (Ge(u, s) - c) / c,
                          d = performance.now();
                        d - o > 25 && ((o = d), t.dispatch('CROP_IMAGE_RESIZE', { value: f }));
                      }
                    } else t.dispatch('RESIZER_CANCEL');
                }),
                (t.ref.select = function (e) {
                  if (t.ref.isActive && po(t.element, e.target)) {
                    var r = n.pivotPoint,
                      o = r.x - i.position.x,
                      a = r.y - i.position.y,
                      l = { x: r.x + o, y: r.y + a },
                      u = i.position;
                    (c = Ge(l, u)),
                      (i.selecting = !0),
                      (i.origin.x = e.pageX),
                      (i.origin.y = e.pageY),
                      t.dispatch('CROP_IMAGE_RESIZE_GRAB');
                  }
                }),
                (t.ref.confirm = function (e) {
                  t.ref.isActive &&
                    po(t.element, e.target) &&
                    ((i.selecting = !1), t.dispatch('CROP_IMAGE_RESIZE_RELEASE'));
                }),
                (t.ref.blur = function () {
                  t.ref.isActive &&
                    ((i.selecting = !1),
                    (i.enabled = !1),
                    document.removeEventListener('mousedown', t.ref.select),
                    document.removeEventListener('mouseup', t.ref.confirm),
                    t.dispatch('RESIZER_CANCEL'));
                }),
                window.addEventListener('blur', t.ref.blur),
                document.addEventListener('mousemove', t.ref.move),
                (t.ref.keyDown = function (e) {
                  t.ref.isActive &&
                    r.includes(e.keyCode) &&
                    i.position &&
                    ((i.enabled = !0),
                    document.addEventListener('mousedown', t.ref.select),
                    document.addEventListener('mouseup', t.ref.confirm),
                    t.dispatch('RESIZER_SHOW', { position: fo({}, i.position) }));
                }),
                (t.ref.keyUp = function (e) {
                  t.ref.isActive &&
                    r.includes(e.keyCode) &&
                    ((i.enabled = !1),
                    document.removeEventListener('mousedown', t.ref.select),
                    document.removeEventListener('mouseup', t.ref.confirm),
                    t.dispatch('RESIZER_CANCEL'));
                }),
                document.body.addEventListener('keydown', t.ref.keyDown),
                document.body.addEventListener('keyup', t.ref.keyUp));
          },
          destroy: function (e) {
            var t = e.root;
            document.removeEventListener('touchmove', t.ref.resizeMove),
              document.removeEventListener('touchend', t.ref.resizeEnd),
              document.removeEventListener('touchstart', t.ref.resizeStart),
              document.removeEventListener('wheel', t.ref.wheel),
              document.removeEventListener('mousedown', t.ref.select),
              document.removeEventListener('mouseup', t.ref.confirm),
              t.ref.hasEnabledResizeModifier &&
                (document.removeEventListener('mousemove', t.ref.move),
                document.body.removeEventListener('keydown', t.ref.keyDown),
                document.body.removeEventListener('keyup', t.ref.keyUp),
                window.removeEventListener('blur', t.ref.blur));
          },
          read: function (e) {
            var t = e.root;
            t.ref.state.scrollY = window.scrollY;
            var n = t.element.getBoundingClientRect();
            (t.ref.state.offsetX = n.x), (t.ref.state.offsetY = n.y);
          },
          write: Z({
            CROP_RECT_DRAG_GRAB: function (e) {
              e.root.ref.isCropping = !0;
            },
            CROP_RECT_DRAG_RELEASE: function (e) {
              e.root.ref.isCropping = !1;
            },
            SHOW_VIEW: function (e) {
              var t = e.root,
                n = e.action;
              t.ref.isActive = 'crop' === n.id;
            },
            RESIZER_SHOW: function (e) {
              var t = e.root,
                n = e.props,
                r = e.action;
              (t.element.dataset.state = 'multi-touch'), go({ root: t, props: n, action: r });
            },
            RESIZER_CANCEL: function (e) {
              e.root.element.dataset.state = 'idle';
            },
            RESIZER_MOVE: go,
          }),
        });
      function yo(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              Eo(e, t, n[t]);
            });
        }
        return e;
      }
      function Eo(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var wo = function (e, t) {
          return (e.style.opacity = t);
        },
        _o = function (e, t) {
          var n = Array.from(e.element.querySelectorAll('.doka--icon-crop-limit rect'));
          n.length && (wo(n[0], t ? 0.3 : 0), wo(n[1], t ? 1 : 0), wo(n[2], t ? 0 : 0.3), wo(n[3], t ? 0 : 1));
        },
        To = function (e, t) {
          var n = e.element.querySelectorAll('.doka--icon-aspect-ratio rect');
          if (n.length) {
            if (!t) return wo(n[0], 0.2), wo(n[1], 0.3), void wo(n[2], 0.4);
            wo(n[0], t > 1 ? 1 : 0.3), wo(n[1], 1 === t ? 0.85 : 0.5), wo(n[2], t < 1 ? 1 : 0.3);
          }
        },
        bo = function (e) {
          var t, n;
          e > 1 ? ((n = 14), (t = Math.round(n / e))) : ((t = 14), (n = Math.round(t * e)));
          var r = Math.round(0.5 * (23 - t)),
            i = Math.round(0.5 * (23 - n));
          return '<svg width="23" height="23" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><g fill="currentColor"><rect x="'
            .concat(r, '" y="')
            .concat(i, '" width="')
            .concat(t, '" height="')
            .concat(n, '" rx="2.5"/></g></svg>');
        },
        Ao = X({
          name: 'crop',
          ignoreRect: !0,
          mixins: { apis: ['viewId', 'stagePosition', 'hidden', 'offsetTop'] },
          create: function (e) {
            var t = e.root,
              n = e.props;
            (n.viewId = 'crop'), (n.hidden = !1), (t.ref.isHiding = !1);
            var r = [];
            t.query('GET_CROP_ALLOW_IMAGE_TURN_LEFT') &&
              r.push({
                view: Sr,
                name: 'tool',
                label: t.query('GET_LABEL_BUTTON_CROP_ROTATE_LEFT'),
                icon: Cr(
                  '<g transform="translate(3 2)" fill="currentColor" fill-rule="evenodd" class="doka--icon-turn"><rect y="9" width="12" height="12" rx="1"/><path d="M9.823 5H11a5 5 0 0 1 5 5 1 1 0 0 0 2 0 7 7 0 0 0-7-7H9.626l.747-.747A1 1 0 0 0 8.958.84L6.603 3.194a1 1 0 0 0 0 1.415l2.355 2.355a1 1 0 0 0 1.415-1.414L9.823 5z" fill-rule="nonzero" /></g>',
                  26,
                ),
                action: function () {
                  return t.dispatch('CROP_IMAGE_ROTATE_LEFT');
                },
              }),
              t.query('GET_CROP_ALLOW_IMAGE_TURN_RIGHT') &&
                r.push({
                  view: Sr,
                  name: 'tool',
                  label: t.query('GET_LABEL_BUTTON_CROP_ROTATE_RIGHT'),
                  icon: Cr(
                    '<g transform="translate(5 2)" fill="currentColor" fill-rule="evenodd" class="doka--icon-turn"><path d="M8.177 5H7a5 5 0 0 0-5 5 1 1 0 0 1-2 0 7 7 0 0 1 7-7h1.374l-.747-.747A1 1 0 0 1 9.042.84l2.355 2.355a1 1 0 0 1 0 1.415L9.042 6.964A1 1 0 0 1 7.627 5.55l.55-.55z" fill-rule="nonzero"/><rect x="6" y="9" width="12" height="12" rx="1"/></g>',
                    26,
                  ),
                  action: function () {
                    return t.dispatch('CROP_IMAGE_ROTATE_RIGHT');
                  },
                }),
              t.query('GET_CROP_ALLOW_IMAGE_FLIP_HORIZONTAL') &&
                r.push({
                  view: Sr,
                  name: 'tool',
                  label: t.query('GET_LABEL_BUTTON_CROP_FLIP_HORIZONTAL'),
                  icon: Cr(
                    '<g fill="currentColor" fill-rule="evenodd"><path d="M11.93 7.007V20a1 1 0 0 1-1 1H5.78a1 1 0 0 1-.93-1.368l5.15-12.993a1 1 0 0 1 1.929.368z"/><path d="M14 7.007V20a1 1 0 0 0 1 1h5.149a1 1 0 0 0 .93-1.368l-5.15-12.993A1 1 0 0 0 14 7.007z" opacity=".6"/></g>',
                    26,
                  ),
                  action: function () {
                    return t.dispatch('CROP_IMAGE_FLIP_HORIZONTAL');
                  },
                }),
              t.query('GET_CROP_ALLOW_IMAGE_FLIP_VERTICAL') &&
                r.push({
                  view: Sr,
                  name: 'tool',
                  label: t.query('GET_LABEL_BUTTON_CROP_FLIP_VERTICAL'),
                  icon: Cr(
                    '<g fill="currentColor" fill-rule="evenodd"><path d="M19.993 12.143H7a1 1 0 0 1-1-1V5.994a1 1 0 0 1 1.368-.93l12.993 5.15a1 1 0 0 1-.368 1.93z"/><path d="M19.993 14a1 1 0 0 1 .368 1.93L7.368 21.078A1 1 0 0 1 6 20.148V15a1 1 0 0 1 1-1h12.993z" opacity=".6"/></g>',
                    26,
                  ),
                  action: function () {
                    return t.dispatch('CROP_IMAGE_FLIP_VERTICAL');
                  },
                });
            var i = t.query('GET_CROP_ASPECT_RATIO_OPTIONS');
            i &&
              i.length &&
              r.push({
                view: Bi,
                name: 'tool',
                label: t.query('GET_LABEL_BUTTON_CROP_ASPECT_RATIO'),
                icon: Cr(
                  '<g class="doka--icon-aspect-ratio" fill="currentColor" fill-rule="evenodd"><rect x="2" y="4" opacity=".3" width="10" height="18" rx="1"/><rect opacity=".5" x="4" y="8" width="14" height="14" rx="1"/><rect x="6" y="12" width="17" height="10" rx="1"/></g>',
                  26,
                ),
                options: null,
                onSelect: function (e) {
                  e.width && e.height
                    ? t.dispatch('RESIZE_SET_OUTPUT_SIZE', { width: e.width, height: e.height })
                    : (t.query('GET_CROP_ASPECT_RATIO_OPTIONS').find(function (e) {
                        return (e.value && e.value.width) || e.value.height;
                      }) && t.dispatch('RESIZE_SET_OUTPUT_SIZE', { width: null, height: null }),
                      t.dispatch('CROP_SET_ASPECT_RATIO', { value: e.aspectRatio }));
                },
                didCreateView: function (e) {
                  t.ref.aspectRatioDropdown = e;
                },
              }),
              t.query('GET_CROP_ALLOW_TOGGLE_LIMIT') &&
                r.push({
                  view: Bi,
                  name: 'tool',
                  label: t.query('GET_LABEL_BUTTON_CROP_TOGGLE_LIMIT'),
                  icon: Cr(
                    '<g class="doka--icon-crop-limit" fill="currentColor" fill-rule="evenodd">\n                    <rect x="2" y="3" width="20" height="20" rx="1"/>\n                    <rect x="7" y="8" width="10" height="10" rx="1"/>\n                    <rect x="4" y="8" width="14" height="14" rx="1"/>\n                    <rect x="12" y="4" width="10" height="10" rx="1"/>\n                </g>',
                    26,
                  ),
                  options: [
                    {
                      value: !0,
                      label: t.query('GET_LABEL_BUTTON_CROP_TOGGLE_LIMIT_ENABLE'),
                      icon: '<svg width="23" height="23" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><g fill="currentColor"><rect x="3" y="3" width="17" height="17" rx="2.5" opacity=".3"/><rect x="7" y="7" width="9" height="9" rx="2.5"/></g></svg>',
                    },
                    {
                      value: !1,
                      label: t.query('GET_LABEL_BUTTON_CROP_TOGGLE_LIMIT_DISABLE'),
                      icon: '<svg width="23" height="23" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><g fill="currentColor"><rect x="3" y="6" width="13" height="13" rx="2.5" opacity=".3"/><rect x="10" y="3" width="9" height="9" rx="2.5"/></g></svg>',
                    },
                  ],
                  onSelect: function (e) {
                    t.dispatch('CROP_SET_LIMIT', { value: e });
                  },
                  didCreateView: function (e) {
                    t.ref.cropToggleLimitDropdown = e;
                  },
                }),
              (t.ref.menu = t.appendChildView(
                t.createChildView(ki('toolbar', ['opacity'], { opacity: { type: 'spring', mass: 15, delay: 50 } }), {
                  opacity: 0,
                  controls: r,
                }),
              )),
              (t.ref.menuItemsRequiredWidth = null),
              (t.ref.subject = t.appendChildView(t.createChildView(co, yo({}, n)))),
              t.query('GET_CROP_ALLOW_ROTATE') &&
                (t.ref.rotator = t.appendChildView(
                  t.createChildView(Ni, { rotation: 0, opacity: 0, translateY: 20, id: n.id }),
                )),
              (t.ref.resizer = t.appendChildView(t.createChildView(vo, { pivotPoint: { x: 0, y: 0 } }))),
              (t.ref.updateControls = function () {
                var e = t.query('GET_IMAGE');
                if (
                  ((function (e, t) {
                    Array.from(e.element.querySelectorAll('.doka--icon-turn rect')).forEach(function (e) {
                      t > 1 && (e.setAttribute('x', e.previousElementSibling ? 5 : 4), e.setAttribute('width', 9)),
                        t < 1 && (e.setAttribute('y', 11), e.setAttribute('height', 10));
                    });
                  })(t, e.height / e.width),
                  t.ref.cropToggleLimitDropdown &&
                    ((t.ref.isLimitedToImageBounds = t.query('GET_CROP_LIMIT_TO_IMAGE_BOUNDS')),
                    _o(t, t.ref.isLimitedToImageBounds),
                    (t.ref.cropToggleLimitDropdown.selectedValue = t.ref.isLimitedToImageBounds)),
                  t.ref.aspectRatioDropdown)
                ) {
                  var n = t.query('GET_MIN_IMAGE_SIZE'),
                    r = i.filter(function (t) {
                      if (!t.value.aspectRatio) return !0;
                      if (t.value.aspectRatio < 1) {
                        if (e.naturalWidth * t.value.aspectRatio < n.height) return !1;
                      } else if (e.naturalHeight / t.value.aspectRatio < n.width) return !1;
                      return !0;
                    });
                  t.ref.aspectRatioDropdown.options = r.map(function (e) {
                    return yo({}, e, { icon: bo(e.value.aspectRatio) });
                  });
                }
              }),
              (t.ref.isModal = /modal|fullscreen/.test(t.query('GET_STYLE_LAYOUT_MODE')));
          },
          read: function (e) {
            var t = e.root,
              n = e.props;
            if (n.hidden) t.ref.menuItemsRequiredWidth = null;
            else {
              var r = t.rect;
              if (0 !== r.element.width && 0 !== r.element.height) {
                if (null === t.ref.menuItemsRequiredWidth) {
                  var i = t.ref.menu.childViews.reduce(function (e, t) {
                    return e + t.rect.outer.width;
                  }, 0);
                  t.ref.menuItemsRequiredWidth = 0 === i ? null : i;
                }
                var o = t.ref.subject.rect.element,
                  a = o.left,
                  c = o.top,
                  l = o.width,
                  u = o.height;
                n.stagePosition = { x: a, y: c, width: l, height: u };
              }
            }
          },
          shouldUpdateChildViews: function (e) {
            var t = e.props,
              n = e.actions;
            return !t.hidden || (t.hidden && n && n.length);
          },
          write: Z(
            {
              SHOW_VIEW: function (e) {
                var t = e.root,
                  n = e.action,
                  r = e.props,
                  i = t.ref,
                  o = i.menu,
                  a = i.rotator,
                  c = i.subject;
                r.viewId === n.id
                  ? ((c.opacity = 1),
                    (o.opacity = 1),
                    a && ((a.opacity = 1), (a.translateY = 0)),
                    (r.hidden = !1),
                    (t.ref.isHiding = !1),
                    t.ref.updateControls())
                  : ((c.opacity = 0),
                    (o.opacity = 0),
                    a && ((a.opacity = 0), (a.translateY = 20)),
                    (t.ref.isHiding = !0));
              },
              UNLOAD_IMAGE: function (e) {
                var t = e.root.ref,
                  n = t.menu,
                  r = t.rotator;
                (n.opacity = 0), r && ((r.opacity = 0), (r.translateY = 20));
              },
              DID_PRESENT_IMAGE: function (e) {
                var t = e.root,
                  n = t.ref,
                  r = n.menu,
                  i = n.rotator;
                (r.opacity = 1), i && ((i.opacity = 1), (i.translateY = 0)), t.ref.updateControls();
              },
            },
            function (e) {
              var t = e.root,
                n = e.props,
                r = e.timestamp,
                i = t.ref,
                o = i.resizer,
                a = i.subject,
                c = i.menu,
                l = i.rotator,
                u = i.isHiding,
                s = i.cropToggleLimitDropdown,
                f = i.aspectRatioDropdown,
                d = n.hidden,
                h = 0 === a.opacity && 0 === c.opacity && (!l || (l && 0 === l.opacity));
              if ((!d && u && h && ((t.ref.isHiding = !1), (n.hidden = !0)), !n.hidden)) {
                var p = t.query('GET_CROP', n.id, r);
                if (p) {
                  if (f) {
                    var g = t.query('GET_ACTIVE_CROP_ASPECT_RATIO'),
                      m = t.query('GET_SIZE'),
                      v = f.selectedValue;
                    v
                      ? (v.aspectRatio !== g && To(t, g),
                        (v.aspectRatio === g && v.width === m.width && v.height === m.height) ||
                          (f.selectedValue = { aspectRatio: g, width: m.width, height: m.height }))
                      : ((f.selectedValue = { aspectRatio: g, width: m.width, height: m.height }), To(t, g));
                  }
                  if (
                    (s &&
                      t.ref.isLimitedToImageBounds !== p.isLimitedToImageBounds &&
                      ((t.ref.isLimitedToImageBounds = p.isLimitedToImageBounds),
                      _o(t, p.isLimitedToImageBounds),
                      (s.selectedValue = p.isLimitedToImageBounds)),
                    (o.pivotPoint = { x: 0.5 * o.rect.element.width, y: 0.5 * o.rect.element.height }),
                    l &&
                      ((l.animate = !p.isDraft),
                      (l.rotation = p.rotation.sub),
                      l.setAllowInteraction(t.query('IS_ACTIVE_VIEW', 'crop'))),
                    (c.element.dataset.layout =
                      t.ref.menuItemsRequiredWidth > t.ref.menu.rect.element.width ? 'compact' : 'spacious'),
                    t.query('GET_CROP_RESIZE_SCROLL_RECT_ONLY'))
                  ) {
                    var y = t.query('GET_STAGE'),
                      E = y.x,
                      w = y.y,
                      _ = t.query('GET_ROOT'),
                      T = t.ref.isModal ? _.left : 0,
                      b = t.ref.isModal ? _.top : 0;
                    o.scrollRect = {
                      x: T + E + p.cropRect.x,
                      y: b + w + p.cropRect.y + n.offsetTop,
                      width: p.cropRect.width,
                      height: p.cropRect.height,
                    };
                  }
                }
              }
            },
          ),
        }),
        xo = X({
          name: 'size-input',
          mixins: {
            listeners: !0,
            apis: ['id', 'value', 'placeholder', 'getValue', 'setValue', 'setPlaceholder', 'hasFocus', 'onChange'],
          },
          create: function (e) {
            var t = e.root,
              n = e.props,
              r = n.id,
              i = n.min,
              o = n.max,
              a = n.value,
              c = n.placeholder,
              l = n.onChange,
              u = void 0 === l ? function () {} : l,
              s = n.onBlur,
              f = void 0 === s ? function () {} : s,
              d = 'doka--'.concat(r, '-').concat(ge()),
              h = g('input', { type: 'number', step: 1, id: d, min: i, max: o, value: a, placeholder: c }),
              p = h.getAttribute('max').length,
              m = g('label', { for: d });
            m.textContent = n.label;
            var v = function (e, t, n) {
                return (
                  te(e)
                    ? ((e = e.replace(/[^0-9]/g, '')).length > p && (e = e.slice(0, p)), (e = parseInt(e, 10)))
                    : (e = Math.round(e)),
                  isNaN(e) ? null : Se(e, t, n)
                );
              },
              y = function (e) {
                return e.length ? parseInt(h.value, 10) : null;
              };
            (t.ref.handleInput = function () {
              (h.value = v(h.value, 1, o)), u(y(h.value));
            }),
              (t.ref.handleBlur = function () {
                (h.value = v(h.value, i, o)), f(y(h.value));
              }),
              h.addEventListener('input', t.ref.handleInput),
              h.addEventListener('blur', t.ref.handleBlur),
              t.appendChild(h),
              t.appendChild(m),
              (t.ref.input = h),
              (n.hasFocus = function () {
                return h === document.activeElement;
              }),
              (n.getValue = function () {
                return y(h.value);
              }),
              (n.setValue = function (e) {
                return (h.value = e ? v(e, 1, 999999) : null);
              }),
              (n.setPlaceholder = function (e) {
                return (h.placeholder = e);
              });
          },
          destroy: function (e) {
            var t = e.root;
            t.ref.input.removeEventListener('input', t.ref.handleInput),
              t.ref.input.removeEventListener('blur', t.ref.handleBlur);
          },
        }),
        Oo = X({
          name: 'checkable',
          tag: 'span',
          mixins: { listeners: !0, apis: ['id', 'checked', 'onChange', 'onSetValue', 'setValue', 'getValue'] },
          create: function (e) {
            var t = e.root,
              n = e.props,
              r = n.id,
              i = n.checked,
              o = n.onChange,
              a = void 0 === o ? function () {} : o,
              c = n.onSetValue,
              l = void 0 === c ? function () {} : c,
              u = 'doka--'.concat(r, '-').concat(ge()),
              s = g('input', { type: 'checkbox', value: 1, id: u });
            (s.checked = i), (t.ref.input = s);
            var f = g('label', { for: u });
            (f.innerHTML = n.label),
              t.appendChild(s),
              t.appendChild(f),
              (t.ref.handleChange = function () {
                l(s.checked), a(s.checked);
              }),
              s.addEventListener('change', t.ref.handleChange),
              (n.getValue = function () {
                return s.checked;
              }),
              (n.setValue = function (e) {
                (s.checked = e), l(s.checked);
              }),
              setTimeout(function () {
                l(s.checked);
              }, 0);
          },
          destroy: function (e) {
            var t = e.root;
            t.ref.input.removeEventListener('change', t.ref.handleChange);
          },
        }),
        Ro = null,
        Io = X({
          ignoreRect: !0,
          name: 'resize-form',
          tag: 'form',
          mixins: { styles: ['opacity'], animations: { opacity: { type: 'spring', mass: 15, delay: 150 } } },
          create: function (e) {
            var t = e.root;
            t.element.setAttribute('novalidate', 'novalidate'),
              t.element.setAttribute('action', '#'),
              (t.ref.shouldBlurKeyboard = xe() || (null === Ro && (Ro = /Android/i.test(navigator.userAgent)), Ro));
            var n = t.query('GET_SIZE_MAX'),
              r = t.query('GET_SIZE_MIN'),
              i = function () {
                var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
                  i = e.axisLock,
                  o = void 0 === i ? 'none' : i,
                  a = e.enforceLimits,
                  c = void 0 !== a && a,
                  l = t.ref,
                  u = l.inputImageWidth,
                  s = l.inputImageHeight,
                  f = l.buttonConfirm,
                  d = t.query('GET_SIZE_ASPECT_RATIO_LOCK'),
                  h = t.query('GET_CROP_RECTANGLE_ASPECT_RATIO'),
                  p = { width: u.getValue(), height: s.getValue() },
                  g = ft(
                    p,
                    c ? r : { width: 1, height: 1 },
                    c ? n : { width: 999999, height: 999999 },
                    d ? h : null,
                    o,
                  );
                if (d)
                  'width' === o
                    ? s.setValue(g.width / h)
                    : 'height' === o
                      ? u.setValue(g.height * h)
                      : (u.setValue(g.width || g.height * h), s.setValue(g.height || g.width / h));
                else if (g.width && !g.height) {
                  var m = Math.round(g.width / h),
                    v = ft(
                      { width: g.width, height: m },
                      c ? r : { width: 1, height: 1 },
                      c ? n : { width: 999999, height: 999999 },
                      h,
                      o,
                    );
                  c && u.setValue(Math.round(v.width)), s.setPlaceholder(Math.round(v.height));
                } else if (g.height && !g.width) {
                  var y = Math.round(g.height * h);
                  u.setPlaceholder(y);
                }
                var E = t.query('GET_SIZE_INPUT'),
                  w = E.width,
                  _ = E.height,
                  T = A(w) ? Math.round(w) : null,
                  b = A(_) ? Math.round(_) : null,
                  x = u.getValue(),
                  O = s.getValue(),
                  R = x !== T || O !== b;
                return (f.opacity = R ? 1 : 0), t.dispatch('KICK'), { width: u.getValue(), height: s.getValue() };
              },
              o = t;
            t.appendChildView(
              t.createChildView(
                Co('Image size', function (e) {
                  var t = e.root,
                    a = t.query('GET_SIZE'),
                    c = t.appendChildView(
                      t.createChildView(xo, {
                        id: 'image-width',
                        label: t.query('GET_LABEL_RESIZE_WIDTH'),
                        value: A(a.width) ? Math.round(a.width) : null,
                        min: r.width,
                        max: n.width,
                        placeholder: 0,
                        onChange: function () {
                          return i({ axisLock: 'width' });
                        },
                        onBlur: function () {
                          return i({ enforceLimits: !1 });
                        },
                      }),
                    ),
                    l = t.appendChildView(
                      t.createChildView(Oo, {
                        id: 'aspect-ratio-lock',
                        label: Cr(
                          '<g fill="none" fill-rule="evenodd"><path stroke="currentColor" stroke-width="1.5" stroke-linecap="round" class="doka--aspect-ratio-lock-ring" d="M9.401 10.205v-.804a2.599 2.599 0 0 1 5.198 0V14"/><rect fill="currentColor" x="7" y="10" width="10" height="7" rx="1.5"/></g>',
                        ),
                        checked: t.query('GET_SIZE_ASPECT_RATIO_LOCK'),
                        onSetValue: function (e) {
                          var t = e ? 0 : -3;
                          l.element
                            .querySelector('.doka--aspect-ratio-lock-ring')
                            .setAttribute('transform', 'translate(0 '.concat(t, ')'));
                        },
                        onChange: function (e) {
                          t.dispatch('RESIZE_SET_OUTPUT_SIZE_ASPECT_RATIO_LOCK', { value: e }), i();
                        },
                      }),
                    ),
                    u = t.appendChildView(
                      t.createChildView(xo, {
                        id: 'image-height',
                        label: t.query('GET_LABEL_RESIZE_HEIGHT'),
                        value: A(a.height) ? Math.round(a.height) : null,
                        min: r.height,
                        max: n.height,
                        placeholder: 0,
                        onChange: function () {
                          return i({ axisLock: 'height' });
                        },
                        onBlur: function () {
                          return i({ enforceLimits: !1 });
                        },
                      }),
                    );
                  (o.ref.aspectRatioLock = l), (o.ref.inputImageWidth = c), (o.ref.inputImageHeight = u);
                }),
              ),
            ),
              (t.ref.buttonConfirm = t.appendChildView(
                t.createChildView(Sr, {
                  name: 'app action-confirm icon-only',
                  label: t.query('GET_LABEL_RESIZE_APPLY_CHANGES'),
                  action: function () {},
                  opacity: 0,
                  icon: Cr(
                    '<polyline fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" points="20 6 9 17 4 12"></polyline>',
                  ),
                  type: 'submit',
                }),
              )),
              (t.ref.confirmForm = function (e) {
                var n = i({ enforceLimits: !0 });
                e.preventDefault();
                var r = t.ref,
                  o = r.shouldBlurKeyboard,
                  a = r.buttonConfirm;
                o && (document.activeElement.blur(), a.element.focus()),
                  (a.opacity = 0),
                  t.dispatch('RESIZE_SET_OUTPUT_SIZE', n);
              }),
              t.element.addEventListener('submit', t.ref.confirmForm);
          },
          destroy: function (e) {
            var t = e.root;
            t.element.removeEventListener('submit', t.ref.confirmForm);
          },
          write: Z(
            {
              EDIT_RESET: function (e) {
                var t = e.root,
                  n = t.query('GET_SIZE'),
                  r = t.ref,
                  i = r.inputImageWidth,
                  o = r.inputImageHeight,
                  a = r.aspectRatioLock,
                  c = r.buttonConfirm;
                i.setValue(n.width),
                  o.setValue(n.height),
                  a.setValue(t.query('GET_SIZE_ASPECT_RATIO_LOCK')),
                  (c.opacity = 0);
              },
              RESIZE_SET_OUTPUT_SIZE: function (e) {
                var t = e.root,
                  n = e.action,
                  r = t.ref,
                  i = r.inputImageWidth,
                  o = r.inputImageHeight;
                i.setValue(n.width), o.setValue(n.height);
              },
              CROP_SET_ASPECT_RATIO: function (e) {
                var t = e.root,
                  n = e.props,
                  r = e.action,
                  i = e.timestamp,
                  o = t.query('GET_CROP', n.id, i);
                if (o) {
                  var a = o.cropStatus,
                    c = t.ref,
                    l = c.inputImageWidth,
                    u = c.inputImageHeight;
                  null !== r.value
                    ? (l.setValue(a.image.width),
                      l.setPlaceholder(a.crop.width),
                      u.setValue(a.image.height),
                      u.setPlaceholder(a.crop.height))
                    : l.getValue() && u.getValue() && (u.setValue(null), u.setPlaceholder(a.crop.height));
                }
              },
            },
            function (e) {
              var t = e.root,
                n = e.props,
                r = e.timestamp,
                i = t.query('GET_CROP', n.id, r);
              if (i) {
                t.opacity;
                var o = i.cropStatus,
                  a = t.ref,
                  c = a.inputImageWidth,
                  l = a.inputImageHeight;
                if (!c.hasFocus() && !l.hasFocus()) {
                  var u = t.query('GET_CROP_RECTANGLE_ASPECT_RATIO');
                  if (null === c.getValue() && null === l.getValue())
                    c.setPlaceholder(o.crop.width), l.setPlaceholder(o.crop.height);
                  else if (null === c.getValue() && null !== o.image.height) {
                    var s = Math.round(o.image.height * u);
                    c.setPlaceholder(s);
                  } else if (null === l.getValue() && null !== o.image.width) {
                    var f = Math.round(o.image.width / u);
                    l.setPlaceholder(f);
                  }
                }
              }
            },
          ),
        }),
        Co = function (e, t) {
          return X({
            tag: 'fieldset',
            create: function (n) {
              var r = n.root,
                i = g('legend');
              (i.textContent = e), r.element.appendChild(i), t({ root: r });
            },
          });
        },
        So = X({
          name: 'resize',
          ignoreRect: !0,
          mixins: { apis: ['viewId', 'stagePosition', 'hidden'] },
          create: function (e) {
            var t = e.root,
              n = e.props;
            (n.viewId = 'resize'),
              (n.hidden = !1),
              (t.ref.isHiding = !1),
              (t.ref.form = t.appendChildView(t.createChildView(Io, { opacity: 0, id: n.id })));
          },
          read: function (e) {
            var t = e.root,
              n = e.props;
            if (!n.hidden) {
              var r = t.rect;
              if (0 !== r.element.width && 0 !== r.element.height) {
                var i = t.ref.form.rect;
                n.stagePosition = {
                  x: r.element.left,
                  y: r.element.top + i.element.height,
                  width: r.element.width,
                  height: r.element.height - i.element.height,
                };
              }
            }
          },
          shouldUpdateChildViews: function (e) {
            var t = e.props,
              n = e.actions;
            return !t.hidden || (t.hidden && n && n.length);
          },
          write: Z(
            {
              SHOW_VIEW: function (e) {
                var t = e.root,
                  n = e.action,
                  r = e.props;
                n.id === r.viewId
                  ? ((t.ref.isHiding = !1), (t.ref.form.opacity = 1))
                  : ((t.ref.isHiding = !0), (t.ref.form.opacity = 0));
              },
            },
            function (e) {
              var t = e.root,
                n = e.props,
                r = t.ref,
                i = r.form,
                o = r.isHiding,
                a = n.hidden;
              o && 0 === i.opacity && !a ? (n.hidden = !0) : (n.hidden = !1);
            },
          ),
        }),
        Mo = X({
          name: 'range-input',
          tag: 'span',
          mixins: { listeners: !0, apis: ['onUpdate', 'setValue', 'getValue', 'setAllowInteraction'] },
          create: function (e) {
            var t = e.root,
              n = e.props,
              r = n.id,
              i = n.min,
              o = n.max,
              a = n.step,
              c = n.value,
              l = n.onUpdate,
              u = void 0 === l ? function () {} : l,
              s = 'doka--'.concat(r, '-').concat(ge()),
              f = g('input', { type: 'range', id: s, min: i, max: o, step: a });
            (f.value = c), (t.ref.input = f);
            var d = g('span');
            d.className = 'doka--range-input-inner';
            var h = g('label', { for: s });
            h.innerHTML = n.label;
            var p = i + 0.5 * (o - i);
            (t.element.dataset.centered = c === p),
              (t.ref.handleRecenter = function () {
                n.setValue(p), t.ref.handleChange();
              });
            var m = g('button', { type: 'button' });
            (m.textContent = 'center'),
              m.addEventListener('click', t.ref.handleRecenter),
              (t.ref.recenter = m),
              d.appendChild(f),
              d.appendChild(m),
              t.appendChild(h),
              t.appendChild(d),
              (t.ref.handleChange = function () {
                var e = n.getValue();
                (t.element.dataset.centered = e === p), u(e);
              }),
              f.addEventListener('input', t.ref.handleChange);
            var v = null;
            (t.ref.dragger = Wr(
              d,
              function () {
                v = f.getBoundingClientRect();
              },
              function (e) {
                var n = (e.pageX - v.left) / v.width;
                (f.value = i + n * (o - i)), t.ref.handleChange();
              },
              function () {},
              { stopPropagation: !0 },
            )),
              (n.getValue = function () {
                return parseFloat(f.value);
              }),
              (n.setValue = function (e) {
                return (f.value = e);
              }),
              (n.setAllowInteraction = function (e) {
                e ? t.ref.dragger.enable() : t.ref.dragger.disable();
              });
          },
          destroy: function (e) {
            var t = e.root;
            t.ref.dragger.destroy(),
              t.ref.recenter.removeEventListener('click', t.ref.handleRecenter),
              t.ref.input.removeEventListener('input', t.ref.handleChange);
          },
        }),
        Lo = {
          brightness: {
            icon: Cr(
              '<g fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="7"/><line x1="12" y1="1" x2="12" y2="3"/><line x1="12" y1="21" x2="12" y2="23"/><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"/><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"/><line x1="1" y1="12" x2="3" y2="12"/><line x1="21" y1="12" x2="23" y2="12"/><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"/><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"/></g>',
            ),
          },
          contrast: {
            icon: Cr(
              '<g fill="none" fill-rule="evenodd"><circle stroke="currentColor" stroke-width="3" cx="12" cy="12" r="10"/><path d="M12 2v20C6.477 22 2 17.523 2 12S6.477 2 12 2z" fill="currentColor"/></g>',
            ),
          },
          exposure: {
            icon: Cr(
              '<g fill="none" fill-rule="evenodd"><rect stroke="currentColor" stroke-width="3" x="2" y="2" width="20" height="20" rx="4"/><path d="M20.828 3.172L3.172 20.828A3.987 3.987 0 0 1 2 18V6a4 4 0 0 1 4-4h12c1.105 0 2.105.448 2.828 1.172zM7 7H5v2h2v2h2V9h2V7H9V5H7v2zM12 15h5v2h-5z" fill="currentColor"/></g>',
            ),
          },
          saturation: {
            icon: Cr(
              '<g fill="none" fill-rule="evenodd"><rect stroke="currentColor" stroke-width="3" x="2" y="2" width="20" height="20" rx="4"/><path fill="currentColor" opacity=".3" d="M7 2.5h5v18.75H7z"/><path fill="currentColor" opacity=".6" d="M12 2.5h5v18.75h-5z"/><path fill="currentColor" opacity=".9" d="M17 2.5h4v18.75h-4z"/></g>',
            ),
          },
        },
        Po = X({
          ignoreRect: !0,
          name: 'color-form',
          tag: 'form',
          mixins: { styles: ['opacity'], animations: { opacity: { type: 'spring', mass: 15 } } },
          create: function (e) {
            var t = e.root;
            t.element.setAttribute('novalidate', 'novalidate');
            var n = t.query('GET_COLOR_VALUES');
            t.ref.tools = Object.keys(Lo).reduce(function (e, r) {
              var i = r,
                o = Lo[r].icon,
                a = t.query('GET_LABEL_COLOR_'.concat(r.toUpperCase())),
                c = t.query('GET_COLOR_'.concat(r.toUpperCase(), '_RANGE')),
                l = n[r];
              return (
                (e[i] = {
                  view: t.appendChildView(
                    t.createChildView(Mo, {
                      id: i,
                      label: ''.concat(o, '<span>').concat(a, '</span>'),
                      min: c[0],
                      max: c[1],
                      step: 0.01,
                      value: l,
                      onUpdate: function (e) {
                        return t.dispatch('COLOR_SET_COLOR_VALUE', { key: i, value: e });
                      },
                    }),
                  ),
                }),
                e
              );
            }, {});
          },
          write: Z({
            COLOR_SET_VALUE: function (e) {
              var t = e.root,
                n = e.action;
              t.ref.tools[n.key].view.setValue(n.value);
            },
            SHOW_VIEW: function (e) {
              var t = e.root,
                n = e.action;
              Object.keys(t.ref.tools).forEach(function (e) {
                t.ref.tools[e].view.setAllowInteraction('color' === n.id);
              });
            },
          }),
        }),
        ko = null,
        Go = null,
        Do = function (e, t) {
          var n = t.brightness,
            r = t.exposure,
            i = t.contrast,
            o = t.saturation;
          if (0 !== n) {
            var a = n < 0,
              c = a ? 'multiply' : 'overlay',
              l = a ? 0 : 255,
              u = a ? Math.abs(n) : 1 - n;
            e.ref.imageOverlay.style.cssText = 'mix-blend-mode: '
              .concat(c, '; background: rgba(')
              .concat(l, ',')
              .concat(l, ',')
              .concat(l, ',')
              .concat(u, ')');
          }
          return (
            (e.ref.imageOverlay.style.cssText = 'background:transparent'),
            (e.ref.image.style.cssText = 'filter: brightness('
              .concat(r, ') contrast(')
              .concat(i, ') saturate(')
              .concat(o, ')')),
            t
          );
        },
        Uo = Object.keys(Lo),
        Vo = function (e) {
          return X({
            ignoreRect: !0,
            tag: 'li',
            name: 'filter-tile',
            mixins: {
              styles: ['opacity', 'translateY'],
              animations: { translateY: { type: 'spring', delay: 10 * e }, opacity: { type: 'spring', delay: 30 * e } },
            },
            create: function (e) {
              var t = e.root,
                n = e.props,
                r = 'doka--filter-'.concat(n.style, '-').concat(ge()),
                i = g('input', { id: r, type: 'radio', name: 'filter' });
              t.appendChild(i),
                (i.checked = n.selected),
                (i.value = n.style),
                i.addEventListener('change', function () {
                  i.checked && n.onSelect();
                });
              var o = g('label', { for: r });
              (o.textContent = n.label), t.appendChild(o);
              var a = n.imageData,
                c = Math.min(a.width, a.height),
                l = c,
                u = g('canvas');
              (u.width = c), (u.height = l);
              var s = u.getContext('2d');
              t.ref.image = u;
              var f = g('div');
              t.ref.imageOverlay = f;
              var d = { x: 0.5 * c - 0.5 * a.width, y: 0.5 * l - 0.5 * a.height },
                h = g('div');
              h.appendChild(u),
                h.appendChild(f),
                t.appendChild(h),
                (t.ref.imageWrapper = h),
                n.matrix
                  ? (ko || (ko = sn(an)),
                    clearTimeout(Go),
                    ko.post(
                      { transforms: [{ type: 'filter', data: n.matrix }], imageData: a },
                      function (e) {
                        s.putImageData(e, d.x, d.y),
                          clearTimeout(Go),
                          (Go = setTimeout(function () {
                            ko.terminate(), (ko = null);
                          }, 1e3));
                      },
                      [a.data.buffer],
                    ),
                    (t.ref.activeColors = Do(t, t.query('GET_COLOR_VALUES'))))
                  : s.putImageData(a, d.x, d.y);
            },
            write: function (e) {
              var t = e.root;
              if (!(t.opacity <= 0)) {
                var n,
                  r,
                  i = t.query('GET_COLOR_VALUES'),
                  o = t.ref.activeColors;
                ((o || !i) &&
                  ((n = o),
                  (r = i),
                  Uo.findIndex(function (e) {
                    return n[e] !== r[e];
                  }) < 0)) ||
                  ((t.ref.activeColors = i), Do(t, i));
              }
            },
          });
        },
        Bo = function (e) {
          var t;
          try {
            t = new ImageData(e.width, e.height);
          } catch (n) {
            t = document.createElement('canvas').getContext('2d').createImageData(e.width, e.height);
          }
          return t.data.set(new Uint8ClampedArray(e.data)), t;
        },
        zo = function (e, t) {
          return (
            Array.isArray(e) &&
            Array.isArray(t) &&
            e.length === t.length &&
            e.every(function (e, n) {
              return e === t[n];
            })
          );
        };
      function No(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var Fo = X({
          ignoreRect: !0,
          tag: 'ul',
          name: 'filter-list',
          mixins: { apis: ['filterOpacity', 'hidden'] },
          create: function (e) {
            var t = e.root,
              n = e.props;
            t.element.setAttribute('role', 'list'), (t.ref.tiles = []);
            var r = t.query('GET_THUMB_IMAGE_DATA'),
              i = t.query('GET_FILTERS'),
              o = [];
            u(i, function (e, t) {
              o.push(
                (function (e) {
                  for (var t = 1; t < arguments.length; t++) {
                    var n = null != arguments[t] ? arguments[t] : {},
                      r = Object.keys(n);
                    'function' == typeof Object.getOwnPropertySymbols &&
                      (r = r.concat(
                        Object.getOwnPropertySymbols(n).filter(function (e) {
                          return Object.getOwnPropertyDescriptor(n, e).enumerable;
                        }),
                      )),
                      r.forEach(function (t) {
                        No(e, t, n[t]);
                      });
                  }
                  return e;
                })({ id: e }, t),
              );
            }),
              (t.ref.activeFilter = t.query('GET_FILTER')),
              (t.ref.tiles = o.map(function (e, i) {
                var o = e.matrix(),
                  a = t.ref.activeFilter === e.id || zo(t.ref.activeFilter, o) || 0 === i;
                return t.appendChildView(
                  t.createChildView(Vo(i), {
                    opacity: 0,
                    translateY: -5,
                    id: n.id,
                    style: e.id,
                    label: e.label,
                    matrix: o,
                    imageData: Bo(r),
                    selected: a,
                    onSelect: function () {
                      return t.dispatch('FILTER_SET_FILTER', { value: o ? e.id : null });
                    },
                  }),
                );
              }));
          },
          write: function (e) {
            var t = e.root,
              n = e.props;
            if (!n.hidden) {
              var r = t.query('GET_FILTER');
              if (r !== t.ref.activeFilter) {
                t.ref.activeFilter = r;
                var i = t.query('GET_FILTERS'),
                  o = r
                    ? te(r)
                      ? r
                      : Wn(r)
                        ? Object.keys(i).find(function (e) {
                            return zo(i[e].matrix(), r);
                          })
                        : null
                    : 'original';
                Array.from(t.element.querySelectorAll('input')).forEach(function (e) {
                  return (e.checked = e.value === o);
                });
              }
              t.query('IS_ACTIVE_VIEW', 'filter')
                ? t.ref.tiles.forEach(function (e) {
                    (e.opacity = 1), (e.translateY = 0);
                  })
                : t.ref.tiles.forEach(function (e) {
                    (e.opacity = 0), (e.translateY = -5);
                  }),
                (n.filterOpacity =
                  t.ref.tiles.reduce(function (e, t) {
                    return e + t.opacity;
                  }, 0) / t.ref.tiles.length);
            }
          },
        }),
        jo = function (e, t) {
          return X({
            name: e,
            ignoreRect: !0,
            mixins: { apis: ['viewId', 'stagePosition', 'hidden'] },
            create: function (n) {
              var r = n.root,
                i = n.props;
              (i.viewId = e),
                (i.hidden = !1),
                (r.ref.isHiding = !1),
                (r.ref.content = r.appendChildView(
                  r.createChildView(
                    (function (e, t) {
                      var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [];
                      return X({
                        name: 'scroller doka--'.concat(e, '-scroller'),
                        ignoreRect: !0,
                        ignoreRectUpdate: !0,
                        mixins: { styles: ['opacity'], animations: { opacity: { type: 'spring' } }, apis: n },
                        create: function (e) {
                          var n,
                            r = e.root,
                            i = e.props;
                          (r.ref.content = r.appendChildView(r.createChildView(t, { id: i.id }))),
                            (r.element.isScrollContainer = !0),
                            io() &&
                              ((r.ref.handleMouseWheel = function (e) {
                                var t = r.element.scrollLeft,
                                  n = r.ref.scrollWidth - r.rect.element.width,
                                  i = t + e.deltaX;
                                (i < 0 || i > n) &&
                                  ((r.element.scrollLeft = Math.min(Math.max(i, 0), n)), e.preventDefault());
                              }),
                              r.element.addEventListener('mousewheel', r.ref.handleMouseWheel),
                              (r.element.dataset.dragState = 'end'),
                              (r.ref.dragger = Wr(
                                r.ref.content.element,
                                function () {
                                  (r.element.dataset.dragState = 'start'), (n = r.element.scrollLeft);
                                },
                                function (e, t) {
                                  (r.element.scrollLeft = n - t.x),
                                    ke({ x: 0, y: 0 }, t) > 0 && (r.element.dataset.dragState = 'dragging');
                                },
                                function () {
                                  r.element.dataset.dragState = 'end';
                                },
                                { stopPropagation: !0 },
                              )));
                        },
                        destroy: function (e) {
                          var t = e.root;
                          t.ref.handleMouseWheel && t.element.removeEventListener('mousewheel', t.ref.handleMouseWheel),
                            t.ref.dragger && t.ref.dragger.destroy();
                        },
                        read: function (e) {
                          var t = e.root;
                          t.ref.scrollWidth = t.element.scrollWidth;
                        },
                        write: function (e) {
                          var t = e.root,
                            n = e.props;
                          (t.ref.content.hidden = n.hidden), (n.contentOpacity = t.ref.content.contentOpacity);
                        },
                      });
                    })(e, t, ['hidden', 'contentOpacity']),
                    { id: i.id },
                  ),
                ));
            },
            read: function (e) {
              var t = e.root,
                n = e.props;
              if (t.ref.content && !n.hidden) {
                var r = t.rect;
                if (0 !== r.element.width && 0 !== r.element.height) {
                  var i = t.ref.content.rect,
                    o = 0 === i.element.top,
                    a = o ? r.element.top + i.element.height + i.element.marginBottom : r.element.top,
                    c = o
                      ? r.element.height - i.element.height - i.element.marginBottom
                      : r.element.height - i.element.height - r.element.top;
                  n.stagePosition = { x: r.element.left, y: a, width: r.element.width, height: c };
                }
              }
            },
            shouldUpdateChildViews: function (e) {
              var t = e.props,
                n = e.actions;
              return !t.hidden || (t.hidden && n && n.length);
            },
            write: Z(
              {
                SHOW_VIEW: function (e) {
                  var t = e.root,
                    n = e.action,
                    r = e.props;
                  t.ref.content &&
                    (n.id === r.viewId
                      ? ((t.ref.isHiding = !1), (r.hidden = !1), (t.ref.content.hidden = !1))
                      : (t.ref.isHiding = !0));
                },
              },
              function (e) {
                var t = e.root,
                  n = e.props;
                (t.ref.content.opacity = t.ref.isHiding || t.ref.content.hidden ? 0 : 1),
                  t.ref.isHiding &&
                    t.ref.content.contentOpacity <= 0 &&
                    ((t.ref.isHiding = !1), (n.hidden = !0), (t.ref.content.hidden = !0));
              },
            ),
          });
        },
        Wo = jo('filter', Fo),
        qo = X({
          name: 'color',
          ignoreRect: !0,
          mixins: { apis: ['viewId', 'stagePosition', 'hidden'] },
          create: function (e) {
            var t = e.root,
              n = e.props;
            (n.viewId = 'color'),
              (n.hidden = !1),
              (t.ref.isHiding = !1),
              (t.ref.form = t.appendChildView(t.createChildView(Po, { opacity: 0, id: n.id })));
          },
          read: function (e) {
            var t = e.root,
              n = e.props;
            if (!n.hidden) {
              var r = t.rect;
              if (0 !== r.element.width && 0 !== r.element.height) {
                var i = t.ref.form.rect,
                  o = i.element.height,
                  a = 0 === i.element.top,
                  c = a ? r.element.top + o : r.element.top,
                  l = a ? r.element.height - o : r.element.height - o - r.element.top;
                n.stagePosition = { x: r.element.left, y: c, width: r.element.width, height: l };
              }
            }
          },
          shouldUpdateChildViews: function (e) {
            var t = e.props,
              n = e.actions;
            return !t.hidden || (t.hidden && n && n.length);
          },
          write: Z(
            {
              SHOW_VIEW: function (e) {
                var t = e.root,
                  n = e.action,
                  r = e.props;
                n.id === r.viewId
                  ? ((t.ref.isHiding = !1), (t.ref.form.opacity = 1), (r.hidden = !1))
                  : ((t.ref.isHiding = !0), (t.ref.form.opacity = 0));
              },
            },
            function (e) {
              var t = e.root,
                n = e.props;
              t.ref.isHiding && 0 === t.ref.form.opacity && ((t.ref.isHiding = !1), (n.hidden = !0));
            },
          ),
        });
      function Ho(e) {
        return (
          (function (e) {
            if (Array.isArray(e)) {
              for (var t = 0, n = new Array(e.length); t < e.length; t++) n[t] = e[t];
              return n;
            }
          })(e) ||
          (function (e) {
            if (Symbol.iterator in Object(e) || '[object Arguments]' === Object.prototype.toString.call(e))
              return Array.from(e);
          })(e) ||
          (function () {
            throw new TypeError('Invalid attempt to spread non-iterable instance');
          })()
        );
      }
      var Yo = X({
        ignoreRect: !0,
        tag: 'div',
        name: 'markup-color',
        mixins: { animations: { opacity: 'spring' }, styles: ['opacity'], apis: ['onSelect', 'selectedValue'] },
        create: function (e) {
          var t = e.root,
            n = e.props,
            r = n.colors,
            i = n.name,
            o = n.onSelect;
          (t.ref.handleChange = function (e) {
            o(e.target.value), e.stopPropagation();
          }),
            t.element.addEventListener('change', t.ref.handleChange);
          var a = g('ul');
          if (
            ((t.ref.inputs = r.map(function (e) {
              var t = 'doka--color-' + ge(),
                n = g('li'),
                r = g('input', { id: t, name: i, type: 'radio', value: e[1] }),
                o = g('label', { for: t, title: e[0], style: 'background-color: ' + (e[2] || e[1]) });
              return (o.textContent = e[0]), m(n)(r), m(n)(o), m(a)(n), r;
            })),
            t.element.appendChild(a),
            t.query('GET_MARKUP_ALLOW_CUSTOM_COLOR') &&
              (function () {
                try {
                  var e = g('input', { type: 'color' }),
                    t = 'color' === e.type;
                  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
                    ? t
                    : t && 'number' != typeof e.selectionStart;
                } catch (e) {
                  return !1;
                }
              })())
          ) {
            var c = g('div', { class: 'doka--color-input' }),
              l = 'doka--color-' + ge(),
              u = g('label', { for: l });
            u.textContent = 'Choose color';
            var s = g('input', { id: l, name: i, type: 'color' }),
              f = g('span', { class: 'doka--color-visualizer' }),
              d = g('span', { class: 'doka--color-brightness' });
            t.ref.handleCustomColorChange = function () {
              var e = dt(s.value),
                t = function (e, t, n) {
                  var r,
                    i = Math.max(e, t, n),
                    o = Math.min(e, t, n),
                    a = i - o,
                    c = 0 === i ? 0 : a / i,
                    l = i / 255;
                  switch (i) {
                    case o:
                      r = 0;
                      break;
                    case e:
                      (r = t - n + a * (t < n ? 6 : 0)), (r /= 6 * a);
                      break;
                    case t:
                      (r = n - e + 2 * a), (r /= 6 * a);
                      break;
                    case n:
                      (r = e - t + 4 * a), (r /= 6 * a);
                  }
                  return [r, c, l];
                }.apply(void 0, Ho(e)),
                n = 360 * t[0] - 90,
                r = 0.625 * t[1],
                i = 1 - t[2];
              (f.style.backgroundColor = s.value),
                (f.style.transform = 'rotateZ('.concat(n, 'deg) translateX(').concat(r, 'em)')),
                (d.style.opacity = i),
                o(s.value);
            };
            var h = !0;
            (t.ref.handleCustomColorSelect = function (e) {
              h ? o(e.target.value) : t.ref.handleCustomColorChange(), (h = !1);
            }),
              s.addEventListener('click', t.ref.handleCustomColorSelect),
              s.addEventListener('input', t.ref.handleCustomColorChange),
              m(c)(s),
              m(c)(u),
              m(c)(f),
              m(c)(d),
              t.appendChild(c),
              (t.ref.customInput = s);
          }
        },
        write: function (e) {
          var t = e.root,
            n = e.props;
          if (n.selectedValue !== t.ref.activeSelectedValue) {
            t.ref.activeSelectedValue = n.selectedValue;
            var r = !1;
            if (
              (t.ref.inputs.forEach(function (e) {
                (e.checked = e.value === n.selectedValue), e.checked && (r = !0);
              }),
              !t.ref.customInput)
            )
              return;
            (t.ref.customInput.dataset.selected = t.ref.inputs.length && !r),
              r || ((t.ref.customInput.value = n.selectedValue), t.ref.handleCustomColorChange());
          }
        },
        destroy: function (e) {
          var t = e.root;
          t.element.removeEventListener('change', t.ref.handleChange),
            t.ref.customInput &&
              (t.ref.customInput.removeEventListener('click', t.ref.handleCustomColorSelect),
              t.ref.customInput.removeEventListener('input', t.ref.handleCustomColorChange));
        },
      });
      function Xo(e, t) {
        return (
          (function (e) {
            if (Array.isArray(e)) return e;
          })(e) ||
          (function (e, t) {
            var n = [],
              r = !0,
              i = !1,
              o = void 0;
            try {
              for (
                var a, c = e[Symbol.iterator]();
                !(r = (a = c.next()).done) && (n.push(a.value), !t || n.length !== t);
                r = !0
              );
            } catch (e) {
              (i = !0), (o = e);
            } finally {
              try {
                r || null == c.return || c.return();
              } finally {
                if (i) throw o;
              }
            }
            return n;
          })(e, t) ||
          (function () {
            throw new TypeError('Invalid attempt to destructure non-iterable instance');
          })()
        );
      }
      var Zo = function (e) {
          var t = e.ref,
            n = t.colorSelect,
            r = t.fontFamilySelect,
            i = t.fontSizeSelect,
            o = t.shapeStyleSelect,
            a = t.lineStyleSelect;
          [r, i, o, t.lineDecorationSelect].forEach(function (e) {
            e.element.dataset.active = 'false';
          }),
            [n, a].forEach(function (e) {
              e.element.dataset.active = 'true';
            });
        },
        Ko = [
          'fontFamily',
          'fontSize',
          'fontWeight',
          'textAlign',
          'backgroundColor',
          'fontColor',
          'borderColor',
          'borderWidth',
          'borderStyle',
          'lineColor',
          'lineWidth',
          'lineDecoration',
          'lineJoin',
          'lineCap',
        ],
        Qo = function (e) {
          return '<svg width="23" height="23" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">'.concat(
            e,
            '</svg>',
          );
        },
        Jo = X({
          name: 'markup-tools',
          ignoreRect: !0,
          mixins: {
            apis: ['onUpdate'],
            animations: { translateY: 'spring', opacity: 'spring' },
            styles: ['translateY', 'opacity'],
          },
          create: function (e) {
            var t = e.root,
              n = e.props.onUpdate;
            (t.ref.colorSelect = t.appendChildView(
              t.createChildView(Yo, {
                onSelect: function (e) {
                  (t.ref.colorSelect.selectedValue = e), n('color', e);
                },
                name: 'color-select',
                colors: t.query('GET_MARKUP_COLOR_OPTIONS'),
              }),
            )),
              (t.ref.shapeStyleSelect = t.appendChildView(
                t.createChildView(Bi, {
                  onSelect: function (e) {
                    (t.ref.shapeStyleSelect.selectedValue = e), n('shapeStyle', [e[1], e[2]]);
                  },
                  name: 'tool',
                  label: t.query('GET_LABEL_MARKUP_SELECT_SHAPE_STYLE'),
                  direction: 'up',
                  options: t.query('GET_MARKUP_SHAPE_STYLE_OPTIONS').map(function (e) {
                    return {
                      value: e,
                      label: e[0],
                      icon:
                        ((t = e[3]),
                        (n = 0 === t ? 'currentColor' : 'none'),
                        (r = t),
                        Qo(
                          '<rect stroke="'
                            .concat(0 === t ? 'none' : 'currentColor', '" fill="')
                            .concat(n, '" stroke-width="')
                            .concat(r, '" x="2" y="3" width="17" height="17" rx="3"/>'),
                        )),
                    };
                    var t, n, r;
                  }),
                }),
              )),
              (t.ref.lineStyleSelect = t.appendChildView(
                t.createChildView(Bi, {
                  onSelect: function (e) {
                    (t.ref.lineStyleSelect.selectedValue = e), n('lineStyle', [e[1], e[2]]);
                  },
                  name: 'tool',
                  label: t.query('GET_LABEL_MARKUP_SELECT_LINE_STYLE'),
                  direction: 'up',
                  options: t.query('GET_MARKUP_LINE_STYLE_OPTIONS').map(function (e) {
                    return {
                      value: e,
                      label: e[0],
                      icon:
                        ((t = e[3]),
                        Qo(
                          '<line stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width="'.concat(
                            t,
                            '" x1="3" y1="12" x2="20" y2="12"/>',
                          ),
                        )),
                    };
                    var t;
                  }),
                }),
              )),
              (t.ref.lineDecorationSelect = t.appendChildView(
                t.createChildView(Bi, {
                  onSelect: function (e) {
                    (t.ref.lineDecorationSelect.selectedValue = e), n('lineDecoration', e);
                  },
                  name: 'tool',
                  label: t.query('GET_LABEL_MARKUP_SELECT_LINE_DECORATION'),
                  direction: 'up',
                  options: t.query('GET_MARKUP_LINE_DECORATION_OPTIONS').map(function (e) {
                    return { value: e[1], label: e[0] };
                  }),
                }),
              )),
              (t.ref.fontFamilySelect = t.appendChildView(
                t.createChildView(Bi, {
                  onSelect: function (e) {
                    (t.ref.fontFamilySelect.selectedValue = e), n('fontFamily', e);
                  },
                  name: 'tool',
                  label: t.query('GET_LABEL_MARKUP_SELECT_FONT_FAMILY'),
                  direction: 'up',
                  options: t.query('GET_MARKUP_FONT_FAMILY_OPTIONS').map(function (e) {
                    return {
                      value: e[1],
                      label: '<span style="font-family:'.concat(e[1], ';font-weight:600;">').concat(e[0], '</span>'),
                    };
                  }),
                }),
              )),
              (t.ref.fontSizeSelect = t.appendChildView(
                t.createChildView(Bi, {
                  onSelect: function (e) {
                    (t.ref.fontSizeSelect.selectedValue = e), n('fontSize', e);
                  },
                  name: 'tool',
                  label: t.query('GET_LABEL_MARKUP_SELECT_FONT_SIZE'),
                  direction: 'up',
                  options: t.query('GET_MARKUP_FONT_SIZE_OPTIONS').map(function (e) {
                    return { value: e[1], label: e[0] };
                  }),
                }),
              )),
              'draw' === t.query('GET_MARKUP_UTIL') && Zo(t);
          },
          write: Z({
            SWITCH_MARKUP_UTIL: function (e) {
              var t = e.root;
              'draw' === e.action.util && Zo(t);
            },
            MARKUP_SELECT: function (e) {
              var t = e.root,
                n = e.action,
                r = t.ref,
                i = r.colorSelect,
                o = r.fontFamilySelect,
                a = r.fontSizeSelect,
                c = r.shapeStyleSelect,
                l = r.lineStyleSelect,
                u = r.lineDecorationSelect,
                s = n.id ? t.query('GET_MARKUP_BY_ID', n.id) : null,
                f = [i, o, a, c, l, u],
                d = [];
              if (s) {
                var h = Xo(s, 2),
                  p = h[0],
                  g = h[1],
                  m = Array.isArray(g.allowEdit) ? g.allowEdit : !1 === g.allowEdit ? [] : Ko,
                  v = Ko.reduce(function (e, t) {
                    return (e[t] = -1 !== m.indexOf(t)), e;
                  }, {});
                if (
                  ((v.color = !!m.find(function (e) {
                    return /[a-z]Color/.test(e);
                  })),
                  'image' !== p && v.color && ((i.selectedValue = $o(g)), d.push(i)),
                  'text' === p &&
                    (v.fontFamily && ((o.selectedValue = g.fontFamily), d.push(o)),
                    v.fontSize && ((a.selectedValue = g.fontSize), d.push(a))),
                  ('rect' === p || 'ellipse' === p) && v.borderStyle)
                ) {
                  var y = t.query('GET_MARKUP_SHAPE_STYLE_OPTIONS').find(function (e) {
                    var t = g.borderWidth === e[1],
                      n = g.borderStyle === e[2] || zo(g.borderStyle, e[2]);
                    return t && n;
                  });
                  (c.selectedValue = y), d.push(c);
                }
                if ('line' === p || 'path' === p) {
                  if (v.lineWidth) {
                    var E = t.query('GET_MARKUP_LINE_STYLE_OPTIONS').find(function (e) {
                      var t = g.lineWidth === e[1],
                        n = g.lineStyle === e[2] || zo(g.lineStyle, e[2]);
                      return t && n;
                    });
                    (l.selectedValue = E), d.push(l);
                  }
                  'line' === p && v.lineDecoration && ((u.selectedValue = g.lineDecoration), d.push(u));
                }
                f.forEach(function (e) {
                  e.element.dataset.active = 'false';
                }),
                  d.forEach(function (e) {
                    e.element.dataset.active = 'true';
                  });
              }
            },
            MARKUP_UPDATE: function (e) {
              var t = e.root,
                n = e.action,
                r = n.style,
                i = n.value;
              t.ref[r + 'Select'] && (t.ref[r + 'Select'].selectedValue = i);
            },
          }),
        }),
        $o = function (e) {
          var t = e.fontColor,
            n = e.backgroundColor,
            r = e.lineColor,
            i = e.borderColor;
          return t || n || r || i;
        },
        ea = X({
          name: 'markup',
          ignoreRect: !0,
          mixins: { apis: ['viewId', 'stagePosition', 'hidden'] },
          create: function (e) {
            var t = e.root,
              n = e.props;
            (n.viewId = 'markup'), (n.hidden = !1), (t.ref.isHiding = !1);
            var r = [
              [
                'select',
                {
                  label: t.query('GET_LABEL_MARKUP_TOOL_SELECT'),
                  icon: Cr(
                    '<g fill="none" fill-rule="evenodd"><path d="M7 13H5a1 1 0 01-1-1V5a1 1 0 011-1h7a1 1 0 011 1v2" stroke="currentColor" stroke-width="2" stroke-linecap="round"/><path d="M10.22 8.914l12.58 5.18a1 1 0 01.012 1.844l-4.444 1.904a1 1 0 00-.526.526l-1.904 4.444a1 1 0 01-1.844-.013l-5.18-12.58a1 1 0 011.305-1.305z" fill="currentColor"/></g>',
                    26,
                  ),
                },
              ],
              [
                'draw',
                {
                  label: t.query('GET_LABEL_MARKUP_TOOL_DRAW'),
                  icon: Cr(
                    '<g fill="currentColor"><path d="M17.86 5.71a2.425 2.425 0 013.43 3.43L9.715 20.714 5 22l1.286-4.715L17.86 5.71z"/></g>',
                    26,
                  ),
                },
              ],
              [
                'line',
                {
                  label: t.query('GET_LABEL_MARKUP_TOOL_LINE'),
                  icon: Cr(
                    '<g transform="translate(3 4.5)" fill-rule="nonzero" fill="currentColor" stroke="none"><path d="M15.414 9.414l-6.01 6.01a2 2 0 1 1-2.829-2.828L9.172 10H2a2 2 0 1 1 0-4h7.172L6.575 3.404A2 2 0 1 1 9.404.575l6.01 6.01c.362.363.586.863.586 1.415s-.224 1.052-.586 1.414z"/></g>',
                    26,
                  ),
                },
              ],
              [
                'text',
                {
                  label: t.query('GET_LABEL_MARKUP_TOOL_TEXT'),
                  icon: Cr(
                    '<g transform="translate(5 5)" fill="currentColor" fill-rule="evenodd"><path d="M10 4v11a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V4H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-5z"/></g>',
                    26,
                  ),
                },
              ],
              [
                'rect',
                {
                  label: t.query('GET_LABEL_MARKUP_TOOL_RECT'),
                  icon: Cr('<g fill="currentColor"><rect x="5" y="5" width="16" height="16" rx="2"/></g>', 26),
                },
              ],
              [
                'ellipse',
                {
                  label: t.query('GET_LABEL_MARKUP_TOOL_ELLIPSE'),
                  icon: Cr('<g fill="currentColor"><circle cx="13" cy="13" r="9"/></g>', 26),
                },
              ],
            ];
            (t.ref.utils = g('fieldset')), (t.ref.utils.className = 'doka--markup-utils'), (t.ref.utilsList = g('ul'));
            var i = 'markup-utils-'.concat(ge());
            (t.ref.inputs = r.map(function (e) {
              var n = e[0],
                r = e[1],
                o = 'doka--markup-tool-' + ge(),
                a = g('li'),
                c = g('input');
              (c.id = o),
                (c.checked = t.query('GET_MARKUP_UTIL') === n),
                c.setAttribute('type', 'radio'),
                c.setAttribute('name', i),
                (c.value = n);
              var l = g('label');
              return (
                l.setAttribute('for', o),
                (l.className = 'doka--button-tool'),
                (l.innerHTML = r.icon + '<span>' + r.label + '</span>'),
                (l.title = r.label),
                a.appendChild(c),
                a.appendChild(l),
                t.ref.utilsList.appendChild(a),
                c
              );
            })),
              t.ref.utils.appendChild(t.ref.utilsList),
              t.ref.utilsList.addEventListener('change', function (e) {
                t.dispatch('SET_MARKUP_UTIL', { value: e.target.value });
              }),
              t.query('GET_MARKUP_ALLOW_ADD_MARKUP') &&
                (t.ref.menu = t.appendChildView(
                  t.createChildView(ki('toolbar', ['opacity'], { opacity: { type: 'spring', mass: 15, delay: 50 } }), {
                    opacity: 0,
                    element: t.ref.utils,
                  }),
                ));
            var o = (t.ref.tools = t.appendChildView(
              t.createChildView(Jo, {
                opacity: 0,
                onUpdate: function (e, n) {
                  t.dispatch('MARKUP_UPDATE', { style: e, value: n });
                },
              }),
            ));
            (t.ref.menuItemsRequiredWidth = null),
              'draw' === t.query('GET_MARKUP_UTIL') &&
                ((o.opacity = 1), (o.translateY = 0), (o.element.dataset.active = 'true'));
          },
          read: function (e) {
            var t = e.root,
              n = e.props;
            if (n.hidden) t.ref.menuItemsRequiredWidth = null;
            else {
              var r = t.rect;
              if (0 !== r.element.width && 0 !== r.element.height) {
                if (t.ref.menu && null === t.ref.menuItemsRequiredWidth) {
                  var i = t.ref.menu.rect.element.width;
                  t.ref.menuItemsRequiredWidth = 0 === i ? null : i;
                }
                var o = t.ref.menu && t.ref.menu.rect,
                  a = t.ref.tools.rect.element.height,
                  c = o ? o.element.height : a,
                  l = !o || 0 === o.element.top,
                  u = l ? r.element.top + c : r.element.top,
                  s = l ? r.element.height - c : r.element.height - c - r.element.top;
                n.stagePosition = { x: r.element.left + 20, y: u, width: r.element.width - 40, height: s - a };
              }
            }
          },
          shouldUpdateChildViews: function (e) {
            var t = e.props,
              n = e.actions;
            return !t.hidden || (t.hidden && n && n.length);
          },
          write: Z(
            {
              SHOW_VIEW: function (e) {
                var t = e.root,
                  n = e.action,
                  r = e.props;
                n.id === r.viewId
                  ? ((r.hidden = !1), (t.ref.isHiding = !1), t.ref.menu && (t.ref.menu.opacity = 1))
                  : ((t.ref.isHiding = !0),
                    t.ref.menu && (t.ref.menu.opacity = 0),
                    (t.ref.tools.opacity = 0),
                    (t.ref.tools.translateY = 5),
                    t.dispatch('SET_MARKUP_UTIL', { value: 'select' }));
              },
              MARKUP_SELECT: function (e) {
                var t = e.root,
                  n = e.action;
                (t.ref.tools.opacity = n.id ? 1 : 0),
                  (t.ref.tools.translateY = n.id ? 0 : 5),
                  (t.ref.tools.element.dataset.active = n.id ? 'true' : 'false');
              },
              DID_SET_MARKUP_UTIL: function (e) {
                var t = e.root,
                  n = e.action,
                  r = t.ref,
                  i = r.inputs,
                  o = r.tools;
                i.forEach(function (e) {
                  e.checked = e.value === n.value;
                }),
                  'draw' === n.value && ((o.opacity = 1), (o.translateY = 0), (o.element.dataset.active = 'true'));
              },
            },
            function (e) {
              var t = e.root,
                n = e.props;
              t.ref.isHiding && t.ref.menu && 0 === t.ref.menu.opacity && ((t.ref.isHiding = !1), (n.hidden = !0)),
                n.hidden ||
                  (t.ref.menu.element.dataset.layout =
                    t.ref.menuItemsRequiredWidth > t.rect.element.width ? 'compact' : 'spacious');
            },
          ),
        });
      function ta(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              na(e, t, n[t]);
            });
        }
        return e;
      }
      function na(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var ra = ['x', 'y', 'left', 'top', 'right', 'bottom'],
        ia = function () {
          return -0.5 + Math.random();
        },
        oa = function (e) {
          return (
            null !==
            e.match(
              /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
            )
          );
        },
        aa = jo(
          'sticker',
          X({
            ignoreRect: !0,
            tag: 'ul',
            name: 'sticker-list',
            create: function (e) {
              var t = e.root;
              t.element.setAttribute('role', 'list');
              var n = function (e, n) {
                var r = e.markup;
                'string' == typeof e || Array.isArray(e) ? (r = e) : e.markup || (r = e.sticker),
                  Array.isArray(e.sticker) && (r = [r[0], ta({}, e.sticker[1], r[1])]);
                var i,
                  o,
                  a = 'string' == typeof r,
                  c = a && oa(r),
                  l = a && !c,
                  u = 0,
                  s = 0,
                  f = t.query('GET_CROP_RECTANGLE_ASPECT_RATIO');
                if (l)
                  (i = 'image'),
                    (u = 0.5 * -(o = { src: r, width: 0.5, height: 0.5 * f, fit: 'contain' }).width),
                    (s = 0.5 * -o.height);
                else {
                  if ((c ? ((i = 'text'), (o = { text: r })) : ((i = r[0]), (o = ta({}, r[1]))), 'text' === i)) {
                    (o.fontColor = o.fontColor || '#000000'),
                      (o.fontSize = o.fontSize || 0.125),
                      (o.allowInput = void 0 !== o.allowInput && o.allowInput),
                      (o.allowEdit = void 0 !== o.allowEdit && o.allowEdit);
                    var d = c ? 0.75 * o.fontSize : 0.35 * o.fontSize * o.text.length;
                    (u = -0.5 * d), (s = 0.5 * (c ? d * f * 0.5 : 0.5 * o.fontSize));
                  }
                  'string' == typeof o.width ||
                    'string' == typeof o.height ||
                    ('rect' !== i && 'ellipse' !== i && 'line' !== i && 'image' !== i) ||
                    ((o.height = o.height * f), (u = 0.5 * -o.width), (s = 0.5 * -o.height));
                }
                n && ((o.x = n.x + u), (o.y = n.y + s)),
                  (function (e) {
                    return ra.every(function (t) {
                      return void 0 === e[t];
                    });
                  })(o) && ((o.x = 0.5 + 0.5 * ia() + u), (o.y = 0.5 + 0.5 * ia() + s)),
                  t.dispatch('MARKUP_ADD', [i, o]);
              };
              t.element.addEventListener('pointerdown', function (e) {
                var r = e.target.dataset.index || '';
                if (r.length) {
                  var i = t.query('GET_STICKERS')[r];
                  if (i) {
                    var o = { x: e.pageX, y: e.pageY },
                      a = Date.now();
                    document.documentElement.addEventListener('pointerup', function e(r) {
                      document.documentElement.removeEventListener('pointerup', e);
                      var c = { x: r.pageX, y: r.pageY },
                        l = ke(o, c),
                        u = Date.now() - a;
                      if (l < 10 && u < 300) n(i);
                      else {
                        var s = t.query('GET_ROOT'),
                          f = t.query('GET_STAGE'),
                          d = t.query('GET_CROP_RECT'),
                          h = void 0 !== r.offsetX ? r.offsetX : r.pageX - s.x - f.x - window.pageXOffset,
                          p = void 0 !== r.offsetY ? r.offsetY : r.pageY - s.y - f.y - window.pageYOffset,
                          g = f.x + d.x,
                          m = f.y + d.y,
                          v = (h - g) / d.width,
                          y = (p - m) / d.height;
                        v < 0 || v > 1 || y < 0 || y > 1 || n(i, { x: v, y: y });
                      }
                    });
                  }
                }
              });
              var r = function () {
                var e = t.query('GET_STICKERS');
                (t.element.innerHTML = ''),
                  e.forEach(function (e, n) {
                    var r, i;
                    'string' == typeof e || Array.isArray(e)
                      ? ((r = ''), (i = e))
                      : ((r = e.alt || ''), (i = e.sticker));
                    var o,
                      a = g('button'),
                      c = 'string' == typeof i,
                      l = c && oa(i);
                    if (c && !l) (o = new Image()).src = i;
                    else {
                      var u, s;
                      (o = g('svg', {
                        viewBox: '0 0 100 100',
                        xmlns: 'http://www.w3.org/2000/svg',
                        'xmlns:xlink': 'http://www.w3.org/1999/xlink',
                      })),
                        l ? ((u = 'text'), (s = { text: i })) : ((u = i[0]), (s = ta({}, i[1]))),
                        'text' === u && ((s.fontColor = s.fontColor || '#000000'), (s.fontSize = s.fontSize || 0.3125));
                      var f = nn(u, s);
                      rn(f, u, s, { width: 200, height: 200 }), f.removeAttribute('id');
                      var d = 'text' === u ? 6 : 0;
                      'ellipse' === u
                        ? (f.setAttribute('cx', '50'), f.setAttribute('cy', '50'))
                        : (f.setAttribute('x', 50 - 0.5 * f.getAttribute('width')),
                          f.setAttribute('y', 50 - 0.5 * f.getAttribute('height'))),
                        'text' === u &&
                          (f.setAttribute('x', '50'),
                          f.setAttribute('y', 50 + d),
                          f.setAttribute('text-anchor', 'middle'),
                          f.setAttribute('dominant-baseline', 'middle')),
                        o.appendChild(f);
                    }
                    r && (a.innerHTML = '<span>'.concat(r, '</span>')),
                      a.appendChild(o),
                      (a.dataset.index = n),
                      a.setAttribute('type', 'button'),
                      (a.className = 'doka--button doka--button-tool');
                    var h = g('li');
                    h.appendChild(a), t.element.appendChild(h);
                  });
              };
              (t.ref.updateStickers = r), r();
            },
            write: Z({
              DID_SET_STICKERS: function (e) {
                var t = e.root;
                e.action, e.props;
                t.ref.updateStickers();
              },
            }),
          }),
        );
      function ca(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var la = { crop: Ao, resize: So, filter: Wo, color: qo, markup: ea, sticker: aa },
        ua = X({
          name: 'view-stack',
          ignoreRect: !0,
          mixins: { apis: ['offsetTop'] },
          create: function (e) {
            var t = e.root;
            (t.ref.activeView = null), (t.ref.activeStagePosition = null), (t.ref.shouldFocus = !1);
          },
          write: Z(
            {
              SHOW_VIEW: function (e) {
                var t = e.root,
                  n = e.props,
                  r = e.action,
                  i = 0 === t.childViews.length,
                  o = t.childViews.find(function (e) {
                    return e.viewId === r.id;
                  });
                o ||
                  (o = t.appendChildView(
                    t.createChildView(
                      la[r.id],
                      (function (e) {
                        for (var t = 1; t < arguments.length; t++) {
                          var n = null != arguments[t] ? arguments[t] : {},
                            r = Object.keys(n);
                          'function' == typeof Object.getOwnPropertySymbols &&
                            (r = r.concat(
                              Object.getOwnPropertySymbols(n).filter(function (e) {
                                return Object.getOwnPropertyDescriptor(n, e).enumerable;
                              }),
                            )),
                            r.forEach(function (t) {
                              ca(e, t, n[t]);
                            });
                        }
                        return e;
                      })({}, n),
                    ),
                  )),
                  (t.ref.activeView = o),
                  t.childViews
                    .map(function (e) {
                      return e.element;
                    })
                    .forEach(function (e) {
                      (e.dataset.viewActive = 'false'), e.removeAttribute('tabindex'), $n() && (e.style.transform = '');
                    });
                var a = t.ref.activeView.element;
                (a.dataset.viewActive = 'true'),
                  a.setAttribute('tabindex', -1),
                  $n() &&
                    setTimeout(function () {
                      a.style.transform = 'translateZ(0)';
                    }, 32),
                  (t.ref.shouldFocus = !i);
              },
              DID_PRESENT_IMAGE: function (e) {
                var t = e.root;
                t.dispatch('CHANGE_VIEW', { id: t.query('GET_UTIL') || t.query('GET_UTILS')[0] });
              },
              DID_SET_UTILS: function (e) {
                var t = e.root;
                t.dispatch('CHANGE_VIEW', { id: t.query('GET_UTIL') || t.query('GET_UTILS')[0] });
              },
            },
            function (e) {
              var t,
                n,
                r = e.root,
                i = e.props,
                o = r.ref,
                a = o.activeView,
                c = o.previousStagePosition;
              if (
                a &&
                a.stagePosition &&
                (r.childViews.forEach(function (e) {
                  (e.offsetTop = i.offsetTop),
                    e.element.viewHidden !== e.hidden &&
                      ((e.element.viewHidden = e.hidden), (e.element.dataset.viewHidden = e.hidden));
                }),
                (t = a.stagePosition),
                (n = c),
                !t || !n || !Be(t, n))
              ) {
                var l = a.stagePosition,
                  u = l.x,
                  s = l.y,
                  f = l.width,
                  d = l.height;
                if (0 === f && 0 === d) return;
                r.dispatch('DID_RESIZE_STAGE', { offset: { x: u, y: s }, size: { width: f, height: d }, animate: !0 }),
                  (r.ref.previousStagePosition = a.stagePosition);
              }
            },
          ),
          didWriteView: function (e) {
            var t = e.root;
            t.ref.shouldFocus && (t.ref.activeView.element.focus({ preventScroll: !0 }), (t.ref.shouldFocus = !1));
          },
        }),
        sa = X({
          name: 'content',
          ignoreRect: !0,
          mixins: { styles: ['opacity'], animations: { opacity: { type: 'tween', duration: 250 } } },
          create: function (e) {
            var t = e.root,
              n = e.props;
            (t.opacity = 1),
              (t.ref.viewStack = t.appendChildView(t.createChildView(ua, { id: n.id }))),
              (t.ref.image = null);
          },
          write: Z(
            {
              DID_LOAD_IMAGE: function (e) {
                var t = e.root,
                  n = e.props;
                t.ref.image = t.appendChildView(t.createChildView(Si, { id: n.id }));
              },
            },
            function (e) {
              var t = e.root,
                n = t.ref,
                r = n.image,
                i = n.viewStack;
              if (r) {
                var o = t.rect.element.top;
                (i.offsetTop = o), (r.offsetTop = o);
              }
            },
          ),
        });
      function fa(e) {
        return (
          (function (e) {
            if (Array.isArray(e)) {
              for (var t = 0, n = new Array(e.length); t < e.length; t++) n[t] = e[t];
              return n;
            }
          })(e) ||
          (function (e) {
            if (Symbol.iterator in Object(e) || '[object Arguments]' === Object.prototype.toString.call(e))
              return Array.from(e);
          })(e) ||
          (function () {
            throw new TypeError('Invalid attempt to spread non-iterable instance');
          })()
        );
      }
      function da(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var ha = X({
          name: 'utils',
          create: function (e) {
            var t = e.root,
              n = {
                crop: {
                  title: t.query('GET_LABEL_BUTTON_UTIL_CROP'),
                  icon: Cr(
                    '<g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width="2"><path d="M23 17H9a2 2 0 0 1-2-2v-5m0-3V1"/><path d="M1 7h14a2 2 0 0 1 2 2v7m0 4v3"/></g>',
                  ),
                },
                filter: {
                  title: t.query('GET_LABEL_BUTTON_UTIL_FILTER'),
                  icon: Cr(
                    '<g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18.347 9.907a6.5 6.5 0 1 0-1.872 3.306M3.26 11.574a6.5 6.5 0 1 0 2.815-1.417"/><path d="M10.15 17.897A6.503 6.503 0 0 0 16.5 23a6.5 6.5 0 1 0-6.183-8.51"/></g>',
                  ),
                },
                color: {
                  title: t.query('GET_LABEL_BUTTON_UTIL_COLOR'),
                  icon: Cr(
                    '<g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 1v5.5m0 3.503V23M12 1v10.5m0 3.5v8M20 1v15.5m0 3.5v3M2 7h4M10 12h4M18 17h4"/></g>',
                  ),
                },
                markup: {
                  title: t.query('GET_LABEL_BUTTON_UTIL_MARKUP'),
                  icon: Cr(
                    '<g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17.086 2.914a2.828 2.828 0 1 1 4 4l-14.5 14.5-5.5 1.5 1.5-5.5 14.5-14.5z"/></g>',
                  ),
                },
                sticker: {
                  title: t.query('GET_LABEL_BUTTON_UTIL_STICKER'),
                  icon: Cr(
                    '<g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke="currentColor" stroke-width="2"><path d="M19.046 14.938a11.87 11.87 0 01-1.796 2.312C16.083 18.417 14.667 19.333 13 20H6.5A2.5 2.5 0 014 17.5v-11A2.5 2.5 0 016.5 4h11A2.5 2.5 0 0120 6.5V12" stroke-linejoin="round"/><path d="M12 20v-5.5a2.5 2.5 0 012.5-2.5h5.473"/></g>',
                  ),
                },
                resize: {
                  title: t.query('GET_LABEL_BUTTON_UTIL_RESIZE'),
                  icon: Cr(
                    '<g fill="none" fill-rule="evenodd" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="12" width="10" height="10" rx="2"/><path d="M4 11.5V4a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-5.5"/><path d="M14 10l3.365-3.365M14 6h4v4" class="doka--icon-resize-arrow-ne"/><path d="M14 10l3.365-3.365M14 6v4h4" class="doka--icon-resize-arrow-sw"/></g>',
                  ),
                },
              };
            (t.ref.utils = Object.keys(n).map(function (e) {
              return (function (e) {
                for (var t = 1; t < arguments.length; t++) {
                  var n = null != arguments[t] ? arguments[t] : {},
                    r = Object.keys(n);
                  'function' == typeof Object.getOwnPropertySymbols &&
                    (r = r.concat(
                      Object.getOwnPropertySymbols(n).filter(function (e) {
                        return Object.getOwnPropertyDescriptor(n, e).enumerable;
                      }),
                    )),
                    r.forEach(function (t) {
                      da(e, t, n[t]);
                    });
                }
                return e;
              })({ id: e }, n[e]);
            })),
              (t.ref.utilMenuRequiredWidth = null);
          },
          read: function (e) {
            var t = e.root;
            if (null === t.ref.utilMenuRequiredWidth) {
              var n = t.childViews.reduce(function (e, t) {
                return e + t.rect.outer.width;
              }, 0);
              t.ref.utilMenuRequiredWidth = 0 === n ? null : n;
            }
          },
          write: Z(
            {
              DID_SET_UTILS: function (e) {
                var t = e.root,
                  n = fa(t.query('GET_UTILS'));
                t.childViews.forEach(function (e) {
                  return t.removeChildView(e);
                }),
                  (t.element.dataset.utilCount = n.length),
                  1 === n.length && (n.length = 0),
                  n.forEach(function (e) {
                    var n = t.ref.utils.find(function (t) {
                        return t.id === e;
                      }),
                      r = t.appendChildView(
                        t.createChildView(Sr, {
                          name: 'tab',
                          view: Sr,
                          label: n.title,
                          opacity: 1,
                          icon: n.icon,
                          id: n.id,
                          action: function () {
                            return t.dispatch('CHANGE_VIEW', { id: n.id });
                          },
                        }),
                      );
                    t.ref['util_button_'.concat(n.id)] = r;
                  });
              },
              SHOW_VIEW: function (e) {
                var t = e.root,
                  n = e.action;
                t.childViews.forEach(function (e) {
                  e.element.dataset.active = e.id === n.id;
                });
              },
            },
            function (e) {
              var t = e.root,
                n = e.props,
                r = e.timestamp,
                i = t.query('GET_CROP', n.id, r);
              if (i) {
                var o,
                  a,
                  c = i.cropStatus;
                t.ref.util_button_resize &&
                  ((o = t.ref.util_button_resize),
                  (a = c.image.width ? c.image.width / c.crop.width : null),
                  (o.element.dataset.scaleDirection = null === a || a > 1 ? 'up' : 'down')),
                  (t.element.dataset.layout =
                    t.ref.utilMenuRequiredWidth > t.rect.element.width ? 'compact' : 'spacious');
              }
            },
          ),
        }),
        pa =
          W() &&
          (function () {
            try {
              var e = { antialias: !1, alpha: !1 },
                t = document.createElement('canvas');
              return (
                !!window.WebGLRenderingContext && (t.getContext('webgl', e) || t.getContext('experimental-webgl', e))
              );
            } catch (e) {
              return !1;
            }
          })();
      function ga(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              ma(e, t, n[t]);
            });
        }
        return e;
      }
      function ma(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var va = X({
          name: 'container',
          create: function (e) {
            var t = e.root,
              n = [];
            t.query('GET_ALLOW_BUTTON_RESET') &&
              n.push({
                view: Sr,
                opacity: 0,
                label: t.query('GET_LABEL_BUTTON_RESET'),
                didCreateView: function (e) {
                  return (t.ref.btnReset = e);
                },
                name: 'app action-reset icon-only',
                icon: Cr(
                  '<g fill="currentColor" fill-rule="nonzero"><path d="M6.036 13.418L4.49 11.872A.938.938 0 1 0 3.163 13.2l2.21 2.209a.938.938 0 0 0 1.326 0l2.209-2.21a.938.938 0 0 0-1.327-1.326l-1.545 1.546zM12 10.216a1 1 0 0 1 2 0V13a1 1 0 0 1-2 0v-2.784z"/><path d="M15.707 14.293a1 1 0 0 1-1.414 1.414l-2-2a1 1 0 0 1 1.414-1.414l2 2z"/><path d="M8.084 19.312a1 1 0 0 1 1.23-1.577 6 6 0 1 0-2.185-3.488 1 1 0 0 1-1.956.412 8 8 0 1 1 2.912 4.653z"/></g>',
                  26,
                ),
                action: function () {
                  return t.dispatch('EDIT_RESET');
                },
              }),
              t.query('GET_ALLOW_BUTTON_CANCEL') &&
                n.unshift({
                  view: Sr,
                  label: t.query('GET_LABEL_BUTTON_CANCEL'),
                  name: 'app action-cancel icon-fallback',
                  opacity: 1,
                  icon: Cr(
                    '<g fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6L6 18M6 6l12 12"/></g>',
                  ),
                  didCreateView: function (e) {
                    t.ref.btnCancel = e;
                  },
                  action: function () {
                    t.dispatch('EDIT_CANCEL');
                  },
                }),
              n.push({ view: ha }),
              t.query('GET_ALLOW_BUTTON_CONFIRM') &&
                n.push({
                  view: Sr,
                  label: t.query('GET_LABEL_BUTTON_CONFIRM'),
                  name: 'app action-confirm icon-fallback',
                  opacity: 1,
                  icon: Cr(
                    '<polyline fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" points="20 6 9 17 4 12"></polyline>',
                  ),
                  didCreateView: function (e) {
                    t.ref.btnConfirm = e;
                  },
                  action: function () {
                    t.dispatch('EDIT_CONFIRM');
                  },
                }),
              (t.ref.menu = t.appendChildView(t.createChildView(ki('menu'), { controls: n }))),
              (t.ref.menu.opacity = 0),
              (t.ref.status = t.appendChildView(t.createChildView(Vr))),
              (t.ref.hasWebGL = pa),
              t.ref.hasWebGL ? t.dispatch('AWAIT_IMAGE') : t.dispatch('MISSING_WEBGL'),
              (t.ref.handleFocusOut = function (e) {
                if (e.relatedTarget && jr(t.element, e.relatedTarget)) {
                  var n = t.ref.status;
                  'busy' === n.element.dataset.viewStatus && n.element.focus();
                }
              }),
              (t.ref.handleFocusIn = function (e) {
                var n = t.ref,
                  r = n.menu,
                  i = n.content,
                  o = e.target;
                if (!jr(r.element, o) && i && jr(i.element, o)) {
                  if (
                    !Array.from(t.element.querySelectorAll('[data-view-active=false]')).reduce(function (e, t) {
                      return jr(t, o) && (e = !0), e;
                    }, !1)
                  )
                    return;
                  r.element.querySelector('button,input,[tabindex]').focus();
                }
              }),
              t.element.addEventListener('focusin', t.ref.handleFocusIn),
              t.element.addEventListener('focusout', t.ref.handleFocusOut),
              (t.ref.previousState = null);
          },
          destroy: function (e) {
            var t = e.root;
            t.element.removeEventListener('focusin', t.ref.handleFocusIn),
              t.element.removeEventListener('focusout', t.ref.handleFocusOut);
          },
          write: Z(
            {
              UNLOAD_IMAGE: function (e) {
                var t = e.root;
                t.ref.content && ((t.ref.content.opacity = 0), (t.ref.menu.opacity = 0));
              },
              DID_UNLOAD_IMAGE: function (e) {
                var t = e.root;
                t.removeChildView(t.ref.content), (t.ref.content = null);
              },
              DID_LOAD_IMAGE: function (e) {
                var t = e.root,
                  n = e.props;
                t.ref.hasWebGL &&
                  ((t.ref.content = t.appendChildView(t.createChildView(sa, { opacity: null, id: n.id }))),
                  (t.ref.menu.opacity = 1));
              },
              SHOW_VIEW: function (e) {
                var t = e.root,
                  n = e.action;
                t.element.dataset.limitOverflow = 'resize' === n.id;
              },
            },
            function (e) {
              var t = e.root,
                n = e.props,
                r = e.timestamp,
                i = t.query('GET_CROP', n.id, r);
              if (i) {
                var o = i.cropStatus,
                  a = o.props,
                  c = {
                    crop: {
                      center: { x: Me(a.center.x, 5), y: Me(a.center.y, 5) },
                      rotation: Me(a.rotation, 5),
                      zoom: Me(a.zoom, 5),
                      aspectRatio: Me(a.aspectRatio, 5),
                      flip: { horizontal: a.flip.horizontal, vertical: a.flip.vertical },
                      scaleToFit: a.scaleToFit,
                      width: o.currentWidth,
                      height: o.currentHeight,
                    },
                    preview: {
                      scale:
                        i.scale /
                        Math.max(i.cropRect.width / i.previewSize.width, i.cropRect.height / i.previewSize.height),
                    },
                  };
                ya(t.ref.previousState, c) &&
                  (t.dispatch('DID_UPDATE', { state: ga({}, c) }), (t.ref.previousState = c));
                var l = t.ref,
                  u = l.btnReset,
                  s = l.btnCancel,
                  f = l.content,
                  d = i.canReset;
                if ((u && (u.opacity = d ? 1 : 0), s && t.query('GET_UTILS').length > 1 && u)) {
                  var h = t.query('GET_ROOT_SIZE');
                  s.opacity = d && h.width <= 600 ? 0 : 1;
                }
                f && 0 === f.opacity && t.dispatch('DID_UNLOAD_IMAGE');
              }
            },
          ),
        }),
        ya = function (e, t) {
          if (!e) return !0;
          var n = e.crop,
            r = t.crop;
          return (
            n.width !== r.width ||
            n.height !== r.height ||
            n.center.x !== r.center.x ||
            n.center.y !== r.center.y ||
            n.rotation !== r.rotation ||
            n.scaleToFit !== r.scaleToFit ||
            n.zoom !== r.zoom ||
            n.aspectRatio !== r.aspectRatio ||
            n.flip.horizontal !== r.flip.horizontal ||
            n.flip.vertical !== r.flip.vertical
          );
        };
      function Ea(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var wa = function (e) {
          'gesturestart' !== e.type
            ? so(e.target, function (e) {
                return e.isScrollContainer;
              }) || e.preventDefault()
            : e.preventDefault();
        },
        _a = X({
          name: 'editor',
          ignoreRect: !0,
          mixins: {
            styles: ['opacity'],
            animations: { opacity: { type: 'tween', duration: 350 } },
            apis: ['markedForRemoval'],
          },
          create: function (e) {
            var t = e.root,
              n = e.props;
            (n.markedForRemoval = !1),
              xe() &&
                (t.element.addEventListener('touchmove', wa, { passive: !1 }),
                t.element.addEventListener('gesturestart', wa)),
              (t.ref.pointerPolyfill = (function (e) {
                var t = { destroy: function () {} };
                if ('onpointerdown' in window || e.pointersPolyfilled) return t;
                e.pointersPolyfilled = !0;
                var n = 0,
                  r = [],
                  i = function (e, t, n) {
                    var r = new UIEvent(t.type, { view: window, bubbles: !n });
                    Object.keys(t).forEach(function (e) {
                      Object.defineProperty(r, e, { value: t[e], writable: !1 });
                    }),
                      e.dispatchEvent(r);
                  },
                  o = function (e, t, o) {
                    return Array.from(t.changedTouches).map(function (a) {
                      var c = r[a.identifier],
                        l = {
                          type: e,
                          pageX: a.pageX,
                          pageY: a.pageY,
                          pointerId: a.identifier,
                          isPrimary: c ? c.isPrimary : 0 === n,
                          preventDefault: function () {
                            return t.preventDefault();
                          },
                        };
                      return i(a.target, l, o), l;
                    });
                  },
                  a = function (e) {
                    o('pointerdown', e).forEach(function (e) {
                      (r[e.pointerId] = e), n++;
                    });
                  },
                  c = function (e) {
                    o('pointermove', e);
                  },
                  l = function (e) {
                    o('pointerup', e).forEach(function (e) {
                      delete r[e.pointerId], n--;
                    });
                  },
                  u = function (e, t, n) {
                    var r = {
                      type: e,
                      pageX: t.pageX,
                      pageY: t.pageY,
                      pointerId: 0,
                      isPrimary: !0,
                      preventDefault: function () {
                        return t.preventDefault();
                      },
                    };
                    return i(t.target, r, n), r;
                  },
                  s = function (e) {
                    u('pointerdown', e);
                  },
                  f = function (e) {
                    u('pointermove', e);
                  },
                  d = function (e) {
                    u('pointerup', e);
                  };
                return (
                  'ontouchstart' in window
                    ? (e.addEventListener('touchstart', a),
                      e.addEventListener('touchmove', c),
                      e.addEventListener('touchend', l))
                    : 'onmousedown' in window &&
                      (e.addEventListener('mousedown', s),
                      e.addEventListener('mousemove', f),
                      e.addEventListener('mouseup', d)),
                  (t.destroy = function () {
                    (r.length = 0),
                      (e.pointersPolyfilled = !1),
                      e.removeEventListener('touchstart', a),
                      e.removeEventListener('touchmove', c),
                      e.removeEventListener('touchend', l),
                      e.removeEventListener('mousedown', s),
                      e.removeEventListener('mousemove', f),
                      e.removeEventListener('mouseup', d);
                  }),
                  t
                );
              })('root' === t.query('GET_POINTER_EVENTS_POLYFILL_SCOPE') ? t.element : document.documentElement)),
              t.appendChildView(
                t.createChildView(
                  va,
                  (function (e) {
                    for (var t = 1; t < arguments.length; t++) {
                      var n = null != arguments[t] ? arguments[t] : {},
                        r = Object.keys(n);
                      'function' == typeof Object.getOwnPropertySymbols &&
                        (r = r.concat(
                          Object.getOwnPropertySymbols(n).filter(function (e) {
                            return Object.getOwnPropertyDescriptor(n, e).enumerable;
                          }),
                        )),
                        r.forEach(function (t) {
                          Ea(e, t, n[t]);
                        });
                    }
                    return e;
                  })({}, n),
                ),
              );
          },
          destroy: function (e) {
            var t = e.root;
            t.ref.pointerPolyfill.destroy(),
              t.element.removeEventListener('touchmove', wa, !0),
              t.element.removeEventListener('gesturestart', wa);
          },
        });
      function Ta(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var ba = function () {
        function e() {
          t.fire('touch-detected'), window.removeEventListener('touchstart', e, !1);
        }
        var t = (function (e) {
          for (var t = 1; t < arguments.length; t++) {
            var n = null != arguments[t] ? arguments[t] : {},
              r = Object.keys(n);
            'function' == typeof Object.getOwnPropertySymbols &&
              (r = r.concat(
                Object.getOwnPropertySymbols(n).filter(function (e) {
                  return Object.getOwnPropertyDescriptor(n, e).enumerable;
                }),
              )),
              r.forEach(function (t) {
                Ta(e, t, n[t]);
              });
          }
          return e;
        })({}, me(), {
          destroy: function () {
            window.removeEventListener('touchstart', e, !1);
          },
        });
        return window.addEventListener('touchstart', e, !1), t;
      };
      function Aa(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var xa = function (e) {
          var t,
            n = { browseEnabled: !1 },
            r = function () {
              t.files.length && i.fire('drop', Array.from(t.files));
            },
            i = (function (e) {
              for (var t = 1; t < arguments.length; t++) {
                var n = null != arguments[t] ? arguments[t] : {},
                  r = Object.keys(n);
                'function' == typeof Object.getOwnPropertySymbols &&
                  (r = r.concat(
                    Object.getOwnPropertySymbols(n).filter(function (e) {
                      return Object.getOwnPropertyDescriptor(n, e).enumerable;
                    }),
                  )),
                  r.forEach(function (t) {
                    Aa(e, t, n[t]);
                  });
              }
              return e;
            })({}, me(), {
              enableBrowse: function () {
                n.browseEnabled ||
                  (((t = document.createElement('input')).style.display = 'none'),
                  t.setAttribute('type', 'file'),
                  t.addEventListener('change', r),
                  e.appendChild(t),
                  e.addEventListener('click', o),
                  (n.browseEnabled = !0));
              },
              disableBrowse: function () {
                n.browseEnabled &&
                  (t.removeEventListener('change', r),
                  t.parentNode.removeChild(t),
                  e.removeEventListener('click', o),
                  (n.browseEnabled = !1));
              },
              destroy: function () {
                e.removeEventListener('dragover', a),
                  e.removeEventListener('drop', c),
                  e.removeEventListener('click', o),
                  t && t.removeEventListener('change', r);
              },
            }),
            o = function () {
              return t.click();
            },
            a = function (e) {
              return e.preventDefault();
            },
            c = function (e) {
              e.preventDefault();
              var t = Array.from(e.dataTransfer.items || e.dataTransfer.files).map(function (e) {
                return e.getAsFile && 'file' === e.kind ? e.getAsFile() : e;
              });
              i.fire('drop', t);
            };
          return e.addEventListener('dragover', a), e.addEventListener('drop', c), i;
        },
        Oa = function (e) {
          return e.ref.isFullscreen;
        },
        Ra = function (e) {
          return /fullscreen/.test(e.query('GET_STYLE_LAYOUT_MODE'));
        },
        Ia = function (e) {
          return /fullscreen|preview/.test(e.query('GET_STYLE_LAYOUT_MODE'));
        },
        Ca = function (e) {
          return /modal/.test(e.query('GET_STYLE_LAYOUT_MODE'));
        },
        Sa = function (e) {
          return e.query('GET_ALLOW_AUTO_CLOSE');
        },
        Ma = Ia,
        La = Ia,
        Pa = function (e) {
          var t = e.ref,
            n = t.environment,
            r = t.isSingleUtil,
            i = t.canBeControlled;
          e.element.dataset.styleViewport =
            Va(e.rect.element.width, e.rect.element.height) +
            ' ' +
            n.join(' ') +
            (r ? ' single-util' : ' multi-util') +
            (i ? ' flow-controls' : ' no-flow-controls');
        },
        ka = function (e) {
          var t = e.touches ? e.touches[0] : e;
          (t.pageX > 10 && t.pageX < window.innerWidth - 10) || e.preventDefault();
        },
        Ga = function (e) {
          var t = e.element,
            n = e.ref,
            r = n.handleFullscreenUpdate,
            i = n.handleEscapeKey;
          t.setAttribute('tabindex', -1),
            r(),
            (e.ref.focusTrap = (function (e) {
              var t = function (t) {
                if (9 === t.keyCode) {
                  var n = Array.from(e.querySelectorAll('button,input,[tabindex]')).filter(function (e) {
                      return 'hidden' !== e.style.visibility && -1 !== e.tabIndex;
                    }),
                    r = n[0],
                    i = n[n.length - 1];
                  t.shiftKey
                    ? document.activeElement === r && (i.focus(), t.preventDefault())
                    : document.activeElement === i && (r.focus(), t.preventDefault());
                }
              };
              return (
                e.addEventListener('keydown', t),
                {
                  destroy: function () {
                    e.removeEventListener('keydown', t);
                  },
                }
              );
            })(t)),
            t.addEventListener('keydown', i),
            xe() && t.addEventListener('touchstart', ka),
            window.addEventListener('resize', r),
            window.innerWidth - document.documentElement.clientWidth > 0 && document.body.classList.add('doka--parent'),
            document.body.appendChild(t);
          var o = document.querySelector('meta[name=viewport]');
          (e.ref.defaultViewportContent = o ? o.getAttribute('content') : null),
            o || ((o = document.createElement('meta')).setAttribute('name', 'viewport'), document.head.appendChild(o)),
            o.setAttribute(
              'content',
              'width=device-width, height=device-height, initial-scale=1, maximum-scale=1, user-scalable=0',
            ),
            (e.opacity = 1),
            jr(e.element, document.activeElement) || t.focus(),
            e.dispatch('INVALIDATE_VIEWPORT'),
            (e.ref.isFullscreen = !0);
        },
        Da = function (e) {
          var t = e.element,
            n = e.ref,
            r = n.handleFullscreenUpdate,
            i = n.focusTrap,
            o = n.handleEscapeKey;
          t.removeAttribute('tabindex'),
            i.destroy(),
            t.removeEventListener('keydown', o),
            xe() && t.removeEventListener('touchstart', ka),
            window.removeEventListener('resize', r),
            document.body.classList.remove('doka--parent');
          var a = document.querySelector('meta[name=viewport]');
          e.ref.defaultViewportContent
            ? (a.setAttribute('content', e.ref.defaultViewportContent), (e.ref.defaultViewportContent = null))
            : a.parentNode.removeChild(a),
            (e.ref.isFullscreen = !1);
        },
        Ua = X({
          name: 'root',
          ignoreRect: !0,
          mixins: { styles: ['opacity'], animations: { opacity: { type: 'tween', duration: 350 } } },
          create: function (e) {
            var t = e.root,
              n = e.props;
            t.element.id = t.query('GET_ID') || 'doka-'.concat(n.id);
            var r = t.query('GET_CLASS_NAME');
            r && t.element.classList.add(r),
              (t.ref.environment = []),
              (t.ref.shouldBeDestroyed = !1),
              (t.ref.isClosing = !1),
              (t.ref.isClosed = !1),
              (t.ref.isFullscreen = !1),
              t.query('GET_ALLOW_DROP_FILES') &&
                ((t.ref.catcher = xa(t.element)),
                t.ref.catcher.on('drop', function (e) {
                  e.forEach(function (e) {
                    t.dispatch('REQUEST_LOAD_IMAGE', { source: e });
                  });
                })),
              (t.ref.touchDetector = ba()),
              t.ref.touchDetector.onOnce('touch-detected', function () {
                t.ref.environment.push('touch');
              }),
              (t.ref.editor = t.appendChildView(t.createChildView(_a, { id: n.id }))),
              t
                .query('GET_STYLES')
                .filter(function (e) {
                  return !Q(e.value);
                })
                .map(function (e) {
                  var n = e.name,
                    r = e.value;
                  t.element.dataset[n] = r;
                }),
              (t.ref.updateViewport = function () {
                t.dispatch('INVALIDATE_VIEWPORT');
              }),
              window.addEventListener('resize', t.ref.updateViewport),
              window.addEventListener('scroll', t.ref.updateViewport),
              (t.ref.isSingleUtil = 1 === t.query('GET_UTILS').length),
              (t.ref.canBeControlled = t.query('GET_ALLOW_BUTTON_CONFIRM') || t.query('GET_ALLOW_BUTTON_CANCEL')),
              Pa(t);
            var i = document.createElement('div');
            (i.style.cssText = 'position:fixed;height:100vh;top:0;'),
              (t.ref.measure = i),
              document.body.appendChild(i),
              (t.ref.handleEscapeKey = function (e) {
                27 === e.keyCode && t.dispatch('EDIT_CANCEL');
              }),
              (t.ref.initialScreenMeasureHeight = null),
              (t.ref.handleFullscreenUpdate = function () {
                t.element.dataset.styleFullscreen = window.innerHeight === t.ref.initialScreenMeasureHeight;
              }),
              (t.ref.clientRect = { left: 0, top: 0 }),
              Ca(t) &&
                ((t.ref.handleModalTap = function (e) {
                  e.target === t.element && t.dispatch('EDIT_CANCEL');
                }),
                t.element.addEventListener('pointerdown', t.ref.handleModalTap));
          },
          read: function (e) {
            var t = e.root,
              n = t.ref.measure;
            n &&
              ((t.ref.initialScreenMeasureHeight = n.offsetHeight),
              n.parentNode.removeChild(n),
              (t.ref.measure = null)),
              (t.ref.clientRect = t.element.getBoundingClientRect()),
              (t.ref.clientRect.leftScroll = t.ref.clientRect.left + (window.scrollX || window.pageXOffset)),
              (t.ref.clientRect.topScroll = t.ref.clientRect.top + (window.scrollY || window.pageYOffset));
          },
          write: Z(
            {
              ENTER_FULLSCREEN: function (e) {
                var t = e.root;
                Ga(t);
              },
              EXIT_FULLSCREEN: function (e) {
                var t = e.root;
                Da(t);
              },
              SHOW_VIEW: function (e) {
                var t = e.root,
                  n = e.action;
                t.element.dataset.view = n.id;
              },
              DID_SET_STYLE_LAYOUT_MODE: function (e) {
                var t = e.root,
                  n = e.action;
                (t.element.dataset.styleLayoutMode = n.value || 'none'),
                  /fullscreen/.test(n.value) && !/fullscreen/.test(n.prevValue) && t.dispatch('ENTER_FULLSCREEN');
              },
              AWAITING_IMAGE: function (e) {
                var t = e.root;
                t.ref.catcher && t.query('GET_ALLOW_BROWSE_FILES') && t.ref.catcher.enableBrowse();
              },
              DID_REQUEST_LOAD_IMAGE: function (e) {
                var t = e.root;
                if (
                  (t.ref.catcher && t.query('GET_ALLOW_BROWSE_FILES') && t.ref.catcher.disableBrowse(),
                  0 === t.opacity && (t.opacity = 1),
                  (t.ref.isClosing = !1),
                  (t.ref.isClosed = !1),
                  !Ra(t) || Oa(t))
                ) {
                  var n = t.query('GET_STYLE_LAYOUT_MODE');
                  (null !== n && 'modal' !== n) ||
                    t.element.parentNode ||
                    t.dispatch('SET_STYLE_LAYOUT_MODE', { value: ('fullscreen ' + (n || '')).trim() });
                } else t.dispatch('ENTER_FULLSCREEN');
              },
              DID_CANCEL: function (e) {
                var t = e.root;
                Ma(t) && Sa(t) && t.dispatch('EDIT_CLOSE');
              },
              DID_CONFIRM: function (e) {
                var t = e.root;
                Ma(t) && Sa(t) && t.dispatch('EDIT_CLOSE');
              },
              EDIT_CLOSE: function (e) {
                var t = e.root;
                La(t) &&
                  ((t.opacity = t.opacity || 1),
                  (t.opacity = 0),
                  (t.ref.isClosed = !1),
                  (t.ref.isClosing = !0),
                  t.query('GET_ALLOW_AUTO_DESTROY') && (t.ref.shouldBeDestroyed = !0),
                  Oa(t) && t.dispatch('EXIT_FULLSCREEN'));
              },
              DID_SET_UTILS: function (e) {
                var t = e.root;
                t.ref.isSingleUtil = 1 === t.query('GET_UTILS').length;
              },
            },
            function (e) {
              var t = e.root;
              Pa(t);
              var n = t.query('GET_ROOT'),
                r = t.rect.element;
              (n.width === r.width &&
                n.height === r.height &&
                n.y === t.ref.clientRect.top &&
                n.topScroll === t.ref.clientRect.topScroll) ||
                t.dispatch('UPDATE_ROOT_RECT', {
                  rect: {
                    x: t.ref.clientRect.left,
                    y: t.ref.clientRect.top,
                    left: t.ref.editor.rect.element.left,
                    top: t.ref.editor.rect.element.top,
                    leftScroll: t.ref.clientRect.leftScroll,
                    topScroll: t.ref.clientRect.topScroll,
                    width: t.rect.element.width,
                    height: t.rect.element.height,
                  },
                });
            },
          ),
          didWriteView: function (e) {
            var t = e.root,
              n = t.ref,
              r = n.isClosed,
              i = n.isClosing,
              o = n.shouldBeDestroyed;
            !r &&
              i &&
              0 === t.opacity &&
              (t.dispatch('DID_CLOSE'),
              (t.ref.isClosed = !0),
              (t.ref.isClosing = !1),
              Ra(t) && t.element.parentNode && document.body.removeChild(t.element),
              o && t.dispatch('EDIT_DESTROY'));
          },
          destroy: function (e) {
            var t = e.root;
            Oa(t) && Da(t),
              Ca(t) && t.element.removeEventListener('pointerdown', t.ref.handleModalTap),
              Ra(t) && t.element.parentNode && document.body.removeChild(t.element),
              window.removeEventListener('resize', t.ref.updateViewport),
              t.ref.touchDetector.destroy(),
              t.ref.catcher && t.ref.catcher.destroy();
          },
        }),
        Va = function (e, t) {
          var n = '';
          return 0 === e && 0 === t
            ? 'detached'
            : ((n += t > e ? 'portrait' : 'landscape'),
              (n += e <= 600 ? ' x-cramped' : e <= 1e3 ? ' x-comfortable' : ' x-spacious').trim());
        };
      function Ba(e) {
        for (var t = 1; t < arguments.length; t++) {
          var n = null != arguments[t] ? arguments[t] : {},
            r = Object.keys(n);
          'function' == typeof Object.getOwnPropertySymbols &&
            (r = r.concat(
              Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }),
            )),
            r.forEach(function (t) {
              za(e, t, n[t]);
            });
        }
        return e;
      }
      function za(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var Na = function () {
        var e,
          t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
          n = Re(),
          o = (function (e) {
            var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : [],
              n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [],
              r = c({}, e),
              i = [],
              o = [],
              a = function (e, t, n) {
                n ? o.push({ type: e, data: t }) : (f[e] && f[e](t), i.push({ type: e, data: t }));
              },
              l = function (e) {
                for (var t, n = arguments.length, r = new Array(n > 1 ? n - 1 : 0), i = 1; i < n; i++)
                  r[i - 1] = arguments[i];
                return s[e] ? (t = s)[e].apply(t, r) : null;
              },
              u = {
                getState: function () {
                  return c({}, r);
                },
                processActionQueue: function () {
                  var e = [].concat(i);
                  return (i.length = 0), e;
                },
                processDispatchQueue: function () {
                  var e = [].concat(o);
                  (o.length = 0),
                    e.forEach(function (e) {
                      var t = e.type,
                        n = e.data;
                      a(t, n);
                    });
                },
                dispatch: a,
                query: l,
              },
              s = {};
            t.forEach(function (e) {
              s = c({}, e(r), s);
            });
            var f = {};
            return (
              n.forEach(function (e) {
                f = c({}, e(a, l, r), f);
              }),
              u
            );
          })(((e = { noImageTimeout: null, options: se(n) }), fe(e), e), [At, pe(n)], [Ir, he(n)]);
        o.dispatch('SET_OPTIONS', { options: t });
        var l = function () {
          document.hidden || o.dispatch('KICK');
        };
        document.addEventListener('visibilitychange', l);
        var f = ge();
        o.dispatch('SET_UID', { id: f });
        var d = null,
          h = Ua(o, { id: f }),
          p = !1,
          g = {
            _read: function () {
              p || h._read();
            },
            _write: function (e) {
              var t = o.processActionQueue().filter(function (e) {
                return !/^SET_/.test(e.type);
              });
              (p && !t.length) ||
                (y(t),
                (p = h._write(e, t)) && o.processDispatchQueue(),
                t.find(function (e) {
                  return 'EDIT_DESTROY' === e.type;
                }) && E());
            },
          },
          m = function (e) {
            return function (t) {
              var n = { type: e };
              return t
                ? (t.hasOwnProperty('error') && (n.error = a(t.error) ? Ba({}, t.error) : t.error || null),
                  t.hasOwnProperty('output') && (n.output = t.output),
                  t.hasOwnProperty('image') && (n.image = t.image),
                  t.hasOwnProperty('source') && (n.source = t.source),
                  t.hasOwnProperty('state') && (n.state = t.state),
                  n)
                : n;
            };
          },
          v = {
            DID_CONFIRM: m('confirm'),
            DID_CANCEL: m('cancel'),
            DID_REQUEST_LOAD_IMAGE: m('loadstart'),
            DID_LOAD_IMAGE: m('load'),
            DID_LOAD_IMAGE_ERROR: m('loaderror'),
            DID_SHOW_IMAGE: m('ready'),
            DID_UPDATE: m('update'),
            DID_CLOSE: m('close'),
            DID_DESTROY: m('destroy'),
            DID_INIT: m('init'),
          },
          y = function (e) {
            e.length &&
              e.forEach(function (e) {
                if (v[e.type]) {
                  var t = v[e.type];
                  (Array.isArray(t) ? t : [t]).forEach(function (t) {
                    setTimeout(function () {
                      !(function (e) {
                        var t = Ba({ doka: w }, e);
                        delete t.type,
                          h &&
                            h.element.dispatchEvent(
                              new CustomEvent('Doka:'.concat(e.type), {
                                detail: t,
                                bubbles: !0,
                                cancelable: !0,
                                composed: !0,
                              }),
                            );
                        var n = [];
                        e.hasOwnProperty('error') && n.push(e.error);
                        var r = ['type', 'error'];
                        Object.keys(e)
                          .filter(function (e) {
                            return !r.includes(e);
                          })
                          .forEach(function (t) {
                            return n.push(e[t]);
                          }),
                          w.fire.apply(w, [e.type].concat(n));
                        var i = o.query('GET_ON'.concat(e.type.toUpperCase()));
                        i && i.apply(void 0, n);
                      })(t(e.data));
                    }, 0);
                  });
                }
              });
          },
          E = function () {
            w.fire('destroy', h.element),
              document.removeEventListener('visibilitychange', l),
              h._destroy(),
              o.dispatch('DID_DESTROY');
          },
          w = Ba(
            {},
            me(),
            g,
            (function (e, t) {
              var n = {};
              return (
                u(t, function (r) {
                  var i = te(t[r]) ? t[r] : r;
                  n[r] = {
                    get: function () {
                      return e.getState().options[i];
                    },
                    set: function (t) {
                      e.dispatch('SET_'.concat(de(i, '_').toUpperCase()), { value: t });
                    },
                  };
                }),
                n
              );
            })(o, n),
            {
              setOptions: function (e) {
                return o.dispatch('SET_OPTIONS', { options: e });
              },
              setData: function (e) {
                o.dispatch('SET_DATA', e);
              },
              getData: function (e) {
                return new Promise(function (t, n) {
                  o.dispatch('GET_DATA', Ba({}, e, { success: t, failure: n }));
                });
              },
              open: function (e) {
                var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
                return new Promise(function (n, r) {
                  e &&
                    o.dispatch('REQUEST_LOAD_IMAGE', {
                      source: e,
                      options: t,
                      success: n,
                      failure: r,
                      resolveOnConfirm: !!t && t.resolveOnConfirm,
                    });
                });
              },
              edit: function (e, t) {
                return w.open(e, Ba({}, t, { resolveOnConfirm: !0 }));
              },
              save: function (e) {
                return new Promise(function (t, n) {
                  o.dispatch('GET_DATA', Ba({}, e, { success: t, failure: n }));
                });
              },
              clear: function () {
                return o.dispatch('REQUEST_REMOVE_IMAGE');
              },
              close: function () {
                return o.dispatch('EDIT_CLOSE');
              },
              interact: function (e, t) {
                'scale' === e && o.dispatch('CROP_IMAGE_RESIZE_SET', { value: t });
              },
              interactEnd: function (e) {
                'scale' === e && o.dispatch('CROP_IMAGE_RESIZE_RELEASE');
              },
              destroy: E,
              insertBefore: function (e) {
                r(h.element, e);
              },
              insertAfter: function (e) {
                i(h.element, e);
              },
              appendTo: function (e) {
                e.appendChild(h.element);
              },
              replaceElement: function (e) {
                r(h.element, e), e.parentNode.removeChild(e), (d = e);
              },
              restoreElement: function () {
                d && (i(d, h.element), h.element.parentNode.removeChild(h.element), (d = null));
              },
              isAttachedTo: function (e) {
                return !!h && (h.element === e || d === e);
              },
              element: {
                get: function () {
                  return h ? h.element : null;
                },
              },
            },
          );
        return o.dispatch('DID_INIT'), s(w);
      };
      function Fa(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var ja = function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
            t = Re(),
            n = {};
          return (
            u(t, function (e, t) {
              te(t) || (n[e] = t[0]);
            }),
            Na(
              (function (e) {
                for (var t = 1; t < arguments.length; t++) {
                  var n = null != arguments[t] ? arguments[t] : {},
                    r = Object.keys(n);
                  'function' == typeof Object.getOwnPropertySymbols &&
                    (r = r.concat(
                      Object.getOwnPropertySymbols(n).filter(function (e) {
                        return Object.getOwnPropertyDescriptor(n, e).enumerable;
                      }),
                    )),
                    r.forEach(function (t) {
                      Fa(e, t, n[t]);
                    });
                }
                return e;
              })({}, n, e),
            )
          );
        },
        Wa = function (e) {
          return (function (e) {
            var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : '-';
            return e.replace(new RegExp(''.concat(t, '.'), 'g'), function (e) {
              return e.charAt(1).toUpperCase();
            });
          })(e.replace(/^data-/, ''));
        },
        qa = function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
            n = [];
          u(e.attributes, function (t) {
            return n.push(e.attributes[t]);
          });
          var r = n
            .filter(function (e) {
              return e.name;
            })
            .reduce(function (t, n) {
              var r = f(e, n.name);
              return (t[Wa(n.name)] = r === n.name || r), t;
            }, {});
          return (
            (function e(t, n) {
              u(n, function (n, r) {
                u(t, function (e, i) {
                  var o = new RegExp(n);
                  if (o.test(e) && (delete t[e], !1 !== r))
                    if (te(r)) t[r] = i;
                    else {
                      var c,
                        l = r.group;
                      a(r) && !t[l] && (t[l] = {}),
                        (t[l][((c = e.replace(o, '')), c.charAt(0).toLowerCase() + c.slice(1))] = i);
                    }
                }),
                  r.mapping && e(t[r.group], r.mapping);
              });
            })(r, t),
            r
          );
        };
      function Ha(e, t, n) {
        return (
          t in e
            ? Object.defineProperty(e, t, { value: n, enumerable: !0, configurable: !0, writable: !0 })
            : (e[t] = n),
          e
        );
      }
      var Ya = function (e) {
        var t = (function (e) {
            for (var t = 1; t < arguments.length; t++) {
              var n = null != arguments[t] ? arguments[t] : {},
                r = Object.keys(n);
              'function' == typeof Object.getOwnPropertySymbols &&
                (r = r.concat(
                  Object.getOwnPropertySymbols(n).filter(function (e) {
                    return Object.getOwnPropertyDescriptor(n, e).enumerable;
                  }),
                )),
                r.forEach(function (t) {
                  Ha(e, t, n[t]);
                });
            }
            return e;
          })({}, arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {}),
          n = qa(e, { '^class$': 'className' });
        Object.keys(n).forEach(function (e) {
          a(n[e]) ? (a(t[e]) || (t[e] = {}), Object.assign(t[e], n[e])) : (t[e] = n[e]);
        }),
          ('CANVAS' !== e.nodeName && 'IMG' !== e.nodeName) || (t.src = e.dataset.dokaSrc ? e.dataset.dokaSrc : e);
        var r = ja(t);
        return r.replaceElement(e), r;
      };
      function Xa(e) {
        return (
          (function (e) {
            if (Array.isArray(e)) {
              for (var t = 0, n = new Array(e.length); t < e.length; t++) n[t] = e[t];
              return n;
            }
          })(e) ||
          (function (e) {
            if (Symbol.iterator in Object(e) || '[object Arguments]' === Object.prototype.toString.call(e))
              return Array.from(e);
          })(e) ||
          (function () {
            throw new TypeError('Invalid attempt to spread non-iterable instance');
          })()
        );
      }
      var Za = function () {
          for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++) t[n] = arguments[n];
          return t[0] instanceof HTMLElement
            ? Ya.apply(void 0, t)
            : ja.apply(
                void 0,
                Xa(
                  t.filter(function (e) {
                    return e;
                  }),
                ),
              );
        },
        Ka = ['fire', '_read', '_write'],
        Qa = function (e) {
          var t,
            n,
            r,
            i = {};
          return (
            (t = e),
            (n = i),
            (r = Ka),
            Object.getOwnPropertyNames(t)
              .filter(function (e) {
                return !r.includes(e);
              })
              .forEach(function (e) {
                return Object.defineProperty(n, e, Object.getOwnPropertyDescriptor(t, e));
              }),
            i
          );
        };
      n.d(t, 'supported', function () {
        return $a;
      }),
        n.d(t, 'OptionTypes', function () {
          return nc;
        }),
        n.d(t, 'create', function () {
          return rc;
        }),
        n.d(t, 'destroy', function () {
          return ic;
        }),
        n.d(t, 'parse', function () {
          return oc;
        }),
        n.d(t, 'find', function () {
          return ac;
        }),
        n.d(t, 'getOptions', function () {
          return cc;
        }),
        n.d(t, 'setOptions', function () {
          return lc;
        });
      var Ja,
        $a =
          ((Ja =
            W() &&
            !('[object OperaMini]' === Object.prototype.toString.call(window.operamini)) &&
            'visibilityState' in document &&
            'Promise' in window &&
            'slice' in Blob.prototype &&
            'URL' in window &&
            'createObjectURL' in window.URL &&
            'performance' in window),
          function () {
            return Ja;
          }),
        ec = { apps: [] },
        tc = function () {},
        nc = {},
        rc = tc,
        ic = tc,
        oc = tc,
        ac = tc,
        cc = tc,
        lc = tc;
      if ($a()) {
        !(function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 60,
            r = '__framePainter';
          if (window[r]) return window[r].readers.push(e), void window[r].writers.push(t);
          window[r] = { readers: [e], writers: [t] };
          var i = window[r],
            o = 1e3 / n,
            a = null,
            c = null,
            l = null,
            u = null,
            s = function () {
              document.hidden
                ? ((l = function () {
                    return window.setTimeout(function () {
                      return f(performance.now());
                    }, o);
                  }),
                  (u = function () {
                    return window.clearTimeout(c);
                  }))
                : ((l = function () {
                    return window.requestAnimationFrame(f);
                  }),
                  (u = function () {
                    return window.cancelAnimationFrame(c);
                  }));
            };
          document.addEventListener('visibilitychange', function () {
            u && u(), s(), f(performance.now());
          });
          var f = function e(t) {
            (c = l(e)), a || (a = t);
            var n = t - a;
            n <= o ||
              ((a = t - (n % o)),
              i.readers.forEach(function (e) {
                return e();
              }),
              i.writers.forEach(function (e) {
                return e(t);
              }));
          };
          s(), f(performance.now());
        })(
          function () {
            ec.apps.forEach(function (e) {
              return e._read();
            });
          },
          function (e) {
            ec.apps.forEach(function (t) {
              return t._write(e);
            });
          },
        );
        var uc = function e() {
          document.dispatchEvent(
            new CustomEvent('doka:loaded', {
              detail: { supported: $a, create: rc, destroy: ic, parse: oc, find: ac, setOptions: lc },
            }),
          ),
            document.removeEventListener('DOMContentLoaded', e);
        };
        'loading' !== document.readyState
          ? setTimeout(function () {
              return uc();
            }, 0)
          : document.addEventListener('DOMContentLoaded', uc);
        (nc = {}),
          u(Re(), function (e, t) {
            nc[e] = t[1];
          }),
          (rc = function () {
            var e = Za.apply(void 0, arguments);
            return e.on('destroy', ic), ec.apps.push(e), Qa(e);
          }),
          (ic = function (e) {
            var t = ec.apps.findIndex(function (t) {
              return t.isAttachedTo(e);
            });
            return t >= 0 && (ec.apps.splice(t, 1)[0].restoreElement(), !0);
          }),
          (oc = function (e) {
            return Array.from(e.querySelectorAll('.'.concat('doka')))
              .filter(function (e) {
                return !ec.apps.find(function (t) {
                  return t.isAttachedTo(e);
                });
              })
              .map(function (e) {
                return rc(e);
              });
          }),
          (ac = function (e) {
            var t = ec.apps.find(function (t) {
              return t.isAttachedTo(e);
            });
            return t ? Qa(t) : null;
          }),
          (cc = function () {
            var e = {};
            return (
              u(Re(), function (t, n) {
                e[t] = n[0];
              }),
              e
            );
          }),
          (lc = function (e) {
            return (
              a(e) &&
                (ec.apps.forEach(function (t) {
                  t.setOptions(e);
                }),
                (function (e) {
                  u(e, function (e, t) {
                    Ce[e] && Ie(e, t);
                  });
                })(e)),
              cc()
            );
          });
      }
    },
  ]);
});
